import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

export const generateMetaFields = (meta: any, restaurant: NonNullable<ReturnType<typeof useRestaurant>['restaurant']>) => {
  const locations = restaurant.locations as NonNullable<typeof restaurant.locations>;
  const name = meta.title || restaurant.name;
  const locationString = locations.length === 1 && locations[0] ? ` | Restaurant in ${locations[0].city}, ${locations[0].state}` : '';
  const title = `${name}${locationString}`;
  const locationsInCitiesStr = locations?.length > 0
    ? `Locations in ${locations.slice(0, 3)
      .map(loc => `${loc.city}, ${loc.state}`)
      .join(', ')}`
    : '';
  const backupDescription = `See our latest menu, find our hours, and order online directly from us. ${locationsInCitiesStr || ''}`;
  const description = meta.description || backupDescription;
  return { title, description };
};

export default generateMetaFields;
