import React, { useMemo, useReducer } from 'react';
import Skeleton from 'react-loading-skeleton';

import classnames from 'classnames';
import ColorContrastChecker from 'color-contrast-checker';

import { TimeBasedRules, UnitOfMeasure } from 'src/apollo/onlineOrdering';
import { CardOrientation, MenuConfig, MenuTemplate } from 'src/apollo/sites';
import useOnVisible from 'src/lib/js/hooks/useOnVisible';

import Image from 'shared/components/common/Image';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';

import MenuItemCard, { getTemplateClass } from './MenuItemCard';
import { MenuSectionHeader } from './MenuSectionHeader';

const TOP_IMAGE_ITEMS_PER_ROW = 4;

const ContrastChecker = new ColorContrastChecker();

export type MenuItem = {
  name?: string | null;
  image?: string | null;
  imageUrls?: { [key: string]: string } | null;
  price?: number | null;
  prices?: number[] | null;
  description?: string | null;
  guid: string | null;
  outOfStock?: boolean | null;
  itemGroupGuid: string;
  timeBasedRules?: TimeBasedRules | null
  masterId?: string | null;
  unitOfMeasure?: UnitOfMeasure | null;
  usesFractionalQuantity?: boolean | null;
}

export type Props = {
  header?: string | null;
  description?: string | null;
  menuItems?: (MenuItem | null)[] | null;
  onVisible?: () => void;
  restaurantGuid?: string;
  shortUrl?: string;
  specialRequestsEnabled?: boolean;
  specialRequestsPlaceholder?: string;
  setIsMapOrModalOpen?: (isOpen: boolean) => void;
  isMapOrModalOpen?: boolean;
  orientation?: CardOrientation | null;
  isReadOnly?: boolean;
  menuConfig?: MenuConfig | null;
  wrapItems?: boolean;
  menuItemIdMap?: Map<number, string>;
  carouselRef?: React.RefObject<HTMLUListElement>;
  groupGuid?: string;
}

type MenuItemCardsProps = {
  menuItems?: (MenuItem | null)[] | null;
  menuItemIdMap?: Map<number, string>;
  setIsMapOrModalOpen?: (isOpen: boolean) => void;
  isMapOrModalOpen?: boolean;
  isReadOnly?: boolean;
  menuConfig?: MenuConfig | null;
  rowHasDescription: Array<boolean>;
};

export const MenuItemCards = ({ menuItems, menuItemIdMap, setIsMapOrModalOpen, isMapOrModalOpen, isReadOnly, menuConfig, rowHasDescription }: MenuItemCardsProps) =>
  <>
    {menuItems
      ? menuItems.map((item, i) => item ?
        <MenuItemCard
          key={item.guid || item.name}
          id={menuItemIdMap?.get(i)}
          item={item}
          setIsMapOrModalOpen={setIsMapOrModalOpen}
          isMapOrModalOpen={isMapOrModalOpen}
          isReadOnly={isReadOnly}
          menuConfig={menuConfig}
          rowHasDescription={rowHasDescription[Math.floor(i / TOP_IMAGE_ITEMS_PER_ROW)]} />
        : null)
      : new Array(8).fill(0)
        .map((_, index) =>
          <li className="item" key={`skeleton${index}`}>
            <Skeleton width="100%" height="100%" />
          </li>)}
  </>;

export const getRowDescriptionArray = (menuItems?: (MenuItem | null)[] | null) => {
  const arr = Array.from({ length: Math.ceil((menuItems?.length || 0) / 4) }, () => false);
  menuItems?.forEach((item, i) => arr[Math.floor(i / TOP_IMAGE_ITEMS_PER_ROW)] ||= Boolean(item?.description));
  return arr;
};

const MenuSection = (props: Props, ref: React.RefObject<HTMLDivElement>) => {
  const { wrapItems = true } = props;
  const { restaurant: { meta } } = useRestaurant();

  const [sectionExpanded, toggleSectionExpanded] = useReducer(state => !state, true);

  useOnVisible(ref, props.onVisible, { rootMargin: '-49% 0px -49% 0px' });

  // Create an array representing rows of menu items. Set the indexed value to true if one of the items in that row has a description.
  const rowHasDescription = useMemo(() => getRowDescriptionArray(props.menuItems), [props.menuItems]);

  const noImages = props.menuItems?.every(item => !item?.image && !item?.imageUrls?.medium);

  if(!props.menuItems) {
    return null;
  }

  const menuFormatConfig = props.menuConfig?.format;
  const hasLowContrast = !ContrastChecker.isLevelAA(meta.secondaryTextColor || DEFAULT_COLORS.secondaryText, meta.backgroundColor || DEFAULT_COLORS.background);

  if(menuFormatConfig?.template === MenuTemplate.Condensed || menuFormatConfig?.template === MenuTemplate.ExtraCondensed || menuFormatConfig?.template === MenuTemplate.Stacked) {
    const sectionStyle = props.menuConfig?.colors?.background ? { backgroundColor: props.menuConfig.colors.background } : {};

    return (
      <section
        role="tabpanel"
        {...props.groupGuid && { 'aria-describedby': `menu-pill-${props.groupGuid}` }}
        className={classnames('menuSection', getTemplateClass(menuFormatConfig?.template), { card: menuFormatConfig?.expandableSections })}
        ref={ref}>
        <div className="menuSectionHeaderWrapper">
          {menuFormatConfig?.expandableSections ?
            <button className={classnames('menuSectionHeader', 'button')} onClick={toggleSectionExpanded}>
              <MenuSectionHeader
                header={props.header} />
              <Image src="icons/caret-down-27px.svg" className={sectionExpanded ? 'expanded' : ''} />
            </button>
            :
            <div className={classnames('menuSectionHeader')}>
              <MenuSectionHeader
                header={props.header} />
            </div>}
          {props.description && <div className={classnames('description', { primaryTextColor: hasLowContrast })}>{props.description}</div>}
          { menuFormatConfig?.columns && <div className="sectionSeparatorWrapper"><hr className="sectionSeparator" /></div> }
        </div>
        {props.menuItems &&
          <div style={sectionStyle} className={classnames('itemSection',
            { expanded: !menuFormatConfig?.expandableSections || sectionExpanded, squaredCorners: menuFormatConfig?.squaredCorners })}>
            { !menuFormatConfig?.columns && <div className="sectionSeparatorWrapper" aria-hidden={true}><hr className="sectionSeparator" /></div> }
            <ul style={sectionStyle} className={classnames('itemSection',
              { columns: menuFormatConfig?.columns, expanded: !menuFormatConfig?.expandableSections || sectionExpanded })}>
              {props.menuItems.map((item: MenuItem) => item ?
                <MenuItemCard
                  key={item.guid}
                  item={item}
                  setIsMapOrModalOpen={props.setIsMapOrModalOpen}
                  isMapOrModalOpen={props.isMapOrModalOpen}
                  isReadOnly={props.isReadOnly}
                  menuConfig={props.menuConfig} />
                : null)}
            </ul>
          </div>}
      </section>
    );
  }

  const condenseGroups = menuFormatConfig?.condenseImagelessGroups && noImages;
  const templateClass = condenseGroups ? '' : getTemplateClass(menuFormatConfig?.template);
  const sectionStyle = condenseGroups && props.menuConfig?.colors?.background ? { backgroundColor: props.menuConfig.colors.background } : {};

  return (
    <>
      <section
        role="tabpanel"
        {...props.groupGuid && { 'aria-describedby': `menu-pill-${props.groupGuid}` }}
        className={classnames('menuSection', templateClass, { card: menuFormatConfig?.expandableSections })}
        ref={ref}
        tabIndex={0}>
        <div className="headerWrapper">
          <MenuSectionHeader
            header={props.header} />
          {props.description && <div className={classnames('description', { primaryTextColor: hasLowContrast })}>{props.description}</div>}
        </div>
        <ul ref={props.carouselRef} style={sectionStyle} className={classnames(
          'itemSection',
          {
            modalOpen: props.isMapOrModalOpen,
            compact: noImages,
            listView: menuFormatConfig?.condenseImagelessGroups,
            squaredCorners: menuFormatConfig?.squaredCorners,
            carousel: !wrapItems
          }
        )} aria-busy={props.menuItems ? 'false' : 'true'}>
          <MenuItemCards
            menuItems={props.menuItems}
            menuItemIdMap={props.menuItemIdMap}
            setIsMapOrModalOpen={props.setIsMapOrModalOpen}
            isMapOrModalOpen={props.isMapOrModalOpen}
            isReadOnly={props.isReadOnly}
            menuConfig={props.menuConfig}
            rowHasDescription={rowHasDescription} />
        </ul>
      </section>
    </>
  );
};

export default React.forwardRef(MenuSection);
