import React, { useContext } from 'react';

type RestaurantRoutesContextType = {
  homePath: string;
  orderPath: string;
  orderPathPattern: string;
  checkoutPath: string;
  confirmationPath: string;
  accountPath: string;
}

export const RestaurantRoutesContext = React.createContext<RestaurantRoutesContextType | undefined>(undefined);

type Props = {
  shortUrl?: string | null;
  homePath?: string;
  orderPath?: string;
  orderPathPattern?: string;
  checkoutPathPrefix?: string;
  confirmPathPrefix?: string;
}

export const RestaurantRoutesContextProvider = ({ shortUrl, homePath, orderPath, orderPathPattern, checkoutPathPrefix, confirmPathPrefix, children }: React.PropsWithChildren<Props>) => {
  return (
    <RestaurantRoutesContext.Provider value={{
      homePath: homePath || '/',
      orderPath: orderPath || `/order/${shortUrl}`,
      orderPathPattern: orderPathPattern || '/order/:slug',
      checkoutPath: `${checkoutPathPrefix || ''}/checkout`,
      confirmationPath: `${confirmPathPrefix || ''}/confirm`,
      accountPath: `${homePath || ''}/account`
    }}>
      {children}
    </RestaurantRoutesContext.Provider>
  );
};

export const useRestaurantRoutes = () => {
  const context = useContext(RestaurantRoutesContext);
  if(!context) {
    throw new Error('useRestaurantRoutes must be used within a RestaurantRoutesContextProvider');
  }

  return context;
};
