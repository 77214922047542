import React, { useCallback, useEffect, useRef, useState } from 'react';

import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import Image from 'shared/components/common/Image';
import ContextualLoadingSpinner from 'shared/components/common/loading_spinner/LoadingSpinner';
import useGeocoder from 'shared/components/common/location_search/useGeocoder';

import { normalizeGoogleAddress } from 'public/components/online_ordering/addressUtils';


const MINIMUM_AUTOCOMPLETE_CHARS = 3;
const LOCATION_BIAS_RADIUS = 10;
const PLACES_API_DEBOUNCE_MS = 1000;

/**
 * We only request BASIC fields in our getDetails request, which minimizes billing costs.
 * When adding fields, check to see if they will increase our GMaps bill first.
 * See https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
 * and https://developers.google.com/maps/documentation/javascript/places#place_details_requests
 * for reference.
 *  */
export const GMAPS_PLACERESULT_FIELDS: google.maps.places.PlaceDetailsRequest['fields'] = ['place_id', 'formatted_address', 'geometry', 'address_components'];

type Props = {
  id: string;
  defaultValue?: string;
  placeholder?: string;
  autoFocus?: boolean;
  apiKey: string;
  placeDetailsFields: google.maps.places.PlaceDetailsRequest['fields']
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
  options: google.maps.places.AutocompleteOptions;
  locationBias?: { lat: number, long: number },
  onValueChange?: (value: string) => void,
  normalized?: boolean,
  useCurrentLocation?: boolean;
}

const PlacesAutocomplete = ({
  id,
  defaultValue,
  placeholder = 'Enter a city or address',
  apiKey,
  onPlaceSelected,
  autoFocus = false,
  options,
  locationBias,
  onValueChange = () => {},
  normalized = false,
  useCurrentLocation = false
}: Props) => {
  const [isLoadingCurrentLocation, setIsLoadingCurrentLocation] = useState(false);
  const [currentLocationError, setCurrentLocationError] = useState<string>();

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading
  } = usePlacesService({ apiKey: apiKey, libraries: ['places', 'geocoding'], debounce: PLACES_API_DEBOUNCE_MS });
  const geocoder = useGeocoder();

  const [searchText, setSearchText] = useState<string>();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const predictionsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Don't close dropdown if click is inside the dropdown
      if(predictionsRef.current && predictionsRef.current.contains(event.target as Node)) {
        return;
      }
      setDropdownOpen(false);
    };
    if(dropdownOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [dropdownOpen, setDropdownOpen, predictionsRef]);

  const onSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentLocationError(undefined);
    const value = event.target.value;
    onValueChange(value || '');
    setSearchText(event.target.value);

    // Only get autocomplete predictions if we have three characters to increase
    // the likelihood of a match
    if(value.length >= MINIMUM_AUTOCOMPLETE_CHARS) {
      setDropdownOpen(true);
      getPlacePredictions({
        input: value,
        ...options,
        radius: locationBias ? LOCATION_BIAS_RADIUS : undefined,
        location: locationBias ? new google.maps.LatLng({ lat: locationBias.lat, lng: locationBias.long }) : undefined
      });
    }
  }, [onValueChange, getPlacePredictions, options, locationBias, setCurrentLocationError]);

  const searchOnGeocodedRequest = useCallback((request: google.maps.GeocoderRequest) => {
    geocoder().geocode(request, result => {
      const place = result?.[0];
      if(place) {
        onPlaceSelected(place);
        if(normalized) {
          const normalizedAddress = normalizeGoogleAddress(place);
          if(normalizedAddress) {
            setSearchText(`${normalizedAddress.address1}, ${normalizedAddress.city}, ${normalizedAddress.state} ${normalizedAddress.zipCode}`);
          }
        }
      }
    });
  }, [geocoder, onPlaceSelected, normalized, setSearchText]);

  const onPredictionClick = useCallback((prediction: google.maps.places.AutocompletePrediction) => {
    setSearchText(`${prediction.structured_formatting.main_text}, ${prediction.structured_formatting.secondary_text}`);
    searchOnGeocodedRequest({ placeId: prediction.place_id });
    inputRef.current?.focus();
    setDropdownOpen(false);
  }, [searchOnGeocodedRequest]);

  const onCurrentLocationSuccess = useCallback((pos: GeolocationPosition) => {
    searchOnGeocodedRequest({ location: new google.maps.LatLng({ lat: pos.coords.latitude, lng: pos.coords.longitude }) });
    inputRef.current?.focus();
    setDropdownOpen(false);
    setIsLoadingCurrentLocation(false);
  }, [searchOnGeocodedRequest, inputRef, setDropdownOpen]);

  const onCurrentLocationError = useCallback((error: GeolocationPositionError) => {
    if(error.PERMISSION_DENIED) {
      setCurrentLocationError('Turn on location services to use your current location');
    } else if(error.POSITION_UNAVAILABLE || error.TIMEOUT) {
      setCurrentLocationError('We could not find your current location');
    }
    setDropdownOpen(false);
    setIsLoadingCurrentLocation(false);
  }, []);

  const onClickUseCurrentLocation = () => {
    setIsLoadingCurrentLocation(true);
    setCurrentLocationError(undefined);
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onCurrentLocationSuccess, onCurrentLocationError, { maximumAge: Infinity, timeout: 10000 });
    } else {
      setCurrentLocationError('Geolocation services not available');
    }
  };

  const keyController = useCallback((event: React.KeyboardEvent) => {
    if(!predictionsRef.current || event.key !== 'ArrowDown' && event.key !== 'ArrowUp') {
      return;
    }
    const focusedElement = predictionsRef.current.querySelector('button:focus');
    let nextElement;

    if(inputRef.current === document.activeElement) {
      nextElement = predictionsRef.current.querySelector('button');
    } else if(focusedElement) {
      // get sibling above or below focused element.
      if(event.key === 'ArrowDown') {
        nextElement = focusedElement.nextElementSibling;
      } else if(event.key === 'ArrowUp') {
        nextElement = focusedElement.previousElementSibling;
      }
    } else {
      return;
    }

    if(nextElement instanceof HTMLButtonElement) {
      event.preventDefault();
      nextElement.focus();
    }
  }, []);

  const onDropdownBlur = useCallback((event: React.FocusEvent<HTMLDivElement>) => {
    if(event.currentTarget.contains(event.relatedTarget as Node)) {
      // blur happens inside the dropdown, don't collapse the dropdown.
      return;
    }
    // blur occured elsewhere, collapse the dropdown.
    setDropdownOpen(false);
  }, []);

  return (
    <div className="autocompleteContainer">
      <div className="autocomplete" role="search">
        <input
          id={id}
          data-testid="placesAutocomplete"
          className="input"
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          aria-placeholder={placeholder}
          value={searchText !== undefined ? searchText : defaultValue}
          onChange={onSearchChange}
          onKeyDown={keyController}
          role="combobox"
          aria-expanded={dropdownOpen}
          aria-controls="locations-dropdown"
          aria-owns="locations-dropdown"
          aria-autocomplete="list"
          autoFocus={autoFocus}>
        </input>
        {(isLoadingCurrentLocation || isPlacePredictionsLoading) &&
          <div className="loadingSpinner">
            <ContextualLoadingSpinner size="24px" />
          </div>}
        {useCurrentLocation && !(isLoadingCurrentLocation || isPlacePredictionsLoading) &&
          <div className="currentLocation">
            <Image alt="Use my current location" src="icons/current-location.svg" onClick={() => setDropdownOpen(true)} />
          </div>}
        {dropdownOpen &&
          <div className="dropdown">
            <div className="predictions" ref={predictionsRef} id="locations-dropdown" role="listbox" onBlur={onDropdownBlur}>
              {useCurrentLocation &&
                <button
                  key="current-location"
                  className="prediction"
                  onClick={onClickUseCurrentLocation}
                  onKeyDown={keyController}
                  tabIndex={0}>
                  <div>Use my current location</div>
                  <Image alt="Use my current location" src="icons/current-location.svg" />
                </button>}
              {placePredictions.map(prediction =>
                <button
                  key={prediction.place_id}
                  className="prediction"
                  onClick={() => onPredictionClick(prediction)}
                  onKeyDown={keyController}
                  tabIndex={0}
                  aria-labelledby={prediction.place_id + '-label'}
                  role="option">
                  <div id={prediction.place_id + '-label'}>
                    {prediction.structured_formatting.main_text}, {prediction.structured_formatting.secondary_text}
                  </div>
                </button>)}
              <div className="attribution">
                <span>Powered by</span>
                <Image src="icons/google_on_white_hdpi.png" alt="Google" />
              </div>
            </div>
          </div>}
      </div>
      {currentLocationError && <div className="error">{currentLocationError}</div>}
    </div>
  );
};

export default PlacesAutocomplete;
