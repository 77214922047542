import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import AppliedInput from 'public/components/default_template/online_ordering/checkout/AppliedInput';
import { useCart } from 'public/components/online_ordering/CartContext';


const PromoCodeInput = () => {
  const { watch } = useFormContext();
  const { cart, applyPromoCode, removePromoCode } = useCart();
  const [warning, setWarning] = useState('');
  const promoCode = watch('promoCode');
  const [loadingPromoCode, setLoadingPromoCode] = useState(false);

  const onApplyCode = async () => {
    setLoadingPromoCode(true);
    try {
      const errorType = await applyPromoCode(promoCode);
      if(errorType === 'ApplyPromoCodeError') {
        setWarning('Invalid promo code.');
      } else {
        setWarning('');
      }
    } catch(error) {
      setWarning('Error adding promo code. Please try again.');
    }
    setLoadingPromoCode(false);
  };

  const onRemoveCode = async () => {
    setLoadingPromoCode(true);
    try {
      const errorType = await removePromoCode();
      if(errorType) {
        setWarning('Error removing promo code. Please try again.');
      } else {
        setWarning('');
      }
    } catch(error) {
      setWarning('Error removing promo code. Please try again.');
    }
    setLoadingPromoCode(false);
  };

  const promoCodeAmount = cart?.order?.discounts?.restaurantDiscount?.promoCode;
  const globalRewardAmount = cart?.order?.discounts?.globalReward?.name;
  const discountName = promoCodeAmount || globalRewardAmount || '';

  return <AppliedInput
    id="promoCode"
    label="Promo Code"
    loading={loadingPromoCode}
    value={discountName}
    isApplied={Boolean(promoCodeAmount || globalRewardAmount)}
    applyButtonActive={!loadingPromoCode && promoCode}
    removeButtonActive={!loadingPromoCode}
    warning={warning}
    onApply={onApplyCode}
    onRemove={onRemoveCode} />;
};

export default PromoCodeInput;
