import React, { useMemo, useRef, useEffect, useState, useReducer } from 'react';

import { ContainsEditableProps, FieldType, ModuleField, _heading, _p, useEditor } from '@toasttab/sites-components';
import classNames from 'classnames';

import { BaseSectionFieldsFragment, ButtonType, FullScreenSectionFieldsFragment, RelativePosition, ThemeTypeEnum } from 'src/apollo/sites';
import { _Image } from 'src/shared/components/common/editable_image/EditableImage';

import { EditableButton } from 'shared/components/common/button';
import { getBackgroundColorModule, getThemeModule, getImageModules, getSectionPaddingModule } from 'shared/components/common/editor_context/editableUtils';
import ImageScroller from 'shared/components/common/image_scroller/ImageScroller';

import { RelativePositionEnum } from 'public/components/default_template/DefaultTemplateUtils';

type TestProps = { testId?: string }
type Props = Omit<FullScreenSectionFieldsFragment & BaseSectionFieldsFragment, '__typename'> & ContainsEditableProps & TestProps

const AUTO_SCROLL_INTERVAL_MS = 3_500;

const FullScreenSection = ({
  sectionName,
  backgroundColor,
  theme,
  fullScreenBody,
  fullScreenHeader,
  fullScreenButton: button,
  textPosition,
  textWithBackground = false,
  textBoxBackgroundColor,
  sectionImages: images,
  padding,
  editPath,
  testId
}: Props) => {
  const [header, body] = [fullScreenHeader ?? '', fullScreenBody ?? ''];
  const textBoxStyle: React.CSSProperties = useMemo(() => {
    if(textWithBackground) {
      if(theme === ThemeTypeEnum.Outlined) {
        return { borderColor: textBoxBackgroundColor ?? 'var(--tsw-primary-color)' };
      } else {
        return { backgroundColor: textBoxBackgroundColor ?? 'var(--tsw-primary-color)' };
      }
    }
    return {};
  }
  , [textBoxBackgroundColor, theme, textWithBackground]);


  const rowPosition = useMemo(() => getRowPosition(textPosition), [textPosition]);
  const colPosition = useMemo(() => getColPosition(textPosition), [textPosition]);
  const scrollerArrowPosition = useMemo(() => getArrowsPosition(textPosition), [textPosition]);
  const [scrollerIndex, setKnownScrollerIndex] = useState<number>(0);
  const scrollerSkipRef = useRef<HTMLDivElement>(null); // passed to the ImageScroller arrows keep them functional in the admin.

  const [mouseHover, toggleMouseHover] = useReducer(active => !active, false);
  const [focused, toggleFocus] = useReducer(active => !active, false);
  const autoScrollActive = useMemo(() => !mouseHover && !focused, [mouseHover, focused]);


  const { useEditableRef, isEditor } = useEditor();
  const { editableRef, pushSchema } = useEditableRef<HTMLDivElement>({
    name: 'full screen section',
    displayName: 'Full Screen Section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    skipRef: scrollerSkipRef,
    path: editPath,
    schema: {
      fields: [
        getSectionPaddingModule(editPath, padding),
        getBackgroundColorModule(editPath, backgroundColor),
        getThemeModule(editPath, theme),
        ...getImageModules(
          `${editPath}.sectionImages[${scrollerIndex}]`,
          images[scrollerIndex],
          { adminLocation: images.length > 1 ? 'action panel' : 'form' }
        ),
        {
          displayName: 'Text Position',
          path: `${editPath}.textPosition`,
          type: FieldType.Enum,
          enums: RelativePositionEnum,
          value: textPosition
        },
        {
          displayName: 'Show Text Background',
          path: `${editPath}.textWithBackground`,
          type: FieldType.Boolean,
          value: textWithBackground
        },
        ... textWithBackground
          ? [{
            displayName: 'Text Box Color',
            path: `${editPath}.textBoxBackgroundColor`,
            type: FieldType.Color,
            value: textBoxBackgroundColor
          } as ModuleField]
          : [],
        {
          path: `${editPath}.fullScreenButton`,
          displayName: 'Button',
          type: FieldType.Boolean,
          value: !!button,
          activeValue: { text: 'Button', type: textWithBackground ? 'secondary' : 'primary', link: '/' }
        }
      ]
    }
  });

  useEffect(() => {
    // resubmit the changed schema when textWithBackground setting changes.
    pushSchema();
  }, [pushSchema, textWithBackground, scrollerIndex]);

  return (
    <div className={classNames(sectionName, theme)} ref={editableRef} data-testid={testId}
      // Pause auto-scrolling on mouseover or focus. See https://toasttab.atlassian.net/browse/WOO-1106 for details.
      onFocus={toggleFocus} onBlur={toggleFocus} onMouseOver={toggleMouseHover} onMouseOut={toggleMouseHover}>
      {images.length > 1
        ?
        <ImageScroller
          images={images}
          arrowPosition={scrollerArrowPosition}
          autoScrollMS={isEditor ? undefined : AUTO_SCROLL_INTERVAL_MS}
          autoScrollActive={autoScrollActive}
          skipRef={scrollerSkipRef}
          onScrollIndexChange={setKnownScrollerIndex}
          ariaLabel={header}
          className="image-fill" />
        :
        <div className="image-fill" data-testid="one-image">
          <_Image imageObject={images[0]} alt={images[0]?.altText ?? 'alt text'} className="coverImage" eagerLoad={false} />
        </div> }
      <div className={classNames('text-box-area', rowPosition)}>
        <div className={classNames('text-box-row', colPosition)}>
          <div className={classNames('text-box themeElement', theme)}
            style={textBoxStyle}>
            {header !== undefined && <_heading styleLevel={1} html={header} editPath={`${editPath}.fullScreenHeader`} />}
            {body !== undefined && <_p html={body} editPath={`${editPath}.fullScreenBody`}></_p>}
            {button && <EditableButton
              editPath={`${editPath}.fullScreenButton`}
              link={button.link}
              type={button?.type || ButtonType.Inverted}
              textColor={button?.textColor}
              alignment={button.alignment}
              text={button.text} />}
          </div>
        </div>
      </div>
    </div>);
};

const getColPosition = (textPosition: RelativePosition | null | undefined) => {
  const position = textPosition?.toLowerCase() || '';
  if(position.includes('left')) {
    return 'left';
  }
  if(position.includes('right')) {
    return 'right';
  }
  return 'center';
};

const getRowPosition = (textPosition: RelativePosition | null | undefined) => {
  const position = textPosition?.toLowerCase() || '';
  if(position.includes('top')) {
    return 'top';
  }
  if(position.includes('bottom')) {
    return 'bottom';
  }
  return 'middle';
};

const getArrowsPosition = (textPosition: RelativePosition | null | undefined) => {
  const rowPos = getRowPosition(textPosition);
  if(rowPos === 'bottom') {
    return 'top';
  } else {
    return 'bottom';
  }
};

export default FullScreenSection;
