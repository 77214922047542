import React from 'react';

import classnames from 'classnames';

import Image from 'shared/components/common/Image';

type Props ={
  rating?: number;
  count?: number;
  units?: string;
  singularUnits?: string;
  className?: string;
};

const AVGRating = ({ rating, count, units, className, singularUnits }: Props) =>
  <div className={classnames('avg-rating', className)} title={rating && count ? `This restaurant has a ${rating} rating from ${count} reviews on restaurant review sites.` : ''}>
    <Image src="/icons/star-full-blue.svg" alt="review star" />
    <div className="txt-body-sm">
      {rating ? <>Avg {rating} <span className="text-color-gray">({count}{count === 1 && singularUnits ? ` ${singularUnits}` : units && ` ${units}`})</span></> : 'No reviews yet'}
    </div>
  </div>;

export default AVGRating;
