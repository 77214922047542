import React, { useMemo } from 'react';

import { Location } from 'src/apollo/onlineOrdering';
import { RxImagesType } from 'src/apollo/toastLocal';

import Image, { ImageProps } from 'shared/components/common/Image';

import { formatLocationAddress } from 'public/components/online_ordering/addressUtils';

import { gMapsAPIKey, resources } from 'config';

type Props = {
  id: string;
  rxName: string;
  location: Location;
  images?: RxImagesType | null;
  rxImageUrl?: string | null ;
};

const removeSlashPrefix = (s?: string | null) => s?.startsWith('/') ? s.slice(1) : s;

const HeaderImage = ({ rxName, images, rxImageUrl, location, children }: React.PropsWithChildren<Props>) => {
  // We have resized images in Toast Local, so use those if they match the path of rxImageUrl
  const image: ImageProps | null = useMemo(() => {
    const bffImage = removeSlashPrefix(rxImageUrl);
    const tlHeadeImage = images?.bg || images?.main;
    const tlHeaderImageSrc = removeSlashPrefix(tlHeadeImage?.downloadData.src);
    if(bffImage && tlHeadeImage && bffImage === tlHeaderImageSrc || !rxImageUrl && tlHeadeImage) {
      return {
        cdnHost: tlHeadeImage.cdnHost || resources.toastLocalCDN,
        src: tlHeadeImage.src || '',
        srcSet: tlHeadeImage.srcSet,
        alt: tlHeadeImage.alt || ''
      };
    } else if(rxImageUrl) {
      return {
        cdnHost: 'https://d2s742iet3d3t1.cloudfront.net',
        src: rxImageUrl,
        alt: 'Restaurant header image'
      };
    }

    return null;
  }, [images, rxImageUrl]);

  const headerMapImg = useMemo(() => {
    if(location) {
      const formattedAddress = formatLocationAddress(location);
      const mapOptions = new URLSearchParams({ size: '600x150', zoom: '13', scale: '2' }).toString();
      const staticMapURL = `https://maps.googleapis.com/maps/api/staticmap?\
      markers=color:0xFF4C00|size:tiny%7C${encodeURIComponent(formattedAddress)}&${mapOptions}\
      &style=feature:poi|visibility:off&maptype=roadmap\
      &key=${gMapsAPIKey}`.replace(/\s+/g, '');

      return {
        alt: `A map showing the location of ${rxName}`,
        src: staticMapURL
      };
    }
    return null;
  }, [location, rxName]);

  return (
    <div className="rx-header-photo">
      <div className={rxImageUrl ? 'image-container' : 'map-container'}>
        {image?.src ?
          <Image {...image} /> :
          headerMapImg?.src ?
            <Image {...headerMapImg}></Image> :
            <p>This restaurant does not have any images</p>}
        {children}
      </div>
    </div>
  );
};

export default HeaderImage;
