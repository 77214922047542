import React from 'react';

import classnames from 'classnames';
import parseISO from 'date-fns/parseISO';

import { TimeBasedRules, DateTimeRange } from 'src/apollo/onlineOrdering';

import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import { CalendarIcon, ChefsHatIcon } from 'public/components/default_template/online_ordering/item_tags/ItemTagIcons';
import { generateTextColor, getTagColor } from 'public/components/default_template/online_ordering/item_tags/tagColorUtils';


export const MenuItemTag = ({
  timeBasedRules,
  showMessage = false,
  mini = true,
  backgroundColor,
  primaryColor
}: {
  timeBasedRules: TimeBasedRules,
  showMessage?: boolean,
  mini?: boolean,
  backgroundColor: string,
  primaryColor?: string;
}) => {
  const background = backgroundColor ?? DEFAULT_COLORS.background ?? '#ffffff';
  const tagColor = getTagColor(background, primaryColor);
  const textColor = generateTextColor(tagColor);
  const style = mini ? { color: textColor, backgroundColor: tagColor } : undefined;
  const Icon = timeBasedRules.leadTimeRule ? ChefsHatIcon : CalendarIcon;
  let tagContent: string = '';
  if(timeBasedRules.leadTimeRule) {
    const leadTime = timeBasedRules.leadTimeRule.leadTime;
    tagContent = mini ? `${leadTime}HR NOTICE` : `REQUIRES ${leadTime} HR NOTICE`;
  } else if(timeBasedRules.preorderRule) {
    const { start, end } = timeBasedRules.preorderRule.fulfillmentDateRange as DateTimeRange;
    const dateRange = getDateRangeForTag(new Date(parseISO(start)), end ? new Date(parseISO(end)) : undefined);
    tagContent = mini ? `${dateRange}` : `PREORDER TODAY FOR PICKUP ${dateRange}`;
  }
  return (
    <div style={style} className={classnames('tag', { mini })} data-testid="time-based-ordering-tag">
      <div className="tagHeader">
        <Icon color={mini ? textColor : DEFAULT_COLORS.text ?? '#252525'} />
        <span>{tagContent}</span>
      </div>
      {!mini && showMessage && <span>Adding this item to your cart will require an update to your order time.</span>}
    </div>
  );
};

export const getDateRangeForTag = (start: Date, end?: Date) => {
  const startMonthAndDay = start.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }).toUpperCase();
  // The front end of the TBR admin spa currently enforces an end time on all ranges, so this should never actually
  // be undefined, but in case it somehow is, we'll treat it as an open-ended range
  if(!end || start.getMonth() === end.getMonth() && start.getDate() === end.getDate()) {
    return startMonthAndDay;
  }
  if(start.getMonth() === end.getMonth()) {
    return `${startMonthAndDay} - ${end.toLocaleDateString('en-US', { day: 'numeric' }).toUpperCase()}`;
  }
  return `${startMonthAndDay} - ${end.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }).toUpperCase()}`;
};

export default MenuItemTag;
