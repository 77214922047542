import React, { useMemo } from 'react';

import { RestaurantType } from 'src/apollo/toastLocal';

import { daysOfWeek, compactTimes, OpenHours } from './timeUtilities';

type Props = {
  schedules: RestaurantType['schedules'];
}

const Hours = ({ schedules } : Props) => {
  const schedule = useMemo(() => {
    let hasSchedule = false;
    schedules?.sort();
    const days: Record<string, OpenHours[]> = {
      Sunday: [],
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: []
    };
    schedules?.forEach(scheduleData => {
      if(scheduleData) {
        scheduleData.days?.forEach(day => {
          hasSchedule = true;
          if(day && day in days && scheduleData.startTime && scheduleData.endTime) {
            const dayList = days[day];
            if(dayList) {
              dayList.push({
                startTime: scheduleData?.startTime,
                endTime: scheduleData?.endTime
              });
            }
          }
        });
      }
    });

    for(const day in days) {
      days[day] = compactTimes(days[day], true);
    }

    return hasSchedule ? days : null;
  }, [schedules]);

  const today = new Date().getDay();

  return (
    <div className="hours-container" id="rx-hours">
      {schedule ?
        <table>
          <tbody>
            {daysOfWeek.map((dayOfWeek, i) =>
              <tr key={dayOfWeek} className={`${i == today ? 'bold' : '' }`}>
                <td>{dayOfWeek}</td>
                <td className="hours">{schedule[dayOfWeek]?.length ? schedule[dayOfWeek]?.map(day => `${day.startTime} - ${day.endTime}`).join(', ') : 'Closed'}</td>
              </tr>)}
          </tbody>
        </table> :
        <p>Call for Open Hours</p>}
    </div>
  );
};
export default Hours;
