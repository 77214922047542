import React, { createContext, useCallback, useContext } from 'react';

import * as Sentry from '@sentry/react';

import { CustomerQuery, LoginErrorCode, MfaType, useImportAccountMutation, useInitiatePasswordResetMutation } from 'src/apollo/onlineOrdering';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';

import { resources } from 'config';

type LoginResponse = {
  success: boolean,
  errorCode?: LoginErrorCode

  // MFA (multi-factor auth) specific fields
  challengeToken?: string,
  mfaRecipient?: string,
  mfaType?: MfaType
}

export interface CustomerContextCommonType {
  customer?: Customer | null;
  loadingCustomer: boolean;
  refetchCustomer: () => void;
  fetchCustomer: () => Promise<Customer | null>;
  passwordlessLogin: (phoneNumber: string) => Promise<boolean>;
  passwordlessConfirmCode: (phoneNumber: string, code: string) => Promise<string | null>;
  completeSignup: (email: string, firstName: string, lastName: string) => Promise<boolean>;
  pwlessLogout: () => Promise<boolean>;

  // DEPRICATE:
  emailAccountExists: (email?: string) => Promise<boolean>;
  login: (email: string, password: string) => Promise<LoginResponse>;
  mfaLogin: (email: string, challengeToken: string, code: string) => Promise<LoginResponse>;
}

export interface CustomerContextType extends CustomerContextCommonType {
  importAccount: () => Promise<boolean>;
  initiatePasswordReset: (email: string, shortUrl: string) => Promise<boolean>;
  savedAddressUsed: boolean;
  setSavedAddressUsed: (savedAddressUsed: boolean) => void;
}

export type Customer = CustomerQuery['customer'] & { __typename: 'Customer' } | undefined | null;

export const CustomerContext = createContext<CustomerContextType | undefined>(undefined);

export const CustomerContextCommonProvider = (props: React.PropsWithChildren<{context: CustomerContextCommonType}>) => {
  const client = useOOClient();
  const [savedAddressUsed, setSavedAddressUsed] = React.useState(false);
  const [importAccountMutation] = useImportAccountMutation({ client });
  const importAccount = useCallback(async () => {
    try {
      const { data } = await importAccountMutation();

      if(data?.importAccount?.__typename !== 'ImportAccountResponse') {
        return false;
      }
    } catch(err) {
      Sentry.captureMessage(`ERROR: import account error ${err}`);
      return false;
    }

    return true;
  }, [importAccountMutation]);

  const [initiatePasswordResetMutation] = useInitiatePasswordResetMutation({ client });
  const initiatePasswordReset = useCallback(async (email: string, shortUrl: string) => {
    try {
      const { data } = await initiatePasswordResetMutation({
        variables: {
          input: {
            email,
            redirectUrl: `${resources.checkoutHost}${shortUrl}/v3?mode=reset`
          }
        }
      });

      if(data?.initiateEmailPasswordReset?.success) {
        return true;
      }
    } catch(err) {
      Sentry.captureException(`ERROR: initiatePasswordReset error ${err}`);
    }

    return false;
  }, [initiatePasswordResetMutation]);

  const context = {
    ...props.context,
    initiatePasswordReset,
    importAccount,
    savedAddressUsed,
    setSavedAddressUsed
  };

  return (
    <CustomerContext.Provider value={context}>
      {props.children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => {
  const context = useContext(CustomerContext);
  if(!context) {
    throw new Error('useCustomer must be used within a CustomerContextProvider');
  }

  return context;
};
