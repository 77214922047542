import React, { FC, useCallback, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';

import { DeliveryInfoInput, SavedAddress, useUpdateAddressMutation } from 'src/apollo/onlineOrdering';

import Button from 'shared/components/common/button';
import FormInput from 'shared/components/common/form_input';
import PlacesAutocomplete, { GMAPS_PLACERESULT_FIELDS } from 'shared/components/common/location_search/PlacesAutocomplete';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';


import { normalizeGoogleAddress } from 'public/components/online_ordering/addressUtils';

import { gPlacesAPIKey } from 'config';


interface EditAddressProps {
  address: SavedAddress
  onSuccessfulUpdate: () => void;
}

type EditAddressFormInputs = {
  label: string;
  addressLine1: string;
  addressLine2: string;
  deliveryInstructions: string;
}

export const EditAddress: FC<EditAddressProps> = ({ address, onSuccessfulUpdate }) => {
  const { selectedLocation } = useRestaurant();
  const [UpdateAddressMutation] = useUpdateAddressMutation();
  const [selectedAddress, setSelectedAddress] = useState<DeliveryInfoInput|null>();
  const formMethods = useForm<EditAddressFormInputs>({
    mode: 'onTouched', defaultValues: {
      label: address.name || '',
      addressLine1: address.deliveryInfo.address1,
      addressLine2: address.deliveryInfo.address2 || '',
      deliveryInstructions: address.deliveryInfo.notes || ''
    }
  });
  formMethods.register('addressLine1', { required: true });
  const label = useWatch({ name: 'label', control: formMethods.control });
  const addressLine2 = useWatch({ name: 'addressLine2', control: formMethods.control });
  const deliveryInstructions = useWatch({ name: 'deliveryInstructions', control: formMethods.control });
  const { setValue } = formMethods;
  const setAddressLines = useCallback((line1: string, line2: string) => {
    setValue('addressLine1', line1, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    setValue('addressLine2', line2, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  }, [setValue]);

  const onPlaceSelected = useCallback(async (place: google.maps.places.PlaceResult) => {
    const normalizedAddress = normalizeGoogleAddress(place);
    setAddressLines(normalizedAddress?.address1 || '', normalizedAddress?.address2 || '');
    setSelectedAddress(normalizedAddress);
  }, [setAddressLines]);


  const onSubmit = useCallback(async () => {
    try {
      const newDeliveryInfo = selectedAddress ? selectedAddress : address.deliveryInfo;
      const { data } = await UpdateAddressMutation({
        variables: {
          input: {
            name: label,
            deliveryInfo: {
              address1: newDeliveryInfo.address1,
              address2: addressLine2,
              city: newDeliveryInfo.city,
              state: newDeliveryInfo.state,
              zipCode: newDeliveryInfo.zipCode,
              latitude: newDeliveryInfo.latitude,
              longitude: newDeliveryInfo.longitude,
              notes: deliveryInstructions
            },
            guid: address.guid
          }
        }
      });
      if(!data || data.updateAddress.__typename === 'UpdateAddressError') {
        toast.error('Something went wrong, please try again later');
      } else if(data.updateAddress.__typename === 'UpdateAddressResponse') {
        toast.success('Address updated successfully');
        onSuccessfulUpdate();
      }
    } catch(e) {
      toast.error('Something went wrong, please try again later');
      reportError(e);
    }
  }, [UpdateAddressMutation, address, addressLine2, deliveryInstructions, label, onSuccessfulUpdate, selectedAddress]);

  return (
    <FormProvider {...formMethods}>
      <div className="editAddressWrapper">
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="editAddressInputs">
            <FormInput id="label" type="text" label="Address Label" required filled={!!address.name} />
            <FormInput id="addressLine1" type="text" label="Address" required filled={!!address.deliveryInfo.address1} >
              <PlacesAutocomplete
                id="edit-address-input-auto-complete"
                defaultValue={`${address.deliveryInfo.address1}, ${address.deliveryInfo.city}, ${address.deliveryInfo.state} ${address.deliveryInfo.zipCode}`}
                placeholder="Enter delivery address"
                apiKey={gPlacesAPIKey}
                placeDetailsFields={GMAPS_PLACERESULT_FIELDS}
                onPlaceSelected={onPlaceSelected}
                locationBias={selectedLocation.lat && selectedLocation.long ? { lat: selectedLocation.lat, long: selectedLocation.long } : undefined}
                options={{ types: ['geocode'], fields: GMAPS_PLACERESULT_FIELDS }}
                normalized={true}
                onValueChange={value => setAddressLines(value, addressLine2)} />
            </FormInput>
            <FormInput id="addressLine2" type="text" label="Apt./Suite no." filled={!!address.deliveryInfo.address2} />
            <FormInput id="deliveryInstructions" type="text" label="Delivery Instructions" filled={!!address.deliveryInfo.notes} />
          </div>

          <div className="actionsWrapper">
            <Button className="cancelAddressEditBtn"
              onClick={onSuccessfulUpdate}>
              Cancel
            </Button>
            <Button className="updateAddressBtn" type="submit"
              disabled={!(formMethods.formState.isValid && formMethods.formState.isDirty) || formMethods.formState.isSubmitting}>
              Update my address
            </Button>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

