import React from 'react';

import classnames from 'classnames';

import ChevronIcon from './ChevronIcon';
import { CarouselDirection } from './useCarousel';

type ArrowProps = {
  onClickScroll: (direction: CarouselDirection) => void;
  currentItem: number;
  whiteInactiveArrow?: boolean;
  color: string;
  totalScrollItems: number;
};

export const CarouselArrowLeft = ({ onClickScroll, currentItem, whiteInactiveArrow, color }: ArrowProps) =>
  <button aria-label="scroll left" className={classnames('carouselArrow left', { inactive: currentItem === 0 })} onClick={() => onClickScroll('backwards')} data-testid="carousel-left-arrow">
    <ChevronIcon color={currentItem === 0 ? whiteInactiveArrow && 'white' || color : 'white'} />
  </button>;

export const CarouselArrowRight = ({ onClickScroll, currentItem, whiteInactiveArrow, color, totalScrollItems }: ArrowProps) =>
  <button
    aria-label="scroll right" className={classnames('carouselArrow right', { inactive: currentItem === totalScrollItems - 1 })}
    onClick={() => onClickScroll('forwards')} data-testid="carousel-right-arrow">
    <ChevronIcon color={currentItem === totalScrollItems - 1 ? whiteInactiveArrow && 'white' || color : 'white'} />
  </button>;


type Props = {
  arrowsRef: React.RefObject<HTMLDivElement>;
  color: string;
  whiteInactiveArrow?: boolean;
  arrowSize?: 'small' | 'large';
  onClickScroll: (direction: CarouselDirection) => void;
  currentItem: number;
  totalScrollItems: number;
};

const CarouselArrows = (props: Props) => {
  const { arrowsRef, arrowSize, ...arrowProps } = props;

  return (
    <div className={classnames('carouselArrows', arrowSize, { whiteBorder: arrowProps.whiteInactiveArrow })} ref={arrowsRef} data-testid="carousel-arrows">
      <CarouselArrowLeft {...arrowProps} />
      <div
        className="itemRange"
        aria-label={`Currently on slide ${arrowProps.currentItem + 1} of ${arrowProps.totalScrollItems}`}>
        {arrowProps.currentItem + 1}
        <span className="itemTotal"> / {arrowProps.totalScrollItems}</span>
      </div>
      <CarouselArrowRight {...arrowProps} />
    </div>
  );
};

export default CarouselArrows;
