import React, { useCallback, useEffect, useState } from 'react';

import classnames from 'classnames';

import Image from 'shared/components/common/Image';

import { DecreaseQuantityIcon, IncreaseQuantityIcon } from 'public/components/default_template/online_ordering/item_modal/ItemModalIcons';

type Props = {
  onQuantityChange: (quantity: number, change: number, defaultValueChange?: boolean) => void;
  defaultValue?: number;
  min?: number;
  max?: number;
  allowDelete?: boolean;
  onDelete?: () => void;
  className?: string;
  canIncrease?: boolean;
}

type Quantity = {
  current: number;
  previous: number;
}

const QuantitySelector = ({ min, max, defaultValue, className, onQuantityChange, allowDelete, onDelete, canIncrease }: Props) => {
  const [quantity, setQuantity] = useState<Quantity>({
    previous: 0,
    current: defaultValue || min || 0
  });

  useEffect(() => {
    const previous = defaultValue || min || 0;
    const current = defaultValue || min || 0;
    setQuantity({
      previous,
      current
    });
    onQuantityChange(current, current - previous, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback((quantity: Quantity) => {
    onQuantityChange(quantity.current, quantity.current - quantity.previous);
    setQuantity(quantity);
  }, [onQuantityChange]);

  const incQuantity = useCallback(() => {
    onChange({
      current: quantity.current + 1,
      previous: quantity.current
    });
  }, [onChange, quantity]);
  const decQuantity = useCallback(() => {
    onChange({
      current: quantity.current - 1,
      previous: quantity.current
    });
  }, [onChange, quantity]);

  const handleArrowKeys = useCallback((e: any) => {
    switch(e.key) {
      case 'ArrowUp':
        if(!canIncrease || max && quantity.current === max) {
          return;
        }
        e.stopPropagation();
        e.preventDefault();
        incQuantity();
        break;
      case 'ArrowDown':
        if(quantity.current === min) {
          return;
        }
        e.stopPropagation();
        e.preventDefault();
        decQuantity();
        break;
      default:
        // no-op
    }
  }, [canIncrease, decQuantity, incQuantity, max, min, quantity]);

  return (
    <div className={classnames('quantitySelector', className)}>
      {quantity.current !== 1 || !allowDelete ?
        <button data-testid="dec-button" type="button" tabIndex={-1} className="button" disabled={min ? quantity.current <= min : false} onClick={e => {
          e.stopPropagation();
          decQuantity();
        }}>
          <DecreaseQuantityIcon />
        </button> :
        <Image alt="Delete" src="/icons/delete.svg" className="button" onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          if(onDelete) {
            onDelete();
          }
        }} />}
      <span
        onKeyDown={handleArrowKeys}
        data-testid="spinner"
        className="quantity"
        tabIndex={0}
        aria-label="Quantity" aria-valuemin={min || 0} aria-valuemax={max || Infinity} aria-valuenow={quantity.current}>
        {quantity.current}
      </span>
      <button data-testid="inc-button" type="button" tabIndex={-1} className="button" disabled={canIncrease === false || (max ? quantity.current >= max : false)} onClick={e => {
        e.stopPropagation();
        incQuantity();
      }}>
        <IncreaseQuantityIcon />
      </button>
    </div>
  );
};

export default QuantitySelector;
