import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import debounce from 'lodash/debounce';

type SearchContextType = {
  searchString: string;
  setSearchString: (value: string) => void;
  clearSearchString: () => void;
  canUseSearch: boolean;
}

export const defaultSearchContext = {
  searchString: '',
  setSearchString: () => {},
  clearSearchString: () => {},
  canUseSearch: false
} as const;

const SearchContext = React.createContext<SearchContextType>(defaultSearchContext);


export const MenuSearchContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const flags = useFlags();
  const canUseSearch = flags.nvrCanSearchOoOrderPage;

  const [searchString, setSearchString] = useState('');

  const debouncedSetSearchString = useMemo(
    () => debounce((value: string) => setSearchString(value), 500),
    [setSearchString]
  );

  // sets the search string to '' without debounce
  const clearSearchString = useCallback(() => setSearchString(''), [setSearchString]);

  const contextData = useMemo(() => ({
    searchString,
    setSearchString: debouncedSetSearchString,
    clearSearchString,
    canUseSearch
  }), [searchString, debouncedSetSearchString, clearSearchString, canUseSearch]);

  return (
    <SearchContext.Provider value={contextData}>
      {children}
    </SearchContext.Provider>
  );
};

export const useMenuSearchContext = () => {
  return useContext(SearchContext);
};
