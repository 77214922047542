let sectionAnimationObserver: IntersectionObserver;

/**
 * The rootMargin option in the IntersectionObserverInit configuration object sets the margin around the viewport that the observer's root element uses for calculations.
 * In this case, setting rootMargin to '-35%' means that the intersection observer will consider elements to be intersecting the viewport only if they have
 * reached 35% (of the viewport) or more into the viewport.
 * This allows animations to start later after elements are more visible on screen, creating a more noticable transition effect.
 * */
const intersectionOptions: IntersectionObserverInit = { rootMargin: '-35%' };

// Returns an unhook function
const pageAnimator = (isEditor: boolean, _document?: Document): (() => void) => {
  try {
    if(isEditor) {
      // In the editor, don't animate the page
      return () => {};
    }
    const doc: Document | ShadowRoot = _document || document;
    if(!doc || typeof IntersectionObserver === 'undefined') throw new Error('no doc or intersection observer');
    sectionAnimationObserver = new IntersectionObserver(sections => {
      sections.forEach(section => {
        if(section.isIntersecting) {
          section.target.classList.add('animated');
          section.target.classList.remove('transparent');
        }
      });
    }, intersectionOptions);
    Array.from(doc.querySelectorAll('.sectionWrapper') || []).forEach(el => {
      el.classList.add('transparent');
      sectionAnimationObserver.observe(el);
    });
    return () => {
      sectionAnimationObserver.disconnect();
      Array.from(doc.querySelectorAll('.sectionWrapper') || []).forEach(el => {
        el.classList.remove('animated');
      });
    };
  } catch(e) {
    console.log(`Error: ${e.message}`);
    return () => {
      // do nothing
    };
  }
};

export default pageAnimator;
