import React, { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import Highlighter from 'react-highlight-words';
import { useParams } from 'react-router';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { MenuConfig, MenuTemplate } from 'src/apollo/sites';
import { useThrottledTracker } from 'src/lib/js/hooks/useTracker';
import { formatImageURL, slugify } from 'src/lib/js/utilities';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

import Image from 'shared/components/common/Image';
import { useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';

import ItemModal from 'public/components/default_template/online_ordering/item_modal/ItemModal';
import ReadOnlyItemModal from 'public/components/default_template/online_ordering/item_modal/ReadOnlyItemModal';
import MenuItemTag from 'public/components/default_template/online_ordering/item_tags/MenuItemTag';
import { useMenuSearchContext } from 'public/components/default_template/search';
import { useTimeBasedRules } from 'public/components/online_ordering/TimeBasedRuleContext';

import { MenuItem } from './MenuSection';

export const IMAGE_SUFFIX_REGEX = /\.(jpg|png)$/;

type ToastImageSet = {
  xs?: string;
  small?: string;
  medium?: string;
  large?: string;
}

type MenuItemImageSet = {
  '140w'?: string
  '300w'?: string
  '600w'?: string
  '900w'?: string
}

// These source set URLs are served by the toast photo service
// and are guaranteed to exist
export const getMenuItemSrcSet = (images: ToastImageSet): MenuItemImageSet => {
  const imgSet: MenuItemImageSet = {};
  if(images.xs) { imgSet['140w'] = formatImageURL(images.xs); }
  if(images.small) { imgSet['300w'] = formatImageURL(images.small); }
  if(images.medium) { imgSet['600w'] = formatImageURL(images.medium); }
  if(images.large) { imgSet['900w'] = formatImageURL(images.large); }
  return imgSet;
};

export const getTemplateClass = (template: MenuTemplate | undefined | null): string => {
  if(!template) return '';
  const classStr = MenuTemplate[template || ''];
  if(!classStr) return '';
  return classStr.charAt(0).toLowerCase() + classStr.slice(1);
};

type Props = {
  item: MenuItem;
  id?: string;
  setIsMapOrModalOpen?: (isOpen: boolean) => void;
  // True if the location map is open, or if any card has the modal open.
  isMapOrModalOpen?: boolean;
  // If true, formats the layout of the card as a popular item card.
  isPopularItem?: boolean;
  // True for ephemeral menu items that shouldn't be linked to.
  preventDeeplink?: boolean;
  isReadOnly?: boolean;
  menuConfig?: MenuConfig | null;
  rowHasDescription?: boolean;
}

type ItemContentProps = {
  item: MenuItem;
  // If true, formats the layout of the card as a popular item card.
  isPopularItem?: boolean;
  menuConfig?: MenuConfig | null;
  rowHasDescription?: boolean;
}

type Params = {
  itemSlug?: string;
  itemGuid?: string;
  guid?: string;
}

// React Router doesn't provide functionality for changing the current route
// without rerendering, so we use native browser functionality
const replaceUrl = (newUrl: string) => {
  window.history.replaceState(null, '', newUrl);
};

type ImageItemDescriptionProps = {
  menuTemplateConfig?: MenuTemplate | null;
  item: MenuItem;
  contentStyle: { color?: string };
  rowHasDescription?: boolean;
};

const ImageItemDescription = ({ menuTemplateConfig, item, contentStyle, rowHasDescription }: ImageItemDescriptionProps) => {
  const { searchString } = useMenuSearchContext();

  const content =
  <div className="itemContent">
    <p data-testid="item-content-description" style={contentStyle} className="itemDescription">{<Highlighter
      textToHighlight={item.description ?? ''}
      searchWords={[searchString]}
      autoEscape />}
    </p>
  </div>;

  return menuTemplateConfig === MenuTemplate.TopImage ?
    <>
      <div className="mobileDescription">
        {item.description ? content : null}
      </div>
      {rowHasDescription &&
        <div className="desktopDescription">
          {content}
        </div>}
    </> :
    item.description ?
      content
      : null;
};

export const ItemContent = (props: ItemContentProps ) => {
  const { item, isPopularItem, menuConfig } = props;
  const { restaurant: { config, meta: { primaryColor } } } = useRestaurant();
  const { timeBasedRulesMap } = useTimeBasedRules();
  const timeBasedRules = item.guid && timeBasedRulesMap[item.guid];
  const backgroundColor = menuConfig?.colors?.background as string;
  const imgSrc = useMemo(() => item.imageUrls?.medium || item.imageUrls?.sm || item.imageUrls?.large || item.imageUrls?.xs, [item.imageUrls]);
  const { searchString } = useMenuSearchContext();

  const menuTemplateConfig = menuConfig?.format?.template;
  const itemDescriptionTextColor = menuConfig?.colors?.secondaryText;
  const contentStyle = itemDescriptionTextColor ? { color: itemDescriptionTextColor } : {};

  const ooBasic = config.isOnlineOrderingOnly && config.hasFullCustomization === false;
  const sideImage = !menuTemplateConfig || menuTemplateConfig === MenuTemplate.SideImage;
  const squareImage = sideImage && (ooBasic || menuConfig?.format?.squareImages);

  const pricingLine = useMemo(() => {
    const perUnitString = item.usesFractionalQuantity && item.unitOfMeasure ? `/${item.unitOfMeasure.toLowerCase()}` : '';
    if(item.price) {
      return <span className="price" data-testid={`price-${item.guid}`}><FormattedPrice value={item.price} />{perUnitString}</span>;
    } else if(item.prices?.length) {
      return <span className="price" data-testid={`price-${item.guid}`}><FormattedPrice value={item.prices.sort((a, b) => a - b)[0]!} />{item.prices.length > 1 ? '+' : ''}{perUnitString}</span>;
    }
    return <span className="price" data-testid={`price-${item.guid}`}></span>;
  }, [item.price, item.prices, item.unitOfMeasure, item.usesFractionalQuantity, item.guid]);

  const pricingOrOutOfStockline = useMemo(() => {
    if(item.outOfStock) {
      return <span className="price">Out of stock</span>;
    }
    return pricingLine;
  }, [item.outOfStock, pricingLine]);

  if(isPopularItem) {
    const backgroundImage = !item.image && item.imageUrls?.raw ? formatImageURL(item.imageUrls.raw) : null;
    const popularItemBackground = backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : null;

    return (
      <div className={classnames('popularItem', popularItemBackground ? 'backgroundImage' : '' )} style={popularItemBackground ? popularItemBackground : undefined}>
        <div className="itemName">{props.item.name}</div>
      </div>
    );
  }
  if(menuTemplateConfig === MenuTemplate.Condensed || menuTemplateConfig === MenuTemplate.ExtraCondensed || menuTemplateConfig === MenuTemplate.Stacked) {
    return (
      <div className={classnames('itemInfo', getTemplateClass(menuTemplateConfig))}>
        <div className="itemHeadAvailDesc">
          {timeBasedRules && <MenuItemTag timeBasedRules={timeBasedRules} backgroundColor={backgroundColor} primaryColor={primaryColor} />}
          <div className="itemHeader">
            <Highlighter
              searchWords={[searchString]}
              textToHighlight={item.name ?? ''}
              autoEscape />
          </div>
          {item.description ?
            <div className="itemContent">
              <p style={contentStyle} className="itemDescription">
                <Highlighter
                  searchWords={[searchString]}
                  textToHighlight={item.description}
                  autoEscape />
              </p>
            </div>
            : null}
          <div className="priceContainer">{pricingOrOutOfStockline}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      {item?.image ? <Image alt={item.name || ''} src={item.image} className={classnames('itemImage', { squareImage })} /> : null}
      {!item.image && imgSrc && item.imageUrls ?
        <Image
          alt={item.name || ''}
          src={formatImageURL(imgSrc)}
          srcSet={getMenuItemSrcSet(item.imageUrls)}
          className={classnames('itemImage', { squareImage })} />
        : null}
      <div className="itemInfo">
        {timeBasedRules && <MenuItemTag timeBasedRules={timeBasedRules} backgroundColor={backgroundColor} primaryColor={primaryColor} />}
        <div className={classnames('itemHeader', { hasTag: timeBasedRules })}>
          <Highlighter
            searchWords={[searchString]}
            textToHighlight={item.name ?? ''}
            autoEscape />
        </div>
        {menuTemplateConfig === MenuTemplate.BigImage ?
          pricingOrOutOfStockline :
          <>
            <div className="priceAvailability">
              {pricingLine}
              {item.outOfStock ? <span>Out of stock</span> : null}
            </div>
            <ImageItemDescription menuTemplateConfig={menuTemplateConfig} item={item} contentStyle={contentStyle} rowHasDescription={props.rowHasDescription} />
          </>}
      </div>
    </>
  );
};

const MenuItemCard = (props: Props) => {
  const { isEditor } = useEditor();
  return isEditor ? <MenuItemCardBase {...props} preventDeeplink={true} disableClickEvents={true} /> : <MenuItemCardWithRouterParamsAndTracker {...props} />;
};

const MenuItemCardWithRouterParamsAndTracker = (props: Props) => {
  const params = useParams<Params>();
  const { track } = useThrottledTracker();
  return <MenuItemCardBase {...props} track={track} params={params} />;
};

type BaseProps = Props & {
  track?: (name: any, data: any) => void
  params?: Params
  disableClickEvents?: boolean
};

const menuItemStyle = (item: MenuItem, menuConfig?: MenuConfig | null) => {
  return {
    ...menuConfig?.colors?.background && { backgroundColor: menuConfig.colors.background },
    ...menuConfig?.colors?.primaryText && !item.outOfStock && { color: menuConfig.colors.primaryText }
  };
};

const MenuItemCardBase = ({ disableClickEvents = false, ...props } : BaseProps) => {
  const track = props.track;
  const { item, setIsMapOrModalOpen, isReadOnly, menuConfig } = props;
  const paramGuid = props.params?.itemSlug?.split('_')?.[1] || props.params?.guid || null;
  const isDeeplinkToItem = !!item.guid && !!paramGuid && paramGuid === item.guid && !props.preventDeeplink;
  const { restaurant, ooRestaurant, selectedLocation } = useRestaurant();
  const { orderPath } = useRestaurantRoutes();
  const { isOpen, onOpen, onClose } = useModal({ defaultOpen: isDeeplinkToItem });

  const openModal = useCallback(() => {
    onOpen();
    if(setIsMapOrModalOpen) {
      setIsMapOrModalOpen(true);
    }
  }, [onOpen, setIsMapOrModalOpen]);

  const closeModal = useCallback(() => {
    onClose();
    if(setIsMapOrModalOpen) {
      setIsMapOrModalOpen(false);
    }
    if(!isReadOnly) {
      replaceUrl(orderPath);
    }
  }, [onClose, setIsMapOrModalOpen, isReadOnly, orderPath]);

  const onItemClick = useCallback(e => {
    onItemClickImpl(e, isReadOnly, item, track, orderPath, openModal);
  }, [openModal, item, track, isReadOnly, orderPath]);

  const displayedRestaurantName = selectedLocation.name || restaurant.name;
  const metaTitle = `${displayedRestaurantName} - ${item.name}`;
  const metaDescription = `Order ${item.name} online from ${displayedRestaurantName}. ${item.description ?? ''}`.trimEnd();
  const imageSet = useMemo(() => item.imageUrls ? getMenuItemSrcSet(item.imageUrls) : {}, [item.imageUrls]);
  const openGraphImage = item.image ?? imageSet['600w'] ?? imageSet['900w'];

  if(!item.name) {
    return null;
  }

  const itemStyle = menuItemStyle(item, props.menuConfig);
  const menuTemplateConfig = menuConfig?.format?.template;

  const itemReference = !isReadOnly ? `${orderPath}/item-${slugify(item.name)}_${item.guid}` : undefined;
  return (
    <>
      {isDeeplinkToItem ?
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="og:title" content={metaTitle} />
          <meta name="description" content={metaDescription} />
          <meta name="og:description" content={metaDescription} />
          {openGraphImage && <meta name="og:image" content={openGraphImage}></meta>}
        </Helmet>
        : null}
      {isReadOnly ?
        <ReadOnlyItemModal
          withTransitions={!isDeeplinkToItem}
          isOpen={isOpen}
          onClose={closeModal}
          name={item.name}
          description={item.description}
          image={item?.image ? item.image : item.imageUrls?.raw ? item.imageUrls?.raw : undefined} />
        :
        <ItemModal
          withTransitions={!isDeeplinkToItem}
          isOpen={isOpen}
          onClose={closeModal}
          restaurantGuid={selectedLocation.externalId}
          shortUrl={ooRestaurant?.shortUrl}
          specialRequestsEnabled={ooRestaurant?.specialRequestsConfig.enabled}
          specialRequestsPlaceholder={ooRestaurant?.specialRequestsConfig.placeholderMessage}
          itemGroupGuid={item.itemGroupGuid}
          itemGuid={item.guid}
          shouldShowHighlights /> }
      {isReadOnly && (menuTemplateConfig === MenuTemplate.Condensed || menuTemplateConfig === MenuTemplate.ExtraCondensed || menuTemplateConfig === MenuTemplate.Stacked) ?
        <li key={item.guid || item.name}
          style={{ ...itemStyle, pointerEvents: disableClickEvents ? 'none' : 'auto' }}
          className={classnames('item',
            restaurant.config?.ooConfig?.cardOrientation || 'left',
            getTemplateClass(menuTemplateConfig))}>
          <ItemContent key={item.guid}
            item={item}
            isPopularItem={props.isPopularItem}
            menuConfig={props.menuConfig} />
        </li>
        :
        <li role="listitem"
          key={item.guid || item.name}
          className={classnames('item',
            restaurant.config?.ooConfig?.cardOrientation || 'left',
            getTemplateClass(menuTemplateConfig),
            { clickable: true, outOfStock: !!item.outOfStock })}
          style={{ ...itemStyle, pointerEvents: disableClickEvents ? 'none' : 'auto' }}>
          <a
            data-testid={'add-to-cart-' + item.guid}
            href={itemReference}
            id={props.id}
            onClick={onItemClick}
            onKeyDown={onItemClick}
            tabIndex={0}
            style={{ ...itemStyle, pointerEvents: disableClickEvents ? 'none' : 'auto' }}>
            <ItemContent key={item.guid}
              item={item}
              isPopularItem={props.isPopularItem}
              menuConfig={props.menuConfig}
              rowHasDescription={props.rowHasDescription} />
          </a>
        </li>}
    </>
  );
};

export const onItemClickImpl = (
  e: React.KeyboardEvent, isReadOnly: boolean | undefined, item: MenuItem, track: ((name: any, data: any) => void) | undefined, orderPath: string, openModal : () => void
) => {
  if(e.type === 'keydown' && (e.code !== 'Space' && e.code !== 'Enter')) {
    return; // only continue if the spacebar or enter key is pressed
  }
  e.preventDefault();
  e.stopPropagation();
  track?.('Clicked menu item', {
    guid: item.guid,
    name: item.name || 'No name',
    readOnly: !!isReadOnly,
    outOfStock: !!item.outOfStock,
    hasImage: Boolean(item.image),
    descLength: item.description ? item.description.length : 0,
    timeBasedRule: item.timeBasedRules?.leadTimeRule ? 'Minimum lead time' : item.timeBasedRules?.preorderRule ? 'Preorder' : null
  });
  if(!item.outOfStock) {
    openModal();
    if(!isReadOnly) {
      replaceUrl(`${orderPath}/item-${slugify(item.name || '')}_${item.guid}`);
    }
  }
};

export default MenuItemCard;
