import React from 'react';
import Skeleton from 'react-loading-skeleton';

import classnames from 'classnames';
import { Maybe } from 'graphql/jsutils/Maybe';

import { DiningOptionBehavior, Selection } from 'src/apollo/onlineOrdering';
import { CartErrorType } from 'src/public/components/online_ordering/CartContext';
import { use3pdConfig } from 'src/public/components/online_ordering/FulfillmentContext';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';
import { normalizeHtmlId } from 'src/shared/js/normalizeUtils';

import { useExperiment } from 'shared/components/common/ab_testing/ABTestContext';
import ErrorNotice from 'shared/components/common/error_notice';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import {
  CartSelections,
  CartSelection,
  CompletedOrderSelections,
  CompletedOrderSelection,
  CompletedOrderModifierSelection
} from 'public/components/online_ordering/types';

import ItemPrice from './ItemPrice';
import TaxesAndFees from './TaxesAndFees';

// This type allows flattenModifiers to return a quantity with CompletedOrderModifierSelection
export type WrappedCompletedOrderModifierSelection = Omit<CompletedOrderModifierSelection, 'modifiers'> & {
  quantity?: number;
  modifiers?: Array<WrappedCompletedOrderModifierSelection>;
};

type FlattenModifiersType = WrappedCompletedOrderModifierSelection | CartSelection;

export const flattenModifiers = (modifiers: Maybe<Partial<FlattenModifiersType>>[], level: number | undefined = 0): Array<FlattenModifiersType & { level: number }> => {
  if(!modifiers.length) {
    return [];
  }

  const nonNullModifiers: Partial<FlattenModifiersType>[] = modifiers
    .filter((m: Maybe<Partial<FlattenModifiersType>>): boolean => !!m) as Partial<FlattenModifiersType>[];

  const flattenedModifiers = [
    ...nonNullModifiers.flatMap(m => [{ ...m, level }, ...m?.modifiers ? flattenModifiers(m.modifiers, level + 1) : []])
  ];

  const countedModifiers = flattenedModifiers.reduce((map, mod) => {
    if(!mod || !mod.name) {
      return map;
    }

    return {
      ...map,
      [mod.name]: {
        modifier: mod,
        level: mod.level,
        count: (map[mod.name]?.count || 0) + 1
      }
    };
  }, {} as { [key: string]: any });

  return Object.values(countedModifiers)
    .map(mod => ({ ...mod.modifier, quantity: mod.count, level: mod.level }));
};

export const OrderItem = ({ selection, itemActions }: { selection: CompletedOrderSelection | CartSelection, itemActions?: React.ReactElement }) => {
  const anySelection = selection as Selection;
  const labelledById = `orderItemSelection-${normalizeHtmlId(anySelection.guid || '')}`;
  const { selectedVariant: mobileUiImprovements } = useExperiment('woo-mobile-ui-improvements');
  const isMobile = useIsMobile(ScreenWidth.SMALL);
  const mobileExperiment = isMobile && mobileUiImprovements === 'test';

  if(!selection) {
    return null;
  }

  const fractionalQuantity = selection.usesFractionalQuantity && `${selection.fractionalQuantity.quantity.toFixed(2)} ${selection.fractionalQuantity.unitOfMeasure.toLowerCase()}`;
  const isItemDiscounted = (selection.price || 0) < (selection.preDiscountPrice || 0);
  const discountedPrice = selection.price === 0 ? 'Free' : <FormattedPrice value={selection.price || 0} />;

  return (
    <div className={classnames('item', { mobileExperiment })} data-testid="orderItem">
      <div className={classnames('row', 'details')} id={labelledById}>
        <div className="quantity">
          {selection.quantity || 1}
        </div>
        <div className="cell">
          <h3 className={classnames('name', { mobileExperiment })}>{selection.name}</h3>
          {selection.usesFractionalQuantity ? <div className="fractional">{fractionalQuantity}</div> : null}
          <div className="mods">
            {selection.modifiers
              ? flattenModifiers(selection.modifiers)
                .map((modifier, index) =>
                  <div style={{ paddingLeft: `${modifier.level * 8}px` }}
                    className="mod" key={`mod${index}`}>{modifier?.quantity && modifier.quantity > 1 ? <span className="quantity">{modifier.quantity}</span> : null}{modifier?.name}
                  </div>)
              : null}
          </div>
        </div>
        <div className="rightColumn">
          <span data-testid="price" className={classnames('price', { ['discountedPrice']: isItemDiscounted, mobileExperiment })}><FormattedPrice value={selection.preDiscountPrice || 0} /></span>
          {isItemDiscounted && <span className="price">{discountedPrice}</span> }
        </div>
      </div>
      <div className={classnames('row', 'details')}>
        <div className="emptyCell">
            &nbsp;
        </div>
        <div className="actions">
          {itemActions}
        </div>
      </div>
    </div>
  );
};

type OrderPriceProps = {
  loading: boolean;
  subtotal?: number | null;
  discountsTotal?: number;
  diningOptionBehavior?: string;
  deliveryFee?: number | null;
  gratuityServiceCharges: number;
  processingServiceCharges: number;
  nonDeliveryNonGratuityNonUbpServiceCharges: number;
  tax: number;
  tip?: number;
  fundraisingAmount?: number | null;
  giftCardAppliedAmount?: number;
};

export const OrderPrices = (props: OrderPriceProps) => {
  const { uses3pd } = use3pdConfig();
  const is3pdDelivery = uses3pd && props.diningOptionBehavior === DiningOptionBehavior.Delivery;
  const { selectedVariant: mobileUiImprovements } = useExperiment('woo-mobile-ui-improvements');
  const isMobile = useIsMobile(ScreenWidth.SMALL);
  const mobileExperiment = isMobile && mobileUiImprovements === 'test';

  return (
    <div className={classnames('prices', { mobileExperiment })}>
      <ItemPrice loading={props.loading} title="Subtotal" amount={props.subtotal} />
      {props.discountsTotal ? <ItemPrice loading={props.loading} title="Discounts" amount={props.discountsTotal} negative={true} /> : null}
      {props.diningOptionBehavior === DiningOptionBehavior.Delivery && <ItemPrice loading={props.loading} title="Delivery" amount={props.deliveryFee} />}
      <TaxesAndFees
        loading={props.loading}
        tax={props.tax}
        gratuityServiceCharges={props.gratuityServiceCharges}
        processingServiceCharges={props.processingServiceCharges}
        nonDeliveryNonGratuityNonUbpServiceCharges={props.nonDeliveryNonGratuityNonUbpServiceCharges} />
      {props.tip ? <ItemPrice loading={props.loading} title={is3pdDelivery ? 'Driver tip' : 'Tip'} amount={props.tip} /> : null}
      {props.fundraisingAmount ? <ItemPrice loading={props.loading} title="Support & Give" amount={props.fundraisingAmount} /> : null}
      {props.giftCardAppliedAmount ? <ItemPrice loading={props.loading} title="Gift Card" amount={props.giftCardAppliedAmount} negative={true} /> : null}
    </div>
  );
};

interface ItemListProps {
  selections?: CompletedOrderSelections | CartSelections | null;
  ItemComponent?: any;
  itemProps?: any;
}

const ItemList = ( { selections, ItemComponent, itemProps }: ItemListProps) => {
  const { selectedVariant: mobileUiImprovements } = useExperiment('woo-mobile-ui-improvements');
  const isMobile = useIsMobile(ScreenWidth.SMALL);
  const mobileExperiment = isMobile && mobileUiImprovements === 'test';
  return (
    <ul className="itemList">
      {selections?.map((selection, index) =>
        <li className={mobileExperiment ? 'mobileExperiment' : undefined} key={`selection${index}`}>
          <ItemComponent selection={selection} {...itemProps} />
        </li>)}
    </ul>
  );
};

type OrderItemsProps = {
  loading: boolean;
  error?: CartErrorType | null;
  selections?: CompletedOrderSelections | CartSelections | null;
  hasItems?: boolean;
  ItemComponent?: any;
  itemProps?: any;
  noItemsComponent?: React.ReactElement;
};

export const OrderItems = (props: OrderItemsProps) => {
  const hasItems = 'hasItems' in props ? props.hasItems : !!props.selections?.length;
  const ItemComponent = props.ItemComponent || OrderItem;

  return (
    <div className="items">
      {props.error?.kind === 'CartOutOfStockError' || props.error?.kind === 'CartError' ? <ErrorNotice expireAfterSeconds={5}>{props.error.message}</ErrorNotice> : null}
      {props.loading ?
        <>
          <Skeleton style={{ marginBottom: '4px' }} />
          <Skeleton style={{ marginBottom: '4px' }} />
          <Skeleton style={{ marginBottom: '4px' }} />
          <Skeleton style={{ marginBottom: '4px' }} />
        </> :
        null}
      {!props.loading
        ? hasItems
          ? <ItemList selections={props.selections} ItemComponent={ItemComponent} itemProps={props.itemProps}></ItemList>
          : props.noItemsComponent
        : null}
    </div>
  );
};

export default OrderItems;
