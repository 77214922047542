import React, { useMemo } from 'react';

import classnames from 'classnames';

import { splitTextWithNewlines } from 'src/lib/js/utilities';

import Link from 'shared/components/common/link';

type Props = {
  title: string;
  subtitle: React.ReactNode;
  description: string;
  linkToURL?: string;
  className?: string;
};

const SimpleListing = ({ title, subtitle, description, linkToURL, className }: Props) => {
  const titleEl = useMemo(() => linkToURL ? <Link href={linkToURL} sameTab><div className="txt-body-xl">{title}</div></Link> : <div className="txt-body-xl">{title}</div>, [title, linkToURL]);

  return (
    <div className={classnames('simple-listing', className)}>
      <div className="listing-content">
        {titleEl}
        {subtitle}
        {splitTextWithNewlines(title, description) && <p className="txt-body-sm">{splitTextWithNewlines(title, description)}</p>}
      </div>
    </div>
  );
};

export default SimpleListing;
