import React from 'react';
import Skeleton from 'react-loading-skeleton';

import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

export type ItemPriceProps = {
  loading: boolean;
  title: string | React.ReactNode;
  amount?: number | null;
  negative?: boolean;
};

const ItemPrice = (props: ItemPriceProps) => {
  // show the amount if it is zero
  if(props.amount === undefined || props.amount === null) {
    return null;
  }

  // make negative if not already and let the formatter properly format negative currency
  const normalizedAmount = props.negative && props.amount > 0 ? props.amount * -1 : props.amount;

  return (
    <div data-testid={`${props.title}-item-test-id`} className={`cart-flex-row ${props.loading ? 'loading' : ''}`}>
      {props.loading ?
        <Skeleton width="100%" height="100%" /> :
        <>
          <span data-testid={'item-price-title'}>{props.title}</span>
          <span data-testid={'item-price-amount'}>{<FormattedPrice value={normalizedAmount} />}</span>
        </>}
    </div>
  );
};

export default ItemPrice;
