import { useCallback, useEffect } from 'react';

import { getTimezoneOffset, formatInTimeZone, toDate } from 'date-fns-tz';

import { ReservationHours, ReservationAvailability, useCancelReservationMutation, ServiceArea, DepositData } from 'src/apollo/sites';
import { reportError } from 'src/lib/js/clientError';

export const LOADING_DATE_STRING = '1900-01-01T00:00:00Z';
const FIFTEEN_MINUTES_MS = 15 * 60 * 1000;
const HOURS_PER_DAY = 24;
const MS_PER_HOUR = 60 * 60 * 1000;

export type SeatingLocation = {
  name: string;
  guid: string;
}

export type SeatingOption = {
  location: SeatingLocation;
  times: TimeWithDeposit[];
}

type TimeWithDeposit = {
  time: Date
  deposit?: Deposit
}

export type Deposit = {
  guid?: string;
  depositPolicy?: string;
  autoCancelUnpaidDeposit?: boolean;
  autoCancelUnpaidDepositTimeframe?: number;
  cancellationRefundableTimeframe?: number;
  actualAmount?: number;
  strategyType?: string;
  amountPerGuest?: number;
}

type ByPartySize = {
  type: 'ByPartySize';
  actualAmount: number;
  amountPerGuest: number;
};

type ByBooking = {
  type: 'ByBooking';
  actualAmount: number;
};

export const formatNumericDateString = (date: Date, timezone: string): string => formatInTimeZone(date, timezone, 'yyyy-MM-dd');
export const formatDateString = (date: Date, timezone: string): string => {
  return formatInTimeZone(date, timezone, 'MMM d');
};
export const formatTimeString = (date: Date, timezone: string): string => formatInTimeZone(date, timezone, 'h:mm aa');

export const getDateOptions = (bookingMaxHoursInAdvance: number, timezone: string): Date[] => {
  const dateOptions: Date[] = [];
  const now = new Date();

  if(bookingMaxHoursInAdvance <= 0) {
    return dateOptions;
  }

  const nowInMilliseconds = now.getTime() + getTimezoneOffset(timezone);
  const daysToAdd = Math.floor(bookingMaxHoursInAdvance / HOURS_PER_DAY);
  const remainingHours = bookingMaxHoursInAdvance % HOURS_PER_DAY;

  //Add every date in the 24 hour intervals
  for(let i = 0; i <= daysToAdd; i++) {
    const newDate = new Date(nowInMilliseconds);
    newDate.setDate(newDate.getDate() + i);
    dateOptions.push(newDate);
  }

  //Add the last 1 to 23 hours in advance date if it is not already in the list
  const possibleLastDateInTimezone = new Date(nowInMilliseconds + daysToAdd * HOURS_PER_DAY * MS_PER_HOUR); //Gets us to the final 24 hour interval day and time
  const absoluteLastDateMilliseconds = possibleLastDateInTimezone.getTime() + remainingHours * MS_PER_HOUR;
  const absoluteLastDateInTimezone = new Date(absoluteLastDateMilliseconds);
  if(possibleLastDateInTimezone.getDate() != absoluteLastDateInTimezone.getDate()) {
    dateOptions.push(absoluteLastDateInTimezone);
  }

  return dateOptions;
};

// Takes in a string of the format '13:00' that represents a time, and a timezone, and returns a date object
// representing that time in that timezone today.
const timeStringToDate = (time: string, timezone: string): Date => {
  const timeDate = new Date();

  const [hours, mins, secs] = time.split(':');
  timeDate.setUTCHours(parseInt(hours || '', 10) || 0);
  timeDate.setUTCMinutes(parseInt(mins || '', 10) || 0);
  timeDate.setUTCSeconds(parseInt(secs || '', 10) || 0);
  timeDate.setUTCMilliseconds(0);
  const timeTZ = new Date(timeDate.getTime() - getTimezoneOffset(timezone));
  return timeTZ;
};

// Given a list of start and end times, create a list of options within those time periods at 15 minute intervals.
export const getHoursListFromReservationHours = (reservationHoursList: (ReservationHours | undefined | null)[], timezone: string, datesLoading: boolean): Date[] => {
  if(datesLoading) {
    // Create an impossible restaurant reservation date to inform TimeSelector that it still hasn't processed all of its possible reservation times.
    // datesLoading has an intermediate state where it is false, but the reservationHoursList is still empty. Hence, we use the following 1900 date to
    // prevent the TimeSelector from flickering through unncessary messages during the loading state.
    // @see {@link https://toasttab.atlassian.net/browse/WOO-1109} for why we default to LOADING_DATE_STRING datetime
    return [new Date(LOADING_DATE_STRING)];
  }

  const reservationHourOptions: Date[] = [];
  if(!timezone) {
    return reservationHourOptions;
  }
  for(const reservationHours of reservationHoursList) {
    if(!reservationHours?.startTime || !reservationHours?.endTime) {
      continue;
    }

    const startDate = timeStringToDate(reservationHours.startTime, timezone);
    const endDate = timeStringToDate(reservationHours.endTime, timezone);
    if(endDate < startDate) {
      endDate.setDate(endDate.getDate() + 1);
    }
    let currTime = startDate;
    while(currTime.getTime() < endDate.getTime()) {
      reservationHourOptions.push(currTime);
      currTime = new Date(currTime.getTime() + FIFTEEN_MINUTES_MS);
    }
    reservationHourOptions.push(endDate);
  }
  return reservationHourOptions;
};

export const getSeatingOptionsFromAvailabilities = (reservationHoursList: (ReservationAvailability | undefined | null)[]): SeatingOption[] => {
  // seatingAreaData collects information about a seating location while we parse the reservation availabilities.
  const seatingAreaData: { [seatingGroupGuid: string]: SeatingLocation } = {};
  // seatingAreaAvailability collects available time slots and their deposits while we parse the reservation availabilities.
  const seatingAreaAvailability: { [seatingGroupGuid: string]: TimeWithDeposit[] } = {};


  // Collect available seats & times with their associated seating area and deposit information.
  for(const entry of reservationHoursList) {
    if(!entry?.dateTime) continue;
    const dateTime = new Date(entry?.dateTime);
    const serviceAreas = entry?.serviceAreas || [];

    serviceAreas.filter(skipBadServiceAreas).forEach(serviceArea => {
      const serviceAreaGroup = serviceArea.serviceAreaGroup;
      const seatingGroupGuid = serviceAreaGroup.guid;

      if(!seatingAreaData[seatingGroupGuid]) {
        seatingAreaData[seatingGroupGuid] = { guid: seatingGroupGuid, name: serviceAreaGroup.name || '' };
      }

      let deposit: Deposit | undefined = undefined;
      if(serviceArea?.deposit?.strategy?.actualAmount) {
        deposit = getDepositFromDepositData(serviceArea.deposit);
      }

      const availabilityData: TimeWithDeposit = {
        time: dateTime,
        deposit: deposit
      };
      seatingAreaAvailability[seatingGroupGuid] = seatingAreaAvailability[seatingGroupGuid] || [];
      seatingAreaAvailability[seatingGroupGuid]?.push(availabilityData);
    });
  }

  const seatingOptions: SeatingOption[] = Object.keys(seatingAreaData).map(seatingGroupGuid => ({
    location: seatingAreaData[seatingGroupGuid]!,
    times: seatingAreaAvailability[seatingGroupGuid] || []
  }));
  return seatingOptions;
};

const skipBadServiceAreas = (serviceArea: ServiceArea): serviceArea is AcceptableServiceArea => {
  const serviceAreaGroup = serviceArea.serviceAreaGroup;
  return Boolean(serviceAreaGroup?.guid && serviceAreaGroup?.enabled);
};

type AcceptableServiceArea = ServiceArea & { serviceAreaGroup: { guid: string, enabled: true }}

/**
 * Gets the deposit details from the API deposit data.
 *
 * @param deposit - The deposit data belonging to a service area.
 * @returns The deposit details if deposit data contains a strategy, otherwise undefined
 */

const getDepositFromDepositData = (
  deposit: DepositData
): Deposit | undefined => {
  if(deposit.strategy?.type === 'ByPartySize') {
    const strategy = deposit.strategy as ByPartySize;
    return {
      guid: deposit.guid!,
      depositPolicy: deposit.depositPolicy!,
      cancellationRefundableTimeframe: deposit.cancellationRefundableTimeframe!,
      strategyType: strategy.type,
      amountPerGuest: strategy.amountPerGuest,
      actualAmount: strategy.actualAmount
    };
  } else if(deposit.strategy?.type === 'ByBooking') {
    const strategy = deposit.strategy as ByBooking;
    return {
      guid: deposit.guid!,
      depositPolicy: deposit.depositPolicy!,
      cancellationRefundableTimeframe: deposit.cancellationRefundableTimeframe!,
      strategyType: strategy.type,
      actualAmount: strategy.actualAmount
    };
  } else {
    reportError(`Unsupported booking type: ${deposit.strategy}`);
    return undefined;
  }
};

// Returns a date object that has the date value from selectedDate, and the hours and minutes from selectedTime.
export const getSelectedDateTime = (selectedDate: Date, selectedTime: Date): Date => {
  const dateTime = new Date(selectedDate.getTime());
  dateTime.setHours(selectedTime.getHours(), selectedTime.getMinutes(), 0, 0);
  return dateTime;
};

// Return whether or not the current time is at least a certain amount of hours before the reservation time.
export const isBookingInAdvance = (selectedDateTime: Date, bookingMinHoursInAdvance: number): boolean => {
  const selectedTimeMillis = toDate(selectedDateTime).getTime();
  const bookingAdvanceTimeMillis = bookingMinHoursInAdvance * MS_PER_HOUR;
  const currentTimeMillis = Date.now();
  return currentTimeMillis + bookingAdvanceTimeMillis < selectedTimeMillis;
};

export const timeInFutureInUTC = (minutes: number): Date => {
  const currentTime = new Date();
  const futureTime = new Date(currentTime.getTime() + minutes * 60000); // Convert minutes to milliseconds

  return futureTime;
};

export type SelectReservation = (location: SeatingLocation, dateTime: Date, deposit?: Deposit) => void

export type OnReservationCancellation = (successfullyCancelled: boolean, depositExpired: boolean | null) => void

export const useReservationCancellation = (reservationGuid: string, restaurantGuid: string, onCancellation: OnReservationCancellation, isDepositExpired: boolean | null) => {
  const [cancelReservation, { data, error }] = useCancelReservationMutation();

  const cancel = useCallback(async () => {
    cancelReservation({
      variables: {
        bookingGuid: reservationGuid || '',
        restaurantGuid: restaurantGuid
      }
    });
  }, [cancelReservation, restaurantGuid, reservationGuid]);

  useEffect(() => {
    if(data || error) {
      onCancellation(!!data, isDepositExpired);
    }
  }, [onCancellation, data, error, isDepositExpired]);

  return { cancel };
};
