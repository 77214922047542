import React, { useMemo } from 'react';

import { formatLocationAddress } from 'public/components/online_ordering/addressUtils';
import { Location as LocationType } from 'public/components/online_ordering/types';

import { gMapsAPIKey } from 'config';

type Props = {
  restaurantName: string,
  location: LocationType
}

const Location = ({ restaurantName, location }: Props) => {
  const formattedAddress = useMemo(() => location ? `${restaurantName}, ${formatLocationAddress(location)}` : null, [location, restaurantName]);

  const center = useMemo(() => {
    if(location.longitude && location.latitude) {
      return {
        long: location.longitude,
        lat: location.latitude
      };
    }
    return null;
  }, [location.latitude, location.longitude]);

  let embeddedMapURL;
  if(formattedAddress) {
    // eslint-disable-next-line max-len
    embeddedMapURL = `https://maps.googleapis.com/maps/api/staticmap?key=${gMapsAPIKey}&style=feature:poi|visibility:off&markers=color:0xFF4C00%7C${encodeURIComponent(formattedAddress)}&zoom=14&size=640x300`;
  } else if(center) {
    // eslint-disable-next-line max-len
    embeddedMapURL = `https://maps.googleapis.com/maps/api/staticmap?key=${gMapsAPIKey}&center=${center.lat},${center.long}&style=feature:poi|visibility:off&markers=color:0xFF4C00%7C${center.lat},${center.long}&zoom=10&size=640x300`;
  }
  const directionsURL = useMemo(() => formattedAddress ? `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(formattedAddress)}}` : '', [formattedAddress]);

  return (
    <div className="location-container">
      <p>{formatLocationAddress(location)}</p>
      <p><a href={directionsURL} target="_blank" rel="noreferrer noopener">Directions</a></p>
      {embeddedMapURL && <img height="300" loading="lazy" src={embeddedMapURL} className="location-img" />}
    </div>
  );
};

export default Location;
