import React, { useContext, useCallback, createContext, useState, useMemo, useEffect, useRef } from 'react';

import { Modifier, Action, WrappedModifier, getWrappedModifierPrice, collapseModifierGroups, ModifierGroupData } from './types';

export type ModifierContextState = {
  pushModifier: (groupGuid: string, mod: Modifier, action: Action) => void;
  dropModifier: () => void;
  commitModifier: () => void;
  select: (groupGuid: string, itemGuid: string, quantity: number, action: Action, itemGroupGuid?: string | null, name?: string, price?: number | null, isDefault?: boolean | null) => void;
  deselect: (groupGuid: string, itemGuid: string) => void;
  addSpecialRequests: (request: string) => void;
  displayedModifier: WrappedModifier | null;
  price: number;
  isInvalid: (groupGuid: string) => boolean;
  quantity: number;
  setQuantity: (quantity: number) => void;
  modifiers: WrappedModifier[];
  validate: () => any;
  registerModifierGroup: (guid: string) => { [key: string]: React.RefObject<HTMLDivElement> };
  modifierGroupData: ModifierGroupData;
}

type RegisteredModifierGroups = { [guid: string]: React.RefObject<HTMLDivElement> };

type Props = {
  // the root modifier (the base selected item)
  modifier: WrappedModifier;
  quantity: number;
  rootRef?: React.RefObject<HTMLDivElement>;
  modifierGroupData: ModifierGroupData;
}

const ModifierContext = createContext<ModifierContextState | undefined>(undefined);

export const ModifierContextProvider = (props: React.PropsWithChildren<Props>) => {
  const [hasValidated, setHasValidated] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [modifiers, setModifiers] = useState<WrappedModifier[]>([]);
  const [modifierGroupCredits, setModifierGroupCredits] = useState<{[key: string]: number }>({});
  const displayedModifier = useMemo(() => modifiers.length ? modifiers[modifiers.length - 1] || null : null, [modifiers]);
  const displayedModifierGroups = useRef<RegisteredModifierGroups>({});
  const [selectedModifiers, setSelectedModifiers] = useState<{[key: string]: string}>({});

  // when the GraphQL query resolves, force a rerender with the root data
  useEffect(() => {
    setModifiers(modifiers => {
      if(modifiers.length <= 1) {
        return [props.modifier];
      }

      return [props.modifier, ...modifiers.slice(1, modifiers.length)];
    });
  }, [props.modifier.modifierGroups, props.modifier.price, props.modifier]);

  useEffect(() => {
    setQuantity(props.quantity);
  }, [props.quantity]);

  const registerModifierGroup = useCallback((groupGuid: string) => {
    const ref = React.createRef<HTMLDivElement>();
    displayedModifierGroups.current[groupGuid] = ref;

    return { ref };
  }, []);

  const select = useCallback((groupGuid: string, itemGuid: string, quantity: number, action: Action, itemGroupGuid?: string | null, name?: string, price?: number | null,
    isDefault?: boolean | null) => {
    const isExistingItem = !!modifiers[modifiers.length - 1]?.modifierGroups[groupGuid]?.[itemGuid];
    const existingDefaultItemInGroup = Object.values(modifiers[modifiers.length - 1]?.modifierGroups[groupGuid] || {})?.filter(mod => mod.isDefault)[0];

    // for checkbox menu groups with default options substitution pricing
    // if this is a default item (meaning we removed it and are adding it back) with a price
    // then we decrease that amount from the credits to this modifier group
    if(!isExistingItem && isDefault && quantity === 1 && price && props.modifierGroupData[groupGuid]?.defaultOptionsSubstitutionPricing === 'YES') {
      setModifierGroupCredits(credits => ({
        ...credits,
        [groupGuid]: (credits[groupGuid] || 0) - price
      }));
    } else if(action === 'replace' && existingDefaultItemInGroup && props.modifierGroupData[groupGuid]?.defaultOptionsSubstitutionPricing === 'YES') {
      // for radio button menu groups with default options substitution pricing, find
      // if the existing checked item was default and credit that price to the modifier group
      setModifierGroupCredits(credits => ({
        ...credits,
        [groupGuid]: (credits[groupGuid] || 0) + (existingDefaultItemInGroup.price || 0)
      }));
    }

    setModifiers(modifiers => {
      if(modifiers.length && modifiers[modifiers.length - 1]) {
        if(action === 'merge') {
          modifiers[modifiers.length - 1]!.modifierGroups[groupGuid] = {
            ...modifiers[modifiers.length - 1]!.modifierGroups[groupGuid],
            [itemGuid]: {
              itemGuid,
              itemGroupGuid,
              isDefault: isDefault || false,
              quantity,
              price,
              name,
              modifierGroups: {}
            }
          };
        } else {
          modifiers[modifiers.length - 1]!.modifierGroups[groupGuid] = {
            [itemGuid]: {
              itemGuid,
              itemGroupGuid,
              isDefault: isDefault || false,
              quantity,
              price,
              name,
              modifierGroups: {}
            }
          };
        }
      }
      setSelectedModifiers(map => ({
        ...map,
        [groupGuid]: itemGuid
      }));

      // force return a new array here and in deselect to force the
      // displayedModifier useMemo to rerun
      return [...modifiers];
    });
  }, [props.modifierGroupData, modifiers]);

  const deselect = useCallback((groupGuid: string, itemGuid: string) => {
    const modifier = modifiers[modifiers.length - 1]?.modifierGroups[groupGuid]?.[itemGuid];
    if(modifier?.isDefault && props.modifierGroupData[groupGuid]?.defaultOptionsSubstitutionPricing === 'YES') {
      setModifierGroupCredits(credits => ({
        ...credits,
        [groupGuid]: (credits[groupGuid] || 0) + (modifier.price || 0)
      }));
    }

    setModifiers(modifiers => {
      if(modifiers.length && modifiers[modifiers.length - 1]?.modifierGroups[groupGuid]) {
        delete modifiers[modifiers.length - 1]!.modifierGroups[groupGuid]![itemGuid];
      }
      return [...modifiers];
    });
    setSelectedModifiers(map => {
      delete map[groupGuid];
      return map;
    });
  }, [modifiers, props.modifierGroupData]);

  const pushModifier = useCallback((groupGuid: string, modifier: Modifier, action: Action = 'merge') => {
    const newModifiers = modifiers[modifiers.length - 1]?.modifierGroups[groupGuid]?.[modifier.itemGuid]?.modifierGroups;

    // Load any existing modifier selections if we have them,
    // otherwise use the default
    const existingModifiers = modifiers.length && newModifiers ?
      newModifiers || {}
      : collapseModifierGroups(modifier.modifierGroups);

    setModifiers(modifiers => modifiers.concat({
      groupGuid,
      modifier,
      modifierGroups: existingModifiers,
      action
    }));
    displayedModifierGroups.current = {};
    setHasValidated(false);
  }, [modifiers]);

  const dropModifier = useCallback(() => {
    setModifiers(modifiers => modifiers.slice(0, modifiers.length - 1));
    displayedModifierGroups.current = {};
    setHasValidated(false);
  }, []);

  const commitModifier = useCallback(() => {
    setModifiers(modifiers => {
      const committedMod = modifiers[modifiers.length - 1];
      const remaining = modifiers.slice(0, modifiers.length - 1);
      const lastUncommitted = remaining.length ? remaining[remaining.length - 1] : null;

      if(lastUncommitted && committedMod) {
        lastUncommitted.modifierGroups = {
          ...lastUncommitted.modifierGroups,
          [committedMod.groupGuid]: {
            ...committedMod.action === 'merge' ? lastUncommitted.modifierGroups[committedMod.groupGuid] : null,
            [committedMod.modifier.itemGuid]: {
              itemGuid: committedMod.modifier.itemGuid,
              itemGroupGuid: committedMod.modifier.itemGroupGuid,
              modifierGroups: committedMod.modifierGroups,
              isDefault: committedMod.isDefault,
              quantity: 1,
              price: committedMod.modifier.price
            }
          }
        };
      }

      return remaining;
    });
    displayedModifierGroups.current = {};
    setHasValidated(false);
  }, []);

  const addSpecialRequests = useCallback((requests: string) => {
    setModifiers(modifiers => {
      if(modifiers.length && modifiers[0]) {
        modifiers[0].specialInstructions = requests;
      }

      // force return a new array here to force the
      // displayedModifier useMemo to rerun
      return [...modifiers];
    });
  }, []);

  const price = useMemo(() => {
    return displayedModifier ? getWrappedModifierPrice(displayedModifier, props.modifierGroupData, modifierGroupCredits, selectedModifiers) : 0;
  // force price update when the modifiers change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiers, modifierGroupCredits]);

  const invalidRequiredGroups = useMemo(() => {
    return displayedModifier ?
      displayedModifier.modifier.modifierGroups
        .filter(group => {
          if(!group.minSelections && !group.maxSelections) {
            return false;
          }

          const groupSelections = displayedModifier.modifierGroups[group.guid];

          const groupSelectionsCount = groupSelections ?
            Object.values(groupSelections)
              .map(selection => selection.quantity)
              .reduce((acc, val) => acc + val, 0)
            : 0;

          if(group.minSelections) {
            if(!groupSelections || groupSelectionsCount < group.minSelections) {
              return true;
            }
          }

          if(group.maxSelections) {
            if(groupSelections && groupSelectionsCount > group.maxSelections) {
              return true;
            }
          }

          return false;
        })
      : [];
  // force update when the modifiers change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiers]);

  const isInvalid = useCallback((groupGuid: string): boolean => {
    return hasValidated && !!invalidRequiredGroups.find(group => group.guid === groupGuid);
  // force update when the modifiers change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiers, hasValidated]);

  const validate = useCallback(() => {
    setHasValidated(true);
    const firstInvalid = invalidRequiredGroups[0];
    if(firstInvalid) {
      const scrollToRef = displayedModifierGroups.current[firstInvalid.guid];
      if(scrollToRef?.current && props.rootRef?.current) {
        props.rootRef.current.scrollTo({ top: scrollToRef.current.offsetTop, behavior: 'smooth' });
      }

      return false;
    }

    return true;
  }, [invalidRequiredGroups, props, displayedModifierGroups]);

  return (
    <ModifierContext.Provider value={{
      pushModifier,
      dropModifier,
      commitModifier,
      select,
      deselect,
      addSpecialRequests,
      displayedModifier,
      price,
      isInvalid,
      quantity,
      setQuantity,
      modifiers,
      validate,
      registerModifierGroup,
      modifierGroupData: props.modifierGroupData
    }}>
      {props.children}
    </ModifierContext.Provider>
  );
};

export const useOptionalModifierContext = () => {
  return useContext(ModifierContext);
};

export const useModifierContext = () => {
  const context = useContext(ModifierContext);
  if(!context) {
    throw new Error('useModifierContext must be used within a ModifierContextProvider');
  }

  return context;
};
