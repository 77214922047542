import * as React from 'react'
import { initGuestAuthentication } from '../initialization'
import { AuthProviderCommon } from './AuthProviderCommon'
import { confirmVerificationCode, confirmPasswordless } from './confirm-passwordless'
import { getAuthenticationHeader } from './authentication-header'
import { logOut } from './log-out'
import { startPasswordless } from './start-passwordless'

export { useAuth } from './AuthProviderCommon'

/**
 * React Context that provides various authentication related functionality for guest accounts.
 * Wrap around the highest level component, under which authentication functionality is required.
 *
 * Upon mounting, attempt to refresh the guest's session. If successful, setIsAuthenticated will be
 * called with `true`, setting the authentication state to be authenticated.
 *
 * It's up to the consuming application to decide when to call setIsAuthenticated after successful
 * calls to confirmVerificationCode, confirmPasswordless, and logOut.
 */
export function AuthProvider({
  gatewayOrigin,
  children
}: {
  gatewayOrigin: string
  children: React.ReactNode
}) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  return (
    <AuthProviderCommon
      gatewayOrigin={gatewayOrigin}
      initGuestAuthentication={initGuestAuthentication}
      confirmVerificationCode={confirmVerificationCode}
      confirmPasswordless={confirmPasswordless}
      getAuthenticationHeader={getAuthenticationHeader}
      logOut={logOut}
      isAuthenticated={isAuthenticated}
      setIsAuthenticated={setIsAuthenticated}
      startPasswordless={startPasswordless}
    >
      {children}
    </AuthProviderCommon>
  )
}
