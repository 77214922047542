import React, { ReactNode, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import classnames from 'classnames';

import Image from 'shared/components/common/Image';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import { useCart } from 'public/components/online_ordering/CartContext';


const CheckoutSection = ({
  title,
  collapsable = false,
  children
} : {
  title?: string;
  collapsable?: boolean;
  children: ReactNode;
}) => {
  const { cart } = useCart();
  const [expanded, setExpanded] = useState(false);

  if(!cart) {
    return (
      <section className="checkoutSection">
        <Skeleton width="100%" height="500px" />
      </section>
    );
  }

  if(collapsable && title) {
    return (
      <section className="checkoutSection">
        <div className="separator top" />
        <div className={classnames('checkoutSectionHeader collapsable')} onClick={() => setExpanded(!expanded)}>
          {title}
          <Image alt={`${expanded ? 'Collapse' : 'Expand'} section`} className={classnames('controlIcon', expanded ? 'rotatedUp' : 'rotatedDown')} src="icons/chevron-right-gray.svg" />
        </div>
        <AnimatedSection expanded={expanded}>
          {children}
        </AnimatedSection>
        <div className="separator bottom" />
      </section>
    );
  }

  return (
    <section className="checkoutSection" >
      {title &&
        <div className={classnames('checkoutSectionHeader')} >
          {title}
        </div>}
      {children}
    </section>
  );
};

export default CheckoutSection;
