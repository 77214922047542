import React from 'react';

import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

import Image from 'shared/components/common/Image';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModal } from 'shared/components/common/modal';

import ItemPrice from './ItemPrice';


const TAXES_DESC = 'Local taxes applied.';
const GRATUITIES_DESC = 'Set by restaurants to help compensate servers.';
const SERVICE_DESC = 'Set by restaurants to help cover operational costs.';
const PROCESSING_DESC = 'Set by Toast to help provide affordable digital ordering services for local restaurants.';

type FeeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  tax: number;
  gratuityServiceCharges: number;
  nonDeliveryNonGratuityNonUbpServiceCharges: number;
  processingServiceCharges: number;
};

const FeeContainer = ({ title, fee, desc }: { title: string, fee: number, desc: string }) =>
  <div className="fee">
    <div className="row">
      <div>{title}</div>
      <div className="bolder"><FormattedPrice value={fee} /></div>
    </div>
    <div className="feeDescription text-color-gray txt-body-sm">{desc}</div>
  </div>;

const FeeModal = (props: FeeModalProps) =>
  <Modal isOpen={props.isOpen} onClose={props.onClose} >
    <ModalOverlay fadeIn />
    <ModalContent fadeIn>
      <div className="feeModal">
        <ModalCloseButton className="closeButton" />
        <div className="title txt-title-md">What fees are there?</div>
        {props.tax > 0 ? <FeeContainer title="Taxes" fee={props.tax} desc={TAXES_DESC} /> : null}
        {props.gratuityServiceCharges > 0 ? <FeeContainer title="Gratuities" fee={props.gratuityServiceCharges} desc={GRATUITIES_DESC} /> : null}
        {props.nonDeliveryNonGratuityNonUbpServiceCharges > 0 ? <FeeContainer title="Service Fee" fee={props.nonDeliveryNonGratuityNonUbpServiceCharges} desc={SERVICE_DESC} /> : null}
        {props.processingServiceCharges > 0 ? <FeeContainer title="Order Processing Fee" fee={props.processingServiceCharges} desc={PROCESSING_DESC} /> : null}
      </div>
    </ModalContent>
  </Modal>;

type TaxesAndFeesProps = {
  loading: boolean;
  tax: number;
  gratuityServiceCharges: number;
  processingServiceCharges: number;
  nonDeliveryNonGratuityNonUbpServiceCharges: number;
};

const TaxesAndFees = (props: TaxesAndFeesProps) => {
  const { isOpen, onClose, onOpen } = useModal();

  const feeSum =
    props.gratuityServiceCharges +
    props.processingServiceCharges +
    props.nonDeliveryNonGratuityNonUbpServiceCharges;

  if(props.tax === 0 && feeSum === 0) {
    return null;
  }

  const title = feeSum > 0 ?
    <div className="taxesAndFees">
      <span>Taxes &amp; Fees</span>
      <Image className="infoIcon" onClick={onOpen} src="icons/info.svg" />
    </div> :
    'Tax';

  return (
    <>
      <FeeModal
        isOpen={isOpen}
        onClose={onClose}
        tax={props.tax}
        gratuityServiceCharges={props.gratuityServiceCharges}
        processingServiceCharges={props.processingServiceCharges}
        nonDeliveryNonGratuityNonUbpServiceCharges={props.nonDeliveryNonGratuityNonUbpServiceCharges} />
      <ItemPrice loading={props.loading} title={title} amount={feeSum + props.tax} />
    </>
  );
};

export default TaxesAndFees;
