import React from 'react';

import { TLRestaurantType } from 'public/components/default_template/restaurant_profile/profileUtils';

import Pill from './Pill';

type Props = {
  restaurant: TLRestaurantType;
};

const CuisinePills = ({ restaurant }: Props) =>
  (restaurant.cuisines?.length || 0) > 0 ?
    <div className="cuisine-pills">
      {restaurant.cuisines?.filter(cuisine => cuisine != null).map(cuisine => <Pill key={cuisine?.slug}>{cuisine?.name}</Pill>)}
    </div> :
    null;

export default CuisinePills;
