import React from 'react';

import {
  PaymentType,
  DiningOptionBehavior,
  LoyaltyInquiryResponse,
  useCustomerLoyaltyInfoQuery
} from 'src/apollo/onlineOrdering';

import Image from 'shared/components/common/Image';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';

import LoyaltySignup from 'public/components/default_template/online_ordering/confirmation/LoyaltySignup';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { useCompletedOrder } from 'public/components/online_ordering/OrderContext';
import { CompletedOrder } from 'public/components/online_ordering/types';

const LoyaltyMessage = ({ message }: { message: string }) =>
  <div className="loyaltyMessageWrapper">
    <Image alt="Loyalty reward" src="icons/loyalty-reward.svg" />
    <div className="loyaltyMessage">{message}</div>
  </div>;


const LoyaltyNote = ({ order }: { order: CompletedOrder }) =>
  <div className="loyaltyNote">
    You&apos;ll accumulate points when this order is
    {order.diningOptionBehavior === DiningOptionBehavior.Delivery ? ' delivered' : ' picked up'}.
    {order.paymentType !== PaymentType.Cash && <><br />Check your email receipt for more details.</>}
  </div>;

const LoyaltySection = ({ order, customerGuid }: { order: CompletedOrder, customerGuid?: string }) => {
  const { data, loading } = useCustomerLoyaltyInfoQuery({
    variables: { input: { restaurantGuid: order.restaurant.guid } },
    skip: !customerGuid
  });
  const cxLoyaltyData = data?.customerLoyaltyInfo as LoyaltyInquiryResponse;

  return loading ?
    <LoadingSpinnerOverlay /> :
    cxLoyaltyData?.pointsBalance !== undefined ?
      <>
        <LoyaltyMessage message={`You have ${cxLoyaltyData.pointsBalance} points`} />
        {order.hasLoyaltyAttached && <LoyaltyNote order={order} />}
      </> :
      <>
        <LoyaltyMessage message="You earned points" />
        {order.hasLoyaltyAttached ?
          <LoyaltyNote order={order} /> :
          order.customerV2 ?
            <LoyaltySignup
              completedOrder={order}
              customerGuid={customerGuid}
              customerFirstName={order.customerV2.firstName}
              customerLastName={order.customerV2.lastName}
              customerEmail={order.customerV2.email}
              customerPhone={order.customerV2.phone} /> :
            null}
      </>;
};

const LoyaltySectionWrapper = () => {
  const { customer } = useCustomer();
  const { completedOrder, loadingCompletedOrder } = useCompletedOrder();

  if(loadingCompletedOrder) {
    return (
      <div className="loyaltySection">
        <LoadingSpinnerOverlay />
      </div>
    );
  }

  if(!completedOrder) {
    return null;
  }

  return (
    <div className="loyaltySection">
      <div className="loyaltyTitle">{completedOrder.restaurant.loyaltyConfig.programName || 'Rewards'}</div>
      <LoyaltySection order={completedOrder} customerGuid={customer?.id} />
    </div>
  );
};

export default LoyaltySectionWrapper;
