import React, { useMemo } from 'react';

import { useRestaurantSchedulesQuery } from 'src/apollo/onlineOrdering';
import { LocationSelector } from 'src/shared/components/common/form_input/LocationInput';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { schedulesByGuid } from 'public/components/online_ordering/scheduleUtils';
import { ScheduleType } from 'public/components/online_ordering/types';

const OOLocationSelector = () => {
  const { selectedLocation, updateLocation, locations } = useRestaurant();

  const locationsGuids = locations?.map(l => l.externalId) || [];

  const { data: scheduleData } = useRestaurantSchedulesQuery({
    variables: { restaurantGuids: locationsGuids },
    skip: !locationsGuids.length
  });

  const locationSchedules = useMemo(() => schedulesByGuid(scheduleData), [scheduleData]) as { [key: string]: ScheduleType };
  const ooLocations = useMemo(() => locations?.filter(l => locationSchedules[l.externalId]?.onlineOrderingEnabled) || [], [locations, locationSchedules]);

  if(!ooLocations?.length || ooLocations.length === 1) {
    return null;
  }

  return (
    <div className="locationSelector">
      <div className="dropDownWrapper">
        <LocationSelector id="oo-location-selector" locations={ooLocations} selectedLocation={selectedLocation} setSelectedLocation={updateLocation} withIcon withBorder />
      </div>
    </div>
  );
};

export default OOLocationSelector;
