import queryString from 'query-string';

import { RestaurantQuery } from 'src/apollo/toastLocal';
import { toBase64 } from 'src/lib/js/base64';

import { TOASTTAB_PROFILE_PATH } from 'public/js/siteUtilities';

import { CarouselElement } from './rx_carousel/RXCarousel';

export type TLRestaurantType = RestaurantQuery['restaurant'] & { __typename: 'RestaurantType' };
export type TLRestaurantLocations = TLRestaurantType['locations'];
type NearbyRestaurants = TLRestaurantType['similarNearbyRestaurants'] & { __typename: 'RXListType' };
type NearbyRestaurantsList = NearbyRestaurants['edges'];

// Takes a list of type nearbyRestaurantFields and gets a list of 6 restaurants
// that have the fields needed for a CarouselListing. Removes duplicate entries
// and the current restaurant.
export const getRestaurantCarouselList = (restaurantList: NearbyRestaurantsList, rxName: string | undefined | null): CarouselElement[] => {
  const rxList = new Map<string, CarouselElement>();
  if(restaurantList) {
    restaurantList.map(rx => {
      // Deduplicate restaurants that have the same name and address. Also remove the current restaurant we are looking at.
      const rxKey = rx?.name + ' ' + (rx?.locations?.length ? rx.locations[0]?.address : '');
      if(!rxList.has(rxKey) && rx?.name != rxName) {
        const el: CarouselElement = {
          name: rx.name,
          path: `${TOASTTAB_PROFILE_PATH}/${rx.slug}/r-${rx?.guid}`,
          images: rx.images,
          avgRating: rx.meta?.avg_rating,
          numRatings: rx.meta?.num_ratings,
          locations: rx.locations
        };
        rxList.set(rxKey, el);
      }
    });
  }
  return Array.from(rxList.values()).slice(0, 6);
};

export const getSimilarRestaurantsLink = (cuisineId: string | undefined | null): string | undefined => {
  if(!cuisineId) {
    return undefined;
  }
  return `/local/search?${queryString.stringify({
    filters: toBase64(JSON.stringify({ cuisineId: Number(cuisineId) })),
    pg: 1
  })}`;
};

export const getPopularRestaurantsLink = (parentCitySlug: string | undefined | null, citySlug: string | undefined | null ): string => {
  if(citySlug) {
    if(parentCitySlug) {
      return `/local/${parentCitySlug}-restaurants/${citySlug}`;
    } else {
      return `/local/${citySlug}-restaurants`;
    }
  }
  return '/local/search';
};
