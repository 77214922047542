import React, { ReactNode } from 'react';

import classnames from 'classnames';

type Props = {
  type: 'radio' | 'checkbox';
  name: string;
  disabled?: boolean;
  checked?: boolean;
  id: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  dataTestId?: string;
  textOnly?: boolean;
  children?: ReactNode;
  ariaLabel?: string
}

const WrappedToggleInput = (props: Props, ref: React.RefObject<any>) => {
  return (
    <div className="option">
      <input
        ref={ref}
        className={props.type}
        type={props.type}
        role={props.type}
        name={props.name}
        id={props.id}
        disabled={props.disabled}
        checked={props.checked}
        onChange={props.onChange}
        onClick={props.onClick}
        onKeyDown={props.onKeyDown}
        data-testid={props.dataTestId}
        aria-labelledby={props.ariaLabel} />
      <label className={classnames('label', { showInput: !props.textOnly })} htmlFor={props.id}>
        <div className="labelContents">
          {props.children}
        </div>
      </label>
    </div>
  );
};

const ToggleInput = React.forwardRef(WrappedToggleInput);
export default ToggleInput;
