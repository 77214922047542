import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';

import * as Sentry from '@sentry/react';
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import isEmail from 'validator/lib/isEmail';

import { DiningOptionBehavior, LoginErrorCode, MfaType } from 'src/apollo/onlineOrdering';
import { ButtonType } from 'src/apollo/sites';
import useTracker from 'src/lib/js/hooks/useTracker';
import Button from 'src/shared/components/common/button';
import { ShowForUS } from 'src/shared/components/common/show_for_us/ShowForUS';

import Image from 'shared/components/common/Image';
import FormInput from 'shared/components/common/form_input';
import PhoneInput from 'shared/components/common/form_input/PhoneInput';
import { ToggleInput } from 'shared/components/common/forms';
import { Modal, ModalContent, ModalOverlay } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import ToastLogo from 'public/components/default_template/logo/Logo';
import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';
import { ConfirmationCodeInputs } from 'public/components/default_template/online_ordering/customer/ConfirmationCode';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';

import { AuthenticationSource, getCustomerInfo } from './checkoutUtils';

export const PHONE_FOCUS_MSG = 'By providing a mobile number, you give Toast permission to contact you using automated text messages to provide transactional messages such as order status updates.';

const getErrorText = (errorCode?: LoginErrorCode) => {
  switch(errorCode) {
    case LoginErrorCode.EmailLocked:
      return 'This email address is locked. Please try again later';
    case LoginErrorCode.IncorrectCredentials:
      return 'Those credentials are incorrect. Please try again.';
    case LoginErrorCode.ServiceUnavailable:
      return 'This service is currently unavailable. Please try again later';
    default:
      return 'There was an error logging you in. Please try again later.';
  }
};

const useToastSignup = () => {
  const { refetchCustomer, completeSignup, emailAccountExists, fetchCustomer } = useCustomer();
  const { setSaveNewCreditCard } = usePayment();
  const [showingEmailModal, setShowingEmailModal] = useState(false);
  const tracker = useTracker();

  const email = useWatch({ name: 'yourInfoEmail' });
  const firstName = useWatch({ name: 'yourInfoFirstName' });
  const lastName = useWatch({ name: 'yourInfoLastName' });

  const linkAccounts = useCallback(async (email?: string) => {
    // Check for existing account based on email, link if exists
    const hasEmailAccount = await emailAccountExists(email);
    if(hasEmailAccount) {
      setShowingEmailModal(true);
    }
  }, [setShowingEmailModal, emailAccountExists]);

  const onToastSignup = useCallback(async () => {
    setSaveNewCreditCard(true);

    try {
      // Fetch customer with new access token
      const customer = await fetchCustomer();
      const newOrExistingCustomer = customer ? 'existing account' : 'new account';

      if(!customer) {
        // Only complete profile if the customer identity profile doesn't yet exist
        await completeSignup(email, firstName, lastName);
        refetchCustomer();
        toast.success('Account created!');
      } else {
        refetchCustomer();
        toast.success('Welcome back!');
      }
      tracker.track('Completed 2FA', { source: AuthenticationSource.AccountCreation, newOrExistingCustomer });

      linkAccounts(email);
    } catch(err) {
      Sentry.captureException(`ERROR: error completing signup ${err}`);
    }
  }, [linkAccounts, setSaveNewCreditCard, refetchCustomer, fetchCustomer, completeSignup, email, firstName, lastName, tracker]);

  return { onToastSignup, showingEmailModal, setShowingEmailModal };
};

export const CustomerInfoSection = () => {
  const { customer } = useCustomer();
  const [editing, setEditing] = useState(false);
  const { paymentOption } = usePayment();

  return (
    <CheckoutSection>
      <AnimatedSection expanded={paymentOption !== PaymentOption.ApplePay} slowTransition>
        <div data-testid={customer ? 'toast-checkout-inputs' : 'guest-checkout-inputs'} role="form">
          <div className="yourInfoHeader">
            <div className="checkoutSectionHeader">Your info</div>
            {customer && <div className="editLink" role="button" onClick={() => setEditing(!editing)}>{editing ? 'Update' : 'Edit'}</div>}
          </div>
          <AnimatedSection expanded={Boolean(customer) && !editing} slowTransition testid="completed-info-animated-section">
            <CompletedInfo />
          </AnimatedSection>
          <AnimatedSection expanded={!customer || editing} slowTransition testid="customer-info-inputs-animated-section">
            <CustomerInfoInputs />
          </AnimatedSection>
        </div>
      </AnimatedSection>
    </CheckoutSection>
  );
};

const CustomerInfoInputs = () => {
  const { createAccount, setCreateAccount, saveNewAddress, setSaveNewAddress } = useCheckout();
  const { onToastSignup, showingEmailModal, setShowingEmailModal } = useToastSignup();
  const { customer, passwordlessLogin } = useCustomer();
  const tracker = useTracker();
  const { formState: { errors }, setFocus, clearErrors } = useFormContext();
  const { cart } = useCart();
  const { savedAddressUsed } = useDelivery();
  const [deliveryAddressLine2, setDeliveryLineAddress2] = useState(cart?.order?.deliveryInfo?.address2);
  const [deliveryNotes, setDeliveryNotes] = useState(cart?.order?.deliveryInfo?.notes);
  const { paymentOption } = usePayment();
  const { ooRestaurant } = useRestaurant();

  useEffect(() => {
    if(cart?.diningOptionBehavior !== DiningOptionBehavior.Delivery) {
      setSaveNewAddress(false);
    }
    setDeliveryLineAddress2(cart?.order?.deliveryInfo?.address2);
    setDeliveryNotes(cart?.order?.deliveryInfo?.notes);
  }, [setSaveNewAddress, cart]);

  useEffect(() => {
    if(saveNewAddress) {
      // forcing the focus here makes sure the validation for this field gets run
      setFocus('addressLabel');
    } else {
      // clear any errors for this required field when it isnt visible
      clearErrors('addressLabel');
    }
  }, [saveNewAddress, setFocus, clearErrors]);

  const handleCreateAccountCheckboxChange = async (checked: boolean) => {
    setCreateAccount(checked);
    if(checked) {
      tracker.track('Started Authentication', { source: AuthenticationSource.AccountCreation });
      await sendPwlessLoginCode();
      tracker.track('Clicked Send code', { source: AuthenticationSource.AccountCreation });
    }
  };

  const phoneNumber = useWatch({ name: 'yourInfoPhone' });
  const email = useWatch({ name: 'yourInfoEmail' });
  const firstName = useWatch({ name: 'yourInfoFirstName' });
  const lastName = useWatch({ name: 'yourInfoLastName' });
  const addressLabel = useWatch({ name: 'addressLabel' });

  const sendPwlessLoginCode = useCallback( async () => {
    await passwordlessLogin(phoneNumber);
  }, [phoneNumber, passwordlessLogin]);

  const rxCountry = ooRestaurant?.i18n.country as CountryCode;
  const validatePhone = useCallback((value: string) => paymentOption === PaymentOption.ApplePay ||
    isValidPhoneNumber(value, rxCountry) || 'enter a valid phone number', [paymentOption, rxCountry]);

  const validateEmail = useCallback((value: string) => paymentOption === PaymentOption.ApplePay || isEmail(value) || 'enter a valid email address', [paymentOption]);
  const required = paymentOption !== PaymentOption.ApplePay;

  const defaultCustomerValues = getCustomerInfo(customer);
  return (
    <>
      <LinkAccountModal isOpen={showingEmailModal} onComplete={() => setShowingEmailModal(false)} email={email} />
      <PhoneInput
        id="yourInfoPhone"
        required={required}
        label="Phone number"
        validate={validatePhone}
        autoComplete="tel"
        defaultValue={defaultCustomerValues.yourInfoPhone || phoneNumber}
        filled={Boolean(customer?.phone)}
        unregisterOnUnmount={false} />
      <FormInput id="yourInfoEmail" type="email" autoComplete="email" label="Email" defaultValue={defaultCustomerValues.yourInfoEmail}
        filled={Boolean(customer?.email)} required={required} validate={validateEmail} className="fs-mask" />
      <FormInput id="yourInfoFirstName" type="text" autoComplete="given-name" label="First name" defaultValue={defaultCustomerValues.yourInfoFirstName}
        filled={Boolean(customer?.firstName)} required={required} className="fs-mask" />
      <FormInput id="yourInfoLastName" type="text" autoComplete="family-name" label="Last name" defaultValue={defaultCustomerValues.yourInfoLastName}
        filled={Boolean(customer?.lastName)} required={required} className="fs-mask" />
      {cart?.diningOptionBehavior === DiningOptionBehavior.Delivery &&
        <>
          <FormInput id="deliveryAddress2" type="text" label="Apt./Suite no." autoComplete="address-line2"
            filled={!!deliveryAddressLine2} value={deliveryAddressLine2 || ''} className="fs-mask" onChange={e => { setDeliveryLineAddress2(e.target.value ); }} />
          <FormInput id="deliveryInstructions" type="text" label="Delivery Instructions"
            filled={!!deliveryNotes} value={deliveryNotes || ''} className="fs-mask" onChange={e => { setDeliveryNotes(e.target.value ); }} />
          {customer && !savedAddressUsed &&
              <div className="formInput"
                data-testid="save-new-address">
                <ToggleInput
                  type="checkbox"
                  name="saveNewAddress"
                  id="saveNewAddress"
                  checked={saveNewAddress}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSaveNewAddress(event.target.checked)}>
                      Save address
                </ToggleInput>
              </div>}
          <AnimatedSection expanded={saveNewAddress}>
            <FormInput id="addressLabel" type="text" label="Address label" filled={Boolean(addressLabel)} defaultValue="" required={saveNewAddress} />
          </AnimatedSection>
        </>}
      {!customer &&
        <div className="createAccountCheckbox">
          <ShowForUS>
            <ToggleInput
              type="checkbox"
              name="createToastAccount"
              id="createToastAccount"
              dataTestId="createToastAccount"
              disabled={!(phoneNumber && email && firstName && lastName &&
                !Object.keys(errors).some(field => ['yourInfoPhone', 'yourInfoEmail', 'yourInfoFirstName', 'yourInfoLastName'].includes(field)))}
              checked={createAccount}
              onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await handleCreateAccountCheckboxChange(event.target.checked)}
              onKeyDown={async (event: React.KeyboardEvent<HTMLInputElement>) => {
                if(event.key === 'Enter') {
                  event.preventDefault();
                  event.stopPropagation();
                  await handleCreateAccountCheckboxChange(!createAccount);
                }
              }}>
              <>
                <Image alt="Toast logo" aria-hidden="true" src="icons/toast-logo-filled.svg" /> Create a Toast account
              </>
            </ToggleInput>
            <div className="note" role="contentinfo">
              Save your info for express checkout next time. Toast&apos;s{' '}
              <a href="https://pos.toasttab.com/terms-of-service/#diner-tos" rel="noopener noreferrer" target="_blank">Guest Terms of Service</a>
              {' '}and{' '}<a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Statement</a>
              {' '}apply. Info for CA residents available{' '}
              <a href="https://pos.toasttab.com/privacy#addendum-a" rel="noopener noreferrer" target="_blank">here</a>.
              Contact and payment information shared above may be stored, used, and shared by Toast for this and future orders from Toast restaurants.
            </div>
          </ShowForUS>
          {createAccount && <AccountCreation onComplete={onToastSignup} phoneNumber={`+1${phoneNumber}`} sendCode={sendPwlessLoginCode} />}
        </div>}
    </>
  );
};

/** Renders the customer's info in a compact format after it's been entered and validated */
const CompletedInfo = () => {
  const phoneNumber = useWatch({ name: 'yourInfoPhone' });
  const email = useWatch({ name: 'yourInfoEmail' });
  const firstName = useWatch({ name: 'yourInfoFirstName' });
  const lastName = useWatch({ name: 'yourInfoLastName' });
  const addressLine2 = useWatch({ name: 'deliveryAddress2' });
  const deliveryInstructions = useWatch({ name: 'deliveryInstructions' });
  return (
    <div className="completedInfo">
      {firstName && lastName && <div className="sectionRow"><div className="icon"><Image alt="Customer icon" src="icons/user-gray.svg" /></div>{firstName} {lastName}</div>}
      {email && <div className="sectionRow"><div className="icon"><Image alt="Email icon" src="icons/email-gray.svg" /></div>{email}</div>}
      {phoneNumber && isValidPhoneNumber(phoneNumber, 'US') &&
          <div className="sectionRow"><div className="icon"><Image alt="Phone icon" src="icons/phone-gray.svg" /></div>{parsePhoneNumber(phoneNumber, 'US').formatNational()}</div>}
      {addressLine2 && <div className="sectionRow"><div className="icon"><Image alt="Location icon" src="icons/location.svg" /></div>{addressLine2}</div>}
      {deliveryInstructions && <div className="sectionRow"><div className="icon"><Image alt="Location icon" src="icons/location.svg" /></div>{deliveryInstructions}</div>}
    </div>
  );
};

/** Renders the passwordless login flow inline at the time of account creation */
const AccountCreation = ({
  phoneNumber,
  onComplete,
  sendCode
}: {
  phoneNumber: string;
  onComplete: (customerGuid?: string) => Promise<any>;
  sendCode: () => void;
}) => {
  return (
    <div className="confirmationSection">
      <div>{`Enter the code sent to ${parsePhoneNumber(phoneNumber, 'US').formatNational()} to confirm it's you.`}</div>
      <ConfirmationCodeInputs phoneNumber={phoneNumber} onComplete={onComplete} />
      <div className="helpText">
        <div>Didn&apos;t receive a code?</div>
        <button className="resend" onClick={sendCode}>Resend code</button>
      </div>
    </div>
  );
};


const LinkAccountModal = ({ isOpen, onComplete, email }: { isOpen: boolean; onComplete: () => void, email: string }) => {
  const { selectedLocation: { shortUrl } } = useRestaurant();
  const { login, importAccount, mfaLogin, initiatePasswordReset } = useCustomer();
  const [mfaData, setMfaData] = useState<{ challengeToken: string, mfaType: MfaType, mfaRecipient?: string}>();
  const formMethods = useForm<{ password: string }>();
  const mfaFormMethods = useForm<{ code: string }>();
  const [error, setError] = useState('');
  const [hasSentForgotPw, toggleForgotPw] = useReducer(hasSent => !hasSent, false);
  const [submitting, setSubmitting] = useState(false);

  const onLinkComplete = useCallback(() => {
    onComplete();
  }, [onComplete]);

  const onForgotPassword = useCallback(async () => {
    try {
      // If we got this far in the checkout process, a shortUrl should exist since its required for OO.
      const success = shortUrl ? await initiatePasswordReset(email, shortUrl) : false;
      if(success) {
        toggleForgotPw();
      } else {
        setError('There was an error resetting your password. Please try again later.');
      }
    } catch(err) {
      Sentry.captureException(`ERROR: forgotPw error ${err}`);
      setError('There was an error resetting your password. Please try again later.');
    }
  }, [email, shortUrl, setError, initiatePasswordReset]);

  const onSubmitMfa = useCallback(async (data: { code: string }) => {
    if(!mfaData?.challengeToken) {
      return;
    }

    setSubmitting(true);

    try {
      const mfaResult = await mfaLogin(email, mfaData.challengeToken, data.code);
      if(mfaResult?.success === true && !mfaResult.challengeToken) {
        await importAccount();
      } else if(!mfaResult.success) {
        setError(getErrorText(mfaResult.errorCode));
        return;
      }
    } catch(err) {
      Sentry.captureException(`ERROR: mfaLogin error ${err}`);
      setError(getErrorText());
    } finally {
      setSubmitting(false);
    }

    onLinkComplete();
  }, [mfaLogin, importAccount, setError, email, onLinkComplete, mfaData]);

  const onSubmit = useCallback(async (data: { password: string }) => {
    if(hasSentForgotPw) {
      toggleForgotPw();
    }
    formMethods.reset();
    setSubmitting(true);
    try {
      const loginResult = await login(email, data.password);
      if(loginResult?.success === true && !loginResult.challengeToken) {
        await importAccount();
      } else if(loginResult.success && loginResult.challengeToken && loginResult.mfaType) {
        setMfaData({ challengeToken: loginResult.challengeToken, mfaType: loginResult.mfaType, mfaRecipient: loginResult.mfaRecipient });
        return;
      } else {
        setError(getErrorText(loginResult.errorCode));
        return;
      }
    } catch(err) {
      Sentry.captureException(`ERROR: login error ${err}`);
      setError(getErrorText());
    } finally {
      setSubmitting(false);
    }

    onLinkComplete();
  }, [importAccount, login, email, onLinkComplete, formMethods, hasSentForgotPw]);

  const getModalContent = () => {
    return (
      mfaData ?
        <div className="linkForm" id="mfaForm" key="mfaForm">
          <FormProvider {...mfaFormMethods}>
            <FormInput key="codeInput" defaultValue="" id="code" type="text" label="Code"
              required className="fs-mask" />
          </FormProvider>
          <Button onClick={mfaFormMethods.handleSubmit(onSubmitMfa)} disabled={submitting}>Finish</Button>
        </div> :
        <div className="linkForm" id="loginForm" key="loginForm">
          <FormProvider {...formMethods}>
            <FormInput key="pwInput" id="password" type="password" label="Password"
              required className="fs-mask" />
          </FormProvider>
          <Button disabled={submitting} onClick={formMethods.handleSubmit(onSubmit)}>Finish</Button>
          <Button variant={ButtonType.Text} onClick={onForgotPassword}>Forgot password</Button>
          <Button variant={ButtonType.Text} onClick={onComplete}>Skip</Button>
        </div>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onComplete}>
      <ModalOverlay />
      <ModalContent>
        <div className="linkAccountModal">
          {mfaData ?
            <>
              <h3>Finish logging in.</h3>
              <p>We&apos;ve sent a code to your email address on file. Enter it below to complete the login process.</p>
            </> :
            <>
              <h3>Good news!</h3>
              <h3>It looks like you already have an account.</h3>
              <p>Enter your password below for access to your order history, personalized information, and payment methods</p>
            </>}
          {error ? <div className="linkLoginError">{error}</div> : null}
          {hasSentForgotPw ? <div className="forgotPw">Check your email for instructions on resetting your password.</div> : null}
          {getModalContent()}
          <ToastLogo />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default CustomerInfoSection;
