import React, { useContext } from 'react';

type PageDomainOverride = {
  domain: string,
  pageRoute?: string | null
};

export type PageDomainContextType = {
  host: string,
  pathname: string,
  pageDomainOverrides: Map<string, string>,
  overriddenDomains: Set<string>,
  rootDomain: string
};

export const PageDomainContext = React.createContext<PageDomainContextType>({
  host: '',
  pathname: '',
  pageDomainOverrides: new Map<string, string>(),
  overriddenDomains: new Set<string>(),
  rootDomain: ''
});

export type Props = {
  domains: Array<PageDomainOverride>,
  host: string,
  pathname: string
};

const routeGroups: { [key: string]: string[] } = {
  // The /order route is a special case where all order-related routes
  // should be on the same domain
  '/order': ['/checkout', '/confirm', '/account']
};

export const PageDomainProvider = ({ domains, host, pathname, children }: React.PropsWithChildren<Props>) => {
  const pageDomainOverrides = new Map<string, string>();
  const overriddenDomains = new Set<string>();
  domains.forEach(d => {
    if(d.domain && d.pageRoute) {
      // If the current path is overridden by a domain, but the current domain does not match the overridding domain,
      // don't use the overridding domain. This allows users to stay on the current domain so that we don't lose any cookies
      // switching to the overridding domain.
      // For example, if a site has the domains 'site.com' and 'order.site.com', and the user is currently at site.com/order, let them
      // complete the order workflow on the root domain.
      if(
        host === d.domain
        || !pathname.startsWith(d.pageRoute) && !routeGroups[d.pageRoute]?.some(page => pathname.startsWith(page))
      ) {
        pageDomainOverrides.set(d.pageRoute, d.domain);
        overriddenDomains.add(d.domain);

        routeGroups[d.pageRoute]?.forEach(page => pageDomainOverrides.set(page, d.domain));
      }
    }
  });

  // This assumes that domains mapped to pages are subdomains of a root domain
  // that hosts all other pages. If that changes, we'll need to find another way
  // to determine the rootDomain (i.e. the domain that hosts non-mapped pages).
  // For example, a site has the domain site.com and a subdomain order.site.com.
  // When the user is on order.site.com, links to the menu page should use the
  // root domain, site.com/menu. If for some reason the subdomain is order.site2.com,
  // and the site does not have the site2.com domain, then the rootDomain will fall back
  // to the current host, order.site2.com/menu
  const hostRootDomain = host.split('.').reverse()
    .splice(0, 2)
    .reverse()
    .join('.');
  const rootDomain = domains.find(d => d.domain === hostRootDomain) ? hostRootDomain : host;
  return (
    <PageDomainContext.Provider
      value={{
        host,
        pathname,
        pageDomainOverrides,
        overriddenDomains,
        rootDomain
      }}>
      {children}
    </PageDomainContext.Provider>
  );
};

export const usePageDomains = () => {
  return useContext(PageDomainContext);
};
