import React, { useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import classnames from 'classnames';

import Image from 'shared/components/common/Image';
import DropDown from 'shared/components/common/dropdown';
import InputMessage from 'shared/components/common/form_input/InputMessage';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { RestaurantLocation } from 'shared/components/common/restaurant_context/RestaurantContext';

export const formatAddress = (loc: Pick<RestaurantLocation, 'address1' | 'address2' | 'city' | 'state' | 'zipcode'>): string =>
  `${loc.address1}${loc.address2 ? `, ${loc.address2}` : ''}, ${loc.city}, ${loc.state}`;
export const formatAddressLabel = (loc: RestaurantLocation): string => `${loc.address1}${loc.address2 ? `, ${loc.address2}` : ''}`;
export const getRxNameAndAddress = (loc: RestaurantLocation): string => `${loc.name} - ${formatAddressLabel(loc)}`;

type Props = {
  locations?: RestaurantLocation[] | null;
  selectedLocation?: RestaurantLocation;
  setSelectedLocation: (arg0: string) => void;
  withIcon?: boolean;
  withBorder?: boolean;
  leftAligned?: boolean;
  id: string;
};

export const LocationSelector = ({ locations, selectedLocation, setSelectedLocation, withIcon, withBorder, leftAligned, id }: Props) => {
  const dropDownLabel =
  <>
    {withIcon ? <Image className="icon" alt="Location logo" src="icons/location.svg" /> : null}
    <div className="selectedLocation">{selectedLocation ? formatAddressLabel(selectedLocation) : ''}</div>
  </>;

  if(!locations?.length || locations.length === 1) {
    return null;
  }

  return (
    <DropDown describedBy={`${id}-input-message`} label={dropDownLabel} withBorder={withBorder} leftAligned={leftAligned} >
      {({ close }) =>
        locations?.map(loc => {
          return (
            <div key={loc.name} onClick={() => {
              close();
              setSelectedLocation(loc.externalId);
            }}>
              {formatAddress(loc)}
            </div>
          );
        })}
    </DropDown>
  );
};

type FormLocationSelectorProps = {
  id: string;
  label?: string;
  required?: boolean;
  warningMessage?: string;
}

const FormLocationSelector = (props: FormLocationSelectorProps) => {
  const { id, label, required, warningMessage } = props;
  const { selectedLocation, locations } = useRestaurant();
  const { setValue, formState: { errors }, watch } = useFormContext();
  const locName = watch(id);

  const formSelected = locations?.find(loc => locName ? locName === getRxNameAndAddress(loc) : false);

  useEffect(() => {
    if(selectedLocation) {
      setValue(props.id, getRxNameAndAddress(selectedLocation));
    }
  // execute once, on render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  const setSelectedLocation = useCallback((guid: string) => {
    const loc = locations?.find(loc => loc.externalId === guid);
    if(!loc) return;
    setValue(props.id, getRxNameAndAddress(loc));
  }, [props.id, setValue, locations]);

  if(!locations?.length || locations.length === 1) {
    return null;
  }

  return (
    <div className={classnames('formInput', errors[id] && 'error', formSelected && 'filled')}>
      <div className="inputWrapper">
        <div className="inputElementWrapper">
          <label htmlFor={id}>{label}{required ? '*' : ''}</label>
          <LocationSelector id={id} locations={locations} selectedLocation={formSelected} setSelectedLocation={setSelectedLocation} />
        </div>
        {errors[id] && <Image alt="Warning icon" src="icons/warning-red.svg" />}
      </div>
      <InputMessage id={id} warning={warningMessage} />
    </div>
  );
};

export default FormLocationSelector;
