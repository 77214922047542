import React, { PropsWithChildren, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import classnames from 'classnames';

import FormInput from 'shared/components/common/form_input';
import ContextualLoadingSpinner from 'shared/components/common/loading_spinner/LoadingSpinner';

type Props = {
  id: string;
  label: string;
  value: string;
  loading?: boolean;
  isApplied: boolean;
  applyButtonActive: boolean;
  removeButtonActive: boolean;
  validate?: (value: string) => boolean | string | undefined;
  warn?: (value: string) => string | undefined;
  warning?: string;
  onApply: () => void;
  onRemove: () => void;
};

interface CustomInputWithApplyProps {
  id: string,
  isApplied: boolean
  onApply: () => void
  onRemove: () => void
  removeButtonActive: boolean
  applyButtonActive: boolean
  loading?: boolean
}

/**
 * This is a single form element that contains the apply button directly in the
 * input field. If there are no children supplied to AppliedInput, DefaultInput
 * is used.
 */
const DefaultInput = ({
  id,
  label,
  isApplied,
  removeButtonActive,
  applyButtonActive,
  onApply,
  onRemove,
  validate,
  warn,
  warning,
  loading,
  value
}: Props) => isApplied ?
  <FormInput
    id={`${id}Entered`}
    type="text"
    label={label}
    button={<button type="button" data-testid={`remove-${id}`} className={classnames('inputButton', removeButtonActive && 'active')} disabled={!removeButtonActive} onClick={onRemove}>Remove</button>}
    filled={true}>
    <div className="appliedInputValue">{value}</div>
  </FormInput> :
  <FormInput
    id={id}
    type="text"
    label={label}
    button={loading ?
      <ContextualLoadingSpinner size="24px" /> :
      <button type="button" data-testid={`apply-${id}`} className={classnames('inputButton', applyButtonActive && 'active')} disabled={!applyButtonActive} onClick={onApply}>Apply</button>}
    buttonOnClick={onApply}
    validate={validate}
    warn={warn}
    warning={warning} />;

/**
 * When children are passed into the AppliedInput they are wrapped by CustomInputWithApply which supplies the
 * apply/remove button. Custom inputs are use to supply multiple custom form elements, tied to the Apply action
 * and behaviors.
 */
const CustomInputWithApply = ({
  id,
  isApplied,
  onApply,
  onRemove,
  removeButtonActive,
  applyButtonActive,
  loading,
  children
}: PropsWithChildren<CustomInputWithApplyProps>) => {
  const buttonRenderer = isApplied ?
    <button type="button" data-testid={`remove-${id}`} className={classnames('inputButton', removeButtonActive && 'active')} disabled={!removeButtonActive} onClick={onRemove}>Remove</button> :
    <button type="button" data-testid={`apply-${id}`} className={classnames('inputButton', applyButtonActive && 'active')} disabled={!applyButtonActive} onClick={onApply}>Apply</button>;

  return (
    <div className={classnames('customInputWrapper')}>
      {children}
      {loading ? <ContextualLoadingSpinner size="24px" /> : buttonRenderer }
    </div>
  );
};

const AppliedInput = (props: PropsWithChildren<Props>) => {
  const { id, loading, onRemove } = props;
  const { setValue } = useFormContext();

  const onRemoveValue = useCallback(() => {
    onRemove();
    setValue(id, '');
  }, [id, onRemove, setValue]);

  return (
    <div className="appliedInput">
      {Boolean(props.children) && React.Children.count(props.children) > 0 ?
        <CustomInputWithApply
          id={id}
          isApplied={props.isApplied}
          onRemove={onRemoveValue}
          onApply={props.onApply}
          removeButtonActive={props.removeButtonActive}
          applyButtonActive={props.applyButtonActive}
          loading={loading}>
          {props.children}
        </CustomInputWithApply> :
        <DefaultInput
          { ...props }
          onRemove={onRemoveValue} />}
    </div>
  );
};

export default AppliedInput;
