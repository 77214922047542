import React, { useCallback, useMemo, useState } from 'react';
import { ApolloProvider } from 'react-apollo';

import { ContainsEditableProps, useEditor, FieldType } from '@toasttab/sites-components';
import classnames from 'classnames';
import isURL from 'validator/lib/isURL';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { Image as ImageType } from 'src/apollo/sites';
import StaticLocationMap from 'src/shared/components/common/location_map/StaticLocationMap';

import Image from 'shared/components/common/Image';
import LocationMap from 'shared/components/common/location_map/LocationMap';
import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import useWindowMessage from 'shared/js/hooks/useWindowMessage';

import OOLocationSelector from 'public/components/default_template/nav/OOLocationSelector';
import DiningOptionBehaviorMessage from 'public/components/default_template/online_ordering/dining_option_message/DiningOptionBehaviorMessage';
import OrderOptions from 'public/components/default_template/online_ordering/order_options/OrderOptions';
import { MapMessage } from 'public/components/google_maps/messages';
import { useFulfillment } from 'public/components/online_ordering/FulfillmentContext';

import { server } from 'config';

type Props = {
  heading: string;
  imgSrc?: string | null;
  image?: Omit<ImageType, '__typename'> | null;
  setIsMapOpen: (isMapOpen: boolean) => void;
  withLocationSelector?: boolean;
} & ContainsEditableProps

type ContentProps = {
  heading: string;
  imgSrc?: string | null;
  image?: Omit<ImageType, '__typename'> | null;
  setIsMapOpen: (isMapOpen: boolean) => void;
  setMapExpanded: (isMapOpen: boolean) => void;
  mapExpanded: boolean;
  withLocationSelector?: boolean;
  allowFulfillmentChange?: boolean;
  selectedDiningOption?: DiningOptionBehavior;
} & ContainsEditableProps

const ExpandIcon = () =>
  <div className="expandIcon">
    <Image src="icons/arrow-up-left.svg" alt="Expand icon" />
    <Image src="icons/arrow-up-left.svg" alt="Expand icon" className="upRight" />
    <Image src="icons/arrow-up-left.svg" alt="Expand icon" className="downLeft" />
    <Image src="icons/arrow-up-left.svg" alt="Expand icon" className="downRight" />
  </div>;

const MAP_SOURCE_ID = 'HeroWithMap';


export const HeroWithMapContent = (props: React.PropsWithChildren<ContentProps>) => {
  const { setIsMapOpen, setMapExpanded } = props;
  const { restaurant, locations, orderingDisabled } = useRestaurant();
  const { useEditableRef } = useEditor();
  const [hasMapOpened, setHasMapOpened] = useState(false);

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'menu hero section',
    actions: [],
    schema: {
      fields: [
        {
          path: `${props.editPath}.heroImage`,
          displayName: 'Image',
          type: FieldType.Image,
          value: props.imgSrc,
          deletable: true
        }
      ]
    }
  });

  const hasMultipleLocations = useMemo(() => (locations?.length || 0) > 1, [locations]);

  const openMap = useCallback(() => {
    setMapExpanded(true);
    setIsMapOpen(true);
  }, [setIsMapOpen, setMapExpanded]);

  const closeMap = useCallback(() => {
    setMapExpanded(false);
    setIsMapOpen(false);
    for(let i = 0; i < window.frames.length; i++) {
      window.frames[i]?.postMessage(JSON.stringify({
        name: MapMessage.CLOSED,
        data: ''
      }), `${server.protocol}://${server.fullHost}`);
    }
  }, [setIsMapOpen, setMapExpanded]);

  return (
    <div ref={editableRef} className={classnames('heroWithMap',
      { withoutImage: !props.imgSrc && !props.image?.src, withLocationSelector: props.withLocationSelector, singleLocation: !hasMultipleLocations })}>
      {props.image?.src ?
        <div className="backgroundImage">
          <div className="gradient" />
          {/*
            Don't use the image object directly if the original source is a fully qualified path, since that means it was probably pulled from
            Toast's CDN and can't use the Imgproxy path
           */}
          {isURL(props.image.src, { require_host: true }) ? <Image src={props.image.src} aria-hidden={true} /> : <Image imageObject={props.image} aria-hidden={true} />}
        </div>
        : null}
      {props.children}
      <div className="contentWrapper paddedContentWrapper">
        <div className="content paddedContent">
          <div className="leftContent">
            <h1 className={classnames('heading', { smaller: props.heading.length > 16 })}>{props.heading}</h1>
            {props.withLocationSelector ? <OOLocationSelector /> : null}
            {!orderingDisabled && props.allowFulfillmentChange ?
              <OrderOptions>
                <DiningOptionBehaviorMessage />
              </OrderOptions>
              : null}
          </div>
          {hasMultipleLocations && !restaurant.config?.ooConfig?.hideMap &&
            <div className={classnames('map', { expanded: props.mapExpanded })}>
              {hasMapOpened ?
                <LocationMap
                  sourceId={MAP_SOURCE_ID}
                  allowFulfillment={props.allowFulfillmentChange}
                  selectedDiningOption={props.selectedDiningOption} /> :
                <StaticLocationMap />}
              <button type="button" className="mapButton" onClick={() => {
                if(props.mapExpanded) {
                  closeMap();
                } else {
                  openMap();
                  setHasMapOpened(true);
                }
              }}>
                {props.mapExpanded ? <Image src="icons/close.svg" /> : <ExpandIcon />}
              </button>
            </div>}
        </div>
      </div>
    </div>
  );
};

export const HeroWithMap = (props: React.PropsWithChildren<Props>) => {
  const [mapExpanded, setMapExpanded] = useState(false);
  const ooClient = useOOClient();
  const { selectedLocation, updateLocation } = useRestaurant();


  const messageHandlers = useMemo(() => ({
    [MapMessage.SELECTED_PIN]: () => setMapExpanded(true),
    [MapMessage.SELECTED_GUID]: (data: { guid: string, diningOptionBehavior: DiningOptionBehavior }) => {
      setMapExpanded(false);
      props.setIsMapOpen(false);
      if(selectedLocation.externalId !== data.guid) {
        updateLocation(data.guid);
      }
    }
  }), [props, setMapExpanded, selectedLocation, updateLocation]);

  useWindowMessage({ sourceId: MAP_SOURCE_ID, origin: `${server.protocol}://${server.fullHost}`, messageHandlers });

  return (
    <ApolloProvider client={ooClient}>
      <HeroWithMapContent
        heading={props.heading}
        imgSrc={props.imgSrc}
        image={props.image}
        setIsMapOpen={props.setIsMapOpen}
        withLocationSelector={props.withLocationSelector}
        setMapExpanded={setMapExpanded}
        mapExpanded={mapExpanded}
        editPath={props.editPath} />
    </ApolloProvider>
  );
};

const HeroWithFulfillmentMap = (props: React.PropsWithChildren<Props>) => {
  const { setIsMapOpen } = props;
  const { selectedLocation, updateLocation } = useRestaurant();
  const { fulfillmentData, updateCartFulfillment, setDefaultDiningOptionBehavior } = useFulfillment();
  const [mapExpanded, setMapExpanded] = useState(false);

  const messageHandlers = useMemo(() => ({
    [MapMessage.SELECTED_PIN]: () => setMapExpanded(true),
    [MapMessage.SELECTED_GUID]: (data: { guid: string, diningOptionBehavior: DiningOptionBehavior }) => {
      setMapExpanded(false);
      setIsMapOpen(false);
      if(selectedLocation.externalId === data.guid) {
        // the location isn't changing, so just update the cart
        updateCartFulfillment({
          ...fulfillmentData!.cartFulfillmentData,
          diningOptionBehavior: data.diningOptionBehavior,
          deliveryInfo: data.diningOptionBehavior === DiningOptionBehavior.Delivery ? fulfillmentData!.cartFulfillmentData.deliveryInfo : null
        });
      } else {
        // setting this default value will update the fulfillment data after the Rx and cart refresh
        setDefaultDiningOptionBehavior(data.diningOptionBehavior);
        updateLocation(data.guid);
      }
    }
  }), [setIsMapOpen, setMapExpanded, selectedLocation, fulfillmentData, updateCartFulfillment, setDefaultDiningOptionBehavior, updateLocation]);

  useWindowMessage({ sourceId: MAP_SOURCE_ID, origin: `${server.protocol}://${server.fullHost}`, messageHandlers });

  return (
    <HeroWithMapContent
      heading={props.heading}
      imgSrc={props.imgSrc}
      image={props.image}
      setIsMapOpen={props.setIsMapOpen}
      withLocationSelector={props.withLocationSelector}
      allowFulfillmentChange
      selectedDiningOption={fulfillmentData?.cartFulfillmentData.diningOptionBehavior}
      setMapExpanded={setMapExpanded}
      mapExpanded={mapExpanded}
      editPath={props.editPath} />
  );
};
export default HeroWithFulfillmentMap;
