import React, { useState, useMemo, SetStateAction, Dispatch } from 'react';

import Image from 'shared/components/common/Image';
import { Modal, ModalContent, ModalOverlay } from 'shared/components/common/modal';

import { resources } from 'config';

type ImageType = {
  cdnHost?: string | null;
  src?: string | null;
  srcSet?: any | null;
  alt?: string | null
};

type GalleryProps = {
  menuImages: ImageType[];
  rxImages: ImageType[];
  rxName: string;
}

type Tab = {
  key: string;
  label: string;
  getImages: () => ImageWithSrc[];
}

type GalleryModalProps = {
  rxName: string;
  images: ImageWithSrc[];
  modalImage: ImageWithSrc;
  modalImageIdx: number | null;
  setModalImageIdx: Dispatch<SetStateAction<number | null>>;
};

const GalleryModal = ({ rxName, images, modalImage, modalImageIdx, setModalImageIdx }: GalleryModalProps) =>
  <Modal isOpen={modalImageIdx !== null} onClose={() => setModalImageIdx(null)}>
    <ModalOverlay />
    <ModalContent>
      <div className="gallery-modal-container">
        <div className="left">
          <button className="left-arrow" onClick={() => setModalImageIdx(oldIdx => shiftIndex(oldIdx || 0, -1, images.length - 1))}>&lt;</button>
          <Image {...modalImage} className="gallery-modal-img" />
          <button className="right-arrow" onClick={() => setModalImageIdx(oldIdx => shiftIndex(oldIdx || 0, 1, images.length - 1))}>&gt;</button>
        </div>
        <div className="right">
          <h2 className="gallery-title">Photos for {rxName}</h2>
          <p className="counter">{modalImageIdx || 0 + 1} of {images.length}</p>
          <div className="modal-photos">
            {images.map((image, i) =>
              <div className="photo" key={image.src}><Image {...image} onClick={() => setModalImageIdx(i)} /></div>)}
          </div>
        </div>
      </div>
    </ModalContent>
  </Modal>;

const Gallery = ( { menuImages, rxImages, rxName } : GalleryProps) => {
  const [selectedTabKey, setSelectedTab] = useState<string>('all');
  const [modalImageIdx, setModalImageIdx] = useState<number | null>(null);

  const tabs: Tab[] = useMemo(() => {
    return [
      {
        key: 'all',
        label: 'All Photos',
        getImages: () => noNullSrcs(menuImages.concat(rxImages))
      },
      {
        key: 'menu',
        label: 'Menu',
        getImages: () => noNullSrcs(menuImages)
      },
      {
        key: 'restaurant',
        label: 'Restaurant',
        getImages: () => noNullSrcs(rxImages)
      }
    ];
  }, [rxImages, menuImages]);

  const images = useMemo(() => {
    const tab = tabs.filter(t => t.key === selectedTabKey)[0];
    return tab ? tab.getImages() : [];
  }, [selectedTabKey, tabs]);

  const modalImage = useMemo(() => {
    let image: ImageWithSrc | undefined = undefined;
    if(modalImageIdx != null && modalImageIdx >= 0) {
      if(images[modalImageIdx]) {
        image = images[modalImageIdx];
      }
    }
    return image;
  }, [modalImageIdx, images]);
  const firstImage = images[0];

  return (
    <div className="gallery-container">
      <div className="tabs">
        {tabs.map(tab =>
          <button
            className={`tab ${tab.key == selectedTabKey ? 'selected' : ''}`}
            key={tab.key}
            onClick={() => setSelectedTab(tab.key)}
            tabIndex={0}
            role="button"
            aria-pressed={tab.key == selectedTabKey}>
            {tab.label}
          </button>)}
      </div>
      <div className="photos">
        {firstImage ?
          <div className="photo first rounded-border" onClick={() => setModalImageIdx(0)}>
            <Image {...firstImage}></Image>
          </div>
          :
          <div className="photo no-photos">
            <p>Photos coming soon!</p>
          </div>}
        {images &&
        <>
          <div className="rest"> {/* Top row */}
            {images.slice(1, images.length / 2 + 1)
              .map((image, i) =>
                <div className="photo rounded-border" key={image.src} onClick={() => setModalImageIdx(i + 1)}><Image {...image} /></div>)}
          </div>
          <div className="rest"> {/* Bottom row */}
            {images.slice(Math.floor(images.length / 2) + 1)
              .map((image, i) =>
                <div className="photo rounded-border" key={image.src} onClick={() => setModalImageIdx(i + Math.floor(images.length / 2) + 1)}><Image {...image} /></div>)}
          </div>
        </>}
      </div>
      {modalImageIdx != null && modalImage &&
        <div className="gallery-modal">
          <GalleryModal
            rxName={rxName}
            images={images}
            modalImage={modalImage}
            modalImageIdx={modalImageIdx}
            setModalImageIdx={setModalImageIdx} />
        </div>}
    </div>
  );
};

type ImageWithSrc = ImageType & { src: string, alt: string };
// Currently, we cannot mark src as nonnull in GraphQL because the DB contains ~1000 rows with null src and srcset.
// TODO: remove those rows and guarantee src.
const noNullSrcs = (images: (ImageType | null | undefined)[]): ImageWithSrc[] => {
  const nonnull: ImageWithSrc[] = [];
  for(const image of images) {
    if(image && image.src) {
      const alt = image.alt;
      nonnull.push({ srcSet: image.srcSet, src: image.src, alt: alt || '', cdnHost: image.cdnHost || resources.toastLocalCDN });
    }
  }
  return nonnull;
};

const shiftIndex = (idx: number, change: number, max: number): number => {
  const newIdx = idx + change;
  if(newIdx > max) {
    return 0;
  }
  if(newIdx < 0) {
    return max;
  }
  return newIdx;
};

export default Gallery;
