import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { generatePath, Redirect, useHistory, useParams, withRouter } from 'react-router';


import { ApolloQueryResult } from 'apollo-client';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import isEmpty from 'lodash/isEmpty';


import { PromoBannersQuery, RestaurantQuery, usePromoBannersQuery, useRestaurantQuery } from 'src/apollo/onlineOrdering';
import {
  RestaurantDataByDomainQuery,
  RestaurantDataByDomainQueryHookResult,
  RestaurantDataByShortUrlQuery,
  RestaurantDataByShortUrlQueryHookResult,
  RestaurantDataFragment,
  RestaurantPage,
  useRestaurantDataByDomainQuery,
  useRestaurantDataByMgmtGuidQuery,
  useRestaurantDataByShortUrlQuery
} from 'src/apollo/sites';
import { reportError } from 'src/lib/js/clientError';
import { RequestContextProps } from 'src/lib/js/context';
import { getHost } from 'src/lib/js/utilities';
import ToastProduct from 'src/public/components/online_ordering/ToastProduct';
import { isToastLocalRequest } from 'src/public/js/siteUtilities';
import useRedirectForUniversalLink from 'src/shared/js/hooks/useRedirectForUniversalLink';

import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import UhOh, { getUhOhPropsForError } from 'shared/components/uh_oh/UhOh';
import useDefaultSiteData, { RxDataUsage } from 'shared/js/hooks/useDefaultSiteData';
import useRestaurantSiteData from 'shared/js/hooks/useRestaurantSiteData';

import config from 'config';

import TrackRestaurant from './TrackRestaurant';

export type OORestaurant = RestaurantQuery['restaurantV2'] & { __typename: 'Restaurant' };
export type PromoBanners = PromoBannersQuery['promoBanners'] & { __typename: 'PromoBannerResponse' };
export type DiningOptions = RestaurantQuery['diningOptions'];
export type Restaurant = RestaurantDataFragment;
export type RestaurantSiteContent = NonNullable<Restaurant>['content'] & { __typename: 'SiteContent' };
export type RestaurantLocation = NonNullable<NonNullable<Restaurant['locations']>[0]>;
export type RestaurantConfig = Restaurant['config'];

type DeletableRestaurantPage = RestaurantPage & { deleted?: boolean };

type DomainHookDataReturnType = RestaurantDataByDomainQueryHookResult['data'] & RestaurantDataByDomainQuery;
type ShortUrlHookDataReturnType = RestaurantDataByShortUrlQueryHookResult['data'] & RestaurantDataByShortUrlQuery;

export type RestaurantContextType = {
  ooRestaurant?: OORestaurant;
  refetchOORestaurant: () => Promise<ApolloQueryResult<RestaurantQuery>>;
  ooPromoBanners?: PromoBanners;
  diningOptions?: DiningOptions;
  orderingDisabled?: boolean;
  restaurant: Restaurant;
  sitePages?: RestaurantPage[] | null;
  locations?: RestaurantLocation[] | null;
  selectedLocation: RestaurantLocation;
  updateLocation: (guid: string, skipReplace?: boolean) => void;
  orderingUrl?: string | null;
  reservationsUrl?: string | null;
  phoneNumber?: string | null;
  pixels?: string[] | null;
  host?: string;
  toastProduct: ToastProduct;
};

type Params = {
  locationGuid?: string;
  slug?: string;
}

type WithRouterProps = RequestContextProps & ProviderProps
type BaseProps = RouterSubstituteProps & ProviderProps & { allowLocationSwitching: boolean }

type ProviderProps = (EditorTrueProps | EditorFalseProps) & EditorSharedProps

type EditorSharedProps = {
  siteRestaurant?: DomainHookDataReturnType['restaurantByDomain'] | ShortUrlHookDataReturnType['restaurantByShortUrl'];
  siteRestaurantError?: RestaurantDataByDomainQueryHookResult['error'] | RestaurantDataByShortUrlQueryHookResult['error'];
  siteRestaurantLoading?: boolean;
  ooUsage?: RxDataUsage;
  sitesUsage?: RxDataUsage;
  shortUrl?: string;
}

type EditorTrueProps = {
  isEditor: true
  routeInfo: RouterSubstituteProps
  site: Restaurant,
  sitePages?: RestaurantPage[] | null
}

type EditorFalseProps = {
  isEditor: false
  routeInfo?: null
  site?: null
  sitePages?: null
}

type RouterSubstituteProps = {
  path: string
  params: Params
  host: string
  history?: any
  staticContext?: any
}


const LOCATION_KEY = 'toast-rx-location';
export const RestaurantContext = createContext<RestaurantContextType | undefined>(undefined);

const RestaurantContextProvider = (props: React.PropsWithChildren<ProviderProps>) =>
  props.isEditor ? <RestaurantContextProviderWithoutRouter {...props} allowLocationSwitching={false} {...props.routeInfo} site={props.site} /> : <RestaurantContextProviderWithRouter {...props} />;

const RestaurantContextProviderWithoutRouter = (props: React.PropsWithChildren<BaseProps>) => {
  return <RestaurantContextProviderBase {...props} {...props.routeInfo} siteRestaurant={props.site} />;
};


const RestaurantContextProviderWithRouter = withRouter<any, React.ComponentType<WithRouterProps>>((props: WithRouterProps) => {
  const host = getHost(props.staticContext);
  const history = useHistory();
  const params = useParams<Params>();

  const { staticContext, sitesUsage = RxDataUsage.Required } = props;
  const { siteRestaurant, error: siteError, loading: siteLoading } = useRestaurantSiteData({
    staticContext,
    domainQueryHook: useRestaurantDataByDomainQuery,
    shortUrlQueryHook: useRestaurantDataByShortUrlQuery,
    mgmtGuidQueryHook: useRestaurantDataByMgmtGuidQuery,
    skipQuery: sitesUsage === RxDataUsage.None
  });

  const { data: rxData, loading: rxLoading, error: rxError } = useDefaultSiteData({
    isPageQuery: false,
    staticContext,
    siteLoading,
    siteRestaurant,
    siteError
  });
  const defaultedData = rxData as RestaurantDataFragment;

  if(isToastLocalRequest(staticContext) && !rxData && !rxLoading) {
    return <NoMatch404 meta={defaultedData?.meta} />;
  }

  return !rxData && rxLoading ?
    <LoadingSpinnerOverlay color="#2B4FB9" /> :
    <RestaurantContextProviderBase
      {...props} host={host} history={history} params={params} path={props.match.path} allowLocationSwitching={true}
      siteRestaurant={defaultedData} siteRestaurantError={rxError} siteRestaurantLoading={rxLoading} />;
});

const RestaurantContextProviderBase = (props: React.PropsWithChildren<BaseProps>) => {
  const {
    siteRestaurant, siteRestaurantError, siteRestaurantLoading,
    sitesUsage = RxDataUsage.Required, ooUsage = RxDataUsage.None,
    history, params, host, path, allowLocationSwitching, children,
    isEditor, sitePages, staticContext
  } = props;

  // NOTE: DO NOT USE ANY react-router HOOKS in this base component.
  const ooClient = useOOClient();
  const filteredSitePages = useMemo(() => {
    return sitePages?.filter((page: DeletableRestaurantPage) => !page.deleted);
  }, [sitePages]);

  let currentLocation = siteRestaurant?.locations?.[0];
  const paramLocation = params.locationGuid;
  if(sitesUsage !== RxDataUsage.None) {
    if(paramLocation) {
      // If a user comes directly to a location link, we do want to 404 if that location doesn't exist
      currentLocation = siteRestaurant?.locations?.find((loc: RestaurantLocation) => loc.externalId === paramLocation);
    } else if(params.slug) {
      currentLocation = siteRestaurant?.locations?.find((loc: RestaurantLocation) => loc.shortUrl === params.slug);
    } else if(typeof window !== 'undefined') {
      const storedLocation = localStorage.getItem(LOCATION_KEY);
      const locationFromLocalStorage = storedLocation ? siteRestaurant?.locations?.find((loc: RestaurantLocation) => loc.externalId === storedLocation) : undefined;
      if(locationFromLocalStorage) {
        currentLocation = locationFromLocalStorage;
      } else {
        // the value in local storage isnt a valid GUID for this Rx
        localStorage.removeItem(LOCATION_KEY);
      }
    }
  }

  const [location, setLocation] = useState<RestaurantLocation | undefined | null>(currentLocation);

  // Ensure that the current location is set in local storage, no matter how it was found
  if(typeof window !== 'undefined' && location?.externalId) {
    localStorage.setItem(LOCATION_KEY, location.externalId);
  }

  // A shortUrl is required to use OO and a lot of the associated Consumer App BFF queries.
  // It is required by the return type of this query.
  const skipOOQuery = ooUsage === RxDataUsage.None || !location?.externalId || !location?.shortUrl;
  const { data: restaurantData, error: ooError, loading: ooLoading, refetch } = useRestaurantQuery(
    {
      variables: { restaurantGuid: location?.externalId || '' },
      skip: skipOOQuery,
      fetchPolicy: 'cache-first',
      client: ooClient
    }
  );

  const { data: promoData } = usePromoBannersQuery({
    variables: { restaurantGuid: location?.externalId || '' },
    skip: !location?.externalId || !!siteRestaurant?.config?.promoBannersDisabled,
    fetchPolicy: 'cache-first',
    client: ooClient
  });

  // If updating the location on an OO page (i.e. the path includes a short URL),
  // this will only update the browser history stack if that location has a short URL.
  const updateLocation = useCallback((guid: string) => {
    if(allowLocationSwitching) {
      const newLoc = siteRestaurant?.locations?.find((loc: RestaurantLocation) => loc.externalId === guid);
      if(newLoc) {
        localStorage.setItem(LOCATION_KEY, guid);
        setLocation(newLoc);
        const pathParams = {} as { slug?: string, rxGuid?: string };
        if(path.includes(':slug')) {
          if(newLoc.shortUrl) {
            pathParams.slug = newLoc.shortUrl;
          } else {
            console.error('Cannot change path for restaurant location');
            return;
          }
        }
        if(path.includes(':rxGuid')) {
          pathParams.rxGuid = newLoc.externalId;
        }

        if(!isEmpty(pathParams)) {
          history.replace(generatePath(path, pathParams));
        }
      }
    }
  }, [siteRestaurant, history, path, allowLocationSwitching]);

  if(
    sitesUsage === RxDataUsage.Required && siteRestaurantError
    || ooUsage === RxDataUsage.Required && !ooLoading && (ooError || !restaurantData || restaurantData?.restaurantV2.__typename === 'GeneralError')
  ) {
    if(sitesUsage === RxDataUsage.Required && siteRestaurantError) {
      reportError('Error querying Sites API', siteRestaurantError);
    } else if(ooUsage === RxDataUsage.Required && ooError) {
      reportError('Error querying consumer-app-bff', ooError);
    } else if(ooUsage === RxDataUsage.Required && restaurantData) {
      reportError('Error returned from consumer-app-bff', undefined, restaurantData);
    } else if(ooUsage === RxDataUsage.Required && !location?.shortUrl) {
      reportError('Restaurant location is attempting to edit without a shortUrl set', undefined, location || undefined);
      return <UhOh {...{
        ...getUhOhPropsForError(null),
        subtitle: 'The "Toast Tab Page" setting is not set or published. \
          Set the "Toast Tab Page" <a href="https://preprod.eng.toasttab.com/restaurants/admin/basicinfo#restaurant_shortUrl">here</a>'
      }} />;
    }

    return <UhOh meta={siteRestaurant?.meta} {...getUhOhPropsForError(siteRestaurantError || ooError)} />;
  }

  if(!isEditor && !siteRestaurant && siteRestaurantLoading || !restaurantData && ooLoading) {
    return <LoadingSpinnerOverlay color="#2B4FB9" />;
  }

  if(!siteRestaurant || !location) {
    let errorMessage: string | undefined = undefined;
    if(!location) {
      errorMessage = 'Please assign a location to this brand in order to use the page.';
    }
    return <NoMatch404 errorMessage={errorMessage} meta={siteRestaurant?.meta} />;
  }

  const LDProvider = withLDProvider({
    clientSideID: config.ldClientId,
    context: { key: location.externalId }
  });


  const isOOBasic = siteRestaurant?.config?.hasFullCustomization === false;
  let toastProduct: ToastProduct = ToastProduct.OOPro;
  if(isToastLocalRequest(staticContext)) {
    toastProduct = ToastProduct.ToastLocal;
  } else if(isOOBasic) {
    toastProduct = ToastProduct.OOBasic;
  } else if(!siteRestaurant?.config?.isOnlineOrderingOnly) {
    toastProduct = ToastProduct.Sites;
  }

  const Component = () => {
    const isClient = typeof window !== 'undefined';
    const clientRedirect = useRedirectForUniversalLink({
      ooRestaurant: restaurantData?.restaurantV2 as OORestaurant,
      staticContext: props.staticContext,
      isClient,
      isOOBasic
    });

    if(clientRedirect) {
      return <Redirect to={clientRedirect} />;
    }

    return (
      <RestaurantContext.Provider value={{
        ooRestaurant: restaurantData?.restaurantV2 as OORestaurant,
        refetchOORestaurant: refetch,
        ooPromoBanners: promoData?.promoBanners as PromoBanners,
        diningOptions: restaurantData?.diningOptions,
        orderingDisabled:
          restaurantData?.diningOptions?.every(
            option => !option.asapSchedule?.availableNow && (!option.futureSchedule?.dates.length || !option.futureSchedule?.dates.filter(date => date?.times?.length).length)
          ),
        restaurant: siteRestaurant,
        sitePages: filteredSitePages,
        locations: siteRestaurant.locations,
        selectedLocation: location,
        updateLocation,
        orderingUrl: location.onlineOrderingUrl || siteRestaurant.onlineOrderingUrl,
        reservationsUrl: location.reservationsUrl || siteRestaurant.reservationsUrl,
        phoneNumber: location.phoneNumber,
        pixels: siteRestaurant.pixels,
        host,
        toastProduct
      }}>
        {children}
        {siteRestaurantLoading || ooLoading ? <LoadingSpinnerOverlay /> : null}
        <TrackRestaurant />
      </RestaurantContext.Provider>
    );
  };

  const LdWrappedComponent = LDProvider(Component);

  return isEditor ? <Component /> : <LdWrappedComponent />;
};

export const useRestaurant = () => {
  const context = useContext(RestaurantContext);
  if(!context) {
    throw new Error('useRestaurant must be used within a RestaurantContextProvider');
  }
  return context;
};

export const useOptionalRestaurant = () => {
  const context = useContext(RestaurantContext);
  return context;
};

export default RestaurantContextProvider;
