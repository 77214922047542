import { Cart, Discounts, LoyaltyDiscount, LoyaltyInquiryResponse, LoyaltyRedemption, LoyaltyRedemptionType, Order, Selection } from 'src/apollo/onlineOrdering';

// Gets all of the loyalty options from the inquiry response and those attached to selections on the check
export const getLoyaltyOptions = (
  inquiryResponse: LoyaltyInquiryResponse,
  order: Order
) => {
  return ensureUniqueRedemptions([
    ...inquiryResponse.redemptions || [],
    ...getRedemptionsFromSelections(order),
    ...getRedemptionsFromCart(order)
  ]).sort((a, b) => (a.name || '').localeCompare(b.name || ''));
};

/**
 * Filter the given redemptionsList to ensure that it only contains unique discounts.
 * Uniqueness is determined by redemptionGuid+referenceId for each discount
 * @param redemptionsList
 */
const ensureUniqueRedemptions = (redemptionsList: LoyaltyRedemption[]) => {
  let seenCombination = new Set();

  return redemptionsList.filter(redemption => {
    // Combine redemptionGuid and referenceId to create a unique key
    let key = `${redemption.redemptionGuid}-${redemption.referenceId}`;

    if(!seenCombination.has(key)) {
      seenCombination.add(key);
      return true;
    }
    return false;
  });
};

const getRedemptionsFromCart = (order: Order | undefined): LoyaltyRedemption[] => {
  return order?.discounts?.loyaltyDiscounts?.map((discount: LoyaltyDiscount) => ({
    redemptionAmount: discount.amount,
    redemptionGuid: discount.guid,
    type: discount.type,
    referenceId: discount.referenceId,
    name: discount.name
  })) || [];
};

// Gets redemptions that are currently applied to selections
const getRedemptionsFromSelections = (order: Order | undefined ) => {
  let redemptions: LoyaltyRedemption[] = [];
  order?.selections?.forEach((selection: Selection) => {
    selection.appliedDiscounts?.forEach((discount: Discounts) => {
      let loyaltyRedemption: LoyaltyRedemption = {
        redemptionAmount: discount.discountAmount!,
        redemptionGuid: discount.discount?.guid!,
        type: LoyaltyRedemptionType.ItemRewards,
        referenceId: discount.loyaltyDetails?.referenceId,
        name: discount.name
      };
      if(discount.loyaltyDetails?.vendor === 'INTEGRATION') {
        loyaltyRedemption.type = LoyaltyRedemptionType.Integration;
      }
      redemptions.push(loyaltyRedemption);
    });
  });
  return redemptions;
};

// Returns true if the given redemption is found in the cart
export const isRedemptionInCart = (r: LoyaltyRedemption, c?: Cart | null) => {
  let redemptions: any[] = [];
  if(c !== null && c) {
    if(c.order?.discounts?.loyaltyDiscounts) {
      redemptions = c.order.discounts.loyaltyDiscounts;
    }
    redemptions = [...redemptions, ...getRedemptionsFromSelections(c.order!)];
    return redemptions.find((l: any) => {
      return (l?.guid === r.redemptionGuid || l.redemptionGuid === r.redemptionGuid) &&
        l?.type === r.type &&
        l?.referenceId === r.referenceId;
    });
  }
  return undefined;
};

export const getRedemptionGuid = (r: LoyaltyRedemption) => {
  return r.redemptionGuid;
};

export const getPointsMessage = (pointsBalance : number): string => {
  if(pointsBalance > 1) {
    return `You have ${pointsBalance} points!`;
  } else if(pointsBalance === 1) {
    return `You have ${pointsBalance} point!`;
  } else {
    return 'You don\'t have any points (yet!)';
  }
};

export const getNoRewardsMessage = (pointsBalance : number, accrualTarget?: number | null): string => {
  if(accrualTarget) {
    return `Collect ${accrualTarget - pointsBalance} more points to unlock a reward!`;
  }
  return pointsBalance === 0 ?
    'Keep ordering to earn points and unlock rewards.'
    : 'No rewards this time.';
};

export const getFirstOrThirdParty = (programName: string | null | undefined) => {
  if(programName === 'INTEGRATION') {
    return '3PL';
  } else {
    if(programName) {
      return 'ToastLoyalty';
    } else {
      return 'none';
    }
  }
};
