import React, { useContext, createContext, useCallback, useState, useEffect, useMemo } from 'react';

import {
  OrderSource,
  CartFulfillmentInput,
  useAddToCartMutation,
  useCartQuery,
  useDeleteFromCartMutation,
  useEditCartItemMutation,
  useApplyPromoCodeMutation,
  useRemovePromoCodeMutation,
  useReorderMutation
} from 'src/apollo/onlineOrdering';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import {
  AddCartData,
  CartData,
  Cart,
  modifierToSelectionInput,
  WrappedModifier,
  calculateSubtotal
} from './types';

export const getOrderSource = () => {
  if(typeof window !== 'undefined' && window.location && window.location.href.includes('toasttab.com/local')) {
    return OrderSource.Local;
  }

  return OrderSource.Boo;
};


type ErrorType = 'CartModificationError' | 'CartOutOfStockError' | 'CartError' | 'ApplyPromoCodeError' | 'ReorderError' | 'ApolloError';
export type CartErrorType = {
  kind: ErrorType;
  message: string;
};

export type CartContextType = {
  cartGuid?: string | null;
  refetchCart: () => void;
  clearCart: () => void;
  addToCart: (item: WrappedModifier, quantity: number, fulfillmentData?: CartFulfillmentInput) => Promise<null | ErrorType>;
  editCartItem: (item: WrappedModifier, quantity: number, selectionGuid: string) => Promise<null | ErrorType>;
  deleteFromCart: (itemGuid: string) => void;
  reorder: (orderGuid: string, restaurantGuid: string, fulfillment?: CartFulfillmentInput) => Promise<void>;
  subtotal?: number | null;
  applyPromoCode: (promoCode: string) => Promise<null | ErrorType>;
  removePromoCode: () => Promise<null | ErrorType>;
  loadingCart: boolean;
  cart?: Cart | null;
  restaurantGuid: string;
  error?: CartErrorType | null;
}

export const CartContext = createContext<CartContextType | undefined>(undefined);

const getCartKey = (locationId: string): string => {
  return `toast-embedded-oo-cart|${locationId}`;
};

export type CartRestaurant = Cart['restaurant'] & { __typename: 'Restaurant' };
export type Location = CartRestaurant['location'];

export const CartContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { selectedLocation } = useRestaurant();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | CartErrorType>(null);
  const [cartGuid, setCartGuid] = useState<string | null>(null);
  const ooClient = useOOClient();
  const [addToCartMutation] = useAddToCartMutation({ client: ooClient });
  const [deleteFromCartMutation] = useDeleteFromCartMutation({ client: ooClient });
  const [editCartItemMutation] = useEditCartItemMutation({ client: ooClient });
  const [applyPromoCodeMutation] = useApplyPromoCodeMutation({ client: ooClient });
  const [removePromoCodeMutation] = useRemovePromoCodeMutation({ client: ooClient });
  const [reorderMutation] = useReorderMutation({ client: ooClient });

  const restaurantGuid = selectedLocation.externalId;
  const cartKey = getCartKey(restaurantGuid);

  const clearCart = useCallback(() => {
    localStorage.setItem(cartKey, '');
    setCartGuid(null);
  }, [cartKey, setCartGuid]);

  useEffect(() => {
    if(typeof window !== 'undefined') {
      const cartGuid = localStorage.getItem(cartKey);
      if(cartGuid) {
        setCartGuid(cartGuid);
      } else {
        setCartGuid(null);
      }
    }
  }, [cartKey]);


  const { data, loading: loadingData, error: reqError, refetch } = useCartQuery({
    ssr: false,
    skip: !cartGuid,
    variables: { guid: cartGuid || '' },
    notifyOnNetworkStatusChange: true,
    client: ooClient
  });

  if(reqError) {
    setError({
      kind: 'ApolloError',
      message: reqError.message
    });
  }

  if(data?.cartV2?.__typename === 'CartError') {
    setError({
      kind: data?.cartV2.__typename,
      message: data?.cartV2.message
    });
    clearCart();
  }

  const cartData: CartData = data?.cartV2 as CartData;

  const addToCart = useCallback(async (item: WrappedModifier, quantity: number, fulfillmentData?: CartFulfillmentInput) => {
    // this should never happen, since we don't show the cart if ordering is unavailable
    if(!fulfillmentData) {
      return null;
    }

    setLoading(true);
    const { data } = await addToCartMutation({
      variables: {
        input: {
          cartGuid,
          createCartInput: cartGuid
            ? null
            : {
              restaurantGuid: restaurantGuid,
              orderSource: getOrderSource(),
              cartFulfillmentInput: fulfillmentData
            },
          selection: modifierToSelectionInput({
            ...item,
            quantity
          })
        }
      }
    });

    if(!cartGuid && data?.addItemToCartV2?.__typename === 'CartResponse') {
      const cartData: AddCartData = data?.addItemToCartV2 as AddCartData;
      localStorage.setItem(cartKey, cartData.cart.guid);
      setCartGuid(cartData.cart.guid);
    }

    refetch();
    setLoading(false);

    if(data?.addItemToCartV2.__typename === 'CartModificationError' || data?.addItemToCartV2?.__typename === 'CartOutOfStockError') {
      setError({
        kind: data?.addItemToCartV2.__typename,
        message: data?.addItemToCartV2?.message
      });
      return data?.addItemToCartV2?.__typename;
    }

    return null;
  }, [cartGuid, addToCartMutation, restaurantGuid, refetch, cartKey]);

  const editCartItem = useCallback(async (item: WrappedModifier, quantity: number, selectionGuid: string) => {
    // this shouldn't be possible
    if(!cartGuid) {
      return null;
    }

    setLoading(true);
    const { data } = await editCartItemMutation({
      variables: {
        input: {
          cartGuid,
          selectionGuid,
          selection: modifierToSelectionInput({
            ...item,
            quantity
          })
        }
      }
    });

    refetch();
    setLoading(false);
    if(data?.editItemInCartV2.__typename === 'CartModificationError' || data?.editItemInCartV2?.__typename === 'CartOutOfStockError') {
      setError({
        kind: data?.editItemInCartV2.__typename,
        message: data?.editItemInCartV2?.message
      });
      return data?.editItemInCartV2?.__typename;
    }

    return null;
  }, [cartGuid, editCartItemMutation, refetch]);

  const deleteFromCart = useCallback(async (itemGuid: string) => {
    if(!cartGuid) {
      return;
    }

    setLoading(true);
    await deleteFromCartMutation({
      variables: {
        input: {
          cartGuid,
          selectionGuid: itemGuid
        }
      }
    });

    // Not necessary, could be replaced with cart returned from mutation
    refetch();
    setLoading(false);
  }, [cartGuid, deleteFromCartMutation, refetch]);

  const applyPromoCode = useCallback(async (promoCode: string) => {
    // this shouldn't be possible
    if(!cartGuid) {
      return null;
    }

    setLoading(true);
    const { data } = await applyPromoCodeMutation({ variables: { input: { cartGuid, promoCode } } });

    if(data?.applyPromoCodeV3.__typename === 'ApplyPromoCodeError') {
      setLoading(false);
      return data?.applyPromoCodeV3.__typename;
    }

    refetch();
    setLoading(false);
    return null;
  }, [cartGuid, applyPromoCodeMutation, refetch]);

  const removePromoCode = useCallback(async () => {
    // this shouldn't be possible
    if(!cartGuid) {
      return null;
    }

    setLoading(true);
    const { data } = await removePromoCodeMutation({ variables: { input: { cartGuid } } });

    if(data?.removePromoCodeV2.__typename === 'CartModificationError' || data?.removePromoCodeV2.__typename === 'CartOutOfStockError' ) {
      setLoading(false);
      return data?.removePromoCodeV2.__typename;
    }

    refetch();
    setLoading(false);
    return null;
  }, [cartGuid, removePromoCodeMutation, refetch]);

  const reorder = useCallback(async (orderGuid: string, restaurantGuid: string, fulfillment?: CartFulfillmentInput) => {
    setLoading(true);

    const { data } = await reorderMutation({
      variables: {
        input: {
          orderGuid,
          restaurantGuid,
          cartFulfillmentInput: fulfillment,
          orderSource: getOrderSource()
        }
      }
    });

    if(data?.reorderV2?.__typename === 'ReorderResponse') {
      localStorage.setItem(cartKey, data.reorderV2.cart.guid);
      setCartGuid(data.reorderV2.cart.guid);
    }

    if(data?.reorderV2?.__typename === 'ReorderError') {
      setError({
        kind: data?.reorderV2.__typename,
        message: data?.reorderV2?.message
      });
    }

    setLoading(false);
    return;
  }, [reorderMutation, cartKey]);

  const subtotal = useMemo(() => calculateSubtotal(cartData?.cart?.order), [cartData]);

  return (
    <CartContext.Provider value={{
      cartGuid,
      refetchCart: refetch,
      clearCart,
      addToCart,
      deleteFromCart,
      editCartItem,
      reorder,
      subtotal,
      applyPromoCode,
      removePromoCode,
      loadingCart: loading || loadingData,
      cart: cartData?.cart,
      restaurantGuid: restaurantGuid,
      error
    }}>
      {props.children}
    </CartContext.Provider>);
};

export const useCart = () => {
  const context = useContext(CartContext);
  if(!context) {
    throw new Error('useCart must be used within a CartContextProvider');
  }

  return context;
};

export const useOptionalCart = () => {
  const context = useContext(CartContext);

  return context;
};
