import React, { useMemo } from 'react';

import { SpotlightConfig } from 'src/apollo/onlineOrdering';
import { Banner as BannerType } from 'src/apollo/sites';

import { PromoBanners } from 'shared/components/common/restaurant_context/RestaurantContext';

import Banner, { BannerMessages } from './Banner';

type Props = {
  sitesBanner?: BannerType | null;
  ooPromoBanners?: PromoBanners | null;
  spotlightConfig?: SpotlightConfig | null;
  className?: string;
};

const PageBanner = ({ sitesBanner, ooPromoBanners, spotlightConfig, className }: Props) => {
  const consolidatedMessages = useMemo(() => ({
    sitesBanners: sitesBanner?.content ? [sitesBanner.content] : [],
    loyaltyBanners: ooPromoBanners?.loyaltyBanners ? [...ooPromoBanners.loyaltyBanners] : [],
    spotlightConfig
  } as BannerMessages), [sitesBanner?.content, ooPromoBanners, spotlightConfig]);

  const hasBanners = useMemo(() => consolidatedMessages.sitesBanners.length ||
    consolidatedMessages.loyaltyBanners.length ||
    consolidatedMessages.spotlightConfig?.headerText,
  [consolidatedMessages]);

  return hasBanners ?
    <Banner messages={consolidatedMessages} className={className} /> :
    null;
};

export default PageBanner;
