
import useCookie from 'react-use-cookie';

import config from 'config';

export const useGiaAuth = () => {
  const [giaAuthOverride] = useCookie('woo-gia-direct-auth', '');

  // if explicitly set return
  if(giaAuthOverride === 'true') {
    return true;
  } else if(giaAuthOverride === 'false') {
    return false;
  }

  // if undefined return the value of config
  return config.giaAuth;
};
