import React from 'react';

import ChevronIcon from 'shared/components/common/carousel/ChevronIcon';
import Link from 'shared/components/common/link';
import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import { CartItems } from 'public/components/default_template/online_ordering/cart/Cart';
import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';
import { useCart } from 'public/components/online_ordering/CartContext';

const OrderSection = () => {
  const { cart } = useCart();
  const { orderPath } = useRestaurantRoutes();
  const numItems = cart?.order?.selections?.length;

  return (
    <CheckoutSection title={`Order details (${numItems})`} collapsable>
      <div className="cartOrder expandableSection">
        <CartItems editable />
        <Link href={orderPath}>
          <div className="addItemsButton" role="button" >
            <ChevronIcon color={DEFAULT_COLORS.text as string} />
            <span>Add more items</span>
          </div>
        </Link>
      </div>
    </CheckoutSection>
  );
};
export default OrderSection;
