import React from 'react';

import { ModalContent } from 'shared/components/common/modal';

import Reservation from 'public/components/default_template/reservation/Reservation';

export const ReservationModalContent = () => {
  return (
    <ModalContent wrapperClassName="reservationModalWrapper" contentClassName="reservationModalContent" slideIn slideOut>
      <div className="reservation" tabIndex={0}>
        <Reservation isModal />
      </div>
    </ModalContent>
  );
};
