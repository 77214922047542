import React, { useCallback, useEffect, useMemo } from 'react';

import classnames from 'classnames';

import {
  LoyaltyRedemption,
  LoyaltyRedemptionType,
  Cart
} from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';
import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';
import { useLoyalty } from 'src/public/components/online_ordering/LoyaltyContext';

import Image from 'shared/components/common/Image';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';
import { useCart } from 'public/components/online_ordering/CartContext';

import { LockedLoyaltyRedemption } from './LockedLoyaltyRedemption';
import { getFirstOrThirdParty, getNoRewardsMessage, getPointsMessage, isRedemptionInCart } from './LoyaltyUtils';
import { ThirdPartyLoyaltyGuestConsentForm } from './ThirdPartyLoyaltyGuestConsentForm';


type RedemptionDescriptionProps = {
  redemption: LoyaltyRedemption;
  conversionRate?: number | null;
  pointsBalance: number;
  applied: boolean;
};

export const RedemptionDescription = ({ redemption, conversionRate, pointsBalance, applied }: RedemptionDescriptionProps) => {
  let description = '';
  const pointsUsed = conversionRate ? Math.round(redemption.redemptionAmount / conversionRate) : pointsBalance;
  const formatAmount = (redemption: LoyaltyRedemption) => redemption.redemptionAmount.toFixed(2);
  switch(redemption.type) {
    case LoyaltyRedemptionType.Integration:
      description = redemption.name || `$${formatAmount(redemption)} off`;
      break;
    case LoyaltyRedemptionType.ItemRewards:
      description = redemption.name || 'Free Item';
      break;
    case LoyaltyRedemptionType.BirthdayRewards:
      description = `$${formatAmount(redemption)} off Birthday Reward`;
      break;
    default:
      description = `$${formatAmount(redemption)} off (${pointsUsed} points)`;
      break;
  }
  const imageSrc = useMemo(() => {
    return applied ? 'icons/diamond-rewards-white.svg' : 'icons/diamond-rewards.svg';
  }, [applied]);

  return (
    <>
      <Image alt="Loyalty reward" src={imageSrc} data-testid="loyalty-icon" />
      {description}
    </>
  );
};

const LoyaltyRedemptionSection = () => {
  const { ooRestaurant } = useRestaurant();
  const { cart, refetchCart } = useCart();
  const {
    hasLoyaltyAccount,
    pointsBalance,
    redemptions,
    removeRedemption,
    addRedemption,
    loadingRedemptions,
    loadingLoyaltyAccount,
    redemptionErrorRef,
    setAllowLookup
  } = useLoyalty();
  const tracker = useTracker();
  const { customer } = useCustomer();

  const requireLookupAuthorization = useMemo(() => getFirstOrThirdParty(ooRestaurant?.loyaltyConfig?.programName) === '3PL', [ooRestaurant?.loyaltyConfig?.programName]);

  useEffect(() => {
    // auto allow 1st party
    if(getFirstOrThirdParty(ooRestaurant?.loyaltyConfig?.programName) === 'ToastLoyalty') {
      setAllowLookup(true, false);
    }
  }, [ooRestaurant?.loyaltyConfig?.programName, setAllowLookup]);

  const onClickRedemption = useCallback((redemption: LoyaltyRedemption) => async () => {
    let action = 'Removed';
    if(isRedemptionInCart(redemption, cart as Cart)) {
      await removeRedemption(redemption);
    } else {
      action = 'Added';
      await addRedemption(redemption);
    }
    tracker.track('Loyalty Reward Tapped', {
      redemptionName: redemption.name,
      action: action,
      firstOrThirdParty: getFirstOrThirdParty(ooRestaurant?.loyaltyConfig?.programName),
      error: redemptionErrorRef?.current
    });
    refetchCart();
  }, [cart, tracker, ooRestaurant?.loyaltyConfig?.programName, redemptionErrorRef, refetchCart, removeRedemption, addRedemption]);

  const pointsMessage = useMemo(() => {
    return getPointsMessage(pointsBalance);
  }, [pointsBalance]);

  const noRewardsMessage = useMemo(() => {
    return getNoRewardsMessage(pointsBalance, ooRestaurant?.loyaltyConfig?.accrualTarget);
  }, [pointsBalance, ooRestaurant?.loyaltyConfig?.accrualTarget]);

  if(requireLookupAuthorization && customer && !hasLoyaltyAccount) return (
    <ThirdPartyLoyaltyGuestConsentForm restaurantName={ ooRestaurant?.name } />
  );

  if(!ooRestaurant?.loyaltyConfig || !cart || !hasLoyaltyAccount || !customer) {
    return null;
  }

  return (
    <CheckoutSection title="Rewards">
      <div className="loyaltyRedemption" data-testid="loyalty-redemption">
        {loadingLoyaltyAccount && <LoadingSpinnerOverlay />}
        <div className="header">{pointsMessage}</div>
        { redemptions.length !== 0 ?
          <>
            <div className="message">Select a reward to redeem it with this order</div>
            <br />
            <div className="redemptions" data-testid="redemptions-list">
              {loadingRedemptions && <LoadingSpinnerOverlay />}
              {redemptions.map((r, i) => {
                const appliedToCart = isRedemptionInCart(r, cart as Cart);
                return (
                  <button
                    key={`redemption-${i}`}
                    type="button"
                    className={classnames('redemption', { added: appliedToCart, loading: loadingRedemptions })}
                    onClick={onClickRedemption(r)}
                    data-testid={`redemption-button-${i}`}>
                    <RedemptionDescription
                      redemption={r}
                      conversionRate={ooRestaurant.loyaltyConfig.conversionRate}
                      pointsBalance={pointsBalance}
                      applied={appliedToCart} />
                  </button>);
              })}
            </div>
          </>
          :
          <>
            { ooRestaurant?.loyaltyConfig.rewardType != 'ITEM' &&
              <div>
                <div className="message">{noRewardsMessage}</div>
                <LockedLoyaltyRedemption accrualTarget={ooRestaurant.loyaltyConfig.accrualTarget} conversionRate={ooRestaurant.loyaltyConfig.conversionRate} />
              </div>}
          </>}
        {redemptionErrorRef?.current && <p className="error">{redemptionErrorRef.current}</p>}
      </div>
      <div className="separator top" />
    </CheckoutSection>
  );
};

export default LoyaltyRedemptionSection;
