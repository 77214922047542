import React, { useMemo } from 'react';
import { useParams, withRouter } from 'react-router';

import { createClient } from 'src/apollo/createClient';
import { useRestaurantQuery } from 'src/apollo/toastLocal';
import { RequestContextProps } from 'src/lib/js/context';
import useTracker from 'src/lib/js/hooks/useTracker';
import { ExperimentContextProvider } from 'src/shared/components/common/ab_testing/ABTestContext';

import RestaurantContextProvider from 'shared/components/common/restaurant_context/RestaurantContext';
import { RestaurantRoutesContextProvider } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import { RxDataUsage } from 'shared/js/hooks/useDefaultSiteData';

import Meta from 'public/components/default_template/meta/Meta';
import { TLRestaurantType } from 'public/components/default_template/restaurant_profile/profileUtils';
import { TOASTTAB_LOCAL_PATH, TOASTTAB_PROFILE_PATH } from 'public/js/siteUtilities';

import { resources } from 'config';


export type WrappedProfilePageProps = {
  rxGuid: string;
  shortUrl: string;
  tlRestaurant: TLRestaurantType;
};

const RestaurantProfilePageWrapper = ({ staticContext, children }: RequestContextProps & { children: ((p: WrappedProfilePageProps) => React.ReactNode) }) => {
  const { slug, rxGuid }: { slug: string, rxGuid: string } = useParams();
  const tracker = useTracker();

  // Create the Apolo Client for querying the Toast Local API.
  // On the client side, use the global state to create a new Apollo Client.
  // On the server side, use the Apollo Client that has been created and attached to the request.
  const tlClient = useMemo(() =>
    typeof window !== 'undefined' ?
      // @ts-ignore
      createClient(resources.toastLocalAPIHost, window.__TL_STATE__, false, true, undefined, false, false, resources.clientQueryTimeoutMs) :
      staticContext?.req?.toastLocalServerClient,
  [staticContext]);

  const { data, loading } = useRestaurantQuery({
    variables: { slug, guid: rxGuid },
    client: tlClient
  });

  if(loading) {
    return null;
  }

  if(!data?.restaurant) {
    return <NoMatch404 />;
  }

  const homePath = `${TOASTTAB_LOCAL_PATH}`;
  const profilePath = `${TOASTTAB_PROFILE_PATH}/${slug}/r-${rxGuid}`;
  const profilePathPattern = `${TOASTTAB_PROFILE_PATH}/:slug/r-:rxGuid`;

  return (
    <ExperimentContextProvider onVariantSelected={(experimentName, variantName) => {
      tracker.register({ [experimentName]: variantName });
    }}>
      <Meta title={`${data.restaurant?.name} | Toast`} primaryColor="#FF4C00" icon="icons/favicon-32x32.png" domain={resources.toastLocalHost} />
      <RestaurantContextProvider ooUsage={RxDataUsage.Required} sitesUsage={RxDataUsage.None} isEditor={false}>
        <RestaurantRoutesContextProvider shortUrl={slug} homePath={homePath} orderPath={profilePath}
          orderPathPattern={profilePathPattern} checkoutPathPrefix={profilePath} confirmPathPrefix={profilePath}>
          {children({ rxGuid, tlRestaurant: data.restaurant as TLRestaurantType, shortUrl: slug })}
        </RestaurantRoutesContextProvider>
      </RestaurantContextProvider>
    </ExperimentContextProvider>
  );
};

export default withRouter<RequestContextProps, React.ComponentType<RequestContextProps>>(RestaurantProfilePageWrapper);
