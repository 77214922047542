export const hexToRgbA = (hex?: string | null, aValue?: number | null) => {
  let alphaChannel = aValue;
  if(hex) {
    hex = hex.trim();
  }
  if(hex && /^rgb/.test(hex)) {
    if(hex.indexOf('rgba') === 0) {
      return alphaChannel != null && alphaChannel != undefined ? hex.replace(/,[0-9.]+?\)$/, `,${alphaChannel})`) : hex;
    } else {
      return hex.replace('rgb', 'rgba').replace(')', `,${alphaChannel ?? 1})`);
    }
  }
  if(hex && /^#([A-Fa-f0-9]){3,8}$/.test(hex)) {
    let hexArray = hex.substring(1).split('');
    if(hexArray.length === 3) {
      hexArray = [hexArray[0]!, hexArray[0]!, hexArray[1]!, hexArray[1]!, hexArray[2]!, hexArray[2]!];
    }
    // Use the hex alpha channel if no alpha channel is provided
    if(hexArray.length === 8) {
      if(alphaChannel === null || alphaChannel === undefined) {
        alphaChannel = (Number('0x' + hexArray[6]! + hexArray[7]!) & 255) / 255;
      }
    }
    const normalizedHex = '0x' + hexArray.slice(0, 6).join('');
    // @ts-ignore
    return 'rgba(' + [normalizedHex >> 16 & 255, normalizedHex >> 8 & 255, normalizedHex & 255].join(',') + ',' + (alphaChannel ?? 1) + ')';
  }
  return null;
};
