import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { MenuFormatConfig, MenuTemplate } from 'src/apollo/sites';
import useRefSize from 'src/lib/js/hooks/useRefSize';
import { ModalCloseButton } from 'src/shared/components/common/modal';

import DropdownArrow from 'shared/components/common/dropdown/DropdownArrow';
import Popover from 'shared/components/common/popover/Popover';

import { scrollToRef } from './menuNavScrollUtils';

// Account for a fixed amount of padding between pills
const PADDING_CONSTANT = 8;
const MORE_PILL_WIDTH = 80;

type Pill = {
  value: string;
  guid: string;
  anchorRef: React.RefObject<HTMLDivElement>;
  pillRef: React.RefObject<HTMLButtonElement>;
}

type Props = {
  options: {
    value: string;
    guid: string;
    anchorRef: React.RefObject<HTMLDivElement>;
  }[];
  selected?: string;
  setSelected: (optionGuid?: string) => void;
  className?: string;
  menuConfig?: MenuFormatConfig | null;
}

const SortPills = (props: Props) => {
  const { isEditor } = useEditor();
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const moreRef = React.useRef<HTMLButtonElement>(null);

  const refPills: Pill[] = useMemo(() => props.options.map(opt => ({
    value: opt.value,
    guid: opt.guid,
    anchorRef: opt.anchorRef,
    pillRef: React.createRef<HTMLButtonElement>()
  })), [props]);

  const [inView, setInView] = useState<Set<string>>(new Set(refPills.map(opt => opt.guid)));
  const [inMore, setInMore] = useState<Set<string>>(new Set());

  const { width } = useRefSize(wrapperRef);
  const isMoreSelected = props.selected && inMore?.has(props.selected);

  const recalculateWidths = useCallback(() => {
    let tempInView: Set<string> = new Set(), tempInMore: Set<string> = new Set();
    let currWidth = MORE_PILL_WIDTH + PADDING_CONSTANT;
    let menuFull = false;
    if(width) {
      refPills.forEach(pill => {
        const pillWidth = pill.pillRef.current?.clientWidth || 0;
        if(currWidth + pillWidth + PADDING_CONSTANT > width || menuFull) {
          tempInMore.add(pill.guid);
          menuFull = true;
        } else {
          tempInView.add(pill.guid);
          currWidth += pillWidth + PADDING_CONSTANT;
        }
      });

      setInView(tempInView);
      setInMore(tempInMore);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, refPills, props.options]);

  useEffect(() => {
    recalculateWidths();
  }, [recalculateWidths]);

  // Callback ref to run so first render overflow can be handled
  const rerenderRef: React.LegacyRef<HTMLDivElement> = useCallback(ref => {
    if(ref) {
      recalculateWidths();
    }
  }, [recalculateWidths]);

  const toShow = useMemo(() => refPills.filter(opt => inView.has(opt.guid)), [inView, refPills]);

  return (
    <nav className={classnames('sortPills', props.className, { condensed: props.menuConfig?.template == MenuTemplate.Condensed })} ref={wrapperRef} data-testid="sort-pills">
      <ul>
        {toShow.map((opt, index) => {
          const isSelected = props.selected === opt.guid;
          return (
            <li key={opt.guid}>
              {index === toShow.length - 1 && inMore.size === 0 ? <div className="hide" ref={rerenderRef} /> : null}
              <button
                ref={opt.pillRef}
                type="button"
                className={classnames('pill', { selected: isSelected })}
                onClick={() => {
                  scrollToRef(opt.anchorRef, isEditor);
                  props.setSelected(opt.guid);
                }}
                role="tab"
                id={`menu-pill-${opt.guid}`}
                aria-selected={isSelected}
                data-testid={`menu-pill-${opt.guid}`}>
                {opt.value}
              </button>
            </li>
          );
        })}
        <li>
          {inMore?.size && inMore.size >= 1 ?
            <Popover
              contextKey="sortPills"
              target={({ open }) =>
                <button type="button" className={classnames('pill', { selected: isMoreSelected })} onClick={open} ref={moreRef}>
              More
                  <DropdownArrow />
                </button>}>
              {(({ close }) =>
                <div className="more">
                  <div className="header">
                    <h3>Menu categories</h3>
                    <ModalCloseButton onClose={close} />
                  </div>
                  <ul className="pills">
                    {refPills.filter(opt => inMore.has(opt.guid)).map(opt => {
                      const isSelected = props.selected === opt.guid;
                      return (
                        <li key={opt.guid}>
                          <button
                            ref={opt.pillRef}
                            type="button"
                            role="tab"
                            id={`menu-pill-${opt.guid}`}
                            aria-selected={isSelected}
                            className={classnames('pill', { selected: isSelected })}
                            onClick={(e: React.SyntheticEvent) => {
                              scrollToRef(opt.anchorRef, isEditor);
                              props.setSelected(opt.guid);
                              close(e);
                            }}>
                            {opt.value}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </Popover>
            : null}
        </li>
      </ul>
    </nav>
  );
};

export default SortPills;
