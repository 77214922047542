import React, { createContext, useCallback, useContext, useEffect } from 'react';

import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { motion, AnimatePresence } from 'framer-motion';

import Portal from './Portal';

type ModalContextType = {
  onClose: () => void;
  preventOverlayClose?: boolean;
}

type OverlayProps = {
  fadeIn?: boolean;
  fadeOut?: boolean;
  opacity?: number;
  color?: string;
}

type Props = {
  // Prevents clicking outside the modal from closing it
  preventOverlayClose?: boolean;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  testId?: string;
}

type ContentProps = {
  wrapperClassName?: string;
  contentClassName?: string;
  slideIn?: boolean;
  slideOut?: boolean;
  fadeIn?: boolean;
  style?: React.CSSProperties | undefined;
  ariaLabelledBy?: string;
}

const ModalContext = createContext<ModalContextType>({ onClose: () => { } });

export const ModalOverlay = ({ fadeIn, fadeOut, opacity, color }: OverlayProps) => {
  const { onClose, preventOverlayClose } = useModalContext();
  const isClient = typeof window !== 'undefined';

  const onOverlayClick = useCallback(() => {
    if(!preventOverlayClose) {
      onClose();
    }
  }, [preventOverlayClose, onClose]);

  const targetOpacity = typeof opacity === 'number' ? opacity / 100 : undefined;

  return (
    <motion.div
      initial={fadeIn && isClient ? { opacity: 0 } : undefined}
      animate={fadeIn ? { opacity: targetOpacity || 1 } : undefined}
      exit={fadeIn || fadeOut ? { opacity: 0 } : undefined}
      transition={{ duration: 0.2 }}
      onClick={onOverlayClick}
      style={{ opacity: targetOpacity, backgroundColor: color }}
      className="modalOverlay" />
  );
};

export const ModalContent = (props: React.PropsWithChildren<ContentProps>) => {
  const isClient = typeof window !== 'undefined';

  return (
    <FocusTrap focusTrapOptions={{ allowOutsideClick: true, initialFocus: '#modal-content' }}>
      <div className={classnames('modalWrapper', props.wrapperClassName)} role="dialog" aria-modal="true">
        <motion.div
          initial={props.slideIn && isClient ? { translateX: '100vw' } : props.fadeIn && isClient ? { opacity: 0 } : undefined}
          animate={props.slideIn ? { translateX: '0vw' } : props.fadeIn ? { opacity: 1 } : undefined}
          exit={props.slideIn || props.slideOut ? { translateX: '100vw' } : props.fadeIn ? { opacity: 0 } : undefined}
          transition={{ duration: props.slideIn ? 0.5 : 0.2 }}
          className={classnames('modalContent', props.contentClassName)}
          aria-labelledby={props.ariaLabelledBy}
          style={props.style}
          id="modal-content">
          {props.children}
        </motion.div>
      </div>
    </FocusTrap>
  );
};

type ModalCloseButtonProps = {
  className?: string
  onClose?: () => void
}

export const ModalCloseButton = ({ className, onClose }: ModalCloseButtonProps) => {
  const { onClose: defaultClose } = useModalContext();

  return (
    <button data-testid="modal-close-button" aria-label="Close" type="button" className={classnames('closeButton', className)} onClick={onClose ?? defaultClose} dangerouslySetInnerHTML={{
      __html: `
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" stroke="currentColor" fill="currentColor" />
    </svg>`
    }}>

    </button>
  );
};

export const Modal = (props: React.PropsWithChildren<Props>) => {
  const { onClose, preventOverlayClose } = props;
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if(e.key === 'Escape' && !preventOverlayClose) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose, preventOverlayClose]);

  return (
    <ModalContext.Provider value={{
      onClose: props.onClose,
      preventOverlayClose: props.preventOverlayClose
    }}>
      <AnimatePresence>
        {props.isOpen &&
          <Portal>
            <div className={classnames('modal', props.className)} data-testid={props.testId}>
              {props.children}
            </div>
          </Portal>}
      </AnimatePresence>
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  return useContext(ModalContext);
};
