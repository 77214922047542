import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { ProfileAddresses } from 'public/components/default_template/online_ordering/account/profile_addresses/ProfileAddresses';
import { ProfileInformation } from 'public/components/default_template/online_ordering/account/profile_information/ProfileInformation';
import { ProfileSettings } from 'public/components/default_template/online_ordering/account/profile_settings/profileSettings';
import { ProfileWallet } from 'public/components/default_template/online_ordering/account/profile_wallet/ProfileWallet';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

interface AccountProps {}

const Account: FC<AccountProps> = () => {
  const { customer, loadingCustomer } = useCustomer();
  const history = useHistory();
  const { orderPath } = useRestaurantRoutes();

  useEffect(() => {
    if(!loadingCustomer && !customer) {
      history.push(orderPath);
    }
  }, [customer, history, loadingCustomer, orderPath]);

  return (
    <>
      <div className="account" role="main" id="main" tabIndex={0} aria-label="My Account">
        <strong className="accountHeading">My Account</strong>
        <div className="accountSections" data-testid="Account">
          <div className="informationSection"><ProfileInformation /></div>
          <div className="addressSection"><ProfileAddresses /></div>
          <div className="walletSection"><ProfileWallet /></div>
          <div className="settingsSection"><ProfileSettings /></div>
        </div>
      </div>
    </>
  );
};

export default Account;
