import React from 'react';

import classnames from 'classnames';

import { SectionImagePosition, ThemeTypeEnum } from 'src/apollo/sites';

type Props = {
  theme?: ThemeTypeEnum | null;
  imgPosition?: SectionImagePosition | null;
  textWithBackground?: boolean | null;
  editableRef: React.Ref<HTMLDivElement>
};

const HeroWrapper = ({ theme, imgPosition, textWithBackground, editableRef, children }: React.PropsWithChildren<Props>) => {
  // Center positioned images are always wide because they look better that way and
  // this allows the text box to use the rounded theme.
  const paddedSection =
    imgPosition !== SectionImagePosition.Center
      && (
        textWithBackground && theme !== ThemeTypeEnum.Wide
        || theme === ThemeTypeEnum.Rounded
        || theme === ThemeTypeEnum.Outlined
        || theme === ThemeTypeEnum.HalfLeaf
      );

  return (
    <div ref={editableRef}
      className={classnames('hero templateHero', theme, { paddedSection })}
      data-testid="heroWrapper">
      {children}
    </div>
  );
};

export default HeroWrapper;
