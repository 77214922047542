import React, { useMemo } from 'react';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { resources } from 'config';


const StaticLocationMap = () => {
  const { locations } = useRestaurant();
  const coordinates = useMemo(() => locations?.map(loc => `markers=${loc.lat},${loc.long}`).join('&'), [locations]);
  const src = useMemo(() => `${resources.mapEndpoint}/multilocationMap?${coordinates}&size=300x160`, [coordinates]);

  return (
    <iframe title="Google Maps" width="100%" height="100%" loading="eager"
      src={src}>
    </iframe>
  );
};

export default StaticLocationMap;
