import { useCallback } from 'react';

import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';

import { useValidateMerchantMutation } from 'src/apollo/onlineOrdering';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useApplePayLineItems } from 'public/components/default_template/online_ordering/cart/cartUtils';
import { useCart } from 'public/components/online_ordering/CartContext';


declare global {
  interface Window {
    ApplePaySession: any;
  }
}

export const getMerchantValidationHandler = ({
  session,
  getMerchantSession
}: any) =>
  async ({ validationURL }: any = {}) => {
    if(!validationURL) throw new Error('No Validation Url');
    const merchantSession = await getMerchantSession(validationURL);
    session.completeMerchantValidation(merchantSession);
  };

export const getPaymentAuthorizationHandler = ({
  session,
  performTransaction
}: any) =>
  async ({ payment }: any = {}) => {
    if(!payment) {
      throw new Error('No payment');
    }
    const {
      STATUS_SUCCESS,
      STATUS_FAILURE
    } = window.ApplePaySession;
    try {
      await performTransaction(payment);
      session.completePayment(STATUS_SUCCESS);
    } catch(error) {
      session.completePayment(STATUS_FAILURE);
    }
  };

export const canMakeApplePayPayment = (atCheckoutOptionsLength: number) => {
  if(atCheckoutOptionsLength === 0) {
    return false;
  }
  try {
    if(window?.ApplePaySession) {
      const canMakePayments = window.ApplePaySession.canMakePayments();
      return canMakePayments;
    }
    return false;
  } catch(error) {
    return false;
  }
};

export const useApplePay = () => {
  const { ooV4ApplePay } = useFlags();
  const cartInfo = useCart();
  const restaurantInfo = useRestaurant()?.ooRestaurant as any;
  const restaurantGuid = restaurantInfo.guid;
  const [validateMerchantMutation] = useValidateMerchantMutation();
  const getLineItems = useApplePayLineItems({
    cart: cartInfo.cart,
    showTip: true,
    showTotal: true
  });

  const getConfig = useCallback(
    (tip, fundraisingAmount) => {
      const allItems = getLineItems(tip, fundraisingAmount);
      const {
        whiteLabelName,
        creditCardConfig
      } = restaurantInfo;
      const supportedNetworks = ['discover', 'masterCard', 'visa'];
      if(creditCardConfig.amexAccepted) {
        supportedNetworks.push('amex');
      }
      const total = allItems.find(({ id }) => id === 'total-order-amount') || { amount: 0 };
      const lineItems = allItems.filter(({ id }) => id !== 'total-order-amount');

      return {
        currencyCode: 'USD',
        countryCode: 'US',
        total: {
          label: whiteLabelName,
          amount: total.amount
        },
        requiredBillingContactFields: ['postalAddress'],
        requiredShippingContactFields: ['name', 'phone', 'email'],
        lineItems,
        supportedNetworks,
        merchantCapabilities: ['supports3DS']
      };
    },
    [getLineItems, restaurantInfo]
  );

  const getMerchantSession = useCallback(
    async (validationURL: any) => {
      const response = await validateMerchantMutation({
        variables: {
          input: {
            validationURL,
            restaurantGuid,
            merchantDomain: window.location.hostname
          }
        }
      });

      const validateApplePayMerchant = response.data?.validateApplePayMerchant;
      if(validateApplePayMerchant && validateApplePayMerchant.__typename === 'ValidateApplePayMerchantSuccessResponse') {
        const string = validateApplePayMerchant.merchantSession;
        return JSON.parse(string);
      }

      return {};
    },
    [validateMerchantMutation, restaurantGuid]
  );

  return {
    canUseApplePay: ooV4ApplePay && canMakeApplePayPayment(cartInfo.cart?.paymentOptions?.atCheckout.length ?? 0),
    getMerchantSession,
    getConfig
  };
};

export const makeApplePayPayment = ({
  getMerchantSession,
  performTransaction,
  onCancel = () => { },
  config,
  initSession = () => { }
}: any) => {
  if(!window.ApplePaySession) {
    throw new Error('Apple Pay session not defined');
  }
  const APPLE_PAY_VERSION = 6;
  const session = new window.ApplePaySession(APPLE_PAY_VERSION, config);
  session.onvalidatemerchant = getMerchantValidationHandler({
    session,
    getMerchantSession
  });
  session.onpaymentauthorized = getPaymentAuthorizationHandler({
    session,
    performTransaction
  });

  session.oncancel = onCancel;
  initSession(session);
  session.begin();
  return session;
};
