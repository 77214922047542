import React, { FC, useCallback, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { parsePhoneNumber } from 'libphonenumber-js';
import isEmail from 'validator/lib/isEmail';

import Button from 'shared/components/common/button';
import FormInput from 'shared/components/common/form_input';
import InputMessage from 'shared/components/common/form_input/InputMessage';

import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

type CompleteProfileProps = {
  setHasSubmitted: () => void;
  phoneNumber: string;
  checkingOut?: boolean;
}

type FormInputs = {
  email: string;
  firstName: string;
  lastName: string;
}

const EMAIL_FOCUS_MSG = 'Your email will be used to provide digital receipts, account communication, and marketing messages from both Toast and restaurants you visit unless you unsubscribe.';

export const CompleteProfileForm: FC<CompleteProfileProps> = ({ setHasSubmitted, phoneNumber, checkingOut = false }) => {
  const { completeSignup, fetchCustomer } = useCustomer();
  const [submitting, setSubmitting] = useState(false);
  const [focus, setFocus] = useState(false);
  const formMethods = useForm<FormInputs>({ mode: 'onTouched' });
  const email = useWatch({ name: 'email', control: formMethods.control });
  const firstName = useWatch({ name: 'firstName', control: formMethods.control });
  const lastName = useWatch({ name: 'lastName', control: formMethods.control });
  const { handleSubmit, setValue } = formMethods;
  const [hasError, setHasError] = useState(false);

  const submit = useCallback(async (inputData: FormInputs) => {
    setSubmitting(true);
    try {
      const success = await completeSignup(inputData.email, inputData.firstName, inputData.lastName);
      if(success) {
        await fetchCustomer();
        setHasSubmitted();
      } else {
        setHasError(true);
      }
    } catch(err) {
      setHasError(true);
    } finally {
      setSubmitting(false);
    }
  }, [completeSignup, fetchCustomer, setHasSubmitted]);

  const onBlurInput = (emailValue: string) => () => {
    setValue('email', emailValue, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
  };
  return (
    <FormProvider {...formMethods}>
      {/* Stop propagation of the event so that submitting this form doesn't also trigger a submit of any parent forms, like the checkout form */}
      <form className="completeProfileForm" onSubmit={event => { event.stopPropagation(); handleSubmit(submit)(event); }}>

        <p>We just need a little more info for faster ordering and checkout</p>

        {hasError ? <span className="formError">The information you entered was invalid. Please try again.</span> : null}

        <FormInput id="firstName" type="text" label="First name" required />
        <FormInput id="lastName" type="text" label="Last name" required />
        <FormInput id="email" type="email" label="Email" required
          validate={value => isEmail(value) || 'enter a valid email address'}
          onFocus={() => setFocus(true)}
          onBlur={onBlurInput(email)} />
        <InputMessage id={'email'} showErrorMessage={false} focus={focus} focusMessage={EMAIL_FOCUS_MSG} />

        <div className="phoneNumberBox">{parsePhoneNumber(phoneNumber, 'US').formatNational()}</div>

        <Button className="submitBtn" type="submit" disabled={submitting || !(isEmail(email || '') && firstName && lastName)}>
          {checkingOut ? 'Create account and check out' : 'Create account'}
        </Button>
      </form>
    </FormProvider>
  );
};
