import React from 'react';
import { useLocation } from 'react-router-dom';

import queryString from 'query-string';

import { fromBase64 } from 'src/lib/js/base64';

/**
  * A convenient hook for getting the query string for the current route.
  *
  * @param {string} base64Decode - An optional array of keys that should be base64 decoded.
  * @param {string} parseJSON - An optional array of keys that should be parsed as JSON objects.
  * @return {object} The parsed query string as an object of key-value pairs.
  */
export const useQueryString = (base64Decode, parseJSON) => {
  const { search } = useLocation();

  if(search) {
    const parsed = queryString.parse(search);

    if(base64Decode) {
      for(const key of base64Decode) { parsed[key] = parsed[key] ? fromBase64(parsed[key]) : parsed[key]; }
    }
    if(parseJSON) {
      for(const key of parseJSON) { parsed[key] = parsed[key] ? JSON.parse(parsed[key]) : parsed[key]; }
    }

    return parsed;
  }

  return {};
};

export default useQueryString;
