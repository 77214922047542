import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import useTracker from 'src/lib/js/hooks/useTracker';

import AppliedInput from 'public/components/default_template/online_ordering/checkout/AppliedInput';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useGiftCard } from 'public/components/online_ordering/GiftCardContext';


const GiftCardInput = () => {
  const { watch, getFieldState } = useFormContext();
  const giftCard = watch('giftCard');
  const { cartGuid } = useCart();
  const { applyGiftCard, removeGiftCard, loadingGiftCard, giftCardNumber, giftCardError } = useGiftCard();
  const [warning, setWarning] = useState('');
  const tracker = useTracker();

  useEffect(() => {
    if(giftCardNumber) {
      tracker.track('Gift card applied successfully', { firstOrThirdParty: '1P' });
    } else if(giftCardError) {
      tracker.track('Gift card error', { firstOrThirdParty: '1P', error: giftCardError });
    }
  }, [giftCardNumber, giftCardError, tracker]);

  const onApplyGiftCard = useCallback(() => {
    if(cartGuid && giftCard) {
      tracker.track('Clicked apply gift card', {
        firstOrThirdParty: '1P',
        verificationCodeOptional: false,
        verificationCodeSubmitted: false
      });
      applyGiftCard(cartGuid, giftCard);
    }
  }, [cartGuid, giftCard, applyGiftCard, tracker]);

  useEffect(() => setWarning(giftCardError), [setWarning, giftCardError]);

  const onRemoveCard = () => removeGiftCard();

  return (
    <div data-testid="gift-card-input">
      <AppliedInput
        id="giftCard"
        label="Gift Card"
        value={giftCard}
        loading={loadingGiftCard}
        isApplied={Boolean(giftCardNumber)}
        applyButtonActive={giftCard && !getFieldState('giftCard').invalid}
        removeButtonActive={true}
        warn={(value: string) => value === '' || value.length < 6 ? 'gifts card must be at least 6 digits' : ''}
        warning={warning}
        onApply={onApplyGiftCard}
        onRemove={onRemoveCard} />
    </div>
  );
};

export default GiftCardInput;
