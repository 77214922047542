import React from 'react';

import { useGiaAuth } from 'src/shared/js/hooks/useGiaAuth';

import { CustomerContextProvider } from './CustomerContext';
import { CustomerContextGiaProvider } from './CustomerContextGia';

/** Based on config use the correct customer context. Use this over CustomerContext and CustomerContextGia */
export const CustomerContextProviderWrapper = (props: React.PropsWithChildren<{}> ) => {
  const giaAuth = useGiaAuth();

  return giaAuth ? <CustomerContextGiaProvider {...props} /> : <CustomerContextProvider {...props} />;
};
