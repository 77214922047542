import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { DiningOptionBehavior, FulfillmentType } from 'src/apollo/onlineOrdering';

import Image from 'shared/components/common/Image';
import FormInput from 'shared/components/common/form_input';
import { ToggleInput } from 'shared/components/common/forms';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';
import DiningOptionsModal, { formatFulfillmentDateTime } from 'public/components/default_template/online_ordering/dining_options/DiningOptionsModal';
import { Map } from 'public/components/default_template/schedule_map';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useFulfillment } from 'public/components/online_ordering/FulfillmentContext';
import { formatDeliveryAddress, formatLocationAddress } from 'public/components/online_ordering/addressUtils';
import { getQuoteTime } from 'public/components/online_ordering/fulfillmentUtils';


const FulfillmentSection = ({ complete }: {complete?: boolean}) => {
  const carMake = useWatch({ name: 'curbsidePickupVehicle' });
  const carColor = useWatch({ name: 'curbsidePickupVehicleColor' });
  const { ooRestaurant } = useRestaurant();
  const { cart } = useCart();
  const [curbside, setCurbside] = useState(false);

  if(!cart?.restaurant?.location || !ooRestaurant || !cart?.order) {
    return null;
  }

  const curbsideIndicator = curbside && carMake &&
      <div className="sectionRow">
        <div className="icon">
          <Image alt="Curbside pickup details" src="icons/car-gray.svg" />
        </div>
        {carMake}{carColor && ` (${carColor})`}
      </div>;
  return (
    <CheckoutSection>
      <FulfillmentDetails />
      {complete ? curbsideIndicator : <TakeoutDetails curbside={curbside} setCurbside={setCurbside} />}
    </CheckoutSection>
  );
};

/** Renders the specifics of how the order is being fulfilled, e.g. dining behavior, time, location */
export const FulfillmentDetails = () => {
  const { canUpdateFulfillment } = useFulfillment();
  const { cart } = useCart();
  const { ooRestaurant } = useRestaurant();

  const minimumTime = useMemo(() => cart && ooRestaurant ? getQuoteTime(cart.diningOptionBehavior, ooRestaurant, cart) : null, [cart, ooRestaurant]);
  const diningOptionButtonOnClick = useCallback((openModal: () => Promise<void>) => () => {
    if(canUpdateFulfillment) {
      openModal();
    }
  }, [canUpdateFulfillment]);
  if(!cart?.restaurant?.location || !ooRestaurant || !cart?.order) {
    return null;
  }
  return (
    <>
      <div className="subHeader">{ooRestaurant.whiteLabelName || cart.restaurant.name}</div>
      <div>{formatLocationAddress(cart.restaurant.location)}</div>
      <hr />
      {canUpdateFulfillment &&
          <DiningOptionsModal>
            {openModal => <button type="button" className="diningOptionsLink" onClick={diningOptionButtonOnClick(openModal)}>Edit</button>}
          </DiningOptionsModal>}
      <div className="sectionRow">
        {cart.diningOptionBehavior === DiningOptionBehavior.Delivery && cart.order?.deliveryInfo ?
          <><div className="icon"><Image alt="Order type" src="icons/delivery.svg" /></div><div>Delivery to {formatDeliveryAddress(cart.order.deliveryInfo)}</div></> :
          <><div className="icon"><Image alt="Order type" src="icons/pickup.svg" /></div><div>Pickup</div></>}
      </div>
      <div className="sectionRow">
        <div className="icon"><Image alt="Order time" src="icons/calendar.svg" /></div>
        {cart.fulfillmentType === FulfillmentType.Asap && minimumTime ?
          `ASAP (${minimumTime}-${minimumTime + 5} minutes)` :
          formatFulfillmentDateTime(cart.fulfillmentDateTime)}
      </div>
      {cart.diningOptionBehavior === DiningOptionBehavior.TakeOut &&
          <div className="sectionRow">
            <div className="icon"><Image alt="Location" src="icons/location.svg" /></div>
              You can pick up your order at {cart.restaurant.location.address1}{cart.restaurant.location.address2 ? `, ${cart.restaurant.location.address2}` : ''}
          </div>}
    </>
  );
};

/** Renders map and curbside pickup options details for takeout orders */
export const TakeoutDetails = ({ curbside, setCurbside }: {curbside: boolean, setCurbside: (curbside: boolean) => void}) => {
  const { cart } = useCart();
  const { ooRestaurant } = useRestaurant();
  const { register } = useFormContext();
  const showCurbside = useMemo(() =>
    (ooRestaurant?.curbsidePickupConfig?.enabled || ooRestaurant?.curbsidePickupConfig?.enabledV2) && cart?.diningOptionBehavior === DiningOptionBehavior.TakeOut,
  [ooRestaurant?.curbsidePickupConfig, cart?.diningOptionBehavior]);
  if(cart?.diningOptionBehavior !== DiningOptionBehavior.TakeOut || !cart?.restaurant?.location || !ooRestaurant || !cart?.order) {
    return null;
  }
  return (
    <>
      <Map
        name={cart.restaurant.name}
        address1={cart.restaurant.location.address1}
        address2={cart.restaurant.location.address2}
        city={cart.restaurant.location.city}
        state={cart.restaurant.location.state}
        zipcode={cart.restaurant.location.zip}
        alwaysShowMap={true} />
      {showCurbside &&
        <div data-testid="curbside-pickup-section">
          <ToggleInput
            type="checkbox"
            id="curbsidePickup"
            dataTestId="curbsidePickupCheckbox"
            checked={curbside}
            onKeyDown={event => {
              if(event.key === 'Enter') {
                setCurbside(!curbside);
              }
            }}
            {...register('curbsidePickup', { onChange: () => setCurbside(!curbside) })}>
            <div className="curbsideText"><span className="bold">Curbside pickup</span> <br className="hidden-sm-up" /><span className="gray">(bring my order to my car)</span></div>
          </ToggleInput>
          <AnimatedSection expanded={Boolean(showCurbside && curbside)} testid="curbside-animated-section">
            <div className="curbsidePickupVehicle">
              {/* max length values from
              https://github.com/toasttab/toast-orders/blob/main/toast-orders-application/src/main/java/com/toasttab/service/orders/resources/orika/mappers/CurbsidePickupMapper.kt */}
              <FormInput maxLength={100} id="curbsidePickupVehicle" label="Vehicle Make/Model" required={curbside} />
              <FormInput maxLength={20} id="curbsidePickupVehicleColor" label="Vehicle Color" />
            </div>
          </AnimatedSection>
        </div>}
    </>
  );
};

export default FulfillmentSection;
