import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { isValidPhoneNumber } from 'libphonenumber-js';
import partition from 'lodash/partition';

import { CompletedOrder, CustomerQuery } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';
import { useCart } from 'src/public/components/online_ordering/CartContext';
import { useCheckout } from 'src/public/components/online_ordering/CheckoutContext';
import { ORDER_KEY } from 'src/public/components/online_ordering/OrderContext';
import { useTimeBasedRules } from 'src/public/components/online_ordering/TimeBasedRuleContext';
import { useExperimentUserId } from 'src/shared/components/common/ab_testing/ABTestContext';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'src/shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';
import { sumByField } from 'public/components/online_ordering/reducerUtils';

import { getFirstOrThirdParty } from './loyalty/LoyaltyUtils';


export type Customer = CustomerQuery['customer'] & { __typename: 'Customer' } | undefined | null;

export const getCustomerInfo = (customer: Customer) => {
  return {
    yourInfoPhone: customer?.phone || '',
    yourInfoEmail: customer?.email || '',
    yourInfoFirstName: customer?.firstName || '',
    yourInfoLastName: customer?.lastName || ''
  };
};

export const submitPhoneNumberImpl = async (
  phoneNumber: string,
  passwordlessLogin: (phoneNumber: string) => Promise<boolean>,
  setSentCode: React.Dispatch<React.SetStateAction<boolean>>,
  setPasswordlessError: React.Dispatch<React.SetStateAction<string>>
) => {
  if(phoneNumber && isValidPhoneNumber(phoneNumber, 'US')) {
    setSentCode(true);
    if(!await passwordlessLogin(phoneNumber)) {
      setPasswordlessError('Error sending confirmation code');
      setSentCode(false);
    } else {
      setPasswordlessError('');
    }
  }
};


// Enums to standardize data values sent to the tracker

export enum AuthenticationStatus {
  Authenticated = 'Authenticated',
  Guest = 'Guest'
}

export enum AuthenticationSource {
  AccountCreation = 'Create a Toast account',
  UserNav = 'User nav',
  ExpressCheckout = 'Express checkout with Toast',
  GuestCheckout = 'Guest checkout page'
}

export function getAuthenticationStatus(customer: Customer | undefined) {
  return customer ? AuthenticationStatus.Authenticated : AuthenticationStatus.Guest;
}

export function getPaymentOption(paymentOption: PaymentOption | null, giftCardAppliedAmount: number, orderTotal: number) {
  if(giftCardAppliedAmount === orderTotal) {
    return 'GC';
  }
  switch(paymentOption) {
    case PaymentOption.UponReceipt:
      return 'CASH';
    case PaymentOption.CreditCard:
      return 'CC';
    case PaymentOption.ApplePay:
      return 'APPLEPAY';
    case PaymentOption.Paypal:
      return 'PAYPAL';
    case PaymentOption.Venmo:
      return 'VENMO';
    default:
      return 'null';
  }
}


export const useHandleCompletedOrderCallback = () => {
  const tracker = useTracker();
  const history = useHistory();
  const { cartGuid, cart, clearCart } = useCart();
  const { paymentOption, tipAmount } = usePayment();
  const { giftCardAppliedAmount, orderTotal } = useCheckout();
  const { confirmationPath } = useRestaurantRoutes();
  const { ooRestaurant } = useRestaurant();
  const { selectionsInCartWithTBRs, getItemLeadTime } = useTimeBasedRules();
  const userId = useExperimentUserId();

  return useCallback((completedOrder: CompletedOrder) => {
    if(completedOrder?.guid) {
      if(cart?.order) {
        // It's safe to assume at this point that if the item has a lead time, its TBR is a lead time rule, and if it doesn't, its TBR is a preorder rule
        const [selectionsWithLeadTimeRules, selectionsWithPreorderRules] = partition(selectionsInCartWithTBRs, s => getItemLeadTime(s.itemGuid) !== undefined);
        tracker.trackConversion(cart, cartGuid!);
        tracker.track('Order placed', {
          restaurantGuid: cart!.restaurant!.guid,
          diningOption: cart.diningOptionBehavior,
          fulfillmentTime: cart.fulfillmentType,
          numItems: cart.order.numberOfSelections,
          subtotal: cart.order.preDiscountItemsSubtotal,
          tax: cart.order.taxV2,
          deliveryChargeTotal: cart.order.deliveryServiceCharges,
          gratuityServiceCharges: cart.order.gratuityServiceCharges,
          processingServiceCharges: cart.order.processingServiceCharges,
          nonDeliveryNonGratuityNonUbpServiceCharges: cart.order.nonDeliveryNonGratuityNonUbpServiceCharges,
          discounts: cart.order.discountsTotal,
          total: cart.order?.totalV2,
          paymentType: getPaymentOption(paymentOption, giftCardAppliedAmount, orderTotal),
          giftCardApplied: giftCardAppliedAmount > 0,
          tipAmount,
          redemptionsAppliedToCheck: completedOrder.discounts.loyaltyDiscounts,
          firstOrThirdParty: getFirstOrThirdParty(ooRestaurant?.loyaltyConfig.programName),
          leadTimeRuleItemCount: sumByField('quantity')(selectionsWithLeadTimeRules),
          leadTimeRuleTotalPrice: sumByField('price')(selectionsWithLeadTimeRules),
          preorderRuleItemCount: sumByField('quantity')(selectionsWithPreorderRules),
          preorderRuleTotalPrice: sumByField('price')(selectionsWithPreorderRules),
          useExperimentUserId: userId
        });
      }

      clearCart();
      localStorage.setItem(ORDER_KEY, completedOrder.guid);
      history.push(confirmationPath, { completedOrder });
    }
  }, [
    cart,
    cartGuid,
    clearCart,
    confirmationPath,
    history,
    tracker,
    paymentOption,
    giftCardAppliedAmount,
    orderTotal,
    tipAmount,
    ooRestaurant?.loyaltyConfig.programName,
    getItemLeadTime,
    selectionsInCartWithTBRs,
    userId
  ]);
};
