import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import classnames from 'classnames';
import { AsYouType } from 'libphonenumber-js';

import Image from 'shared/components/common/Image';
import InputMessage from 'shared/components/common/form_input/InputMessage';

const MIN_PHONE_NUMBER_LENGTH = 10;
const MAX_PHONE_NUMBER_LENGTH = 11;

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  prefix?: string;
  id: string;
  label?: string;
  required?: boolean;
  warning?: string;
  focusMessage?: string;
  filled?: boolean;
  validate?: (value: string) => boolean | string | undefined;
  onChangeNumber?: (rawPhoneNumber: string) => void;
  defaultValue?: string;
  button?: React.ReactElement;
  unregisterOnUnmount?: boolean;
  shouldUnFocus?: boolean;
  initialFocus?: boolean;
};

// TODO: intl support
export const formatPhoneNumber = (phoneNumber: string): string => {
  if(phoneNumber.length === MAX_PHONE_NUMBER_LENGTH) {
    if(phoneNumber.charAt(0) === '1') {
      return `+${phoneNumber}`; // has intl prefix code, throw the + on the front
    } else {
      throw new Error('Invalid or disallowed phone number'); // can't be 11 digits with the wrong intl code
    }
  } else if(phoneNumber.length === MIN_PHONE_NUMBER_LENGTH) {
    return `+1${phoneNumber}`;
  }

  throw new Error('Invalid phone number');
};

const PhoneInput = (props: Props) => {
  const {
    prefix, onChangeNumber, defaultValue, id, label, required, warning, focusMessage, filled, validate, button, unregisterOnUnmount = true, shouldUnFocus = true, initialFocus = true,
    ...inputProps
  } = props;
  const { register, formState: { errors }, setValue, unregister } = useFormContext();
  const [focus, setFocus] = useState(initialFocus);
  useEffect(() => {
    register(id, { required: required ? 'required' : false, validate });
    return unregisterOnUnmount ? () => unregister(id) : () => {};
  }, [register, validate, id, required, unregister, unregisterOnUnmount]);

  const [phoneNumber, setPhoneNumber] = useState(defaultValue || '');
  useEffect(() => setPhoneNumber(defaultValue || ''), [defaultValue]);
  useEffect(() => onChangeNumber && onChangeNumber(phoneNumber), [phoneNumber, onChangeNumber]);

  const formattedPhoneNumber = useMemo(() => new AsYouType('US').input(phoneNumber), [phoneNumber]);
  const onBlurInput = (id: string, phoneNumber: string) => () => {
    setValue(id, phoneNumber, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
    if(shouldUnFocus) {
      setFocus(false);
    }
  };

  return (
    <div className={classnames('formInput', errors[id] && 'error', (filled || phoneNumber?.length) && 'filled')}>
      <div className="inputWrapper">
        <div className="inputElementWrapper">
          <label data-testid="phone-input-label" htmlFor={id}>{label}<span aria-hidden="true">{required ? '*' : ''}</span></label>
          <input
            aria-required={required}
            id={id}
            aria-describedby={`${id}-input-message`}
            data-testid={`input-${id}`} {...inputProps}
            value={formattedPhoneNumber}
            type="tel"
            autoComplete="tel"
            onChange={e => {
              const event = e.nativeEvent as InputEvent;
              let newValue = e.target.value;

              // handle the three character delete case
              if(event.inputType === 'deleteContentBackward'
              && formattedPhoneNumber.endsWith(')')
              && !newValue.endsWith(')')) {
                newValue = newValue.slice(0, newValue.length - 1);
              }
              const rawNewValue = newValue.replace(/[^0-9]/g, '');

              if(rawNewValue.length <= MAX_PHONE_NUMBER_LENGTH) {
                setPhoneNumber(rawNewValue);

                if(rawNewValue.length >= MIN_PHONE_NUMBER_LENGTH) {
                  setValue(id, rawNewValue, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                }
              }
            }} onFocus={() => setFocus(true)} onBlur={onBlurInput(id, phoneNumber)} />
        </div>
        {button}
        {errors[id] && <Image alt="Warning icon" src="icons/warning-red.svg" />}
      </div>
      <InputMessage id={id} warning={warning} focus={focus} focusMessage={focusMessage} />
    </div>
  );
};

export default PhoneInput;
