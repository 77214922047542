import { useCallback, useEffect } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import tracker from 'src/lib/js/tracker';

interface TrackingEvent {
  name: string,
  data: object
}

interface TrackerConfig {
  debug?: boolean,
  blacklisted?: boolean,
  sendToCustomService?: (batch: [TrackingEvent]) => Promise<boolean>
}

/**
  * A convenient hook for initialization and accessing the singleton Tracker instance.
  *
  * @param {object} options - An optional object containing the configuration for the tracker instance. Should only be called with
  *   options once at the highest level possible in your app. Has the following keys:
  *   @prop {boolean} blacklisted - A truthy/falsey value indicating whether the current environment/client should be tracked.
  *   @prop {function} sendToCustomService - A function that takes an array of tracking event objects of the form { name, data }
  *   as its only parameter, and returns a promise that either resolves on successful transmission of the data, or rejects on failure.
  *   @prop {boolean} debug - A truthy value will enable logging during tracking events.
  */
export const useTracker = (options? : TrackerConfig) => {
  useEffect(() => {
    if(options) {
      tracker.debug = options.debug ? options.debug : tracker.debug;
      tracker.blacklisted = options.blacklisted ? options.blacklisted : tracker.blacklisted;
      tracker.sendToCustomService = options.sendToCustomService ? options.sendToCustomService : tracker.sendToCustomService;

      if(tracker.sendToCustomService) { tracker.start(); }
    }
  }, [options]);

  return tracker;
};

export const useThrottledTracker = () => {
  const { ooTrackingDisabled } = useFlags();
  const tracker = useTracker();

  // use the FF to determine if events should be tracked with amplitude
  const track = useCallback((name, data) => {
    tracker?.track(name, data, ooTrackingDisabled);
  }, [ooTrackingDisabled, tracker]);

  return { track };
};

export default useTracker;
