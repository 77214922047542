type RGB = {
  r: number,
  g: number,
  b: number
}

export type Hex = `#${string}`;

// Assuming regular text; for large text, replace 4.5 with 3
const MINIMUM_CONTRAST = 4.5;

export const getTagColor = (bgColor: Hex | string, primaryColor?: Hex | string): Hex => {
  let modifierFactor = 70; // You can adjust this value for stronger or lighter shades
  let color = bgColor;
  // When the background is white and there's a primary color defined, we want to use the primary color, (or a darker version of it
  // if the primary color is very light), as the tag color.  We do that by pretending the primary color is the background color and
  // lessening the modifier somewhat (maybe a bit lazy, but ¯\_(ツ)_/¯)
  if(primaryColor !== undefined && bgColor.toLowerCase() === '#ffffff') {
    const primaryRGB = hexToRgb(primaryColor as Hex);
    const white = { r: 255, g: 255, b: 255 };
    if(contrastRatio(primaryRGB, white) > MINIMUM_CONTRAST) {
      return primaryColor as Hex;
    }
    modifierFactor = 40;
    color = primaryColor;
  }
  let r = parseInt(color.slice(1, 3), 16);
  let g = parseInt(color.slice(3, 5), 16);
  let b = parseInt(color.slice(5, 7), 16);
  let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  let modifier = luminance > 0.5 ? -modifierFactor : modifierFactor;

  r = Math.max(0, Math.min(255, r + modifier));
  g = Math.max(0, Math.min(255, g + modifier));
  b = Math.max(0, Math.min(255, b + modifier));

  return rgbToHex({ r, g, b });
};

const rgbToHex = (rgb: RGB): Hex => {
  const { r, g, b } = rgb;
  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

const hexToRgb = (hex: Hex): RGB => {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
};

const relativeLuminance = (rgb: RGB): number => {
  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;

  const R = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  const G = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  const B = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
};

const contrastRatio = (rgb1: RGB, rgb2: RGB): number => {
  const luminance1 = relativeLuminance(rgb1) + 0.05; // add 0.05 per WCAG formula
  const luminance2 = relativeLuminance(rgb2) + 0.05; // add 0.05 per WCAG formula

  return luminance1 > luminance2
    ? luminance1 / luminance2
    : luminance2 / luminance1;
};

export const generateTextColor = (hex: Hex): Hex => {
  const bgColor = hexToRgb(hex);
  const contrastWithWhite = contrastRatio(bgColor, { r: 255, g: 255, b: 255 });
  const contrastWithBlack = contrastRatio(bgColor, { r: 0, g: 0, b: 0 });

  return contrastWithWhite >= MINIMUM_CONTRAST && contrastWithWhite > contrastWithBlack ? '#ffffff' : '#000000';
};
