import React from 'react';

import { useEditor } from '@toasttab/sites-components';
import { ContainsEditableProps } from '@toasttab/sites-components/dist/types/components/editor_context/types';

import { PaddingEnum, LoyaltySignupSectionFieldsFragment } from 'src/apollo/sites';

import { getBackgroundColorModule, getSectionPaddingModule } from 'shared/components/common/editor_context/editableUtils';

import { BetterTogetherSection } from 'public/components/default_template/better_together_section/BetterTogetherSection';
import { useLoyaltyContext } from 'public/components/pages/loyalty_page/LoyaltyPage';


type LoyaltyWidgetProps = {
  section: LoyaltySignupSectionFieldsFragment
} & ContainsEditableProps

export const LoyaltySignupSection = ({ editPath, ...props }: LoyaltyWidgetProps) => {
  const {
    onSubmit,
    programDescription,
    useEmail,
    submitState
  } = useLoyaltyContext();
  const { useEditableRef } = useEditor();

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'loyalty signup section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    schema: {
      fields: [
        getSectionPaddingModule(editPath, PaddingEnum.Medium),
        getBackgroundColorModule(editPath, '#ffffff')
      ]
    },
    path: editPath
  });

  const headerInfo = {
    content: props.section.loyaltySignupHeader || 'Sign up for rewards',
    editPath: `${editPath}.loyaltySignupHeader`
  };

  const subheaderInfo = {
    content: programDescription,
    editPath: undefined // not editable.
  };

  return (
    <div className={'loyaltySignupSection'}>
      <BetterTogetherSection
        ignoredRef={editableRef}
        isSection={true}
        submitState={submitState}
        onSubmit={onSubmit}
        successLine1={'You are signed up for rewards!'}
        successLine2={'You\'ll earn points with every order, whether online or in person.'}
        errorLine1={'We had trouble submitting'}
        errorLine2={'Please try again later'}
        header={headerInfo}
        subheader={subheaderInfo}
        inputType={useEmail ? 'email' : 'phone'}
        disclaimer={
          <>
            {useEmail
              ? <>By providing your email, you are agreeing to participate in the rewards program and to be contacted through this email as part of the program.</>
              :
              <>
                By providing your phone number, you are agreeing to participate in the rewards program and to be contacted
                through this number as part of the program. Rewards program information is subject to{' '}
                <a href="https://pos.toasttab.com/terms-of-service" rel="noopener noreferrer" target="_blank">Toast&apos;s Terms of Service</a> and{' '}
                <a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Statement</a>.
                Message and data rates may apply. Msg frequency varies. Reply STOP to opt out.
              </>}
          </>
        } />
    </div>
  );
};
