import React, { useCallback, useMemo, useState } from 'react';

import { useEditor } from '@toasttab/sites-components';

import { CtaType } from 'src/apollo/sites';
import useTracker from 'src/lib/js/hooks/useTracker';

import DropDown from 'shared/components/common/dropdown';
import { Modal, ModalCloseButton, ModalOverlay, useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { CTAData } from 'public/components/default_template/ctas';
import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import NavItems, { MobileNavItems } from 'public/components/default_template/nav/NavItems';
import { Logout } from 'public/components/default_template/online_ordering/account/logout/Logout';
import { PwlessAuth } from 'public/components/default_template/online_ordering/account/pwlessAuth/PwlessAuth';
import { AuthenticationSource } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

import LogoutIcon from './LogoutIcon';
import UserIcon from './UserIcon';
import { getNavColorStyles } from './navUtils';

const accountCtas = (accountPath: string, onClick: () => void): CTAData[] => [
  { nav: true, text: 'My account', type: CtaType.Link, link: accountPath, icon: <UserIcon color={'currentColor'} /> },
  { nav: true, text: 'Log out', type: 'customAction', onClick: onClick, icon: <LogoutIcon color={'currentColor'} /> }
];

type MobileUserNavItemsProps = {
  onOpen: () => void;
  setModalContent: (content: JSX.Element) => void;
  closeOverlay: () => void;
  color?: string | null;
};

export const MobileUserNavItems = ({ onOpen, setModalContent, closeOverlay, color }: MobileUserNavItemsProps) => {
  const { customer } = useCustomer();
  const { restaurant } = useRestaurant();
  const { accountPath } = useRestaurantRoutes();

  const modalContentStyle = getNavColorStyles(restaurant, false);

  const openModal = useCallback(
    (content: JSX.Element) => () => {
      setModalContent(content);
      onOpen();
    },
    [onOpen, setModalContent]
  );

  const customerCtas = useMemo((): CTAData[] => accountCtas(accountPath, openModal(<Logout />)), [accountPath, openModal]);

  const loginCtas = useMemo(
    (): CTAData[] => [
      { nav: true, text: 'Log In / Sign Up', type: 'customAction', onClick: openModal(<PwlessAuth source={AuthenticationSource.UserNav} />), icon: <UserIcon color={'currentColor'} /> }
    ],
    [openModal]
  );

  return <MobileNavItems ctas={customer ? customerCtas : loginCtas} closeOverlay={closeOverlay} color={color || modalContentStyle.color} />;
};

const UserNav = () => {
  const { customer } = useCustomer();
  const { restaurant } = useRestaurant();
  const { accountPath } = useRestaurantRoutes();
  const { isOpen, onClose, onOpen } = useModal();
  const { isOpen: isLogoutOpen, onClose: logoutOnClose, onOpen: logoutOnOpen } = useModal();
  const [showOverlay, setShowOverlay] = useState(false);
  const tracker = useTracker();

  const customerCtas = useMemo((): CTAData[] => accountCtas(accountPath, () => logoutOnOpen()), [accountPath, logoutOnOpen]);
  const iconColor = restaurant?.content?.navConfig?.textColor || restaurant?.meta.textColor || DEFAULT_COLORS.text;

  if(!customer) {
    return (
      <>
        <div
          className="userNav"
          data-testid="userNav"
          onClick={() => {
            onOpen();
            tracker.track('Started Authentication', { source: AuthenticationSource.UserNav });
          }}>
          <UserIcon color={iconColor} />
        </div>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay fadeIn fadeOut />
          <PwlessAuth source={AuthenticationSource.UserNav} />
        </Modal>
      </>
    );
  }

  const modalContentStyle = getNavColorStyles(restaurant, false);

  return (
    <>
      <div className="hidden-sm-down">
        <div className="userNav" data-testid="userNav">
          <DropDown hideArrow label={<UserIcon color={iconColor} />}>
            <NavItems ctas={customerCtas} />
          </DropDown>
          <Modal isOpen={isLogoutOpen} onClose={logoutOnClose}>
            <ModalOverlay fadeIn fadeOut />
            <Logout />
          </Modal>
        </div>
      </div>
      <div className="hidden-md-up">
        <div className="userNav" onClick={() => setShowOverlay(true)}>
          <UserIcon color={iconColor} />
        </div>
        {showOverlay
          ?
          <div className="mobileNavOverlay">
            <div className="overlay" onClick={() => setShowOverlay(false)} />
            <div className="content" style={modalContentStyle}>
              <div className="header">
                <ModalCloseButton onClose={() => setShowOverlay(false)} />
              </div>
              <div className="body">
                <div className="navLinks">
                  <MobileNavItems ctas={customerCtas} closeOverlay={() => setShowOverlay(false)} color={modalContentStyle.color} />
                </div>
              </div>
              <div className="footer" />
            </div>
          </div>
          : null}
      </div>
    </>
  );
};

const EditorUserNav = () => {
  const { restaurant } = useRestaurant();
  const iconColor = restaurant?.content?.navConfig?.textColor || restaurant?.meta.textColor || DEFAULT_COLORS.text;
  return (
    <div className="userNav">
      <UserIcon color={iconColor} />
    </div>
  );
};

const UserNavWrapper = () => {
  const { isEditor } = useEditor();
  return isEditor ? <EditorUserNav /> : <UserNav />;
};

export default UserNavWrapper;
