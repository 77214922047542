import React from 'react';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import DiningBehaviorToggle from 'public/components/default_template/online_ordering/dining_behavior_toggle/DiningBehaviorToggle';
import { SimpleDiningOptionBehaviorMessage } from 'public/components/default_template/online_ordering/dining_option_message/DiningOptionBehaviorMessage';
import OrderOptions from 'public/components/default_template/online_ordering/order_options/OrderOptions';

const MobileFulfillment = ({ expanded }: { expanded: boolean }) =>
  <AnimatedSection expanded={expanded}>
    <div className="mobileFulfillment hidden-md-up">
      <DiningBehaviorToggle saveChange withSpinner className="fullWidthFulfillment" />
      <SimpleDiningOptionBehaviorMessage />
      <OrderOptions />
    </div>
  </AnimatedSection>;

export default MobileFulfillment;
