import React, { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';

import { useUpdateBasicInfoMutation } from 'src/apollo/onlineOrdering';
import { profileInformationSchema } from 'src/public/components/default_template/online_ordering/account/form-helpers';
import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';

import Button from 'shared/components/common/button';
import FormInput from 'shared/components/common/form_input';

interface ProfileInformationProps {}

const toastSuccess = () => {
  toast.success('Your info has been updated');
};

const toastFail = () => {
  toast.error('There was an error while saving. Please refresh the page.');
};

export const ProfileInformation: FC<ProfileInformationProps> = () => {
  const { customer } = useCustomer();
  const [updateBasicInfo] = useUpdateBasicInfoMutation();

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(profileInformationSchema),
    defaultValues: {
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      email: customer?.email,
      phone: customer?.phone
    }
  });

  useEffect(() => {
    formMethods.reset({
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      email: customer?.email,
      phone: customer?.phone
    });
  }, [formMethods, customer, formMethods.reset]);

  const onSubmit = useCallback(async (data: any) => {
    const firstName = data.firstName;
    const lastName = data.lastName;
    const phone = data.phone;

    try {
      const { data } = await updateBasicInfo({
        variables: {
          input: {
            firstName,
            lastName,
            phone
          }
        }
      });
      if(data?.updateBasicInfo.__typename === 'UpdateBasicInfoResponse') {
        toastSuccess();
      } else {
        toastFail();
      }
    } catch(err) {
      toastFail();
    }
  }, [updateBasicInfo]);

  return (
    <div className="profileInformation" data-testid="ProfileInformation" role="form">
      <div className="accountSubheading">My Information</div>
      <div className="infoWrapper">
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <FormInput id="firstName" type="text" label="First name" required />
            <FormInput id="lastName" type="text" label="Last name" required />
            <FormInput id="email" type="text" label="Email" readOnly={true} />
            <FormInput id="phone" type="text" label="Phone" readOnly={true} />
            <Button
              type="submit"
              disabled={!(formMethods.formState.isValid && formMethods.formState.isDirty) || formMethods.formState.isSubmitting}>Update my information
            </Button>
          </form>
        </FormProvider>
      </div>
      <div className="sectionSeparatorWrapper">
        <hr className="sectionSeparator" />
      </div>
    </div>
  );
};
