import React, { useRef } from 'react';

import { MenuTemplate } from 'src/apollo/sites';

import { CarouselArrowLeft, CarouselArrowRight } from 'shared/components/common/carousel/CarouselArrows';
import { useCarousel } from 'shared/components/common/carousel/useCarousel';

import MenuSection, { Props } from './MenuSection';


type CarouselProps = {
  containerId: string;
  numItems: number;
  primaryColor?: string | null;
} & Props;

const CarouselMenuSection = (props: CarouselProps) => {
  const { containerId, numItems, primaryColor, ...rest } = props;

  const containerRef = useRef<HTMLUListElement>(null);
  // A row in the SideImage template has 2 menu items, the others have 4
  const itemsPerPage = props.menuConfig?.format?.template !== MenuTemplate.SideImage ? 4 : 2;
  const carouselProps = useCarousel({ containerId, containerRef, numItems, itemsPerPage });
  const color = primaryColor || '#000000';

  return (
    <div className="carouselMenuSection">
      {carouselProps.currentItem !== 0 ? <CarouselArrowLeft color={color} {...carouselProps} onClickScroll={carouselProps.scroll} /> : null}
      <MenuSection {...rest} carouselRef={containerRef} menuItemIdMap={carouselProps.itemNamesMap} wrapItems={false} />
      {carouselProps.currentItem !== carouselProps.totalScrollItems - 1 ? <CarouselArrowRight color={color} {...carouselProps} onClickScroll={carouselProps.scroll} /> : null}
    </div>
  );
};

const CarouselMenuSectionWrapper = (props: CarouselProps) => {
  // text-based templates dont need a carousel
  const menuTemplate = props.menuConfig?.format?.template;
  return menuTemplate === MenuTemplate.Condensed || menuTemplate === MenuTemplate.ExtraCondensed || menuTemplate === MenuTemplate.Stacked
    ? <MenuSection {...props} />
    : <CarouselMenuSection {...props} />;
};

export default CarouselMenuSectionWrapper;
