import React, { useCallback, useEffect, useRef, useState } from 'react';
import useCookie, { getCookie } from 'react-use-cookie';

import * as Sentry from '@sentry/react';

import { createClient } from 'src/apollo/createClient';
import {
  CustomerDocument,
  CustomerQueryResult,
  PasswordlessLogoutInput,
  PasswordlessRefreshTokenDocument,
  SearchForEmailAccountDocument,
  SearchForEmailAccountQueryResult,
  useCompleteIdentityProfileMutation,
  useConfirmCodeMutation,
  useCustomerQuery,
  useLoginMutation,
  useLogoutMutation,
  useMfaLoginMutation,
  usePasswordlessLoginMutation
} from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';
import { getRegistrationSource, getSource } from 'src/shared/components/common/authentication';
import { formatPhoneNumber } from 'src/shared/components/common/form_input/PhoneInput';

import { PW_ACCESS, PWLESS_ACCESS, PWLESS_REFRESH, REFRESH_COOKIE_EXPIRY_DAYS } from 'shared/components/common/authentication/constants';
import { useOOClient, useRefreshOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';

import { AuthenticationStatus, getAuthenticationStatus } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';

import { resources } from 'config';

import { Customer, CustomerContextCommonProvider } from './CustomerContextCommon';

/**
 * Do not use this directly. Use CustomerContextProviderWrapper instead.
 */
export const CustomerContextProvider = (props: React.PropsWithChildren<{}>) => {
  const client = useOOClient();
  const refreshOOClient = useRefreshOOClient();
  const [pwlessAccessToken, setPwlessAccessToken] = useCookie(PWLESS_ACCESS);
  const [pwlessRefreshToken, setPwlessRefreshToken] = useCookie(PWLESS_REFRESH);
  const [refreshedToken, setRefreshedToken] = useState(false);
  const [, setPwAccessToken] = useCookie(PW_ACCESS);
  const [loading, setLoading] = useState(false);
  const tracker = useTracker();
  const { data, loading: loadingCustomer, refetch: refetchCustomer, error: customerError } = useCustomerQuery({
    ssr: false,
    fetchPolicy: 'cache-first',
    client
  });
  const customer = data?.customer as Customer;

  const [pwlessLogin] = usePasswordlessLoginMutation({ client });
  const passwordlessLogin = useCallback(async (phoneNumber: string) => {
    setLoading(true);
    let hasError = false;

    try {
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

      const { data } = await pwlessLogin({
        variables: {
          input: {
            phone: formattedPhoneNumber,
            source: getSource()
          }
        }
      });

      if(!(data?.passwordlessLoginUnified.__typename === 'PasswordlessLoginUnifiedResponse' && data.passwordlessLoginUnified.success)) {
        hasError = true;
      }
    } catch(err) {
      hasError = true;
    }

    setLoading(false);
    return !hasError;
  }, [pwlessLogin, setLoading]);

  const [completeIdentityProfile] = useCompleteIdentityProfileMutation({ client });
  const completeSignup = useCallback(async (email: string, firstName: string, lastName: string) => {
    setLoading(true);
    let hasError = false;

    try {
      const { data } = await completeIdentityProfile({
        variables: {
          input: {
            email: email,
            source: getRegistrationSource(),
            firstName,
            lastName
          }
        }
      });

      if(data?.completeIdentityProfile.__typename !== 'CompleteIdentityProfileResponse') {
        hasError = true;
      }
    } catch(err) {
      hasError = true;
    }

    setLoading(false);
    return !hasError;
  }, [completeIdentityProfile, setLoading]);

  const [confirmCode] = useConfirmCodeMutation({ client });
  const passwordlessConfirmCode = useCallback(async (phoneNumber: string, code: string) => {
    setLoading(true);
    let customerGuid = null;

    try {
      const { data } = await confirmCode({
        variables: {
          input: {
            code,
            phone: phoneNumber,
            source: getSource()
          }
        }
      });

      if(data?.passwordlessConfirmCodeUnified.__typename === 'PasswordlessTokenUnifiedResponse') {
        customerGuid = data.passwordlessConfirmCodeUnified.guestGuid;
        setPwlessAccessToken(data.passwordlessConfirmCodeUnified.accessToken);
        setPwlessRefreshToken(data.passwordlessConfirmCodeUnified.refreshToken, { days: REFRESH_COOKIE_EXPIRY_DAYS });
      }
    } catch(err) {
      customerGuid = null;
    }

    setLoading(false);
    return customerGuid;
  }, [confirmCode, setLoading, setPwlessRefreshToken, setPwlessAccessToken]);

  const emailAccountExists = useCallback(async (email?: string) => {
    try {
      const { data } = await client.query<SearchForEmailAccountQueryResult['data']>({
        query: SearchForEmailAccountDocument,
        variables: { input: email ? { email } : null },
        fetchPolicy: 'network-only'
      });
      return data?.searchForCustomermgmtAccount?.foundMatchingEmail || false;
    } catch(err) {
      Sentry.captureException(`ERROR: searchForCustomermgmtAccount error: ${err}`);
    }

    return false;
  }, [client]);

  // For use in instances where operations are contingent upon the existence of an account
  const fetchCustomer = useCallback(async () => {
    try {
      const { data } = await client.query<CustomerQueryResult['data']>({ query: CustomerDocument, fetchPolicy: 'network-only' });

      return (data?.customer || null) as Customer | null;
    } catch(err) {
      // Error expected, BFF throws when customer doesn't exist
      return null;
    }
  }, [client]);

  const [loginMutation] = useLoginMutation({ client });
  const login = useCallback(async (email: string, password: string) => {
    setLoading(true);

    try {
      const { data } = await loginMutation({
        variables: {
          input: {
            email,
            password
          }
        }
      });

      if(data?.login?.__typename === 'LoginError') {
        return { success: false, errorCode: data.login.code };
      } else if(data?.login?.__typename === 'MfaChallengeGeneratedResponse') {
        return { success: true, ...data.login };
      } else if(data?.login?.__typename === 'AuthenticationResponse') {
        setPwAccessToken( data.login.accessToken);
        return { success: true };
      } else {
        return { success: false };
      }
    } catch(err) {
      Sentry.captureException(`ERROR: login error ${err}`);
    } finally {
      setLoading(false);
    }

    return { success: false };
  }, [loginMutation, setPwAccessToken]);

  const [mfaLoginMutation] = useMfaLoginMutation({ client });
  const mfaLogin = useCallback(async (email: string, challengeToken: string, code: string) => {
    setLoading(true);

    try {
      const { data } = await mfaLoginMutation({
        variables: {
          input: {
            email,
            challengeToken,
            code
          }
        }
      });

      if(data?.mfaLogin?.__typename === 'LoginError') {
        return { success: false, errorCode: data.mfaLogin.code };
      } else if(data?.mfaLogin?.__typename === 'MfaChallengeGeneratedResponse') {
        return { success: true, ...data.mfaLogin };
      } else if(data?.mfaLogin?.__typename === 'AuthenticationResponse') {
        setPwAccessToken(data.mfaLogin.accessToken);
        return { success: true };
      } else {
        return { success: false };
      }
    } catch(err) {
      Sentry.captureException(`ERROR: login error ${err}`);
    } finally {
      setLoading(false);
    }

    return { success: false };
  }, [mfaLoginMutation, setPwAccessToken]);

  const [logoutMutation] = useLogoutMutation({ client });
  const pwlessLogout = useCallback(async () => {
    const input: PasswordlessLogoutInput = {
      source: getSource(),
      refreshToken: pwlessRefreshToken
    };
    try {
      const { data: _data } = await logoutMutation({ variables: { input } });
      if(_data?.passwordlessLogout?.__typename === 'PasswordlessAuthenticationError') {
        Sentry.captureException(`ERROR: passwordless logout error code: ${_data.passwordlessLogout.code}, message ${_data.passwordlessLogout.message}`);
        return false;
      }

      setPwlessAccessToken('', { days: 0 });
      setPwlessRefreshToken('', { days: 0 });
      client.writeQuery({
        query: CustomerDocument,
        data: { customer: null }
      });
      return true;
    } catch(err) {
      Sentry.captureException(`ERROR: passwordless logout error ${err}`);
      return false;
    }
  }, [logoutMutation, pwlessRefreshToken, setPwlessAccessToken, setPwlessRefreshToken, client]);

  const authenticationStatus = useRef<AuthenticationStatus>();
  useEffect(() => {
    const status = getAuthenticationStatus(customer);
    if(authenticationStatus.current !== status) {
      tracker.register({ authenticationStatus: status });
      authenticationStatus.current = status;
    }
  }, [customer, authenticationStatus, tracker]);

  const authRefreshClient = createClient(resources.ooProxyHost, undefined, true, true, undefined, false, false, resources.clientQueryTimeoutMs);
  // Refreshes the access token if a refresh token exists on first render
  useEffect(() => {
    const updateTokens = async () => {
      // eslint-disable-next-line max-len
      if(pwlessRefreshToken && !customer && !pwlessAccessToken && !refreshedToken && refreshOOClient) {
        const result = await authRefreshClient.mutate({
          mutation: PasswordlessRefreshTokenDocument,
          // refreshToken is populated by the server
          variables: { input: { refreshToken: pwlessRefreshToken, source: getSource() } }
        });

        // If the refresh was successful, set the tokens and refetch the OOClient and Customer
        if(result.data?.passwordlessRefreshToken?.__typename === 'PasswordlessTokenResponse') {
          setRefreshedToken(true);
          setPwlessAccessToken(result.data.passwordlessRefreshToken.accessToken);
          setPwlessRefreshToken(result.data.passwordlessRefreshToken.refreshToken, { days: REFRESH_COOKIE_EXPIRY_DAYS });
          refreshOOClient();
          refetchCustomer();
        }
      }
    };
    updateTokens();
  }, [authRefreshClient, customer, pwlessRefreshToken, setPwlessAccessToken, setPwlessRefreshToken, pwlessAccessToken, refreshedToken, refetchCustomer, refreshOOClient]);

  useEffect(() => {
    // The first customer call can trigger an auth error and refresh of the authToken cookie outside of the
    // customer context. This happens when the cookie is set to an expired token and apollo client catches this
    // and does the refresh. In this case just refetch the customer if the token has changed.
    const hasAuthErrors = customerError?.graphQLErrors && customerError.graphQLErrors.findIndex((err: any) => err?.extensions?.code === 'UNAUTHENTICATED') !== -1;
    if(hasAuthErrors) {
      // reread cookie which should be refreshed by apollo client
      const newAccessToken = getCookie(PWLESS_ACCESS);
      if(newAccessToken !== pwlessAccessToken) {
        setPwlessAccessToken(newAccessToken);
        const newRefreshToken = getCookie(PWLESS_REFRESH);
        if(newRefreshToken !== pwlessRefreshToken) {
          setPwlessRefreshToken(newRefreshToken);
        }
        // client is already updated, just refetch the customer
        refetchCustomer();
      }
    }
  }, [customerError, pwlessAccessToken, pwlessRefreshToken, refetchCustomer, setPwlessAccessToken, setPwlessRefreshToken]);

  return (
    <CustomerContextCommonProvider context={{
      customer,
      loadingCustomer: loading || loadingCustomer,
      refetchCustomer,
      passwordlessLogin,
      passwordlessConfirmCode,
      completeSignup,
      emailAccountExists,
      login,
      mfaLogin,
      fetchCustomer,
      pwlessLogout
    }}>
      {props.children}
    </CustomerContextCommonProvider>
  );
};
