import React, { useEffect } from 'react';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { MenuFormatConfig, MenuTemplate } from 'src/apollo/sites';
import Image from 'src/shared/components/common/Image';
import { normalizeHtmlId } from 'src/shared/js/normalizeUtils';

import { ScrollDirection, scrollToRef, useHorizontalScroll } from './menuNavScrollUtils';

type Menu = {
  name: string;
  ref: React.RefObject<HTMLDivElement>;
};

type Props = {
  menus: Menu[];
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  menuConfig?: MenuFormatConfig | null;
};

const MenuTabs = (props: Props) => {
  const { scrollContainerRef, showLeftArrow, showRightArrow, scroll, scrollEvent } = useHorizontalScroll();
  const { isEditor } = useEditor();

  useEffect(() => {
    scrollEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.menus.length]);

  if(props.menus.length === 1) {
    return null;
  }

  return (
    <nav role="tablist" className={classnames('menuTabs', { condensed: props.menuConfig?.template === MenuTemplate.Condensed })} data-testid="menu-tabs">
      <div className="menuTabsWrapper">
        <button type="button" onClick={scroll(ScrollDirection.Backwards)} className={classnames('arrow leftArrow', { arrowHidden: !showLeftArrow })} aria-hidden={true}>
          <Image src="icons/caret-left.svg" eagerLoad alt="Scroll menus left" />
        </button>
        <div className="sections" ref={scrollContainerRef} onScroll={scrollEvent} role="tablist">
          {props.menus.map((page, index) =>
            <button
              key={page.name}
              role="tab"
              aria-selected={index === props.selectedIndex}
              aria-controls={page.ref.current?.id}
              id={`${normalizeHtmlId(page.name)}-tab`}
              onClick={() => {
                scrollToRef(page.ref, isEditor);
                props.setSelectedIndex(index);
              }}
              className={`menuItemTarget ${index === props.selectedIndex ? 'selected' : ''}`}
              data-testid={`menu-tab-${page.name}`}>
              {page.name}
            </button>)}
        </div>
        <button type="button" onClick={scroll(ScrollDirection.Forwards)} className={classnames('arrow rightArrow', { arrowHidden: !showRightArrow })} aria-hidden={true}>
          <Image src="icons/caret-right.svg" eagerLoad alt="Scroll menus right" />
        </button>
      </div>
    </nav>
  );
};

export default MenuTabs;
