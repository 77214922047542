import React from 'react';

import classnames from 'classnames';

import { CardOrientation, MenuConfig, MenuFormatConfig } from 'src/apollo/sites';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { getTemplateClass } from 'public/components/default_template/menu_section/MenuItemCard';

import CarouselMenuSection from './CarouselMenuSection';
import { MenuItem } from './MenuSection';


type Props = {
  primaryColor?: string | null;
  ooFormatConfig: MenuFormatConfig | undefined | null;
  shortUrl?: string;
  setIsMapOrModalOpen?: (isOpen: boolean) => void;
  isMapOrModalOpen?: boolean;
  orientation?: CardOrientation | null;
  popularItems?: (MenuItem | null)[] | null;
  menuConfig?: MenuConfig | null;
};

const PopularItemsMenuSection = (props: Props) => {
  const { restaurant: siteRestaurant, selectedLocation, ooRestaurant: restaurant } = useRestaurant();
  const restaurantGuid = selectedLocation.externalId;
  // TODO: Upcoming work will make the menu nav type configurable.  Until then, we'll hard-code it as necessary.
  // https://toasttab.atlassian.net/browse/WOO-772
  const sideBySide = false;

  if(selectedLocation.featuredItems && !selectedLocation.featuredItems?.enabled || siteRestaurant.config.ooConfig?.popularItemsDisabled) {
    return null;
  }

  return (
    <div className={classnames('menuSectionWrapper', getTemplateClass(props.ooFormatConfig?.template), { columns: props.ooFormatConfig?.columns })}>
      <div className={classnames('menuWrapper paddedContentWrapper', { sideBySide })}>
        <div className="menu paddedContent">
          <div className="menuGroup">
            <CarouselMenuSection
              containerId="popular-items-menu-section"
              numItems={props.popularItems?.length || 0}
              primaryColor={props.primaryColor}
              shortUrl={restaurant?.shortUrl}
              restaurantGuid={restaurantGuid}
              setIsMapOrModalOpen={props.setIsMapOrModalOpen}
              isMapOrModalOpen={props.isMapOrModalOpen}
              header="Featured Items"
              orientation={siteRestaurant.config.ooConfig?.cardOrientation}
              menuItems={props.popularItems}
              menuConfig={{ format: props.ooFormatConfig, colors: siteRestaurant.config.ooConfig?.colors }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularItemsMenuSection;
