import React from 'react';

import classnames from 'classnames';

import Image from 'shared/components/common/Image';

type LabeledIconProps = {
  iconSrc: string;
  iconAlt: string;
  iconSize?: number;
  title?: string;
  itemProp?: string;
  editMode?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const LabeledIcon = ({ iconSrc, iconAlt, iconSize = 32, title, itemProp, editMode = false, onClick, className, children }: React.PropsWithChildren<LabeledIconProps>) => {
  return (
    <div title={editMode ? 'Click to edit...' : title} className={classnames('labeled-icon', { editable: editMode }, className)} onClick={onClick}>
      {editMode ? <div className="edit">edit</div> : null}
      <Image className="icon-img label-icon" src={iconSrc} height={iconSize} alt={iconAlt} />
      <span className="label-content" itemProp={itemProp}>{children}</span>
    </div>
  );
};

type Props = {
  ambiances?: (string | null)[] | null;
  amenities?: (string | null)[] | null;
  className?: string;
}

const Attributes = ({ ambiances, amenities, className }: Props) => {
  return (
    <div className={classnames('attributes', className)}>
      <div className="available-attributes">
        {ambiances && ambiances.map(ambiance => <LabeledIcon iconSrc="/icons/check-mark.svg" iconSize={14} iconAlt="check mark" className="attribute" key={ambiance}>{ambiance}</LabeledIcon>)}
        {amenities && amenities.map(amenity => <LabeledIcon iconSrc="/icons/check-mark.svg" iconSize={14} iconAlt="check mark" className="attribute" key={amenity}>{amenity}</LabeledIcon>)}
      </div>
    </div>
  );
};

export default Attributes;
