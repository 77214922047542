import React, { useRef } from 'react';

import { _heading, ContainsEditableProps, useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { Image, PaddingEnum, ThemeTypeEnum } from 'src/apollo/sites';

import Carousel, { CarouselTypes } from 'shared/components/common/carousel/Carousel';
import { _Image } from 'shared/components/common/editable_image/EditableImage';
import { getBackgroundColorModule, getSectionPaddingModule, getThemeModule } from 'shared/components/common/editor_context/editableUtils';

type Props = {
  id: string;
  className?: string | null;
  header?: string | null;
  images?: Array<Omit<Image, '__typename'>> | null;
  theme?: ThemeTypeEnum | null;
  primaryColor?: string;
  backgroundColor?: string | null;
  padding?: PaddingEnum | null;
} & ContainsEditableProps;

const pathToImg = (editPath: string, idx: number) => `${editPath}.images[${idx}]`;
const pathToImgSrc = (editPath: string, idx: number) => `${pathToImg(editPath, idx)}.src`;
const pathToImgAlt = (editPath: string, idx: number) => `${pathToImg(editPath, idx)}.altText`;
const pathToImgFit = (editPath: string, idx: number) => `${pathToImg(editPath, idx)}.fit`;
const pathToImgLink = (editPath: string, idx: number) => `${pathToImg(editPath, idx)}.link`;

const MobileGallery = ({ editPath, id, header, images, theme, primaryColor, backgroundColor, padding = PaddingEnum.Medium }: Props) => {
  const { useEditableRef } = useEditor();

  const carouselRef = useRef<HTMLDivElement>(null);
  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'gallery section',
    path: editPath,
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    skipRef: carouselRef,
    schema: {
      fields: [
        getSectionPaddingModule(editPath, padding),
        getBackgroundColorModule(editPath, backgroundColor),
        getThemeModule(editPath, theme)
      ]
    }
  });
  const backgroundColorStyle = backgroundColor ? { backgroundColor: backgroundColor } : { };


  if(!images?.length) {
    return null;
  }

  return (
    <div ref={editableRef} className="gallery mobileGallery topPadding bottomPadding" data-testid="gallery-module" style={backgroundColorStyle}>
      <div className="galleryWrapper">
        {header &&
          <div className="paddedSection">
            <_heading
              styleLevel={2}
              className="sectionHeader"
              html={header}
              editPath={`${editPath}.galleryHeader`} />
          </div>}
        <Carousel
          id={id}
          theme={theme}
          hideArrows={false}
          primaryColor={primaryColor}
          carouselRef={carouselRef}
          carouselType={CarouselTypes.Gallery}
          ariaLabel={header ?? undefined}
          items={images.map((image, i) =>
            <div className={classnames('galleryImage themeElement', theme)} key={`gallery-image-${i}`}>
              <_Image
                imageObject={image}
                className={classnames('galleryImage themeElement carouselItem', theme)}
                src={image.src} alt={image.altText || 'Gallery image'}
                fit={image.fit} fitEditPath={pathToImgFit(editPath, i)}
                link={image.link} linkEditPath={pathToImgLink(editPath, i)}
                editPath={pathToImgSrc(editPath, i)}
                imageObjectPath={pathToImg(editPath, i)}
                altEditPath={pathToImgAlt(editPath, i)} />
            </div>)} />
      </div>
    </div>
  );
};

const LargeGallery = ({ editPath, className, header, images, theme, backgroundColor, padding = PaddingEnum.Medium }: Props) => {
  const { useEditableRef } = useEditor();

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'gallery section',
    path: editPath,
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    schema: {
      fields: [
        getSectionPaddingModule(editPath, padding),
        getBackgroundColorModule(editPath, backgroundColor),
        getThemeModule(editPath, theme)
      ]
    }
  });
  const backgroundColorStyle = backgroundColor ? { backgroundColor: backgroundColor } : { };

  // if there are an odd number of images, display the first in its own column
  const firstImage = (images?.length || 0) % 2 === 1 ? images![0] : null;
  const rowOffset = firstImage ? 1 : 0;
  const topRowStart = rowOffset;
  const bottomRowStart = images ? Math.floor(images.length / 2 + rowOffset) : 0;

  return (
    <div ref={editableRef} className={classnames('gallery topPadding bottomPadding', className, theme, { paddedSection: theme !== ThemeTypeEnum.Wide })}
      data-testid="gallery-module" style={backgroundColorStyle}>
      {header && <div className={classnames({ paddedSection: theme === ThemeTypeEnum.Wide })}><div className="sectionHeader">{header}</div></div>}
      <div className="galleryWrapper">
        {firstImage &&
          <div className={classnames('firstImage', theme)}>
            <_Image
              imageObject={firstImage}
              className={classnames('galleryImage themeElement', theme)}
              src={firstImage.src} alt={firstImage.altText || 'Gallery image'}
              imageObjectPath={pathToImg(editPath, 0)}
              editPath={pathToImgSrc(editPath, 0)}
              altEditPath={pathToImgAlt(editPath, 0)}
              fit={firstImage.fit} fitEditPath={pathToImgFit(editPath, 0)}
              link={firstImage.link} linkEditPath={pathToImgLink(editPath, 0)} />
          </div>}
        {images &&
        <div className="imageRows">
          <div className="imageRow"> {/* Top row */}
            {images.slice(topRowStart, bottomRowStart)
              .map((image, i) =>
                <div className={classnames('galleryImage themeElement', theme)} key={`gallery-image-${i}`}>
                  <_Image
                    imageObject={image}
                    className={classnames('galleryImage themeElement', theme)}
                    src={image.src}
                    alt={image.altText || 'Gallery image'}
                    imageObjectPath={pathToImg(editPath, topRowStart + i)}
                    editPath={pathToImgSrc(editPath, topRowStart + i)}
                    altEditPath={pathToImgAlt(editPath, topRowStart + i)}
                    fit={image.fit} fitEditPath={pathToImgFit(editPath, topRowStart + i)}
                    link={image.link} linkEditPath={pathToImgLink(editPath, topRowStart + i)} />
                </div>)}
          </div>
          <div className="imageRow"> {/* Bottom row */}
            {images.slice(bottomRowStart)
              .map((image, i) =>
                <div className={classnames('galleryImage themeElement', theme)} key={`gallery-image-${i}`}>
                  <_Image
                    imageObject={image}
                    className={classnames('galleryImage themeElement', theme)}
                    src={image.src} alt={image.altText || 'Gallery image'}
                    imageObjectPath={pathToImg(editPath, bottomRowStart + i)}
                    editPath={pathToImgSrc(editPath, bottomRowStart + i)}
                    altEditPath={pathToImgAlt(editPath, bottomRowStart + i)}
                    fit={image.fit} fitEditPath={pathToImgFit(editPath, bottomRowStart + i)}
                    link={image.link} linkEditPath={pathToImgLink(editPath, bottomRowStart + i)} />
                </div>)}
          </div>
        </div>}
      </div>
    </div>
  );
};

const Gallery = ({ editPath, id, header, images, theme, primaryColor, backgroundColor, padding }: Props) =>
  <>
    <MobileGallery editPath={editPath} id={id} header={header} images={images} theme={theme} primaryColor={primaryColor} backgroundColor={backgroundColor} padding={padding} />
    <LargeGallery editPath={editPath} id={id} className="desktopGallery" header={header} images={images} theme={theme} backgroundColor={backgroundColor} padding={padding} />
  </>;

export default Gallery;
