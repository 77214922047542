import React, { useMemo } from 'react';

import classnames from 'classnames';

import { RxImagesType } from 'src/apollo/toastLocal';
import Link from 'src/shared/components/common/link';

import Image from 'shared/components/common/Image';

import { TLRestaurantLocations } from 'public/components/default_template/restaurant_profile/profileUtils';

import { resources } from 'config';

type Props = {
  title: string;
  linkToURL: string;
  className?: string | null;
  images?: RxImagesType | null;
  avgRating?: number | null;
  numRatings?: number | null;
  locations?: TLRestaurantLocations;
};

const CarouselListing = ({ title, linkToURL, className, images, avgRating, numRatings, locations }: Props) => {
  const imgUrl = useMemo(() => images?.consumer?.src || images?.banner?.src || images?.bg?.src || images?.main?.src || null, [images]);
  const address = useMemo(() => locations?.length == 1 && locations[0] ? `${locations[0].address} ${locations[0].city}, ${locations[0].state} ${locations[0].zip}` : null, [locations]);

  return (
    <div className={classnames('carousel-listing', className)}>
      <Link href={linkToURL}>
        <div className="listing-image-container">
          {imgUrl && <Image className="listing-image" cdnHost={resources.toastLocalCDN} src={imgUrl}></Image>}
        </div>
        <div className="listing-content">
          <div className="first-line">
            <span className="rx-name">{title}</span>
            <div className="review-summary">
              <Image src="/icons/star-solid-orange.svg" alt="orange star"></Image>{avgRating && numRatings ? `${avgRating.toFixed(1)} • ${Intl.NumberFormat().format(numRatings)}` : 'No Reviews'}
            </div>
          </div>
          <div className="address-line">{address}</div>
          <div className="view-rx">
            View restaurant
            <Image src="/icons/arrow-right-gray.svg" alt="next" height={18} className="icon-img" />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CarouselListing;
