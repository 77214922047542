import React from 'react';

import classnames from 'classnames';

import Image from 'shared/components/common/Image';

const MAX_NUM_DOTS = 4;

type Props = {
  currentIndex: number;
  numPages: number;
  shouldScroll: (amount: number) => void;
}

type DotProps = { active?: boolean; }

const Dot = (props: DotProps) => <div className={classnames('dot', props.active ? 'active' : null)} />;

const MiniScroll = (props: Props) => {
  const dots = [];

  const dotIsActive = (dotIndex: number) => {
    const percentComplete = props.currentIndex / (props.numPages - 1);
    const convertedIndex = Math.round((Math.min(props.numPages, MAX_NUM_DOTS) - 1) * percentComplete);

    return convertedIndex == dotIndex;
  };

  const onClickDecrement = () => {
    if(props.currentIndex) {
      props.shouldScroll(-1);
    }
  };

  const onClickIncrement = () => {
    if(props.currentIndex < props.numPages - 1) {
      props.shouldScroll(1);
    }
  };

  for(let i = 0; i < props.numPages && i < MAX_NUM_DOTS; i++) {
    dots.push(<li key={i}><Dot active={dotIsActive(i)} /></li>);
  }

  return props.numPages > 1 ?
    <div className="miniScroll">
      <ul className="dots">{dots}</ul>
      <ul className="navButtons">
        <li><button onClick={onClickDecrement} disabled={!props.currentIndex}><Image src="/icons/caret-left.svg" /></button></li>
        <li><button onClick={onClickIncrement} disabled={props.currentIndex + 1 == props.numPages}><Image src="/icons/caret-right.svg" /></button></li>
      </ul>
    </div>
    : null;
};

export default MiniScroll;
