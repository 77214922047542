import React from 'react';

import { InfoIcon } from '@toasttab/buffet-pui-icons';
import classnames from 'classnames';

import { ButtonType } from 'src/apollo/sites';

import Image from 'shared/components/common/Image';
import Button, { ButtonLink } from 'shared/components/common/button';
import Link from 'shared/components/common/link';

// eslint-disable-next-line no-restricted-imports
import { EncryptedCreditCardData } from '../online_ordering/checkout/payment/CreditCardForm';
import ReservationOverview from './ReservationOverview';
import { SeatingLocation, Deposit, isBookingInAdvance, useReservationCancellation } from './reservationUtils';

type Props = {
  restaurantGuid: string;
  reservationGuid: string;
  numGuests: number;
  deposit: Deposit | null;
  serviceArea: SeatingLocation | null;
  selectedDate: Date | null;
  selectedTime: Date | null;
  cancellationPolicy?: string | null;
  loyaltySignupUrl?: string | null;
  onCancellation: (arg0: boolean, arg1: boolean | null) => void;
  timezone: string;
  occasion: string | null;
  creditCardData?: EncryptedCreditCardData | null;
};

const ReservationConfirmation = ({
  restaurantGuid, reservationGuid, numGuests, deposit, serviceArea, selectedDate, selectedTime, cancellationPolicy,
  loyaltySignupUrl, onCancellation, timezone, occasion, creditCardData
}: Props) => {
  const { cancel } = useReservationCancellation(reservationGuid, restaurantGuid, onCancellation, null);

  return (
    <div className="confirmation">
      <div className="description">Your table has been booked. We sent you a SMS with the details.</div>
      <div className="buttons">
        <ButtonLink variant={ButtonType.Primary} href="/menu">View menu</ButtonLink>
        <Button variant={ButtonType.Secondary} className="secondary-btn" onClick={cancel}><div>Cancel Reservation</div></Button>
        {loyaltySignupUrl &&
          <div className="loyaltyContainer">
            <Link href={loyaltySignupUrl} isBold>
              <Image alt="Join loyalty icon" src="icons/join-loyalty.svg" /><div className="joinLoyalty">Join our Loyalty Program</div>
            </Link>
          </div> }
      </div>
      <ReservationOverview numGuests={numGuests} selectedDate={selectedDate} selectedTime={selectedTime} serviceArea={serviceArea} timezone={timezone} occasion={occasion} />
      {deposit &&
        <div className="depositSummary">
          <h3 className="depositDescriptionTitle">Deposit Summary</h3>
          {deposit?.strategyType === 'ByBooking' ?
            <div>
              <p className="depositMessage morePaddingBottom">${deposit?.actualAmount} deposit per booking</p>
              <div className="depositPaymentContainer">
                <p className="depositMessage black">Reservation x1</p>
                <p className="depositMessage black">${deposit?.actualAmount}</p>
              </div>
            </div>
            :
            <div>
              <p className="depositMessage morePaddingBottom">${deposit?.amountPerGuest} deposit per person</p>
              <div className="depositPaymentContainer">
                <p className="depositMessage black">Reservation x{numGuests}</p>
                <p className="depositMessage black">${deposit?.actualAmount}</p>
              </div>
            </div> }
          <hr className="menuDividingLine menuDividingLinePaddingBottom" />
          <div className="depositPaymentContainer">
            <p className="depositMessage morePaddingBottom black">Amount Paid</p>
            <p className="depositMessage morePaddingBottom black">${deposit?.actualAmount?.toFixed(2)}</p>
          </div>
          <p className="depositMessage bold black">Payment Method</p>
          <p className="depositMessage noPaddingBottom black">Card ending in {creditCardData?.cardLast4}</p>
        </div> }
      {deposit?.cancellationRefundableTimeframe !== 0 && deposit?.cancellationRefundableTimeframe &&
        // eslint-disable-next-line no-extra-parens
        (deposit?.cancellationRefundableTimeframe === -1 ||
        // eslint-disable-next-line no-extra-parens
        (selectedDate && !isBookingInAdvance(selectedDate, deposit.cancellationRefundableTimeframe))) &&
          <div className="minHoursInAdvance forReservationConfirmation">
            <InfoIcon className="infoIcon" />
            <p className="minHoursInAdvanceMessage">
              Booking is no longer refundable.
            </p>
          </div> }
      <hr className="menuDividingLine menuDividingLineMarginTop" />
      {deposit?.cancellationRefundableTimeframe === 0 &&
        <div className={classnames('policies')}>
          <h3 className="cancellationTitleSection">Deposit Policy</h3>
          <div className="cancellationPolicy">
            <>
              This deposit is refundable until the time of your reservation.
            </>
          </div>
        </div> }
      {deposit?.cancellationRefundableTimeframe !== 0 && deposit?.cancellationRefundableTimeframe && deposit?.cancellationRefundableTimeframe > 0 &&
        <div className={classnames('policies')}>
          <h3 className="cancellationTitleSection">Deposit Policy</h3>
          <div className="cancellationPolicy">
            Refundable if you cancel up to <span>{deposit.cancellationRefundableTimeframe} hours</span> before your reservation time.
          </div>
        </div> }
      {cancellationPolicy &&
        <div className={classnames('policies')}>
          <h3 className="cancellationTitleSection">Reservation Policy</h3>
          <div className="cancellationPolicy">
            {cancellationPolicy}
          </div>
        </div> }
    </div>
  );
};

export default ReservationConfirmation;
