import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
import _ from 'lodash';

import { RequestContext } from 'src/lib/js/context';

import { getSiteShortUrl, getSiteDomain, isToastOrderLocationsRequest, getSiteRef, getSiteMgmtGroupGuid } from 'public/js/siteUtilities';


type Props = {
  staticContext?: RequestContext;
  host?: string;
  domainQueryHook: (opts: ApolloReactHooks.QueryHookOptions) => ApolloReactCommon.QueryResult<any, any>;
  shortUrlQueryHook: (opts: ApolloReactHooks.QueryHookOptions) => ApolloReactCommon.QueryResult<any, any>;
  mgmtGuidQueryHook: (opts: ApolloReactHooks.QueryHookOptions) => ApolloReactCommon.QueryResult<any, any>;
  skipQuery?: boolean;
  shortUrlQueryString?: string | null;
};

const useRestaurantSiteData = ({ staticContext, host, domainQueryHook, shortUrlQueryHook, mgmtGuidQueryHook, skipQuery, shortUrlQueryString }: Props) => {
  const siteShortUrl = getSiteShortUrl(staticContext, shortUrlQueryString);
  const mgmtGroupGuid = getSiteMgmtGroupGuid(staticContext);
  const siteRef = getSiteRef(staticContext);
  const shortUrlResp = shortUrlQueryHook({ variables: { shortUrl: siteShortUrl }, skip: skipQuery || !siteShortUrl });
  const domain = host || getSiteDomain(staticContext);
  const domainResp = domainQueryHook({ variables: { domain }, skip: skipQuery || !!siteShortUrl || !domain || isToastOrderLocationsRequest(staticContext) });
  const mgmtGuidResp = mgmtGuidQueryHook({
    variables: {
      mgmtGuid: mgmtGroupGuid,
      siteRef: siteRef || 'default'
    }, skip: skipQuery || !!siteShortUrl || !isToastOrderLocationsRequest(staticContext) || !mgmtGroupGuid
  });

  const shortUrlData = shortUrlResp?.data?.restaurantByShortUrl;
  // Only use customization data obtained by shortURL if the site is enabled
  const shortUrlRestaurant = shortUrlData?.enabled ? shortUrlData : undefined;

  return {
    siteRestaurant: shortUrlRestaurant || domainResp.data?.restaurantByDomain || mgmtGuidResp.data?.restaurant,
    error: shortUrlResp.error || domainResp.error || mgmtGuidResp.error,
    loading: shortUrlResp.loading || domainResp.loading || mgmtGuidResp.loading
  };
};

export default useRestaurantSiteData;
