import React, { useContext, createContext, useEffect, useMemo, useState } from 'react';

import { NewCardInput } from 'src/apollo/onlineOrdering';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { useCart } from 'public/components/online_ordering/CartContext';

export enum PaymentOption {
  CreditCard = 'CreditCard',
  UponReceipt = 'UponReceipt',
  ApplePay = 'ApplePay',
  Paypal = 'PayPal',
  Venmo = 'Venmo'
}

export type SelectedCreditCard = {
  newCardSelected: boolean;
  savedCardGuid: string | null;
};

export type PaymentContextType = {
  paymentOption: PaymentOption | null;
  setPaymentOption: (option: PaymentOption | null) => void;
  newCreditCard: NewCardInput | null;
  setNewCreditCard: (card: NewCardInput | null) => void;
  setSaveNewCreditCard: (saveCard: boolean) => void;
  selectedCreditCard: SelectedCreditCard | null;
  setSelectedCreditCard: (card: SelectedCreditCard | null | ((old: SelectedCreditCard) => SelectedCreditCard)) => void;
  tipEnabled: boolean;
  tipAmount: number;
  setTipAmount: (tip: number) => void;
  fundraisingEnabled: boolean;
  fundraisingAmount: number;
  setFundraisingAmount: (amount: number) => void
}

const TIPPABLE_PAYMENT_OPTIONS = [PaymentOption.CreditCard, PaymentOption.ApplePay, PaymentOption.Paypal, PaymentOption.Venmo] as PaymentOption[];

export const PaymentContext = createContext<PaymentContextType | undefined>(undefined);

export const PaymentContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { ooRestaurant } = useRestaurant();
  const { cart } = useCart();

  const [paymentOption, setPaymentOption] = useState<PaymentOption | null>(null);
  const [newCreditCard, setNewCreditCard] = useState<NewCardInput | null>(null);
  const [selectedCreditCard, setSelectedCreditCard] = useState<SelectedCreditCard | null>(null);
  const [tipAmount, setTipAmount] = useState(0.00);
  const tipEnabled = useMemo(() => {
    return Boolean(ooRestaurant?.creditCardConfig.tipEnabled && paymentOption && TIPPABLE_PAYMENT_OPTIONS.includes(paymentOption));
  }, [ooRestaurant, paymentOption]);
  const [fundraisingAmount, setFundraisingAmount] = useState(0.00);
  const fundraisingEnabled = useMemo(() => {
    return Boolean(ooRestaurant?.fundraisingConfig?.active && (paymentOption === PaymentOption.CreditCard || paymentOption === PaymentOption.ApplePay));
  }, [ooRestaurant, paymentOption]);

  useEffect(() => {
    if(cart) {
      const canPayAtCheckout = cart.paymentOptions.atCheckout.length > 0;
      const canPayUponReceipt = cart.paymentOptions.uponReceipt.length > 0;
      if(!canPayAtCheckout && !canPayUponReceipt) {
        setPaymentOption(null);
      } else if(canPayAtCheckout && (paymentOption === null || paymentOption === PaymentOption.UponReceipt && !canPayUponReceipt)) {
        setPaymentOption(PaymentOption.CreditCard);
      } else if(canPayUponReceipt && (paymentOption === null || paymentOption === PaymentOption.CreditCard && !canPayAtCheckout)) {
        setPaymentOption(PaymentOption.UponReceipt);
      }
    }
  }, [cart, paymentOption]);

  return (
    <PaymentContext.Provider value={{
      paymentOption,
      setPaymentOption,
      newCreditCard,
      setNewCreditCard,
      setSaveNewCreditCard: (saveCard: boolean) => setNewCreditCard(old => old ? { ...old, saveCard } : null),
      selectedCreditCard,
      setSelectedCreditCard,
      tipEnabled,
      tipAmount,
      setTipAmount,
      fundraisingEnabled,
      fundraisingAmount,
      setFundraisingAmount
    }}>
      {props.children}
    </PaymentContext.Provider>);
};

export const usePayment = () => {
  const context = useContext(PaymentContext);
  if(!context) {
    throw new Error('usePayment must be used within a PaymentContextProvider');
  }

  return context;
};
