import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router';

import { init, Identify, identify, track, flush } from '@amplitude/analytics-node';

import EditorAwareExport from 'src/editor/build-tools/exporter';
import { RequestContext } from 'src/lib/js/context';
import useTracker from 'src/lib/js/hooks/useTracker';
import { Tracker } from 'src/lib/js/tracker';
import { TOASTTAB_BOO_PATH } from 'src/public/js/siteUtilities';
import { getSelectedVariant, useExperimentUserId, COOKIE_NAME } from 'src/shared/components/common/ab_testing/ABTestContext';
import { TESTS } from 'src/shared/components/common/ab_testing/tests.config';
import { useFlags } from 'src/shared/components/common/feature_flags/useFlags';
import { OORestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import config, { amplitudeApiKey } from 'config';


//Regex pattern ensures that we're not trying to deeplink on the confirm or cart page and only on /online/shortURL(/)
const VALID_PATH_PATTERN_TO_DEEPLINK = /^\/online\/[^/]+\/?$/;
const DEEPLINK = 'deeplink';
const UTM_CAMPAIGN_KEY = 'utm_campaign';
const UTM_CAMPAIGN_VALUE = 'tto_universal_deeplink_v2';
const EXPERIMENT_USER_ID = 'experiment_user_id';


if(typeof window === 'undefined') {
  init(amplitudeApiKey);
}

const shouldRegisterExperiment = (
  { isOOBasic, backendFlag, frontendFlag, isIOS, isConsumerAppEnabled, isValidPath }:
  { isOOBasic: boolean, backendFlag: boolean, frontendFlag: boolean, isIOS: boolean, isConsumerAppEnabled: boolean, isValidPath: boolean }
) => {
  return isOOBasic && (backendFlag || frontendFlag) && isIOS && isConsumerAppEnabled && isValidPath;
};

const onVariantSelected = (
  { isClient, tracker, userId, staticContext, hasDeepLinked, restaurantGuid }:
  {
    isClient: boolean, tracker: Tracker, userId: string, staticContext: RequestContext | undefined, hasDeepLinked: boolean, restaurantGuid: string | undefined
  }
) => (experimentName: string, variantName: string) => {
  if(isClient) {
    tracker.register({ [experimentName]: variantName, userExperimentId: userId, sessionTtoDeepLinkExperiment: variantName });
    tracker.track('Frontend Register tto-universal-links-v2', { [experimentName]: variantName, userExperimentId: userId, sessionTtoDeepLinkExperiment: variantName });
    tracker.flush();
  } else {
    if(!hasDeepLinked) {
      const identifyObj = new Identify();
      identifyObj.set(experimentName, variantName);
      identifyObj.set('userExperimentId', userId);
      identifyObj.set('sessionTtoDeepLinkExperiment', variantName);
      identify(identifyObj, { user_id: userId });
      track(
        'Register tto-universal-links-v2',
        { [experimentName]: variantName, userExperimentId: userId, restaurantGuid, sessionTtoDeepLinkExperiment: variantName },
        { user_id: userId }
      );
      flush();

      if(staticContext) {
        staticContext.splunkLogger?.info(`Register tto-universal-links-v2 userId=${userId}, variantGroup=${variantName}, restaurantGuid=${restaurantGuid}`);
      }
    }
  }
};

const removeExperimentQueryStringParams = (queryParams: URLSearchParams) => {
  queryParams.delete(DEEPLINK);
  queryParams.delete(EXPERIMENT_USER_ID);

  const hasV1Compaign = queryParams.get(UTM_CAMPAIGN_KEY) === UTM_CAMPAIGN_VALUE;
  if(hasV1Compaign) {
    queryParams.delete(UTM_CAMPAIGN_KEY);
  }
  const qps = queryParams.toString();
  return qps.length ? `?${qps}` : '';
};


const useRedirectForUniversalLink = ({ ooRestaurant, staticContext, isClient, isOOBasic }:
{ ooRestaurant: OORestaurant | undefined, staticContext: RequestContext | undefined, isClient: boolean, isOOBasic: boolean })
: string => {
  const userId = useExperimentUserId();
  const [cookies, setCookie] = useCookies();
  const { 'oo-tto-universal-links': ooTtOUniversalLinksBackend, ooTtoUniversalLinks } = useFlags();
  const { pathname, search } = useLocation();

  const userAgent = isClient ? window.navigator.userAgent : staticContext?.req?.headers['user-agent'];
  const queryParams = new URLSearchParams(search);

  const hasDeepLinked = queryParams.get(DEEPLINK) === 'true';
  const isValidPath = VALID_PATH_PATTERN_TO_DEEPLINK.test(pathname);
  const isIOS = /(iPad|iPhone|iPod)/g.test(userAgent || '');
  const isConsumerAppEnabled = ooRestaurant?.consumerAppEnabled;
  const tracker = useTracker();

  if(shouldRegisterExperiment({
    isOOBasic,
    backendFlag: ooTtOUniversalLinksBackend,
    frontendFlag: ooTtoUniversalLinks,
    isIOS,
    isConsumerAppEnabled: Boolean(isConsumerAppEnabled),
    isValidPath
  })) {
    const expID = queryParams.get(EXPERIMENT_USER_ID);
    // Initial results of the universal link test indicated that users may be losing or getting their cookies reset after the redirect.
    // This pulls the experiment ID from the param and updates the cookie if there is a mismatch.
    if(expID && expID !== userId) {
      setCookie(COOKIE_NAME, expID, { path: '/' });
    }
    const experimentUserId = expID || userId;
    const selectedVariant = getSelectedVariant({
      experimentName: 'tto-universal-links-v2',
      userId: experimentUserId,
      cookies,
      tests: TESTS,
      onVariantSelected: onVariantSelected({ isClient, tracker, userId: experimentUserId, staticContext, hasDeepLinked, restaurantGuid: ooRestaurant?.guid }),
      staticContext
    });

    if(!hasDeepLinked && staticContext && selectedVariant === 'variant') {
      queryParams.append(DEEPLINK, 'true');
      queryParams.append(UTM_CAMPAIGN_KEY, UTM_CAMPAIGN_VALUE);
      queryParams.append(EXPERIMENT_USER_ID, userId);
      staticContext.statusCode = 307;
      staticContext.url = `${config.server.protocol}://${config.resources.toastOrderHost}${TOASTTAB_BOO_PATH}/${ooRestaurant?.shortUrl}/?${queryParams.toString()}`;
    }
  } else if(!isOOBasic || !isIOS || !isConsumerAppEnabled ) {
    //If any of these are not true wipe away user property. Flag is a killswitch so not including it as we get false negatives since it's not immediately available.
    tracker.register({ sessionTtoDeepLinkExperiment: 'NONE' });
    // Commenting this out for now because it is called twice per page render and is driving up our Amplitude bill
    //tracker.track('Reset Property sessionTtoDeepLinkExperiment', { userExperimentId: userId, sessionTtoDeepLinkExperiment: 'NONE' });
  }
  if(isClient && hasDeepLinked && ooTtoUniversalLinks) {
    const queryString = removeExperimentQueryStringParams(queryParams);
    return pathname + queryString;
  }

  return '';
};

const stub = () => {
  // a version of useRedirectForUniversalLink that does nothing. This will be used by the editor.
  return '';
};

export default EditorAwareExport(useRedirectForUniversalLink, stub);
