import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import { dataByTypename } from 'src/apollo/apolloUtils';
import {
  DiningOptionBehavior,
  PackagingInfo,
  PlaceApmOrderInput,
  PlaceApplePayOrderInput,
  PlaceCashOrderInput,
  PlaceCcOrderInput,
  PlaceCcOrderMutation,
  PlaceOrderErrorCode,
  usePlaceApmOrderMutation,
  usePlaceApplePayOrderMutation,
  usePlaceCashOrderMutation,
  usePlaceCcOrderMutation
} from 'src/apollo/onlineOrdering';
import { reportError } from 'src/lib/js/clientError';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { getRawPhoneNumber } from 'public/components/default_template/online_ordering/cart/cartUtils';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { useGiftCard } from 'public/components/online_ordering/GiftCardContext';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';
import { makeApplePayPayment, useApplePay } from 'public/components/online_ordering/applePayUtils';

import { PaymentOptionToAlternativePaymentMap, usePaypal } from './paypalUtils';


type PlaceOrderResponse = PlaceCcOrderMutation['placeOrder'] & { __typename: 'PlaceOrderResponse' };
type CompletedOrder = PlaceOrderResponse['completedOrder'];

export type CheckoutFormData = {
  yourInfoFirstName: string;
  yourInfoLastName: string;
  yourInfoEmail: string;
  yourInfoPhone: string;
  curbsidePickup: boolean;
  curbsidePickupVehicle?: string;
  curbsidePickupVehicleColor?: string;
  deliveryAddress2?: string;
  deliveryInstructions?: string;
  addressLabel?: string;
  semiPaymentIntentId?: string;
};

export type OrderError = {
  message: string;
  type: string;
};

export enum CheckoutMode {
  Toast = 'Toast',
  Guest = 'Guest'
}

export type CompleteOrderHandler = (order: CompletedOrder | undefined | null) => void;

export type ApmAuthResult = {
  apmName: string,
  canPlaceOrder: boolean,
  criticalError: boolean,
  token?: string
}

interface Payment {
  shippingContact: {
    emailAddress: string,
    givenName: string,
    familyName: string,
    phoneNumber: string
  }
}

export type CheckoutContextType = {
  checkoutMode: CheckoutMode;
  setCheckoutMode: (mode: CheckoutMode) => void
  createAccount: boolean;
  setCreateAccount: (create: boolean) => void;
  saveNewAddress: boolean;
  setSaveNewAddress: (save: boolean) => void;
  giftCardAppliedAmount: number;
  orderTotal: number;
  placeOrder: (cartGuid: string, checkoutFormData: CheckoutFormData, completedOrderHandler: CompleteOrderHandler) => Promise<CompletedOrder | undefined | null>;
  orderError: OrderError | null;
  setOrderError: (err: OrderError | null) => void;
  canCheckout: (formState: any) => boolean;
  enabledPaymentOptions: Set<PaymentOption>
  packagingOptions: PackagingInfo;
  setPackagingOptions: (packagingOptions: PackagingInfo) => void
};

export const CheckoutContext = createContext<CheckoutContextType | undefined>(undefined);

export const CheckoutContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { ooRestaurant } = useRestaurant();
  const { cart, refetchCart } = useCart();
  const { giftCardNumber, verificationCode, giftCardAvailableBalance } = useGiftCard();
  const { customer } = useCustomer();
  const giftCardAppliedAmount = useMemo(() => Math.min(giftCardAvailableBalance, cart?.order?.totalV2 || 0), [giftCardAvailableBalance, cart?.order]);

  const [placeCashOrderMutation] = usePlaceCashOrderMutation();
  const [placeCcOrderMutation] = usePlaceCcOrderMutation();
  const [orderError, setOrderError] = useState<OrderError | null>(null);

  // Alternative Payment Methods
  const [placeApmOrderMutation] = usePlaceApmOrderMutation();

  // Customer Info
  const [checkoutMode, setCheckoutMode] = useState<CheckoutMode>(customer ? CheckoutMode.Toast : CheckoutMode.Guest);
  const [createAccount, setCreateAccount] = useState(false);
  const [saveNewAddress, setSaveNewAddress] = useState(false);

  // Payment
  const { paymentOption, selectedCreditCard, newCreditCard, tipEnabled, tipAmount, fundraisingAmount } = usePayment();
  const { getConfig, getMerchantSession, canUseApplePay } = useApplePay();
  const [placeApplePayOrderMutation] = usePlaceApplePayOrderMutation();
  const { enabledPayPalPaymentOptions } = usePaypal();

  const enabledPaymentOptions = useMemo(() => {
    const enabledOptions = new Set<PaymentOption>();

    const canPayLater = (cart?.paymentOptions.uponReceipt.length || 0) > 0;
    const canPayNow = (cart?.paymentOptions.atCheckout.length || 0) > 0;

    if(canPayLater) {
      enabledOptions.add(PaymentOption.UponReceipt);
    }
    if(canPayNow) {
      enabledOptions.add(PaymentOption.CreditCard);
    } else {
      return enabledOptions;
    }
    if(canUseApplePay) {
      enabledOptions.add(PaymentOption.ApplePay);
    }
    enabledPayPalPaymentOptions.forEach(option => enabledOptions.add(option));
    return enabledOptions;
  }, [canUseApplePay, enabledPayPalPaymentOptions, cart?.paymentOptions.uponReceipt.length, cart?.paymentOptions.atCheckout.length]);

  // PackagingOptions
  const [packagingOptions, setPackagingOptions] = useState<PackagingInfo>({ packagingItems: [] } as PackagingInfo);

  // Return new delivery address if the customer has provided an apt number or delivery instructions
  // during checkout. Also return data if the customer wants to save their address. The addressLabel
  // field only appears for logged in customers.
  const getNewDeliveryAddress = useCallback((checkoutFormData: CheckoutFormData) => {
    if(cart?.diningOptionBehavior === DiningOptionBehavior.Delivery) {
      const saveAddressFields = saveNewAddress && checkoutFormData.addressLabel
        ? { name: checkoutFormData.addressLabel, saveAddress: true }
        : {};

      if(!isEmpty(saveAddressFields) || checkoutFormData.deliveryAddress2 || checkoutFormData.deliveryInstructions) {
        return {
          newAddress: {
            deliveryInfo: {
              address1: cart?.order?.deliveryInfo?.address1,
              address2: checkoutFormData.deliveryAddress2 || cart?.order?.deliveryInfo?.address2,
              city: cart?.order?.deliveryInfo?.city,
              state: cart?.order?.deliveryInfo?.state,
              zipCode: cart?.order?.deliveryInfo?.zipCode,
              latitude: cart?.order?.deliveryInfo?.latitude,
              longitude: cart?.order?.deliveryInfo?.longitude,
              notes: checkoutFormData.deliveryInstructions || cart?.order?.deliveryInfo?.notes
            },
            ...saveAddressFields
          }
        };
      }
    }

    return {};
  }, [saveNewAddress, cart]);

  const getCommonPlaceOrderParams = useCallback((checkoutFormData: CheckoutFormData) => {
    return {
      ...getNewDeliveryAddress(checkoutFormData),
      tipAmount: tipAmount,
      ...fundraisingAmount && { fundraisingInput: { fundraisingAmount } },
      ...checkoutFormData.curbsidePickup && checkoutFormData.curbsidePickupVehicle && {
        curbsidePickupV2: {
          transportDescription: checkoutFormData.curbsidePickupVehicle,
          ...checkoutFormData.curbsidePickupVehicleColor && { transportColor: checkoutFormData.curbsidePickupVehicleColor }
        }
      },
      ...checkoutFormData.curbsidePickup && !checkoutFormData.curbsidePickupVehicle && { curbsidePickup: { selected: true } }
    };
  }, [fundraisingAmount, tipAmount, getNewDeliveryAddress]);

  const getPerformTransaction = useCallback((cartGuid: string, checkoutFormData: CheckoutFormData, completeOrderHandler: CompleteOrderHandler) => async (payment: Payment) => {
    const { shippingContact } = payment;
    shippingContact.phoneNumber = getRawPhoneNumber(
      shippingContact.phoneNumber
    );

    const initialContactInfo = {
      cartGuid: cartGuid,

      pkPaymentToken: JSON.stringify(payment),
      customer: {
        email: shippingContact.emailAddress,
        firstName: shippingContact.givenName,
        lastName: shippingContact.familyName,
        phone: shippingContact.phoneNumber
      },
      ...getCommonPlaceOrderParams(checkoutFormData)
    };
    const response = await placeApplePayOrderMutation({ variables: { input: initialContactInfo as PlaceApplePayOrderInput } });

    const placeOrderResponse = response?.data?.placeApplePayOrder as any;
    const {
      PlaceOrderResponse,
      PlaceOrderError,
      PlaceOrderCartUpdatedError
    } = dataByTypename(placeOrderResponse);
    const error = PlaceOrderError || PlaceOrderCartUpdatedError;
    if(error) {
      if(PlaceOrderError) {
        const errorData = {
          type: PlaceOrderError,
          message: placeOrderResponse.message
        };
        if(errorData.type == PlaceOrderErrorCode.CriticalError || errorData.type == PlaceOrderErrorCode.PlaceOrderFailed) {
          reportError('Error placing order', errorData);
        }
        setOrderError(errorData);
      } else if(PlaceOrderCartUpdatedError) {
        refetchCart();
        setOrderError({
          type: PlaceOrderCartUpdatedError,
          message: placeOrderResponse.message
        });
      }
      throw new Error(error.message);
    } else {
      completeOrderHandler(PlaceOrderResponse.completedOrder);
      return PlaceOrderResponse.completedOrder;
    }
  }, [getCommonPlaceOrderParams, placeApplePayOrderMutation, refetchCart]);

  const getApmPlaceOrderCommon = useCallback((cartGuid: string, checkoutFormData: CheckoutFormData, completeOrderHandler: CompleteOrderHandler) => async (result: ApmAuthResult) => {
    if(result.criticalError) {
      // critical error flow
      setOrderError({
        type: PlaceOrderErrorCode.CriticalError,
        message: `We were unable to process your order using ${result.apmName}. Please select another payment method and try again.`
      });
      return;
    }
    if(!result.canPlaceOrder) {
      // non-critical error flow
      setOrderError({
        type: PlaceOrderErrorCode.PlaceOrderFailed,
        message: `We were unable to process your order using ${result.apmName}. Please verify your payment information and try again.`
      });
      return;
    }
    const placeApmOrderInput = {
      cartGuid: cartGuid,
      apmAuthToken: result.token,
      paymentMethod: result.apmName,
      customer: {
        email: checkoutFormData.yourInfoEmail,
        firstName: checkoutFormData.yourInfoFirstName,
        lastName: checkoutFormData.yourInfoLastName,
        phone: checkoutFormData.yourInfoPhone
      },
      ...giftCardNumber && giftCardAppliedAmount && {
        giftCard: {
          cardNumber: giftCardNumber.replace(/\s/g, ''),
          expectedAvailableBalance: giftCardAppliedAmount,
          verificationCode
        }
      },
      ...getCommonPlaceOrderParams(checkoutFormData)
    };
    const apmOrderResponse = await placeApmOrderMutation({ variables: { input: placeApmOrderInput as PlaceApmOrderInput } });
    const placeOrderResponse = apmOrderResponse?.data?.placeApmOrder as any;
    const {
      PlaceOrderResponse,
      PlaceOrderError,
      PlaceOrderCartUpdatedError
    } = dataByTypename(placeOrderResponse);

    if(PlaceOrderError || PlaceOrderCartUpdatedError) {
      const errorData = {
        type: PlaceOrderError?.code || PlaceOrderCartUpdatedError?.code,
        message: placeOrderResponse.message
      };
      if(errorData.type == PlaceOrderErrorCode.CriticalError || errorData.type == PlaceOrderErrorCode.PlaceOrderFailed) {
        reportError('Error placing order', errorData);
      }
      if(PlaceOrderCartUpdatedError) refetchCart();
      setOrderError(errorData);
    } else {
      completeOrderHandler(PlaceOrderResponse.completedOrder);
      return PlaceOrderResponse.completedOrder;
    }
  }, [getCommonPlaceOrderParams, giftCardAppliedAmount, giftCardNumber, verificationCode, placeApmOrderMutation, refetchCart]);


  const placeOrder = async (cartGuid: string, checkoutFormData: CheckoutFormData, completeOrderHandler: CompleteOrderHandler) => {
    if(paymentOption === PaymentOption.ApplePay) {
      const config = getConfig(tipAmount, fundraisingAmount);
      const performTransaction = getPerformTransaction(cartGuid, checkoutFormData, completeOrderHandler);
      const onCancel = () => { };
      makeApplePayPayment({ config, getMerchantSession, performTransaction, onCancel });

      return;
    }

    if(paymentOption === PaymentOption.Paypal || paymentOption === PaymentOption.Venmo) {
      if(!ooRestaurant || !cart) return;
      const placeApmOrderCallback = getApmPlaceOrderCommon(cartGuid, checkoutFormData, completeOrderHandler);

      await placeApmOrderCallback({
        apmName: PaymentOptionToAlternativePaymentMap[paymentOption],
        criticalError: false,
        canPlaceOrder: true,
        token: ''
      });

      return;
    }

    let response = null;
    const commonInputData = {
      cartGuid,
      customer: {
        firstName: checkoutFormData.yourInfoFirstName,
        lastName: checkoutFormData.yourInfoLastName,
        email: checkoutFormData.yourInfoEmail,
        phone: checkoutFormData.yourInfoPhone.replace(/\D/g, '')
      },
      ...ooRestaurant?.packagingConfig?.enabled && { packagingInfo: packagingOptions },
      ...getNewDeliveryAddress(checkoutFormData),
      ...giftCardNumber && giftCardAppliedAmount && {
        giftCard: {
          cardNumber: giftCardNumber.replace(/\s/g, ''),
          expectedAvailableBalance: giftCardAppliedAmount,
          verificationCode
        }
      },
      ...checkoutFormData.curbsidePickup && checkoutFormData.curbsidePickupVehicle && {
        curbsidePickupV2: {
          transportDescription: checkoutFormData.curbsidePickupVehicle,
          ...checkoutFormData.curbsidePickupVehicleColor && { transportColor: checkoutFormData.curbsidePickupVehicleColor }
        }
      },
      ...checkoutFormData.curbsidePickup && !checkoutFormData.curbsidePickupVehicle && { curbsidePickup: { selected: true } }
    };

    if(paymentOption === PaymentOption.CreditCard && (newCreditCard || selectedCreditCard?.savedCardGuid || isIntlRestaurant)) {
      const orderInput = commonInputData as PlaceCcOrderInput;
      orderInput.deliveryCommunicationConsentGiven = true;
      if(selectedCreditCard?.newCardSelected && newCreditCard) {
        orderInput.newCardInput = newCreditCard;
      } else if(!selectedCreditCard?.newCardSelected && selectedCreditCard?.savedCardGuid) {
        orderInput.savedCardInput = { cardGuid: selectedCreditCard.savedCardGuid };
      }
      orderInput.tipAmount = tipAmount;
      if(fundraisingAmount) {
        orderInput.fundraisingInput = { fundraisingAmount };
      }

      if(checkoutFormData.semiPaymentIntentId) {
        orderInput.semiPaymentIntentId = checkoutFormData.semiPaymentIntentId;
      }

      response = await placeCcOrderMutation({
        variables: { input: orderInput },
        context: { headers: { 'Toast-Restaurant-External-ID': ooRestaurant?.guid } }
      });
    } else {
      const orderInput = commonInputData as PlaceCashOrderInput;
      response = await placeCashOrderMutation({ variables: { input: orderInput } });
    }

    if(response?.data?.placeOrder.__typename === 'PlaceOrderResponse') {
      completeOrderHandler(response.data.placeOrder.completedOrder);
      return response.data.placeOrder.completedOrder;
    } else if(response?.data?.placeOrder.__typename === 'PlaceOrderError') {
      const errorData = {
        type: response.data.placeOrder.placeOrderErrorCode,
        message: response.data.placeOrder.message
      };
      if(errorData.type == PlaceOrderErrorCode.CriticalError || errorData.type == PlaceOrderErrorCode.PlaceOrderFailed) {
        reportError('Error placing order', errorData);
      }
      setOrderError(errorData);
    } else if(response?.data?.placeOrder.__typename === 'PlaceOrderCartUpdatedError') {
      refetchCart();
      setOrderError({
        type: response.data.placeOrder.placeOrderCartUpdatedErrorCode,
        message: response.data.placeOrder.message
      });
    }

    return null;
  };

  const orderTotal = useMemo(
    () => Number(cart?.order?.totalV2 || 0) + (tipEnabled ? tipAmount : 0) + fundraisingAmount - giftCardAppliedAmount,
    [cart?.order?.totalV2, tipEnabled, tipAmount, fundraisingAmount, giftCardAppliedAmount]
  );

  const isIntlRestaurant = useIsIntlRestaurant();

  const canCheckout = useCallback((formState: any) => {
    if(isIntlRestaurant && formState.isValid && !formState.isSubmitting) {
      return true;
    }

    return !!(!formState.isSubmitting
      && (formState.isValid || paymentOption === PaymentOption.ApplePay)
      && paymentOption !== null
      && (
        paymentOption === PaymentOption.UponReceipt
        || orderTotal === 0
        || paymentOption === PaymentOption.CreditCard && (newCreditCard || selectedCreditCard?.savedCardGuid)
        || paymentOption === PaymentOption.ApplePay
        || paymentOption === PaymentOption.Paypal
        || paymentOption === PaymentOption.Venmo
      ));
  }, [paymentOption, orderTotal, newCreditCard, selectedCreditCard, isIntlRestaurant]);

  return (
    <CheckoutContext.Provider value={{
      checkoutMode,
      setCheckoutMode,
      createAccount,
      setCreateAccount,
      saveNewAddress,
      setSaveNewAddress,
      giftCardAppliedAmount,
      orderTotal,
      placeOrder,
      orderError,
      setOrderError,
      canCheckout,
      enabledPaymentOptions,
      packagingOptions,
      setPackagingOptions
    }}>
      {props.children}
    </CheckoutContext.Provider>);
};

export const useCheckout = () => {
  const context = useContext(CheckoutContext);
  if(!context) {
    throw new Error('useCheckout must be used within a CheckoutContextProvider');
  }

  return context;
};
