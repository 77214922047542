import React, { useCallback, useEffect, useState } from 'react';

export type MoreItemRefs = {
  inMoreMenu?: boolean | null,
  ref: React.RefObject<any>
};

export const useMoreItems = (itemRefs: MoreItemRefs[], moreWidth: number, padding: number, width?: number) => {
  const [viewItems, setViewItems] = useState<MoreItemRefs[]>([]);
  const [moreItems, setMoreItems] = useState<MoreItemRefs[]>([]);

  const recalculateWidths = useCallback(() => {
    const tempViewItems: MoreItemRefs[] = [];
    const tempMoreItems: MoreItemRefs[] = [];
    let currWidth = moreWidth + padding;
    let isFull = false;
    if(width) {
      itemRefs.forEach(item => {
        const itemWidth = item.ref.current?.offsetWidth || 0;
        if(item.inMoreMenu) {
          tempMoreItems.push(item);
        } else if(currWidth + itemWidth + padding > width || isFull) {
          tempMoreItems.push(item);
          isFull = true;
        } else {
          tempViewItems.push(item);
          currWidth += itemWidth + padding;
        }
      });

      setViewItems(tempViewItems);
      setMoreItems(tempMoreItems);
    }
  }, [width, itemRefs, moreWidth, padding]);

  useEffect(() => recalculateWidths(), [recalculateWidths]);

  // Callback ref to run so first render overflow can be handled
  const rerenderRef: React.LegacyRef<HTMLDivElement> = useCallback(ref => {
    if(ref) {
      recalculateWidths();
    }
  }, [recalculateWidths]);

  return {
    viewItems,
    moreItems,
    rerenderRef
  };
};

export default useMoreItems;
