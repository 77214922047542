import React, { CSSProperties, useCallback, useMemo, useState } from 'react';

import classnames from 'classnames';
import parseISO from 'date-fns/parseISO';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { formatAddressLabel } from 'src/shared/components/common/form_input/LocationInput';

import Image from 'shared/components/common/Image';
import LocationMap from 'shared/components/common/location_map/LocationMap';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import useWindowMessage from 'shared/js/hooks/useWindowMessage';
import { getASAPText } from 'shared/js/timeUtils';

import OOLocationSelector from 'public/components/default_template/nav/OOLocationSelector';
import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import DiningBehaviorToggle from 'public/components/default_template/online_ordering/dining_behavior_toggle/DiningBehaviorToggle';
import DiningOptions from 'public/components/default_template/online_ordering/dining_options/DiningOptions';
import { DeliveryAddressSelection } from 'public/components/default_template/online_ordering/dining_options/delivery_address_selection/DeliveryAddressSelection';
import { MapMessage } from 'public/components/google_maps/messages';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { useFulfillment } from 'public/components/online_ordering/FulfillmentContext';
import { formatDeliveryAddress } from 'public/components/online_ordering/addressUtils';
import { getQuoteTime } from 'public/components/online_ordering/fulfillmentUtils';
import { Cart } from 'public/components/online_ordering/types';

import { server } from 'config';


const MAP_SOURCE_ID = 'OrderOptions';

type Props = {
  className?: string
  style?: CSSProperties
}

type TestProps = {
  buttonTestId?: string
  modalTestId?: string
}

const OrderOptions = ({ className, children, buttonTestId, modalTestId, style }: React.PropsWithChildren<Props & TestProps>) => {
  const { isOpen, onOpen, onClose } = useModal();
  const { ooRestaurant, orderingDisabled, updateLocation, selectedLocation, locations } = useRestaurant();
  const { cart } = useCart();
  const { canOrderTakeout, canOrderDelivery, fulfillmentData, canScheduleSelectedBehavior, selectedDiningOptionBehavior, setSelectedDiningOptionBehavior } = useFulfillment();
  const { clearDeliveryValidationError, validDeliveryAddress } = useDelivery();
  const [mapExpanded, setMapExpanded] = useState(false);

  const messageHandlers = useMemo(() => ({
    [MapMessage.SELECTED_PIN]: () => setMapExpanded(true),
    [MapMessage.SELECTED_GUID]: (data: { guid: string, diningOptionBehavior: DiningOptionBehavior }) => {
      setMapExpanded(false);
      updateLocation(data.guid);
      setSelectedDiningOptionBehavior(data.diningOptionBehavior);
    },
    [MapMessage.INTERACT]: () => setMapExpanded(true)
  }), [updateLocation, setSelectedDiningOptionBehavior]);

  useWindowMessage({ sourceId: MAP_SOURCE_ID, origin: `${server.protocol}://${server.fullHost}`, messageHandlers });

  const closeMap = useCallback(() => {
    setMapExpanded(false);
    for(let i = 0; i < window.frames.length; i++) {
      window.frames[i]?.postMessage(JSON.stringify({
        name: MapMessage.CLOSED,
        data: ''
      }), `${server.protocol}://${server.fullHost}`);
    }
  }, []);

  const noOptions = useMemo(() =>
    (locations?.length || 0) === 1
      && !(canOrderTakeout && canOrderDelivery)
      && !canScheduleSelectedBehavior
  , [locations, canOrderTakeout, canOrderDelivery, canScheduleSelectedBehavior]);

  const openModal = useCallback(() => {
    if(!noOptions) {
      onOpen();
    }
  }, [noOptions, onOpen]);

  const fulfillmentType = fulfillmentData?.cartFulfillmentData.fulfillmentType;
  const parsedTime = fulfillmentData?.cartFulfillmentData.fulfillmentDateTime ? parseISO(fulfillmentData.cartFulfillmentData.fulfillmentDateTime) : null;
  const behaviorText = fulfillmentData?.cartFulfillmentData.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'Deliver' : 'Pickup';
  const behaviorModifier = fulfillmentData?.cartFulfillmentData.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'to' : 'from';
  const minimumTime = useMemo(
    () => selectedDiningOptionBehavior && ooRestaurant ? getQuoteTime(selectedDiningOptionBehavior, ooRestaurant, cart as Cart) : null,
    [selectedDiningOptionBehavior, ooRestaurant, cart]
  );
  let fulfillmentText = getASAPText(fulfillmentType, parsedTime, minimumTime);
  return (
    <>
      <Modal isOpen={isOpen} onClose={() => {
        closeMap();
        clearDeliveryValidationError();
        onClose();
      }} testId={modalTestId}>
        <ModalOverlay fadeIn />
        <ModalContent contentClassName="orderOptionsModal" fadeIn>
          {mapExpanded ?
            <button onClick={closeMap} className="backButton">
              <Image alt="Back button" src="icons/caret-left.svg" />
            </button>
            : null}
          <ModalCloseButton />
          <div className="hidden-md-up">
            <LocationMap allowFulfillment sourceId={MAP_SOURCE_ID}
              selectedDiningOption={fulfillmentData?.cartFulfillmentData.diningOptionBehavior} className={classnames('optionsMapFrame', { expanded: mapExpanded })} />
            {(locations?.length || 0) > 1 ? <div className={classnames('updateWarning', { expanded: mapExpanded })}>Updating the location may affect item availability.</div> : null}
          </div>
          <div className="optionsLocationSelector hidden-sm-down">
            <div className="header">Order details</div>
            {!orderingDisabled && <DiningBehaviorToggle />}
            {(locations?.length || 0) > 1 ? <h3>{selectedDiningOptionBehavior === DiningOptionBehavior.Delivery ? 'Deliver to' : 'Pickup from'}</h3> : null}
            <AnimatedSection expanded={orderingDisabled || selectedDiningOptionBehavior === DiningOptionBehavior.TakeOut}>
              <OOLocationSelector />
            </AnimatedSection>
            <AnimatedSection expanded={selectedDiningOptionBehavior === DiningOptionBehavior.Delivery}>
              {(locations?.length || 0) === 1 ? <h3>Deliver to</h3> : null}
              <DeliveryAddressSelection />
            </AnimatedSection>
          </div>
          {!orderingDisabled ?
            <div className={classnames('optionItems', { collapsed: mapExpanded })}>
              <DiningOptions behaviorToggleClassName="hidden-md-up" />
            </div>
            : null}
        </ModalContent>
      </Modal>
      {fulfillmentData?.cartFulfillmentData.diningOptionBehavior === DiningOptionBehavior.Delivery && !cart?.order?.deliveryInfo && !validDeliveryAddress ?
        null :
        <button className={classnames('orderOptions', className, { noOptions })} disabled={orderingDisabled && (locations?.length || 0) <= 1} onClick={openModal} data-testid={buttonTestId}>
          {children ||
                <div className="diningOptionBehavior redesign" style={style}>
                  {orderingDisabled ?
                    'Online Ordering Unavailable' :
                    <div className="diningOptionAnchor">
                      <span className="diningOptionAnchor bold">
                        <span>{behaviorText} <span className={classnames(!noOptions && 'link')}>{fulfillmentText}</span></span>
                        <span>{behaviorModifier}</span>
                      </span>
                      <span className="address">
                        {fulfillmentData?.cartFulfillmentData.diningOptionBehavior === DiningOptionBehavior.Delivery ?
                          formatDeliveryAddress(cart ? cart.order?.deliveryInfo : validDeliveryAddress) :
                          formatAddressLabel(selectedLocation)}
                      </span>
                    </div>}
                  <Image src="icons/pencil_icon.svg" className="hidden-md-up" />
                </div> }
        </button>}
    </>
  );
};

export default OrderOptions;
