import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import classnames from 'classnames';

import { GiftCardConfigFieldsFragment } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';
import FormInput from 'src/shared/components/common/form_input';

import AppliedInput from 'public/components/default_template/online_ordering/checkout/AppliedInput';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useGiftCard } from 'public/components/online_ordering/GiftCardContext';

import config from 'config';

interface GiftCardExtensionInputParams {
  gcConfig: GiftCardConfigFieldsFragment
}

interface CardAndPinInputParams {
  isApplied?: boolean
  giftCard: string
  pinCode: string
  warning: string
}

const CardAndPinInput = ({ isApplied, giftCard, pinCode, warning }: CardAndPinInputParams) => isApplied ?
  <div className={classnames('giftCardAndPinWrapper')}>
    <div className={classnames('giftCardWrapper')}>
      <FormInput
        id="giftCardEntered"
        type="text"
        label="Gift Card"
        filled={true}>
        <div className="appliedInputValue">{giftCard}</div>
      </FormInput>
    </div>
    <div className={classnames('giftCardPinWrapper')}>
      <FormInput
        id="pinCodeEntered"
        type="password"
        label="Code"
        filled={true}>
        <div className="appliedInputValue">{'*'.repeat(pinCode?.length || 0)}</div>
      </FormInput>
    </div>
  </div>
  :
  <div className={classnames('giftCardAndPinWrapper')}>
    <div className={classnames('giftCardWrapper')}>
      <FormInput
        id="giftCard"
        type="text"
        label="Gift Card"
        warn={(value: string) => value === '' || value.length < 6 ? 'gifts card must be at least 6 digits' : ''}
        warning={warning} />
    </div>
    <div data-testid="gift-card-pin-code-input" className={classnames('giftCarPinWrapper')}>
      <FormInput
        id="pinCode"
        type="password"
        label="Code" />
    </div>
  </div>;

const GiftCardExtensionInput = ({ gcConfig }: GiftCardExtensionInputParams) => {
  const { watch, getFieldState } = useFormContext();
  const giftCard = watch('giftCard');
  const pinCode = watch('pinCode');
  const { cartGuid } = useCart();
  const { applyGiftCardExtension, removeGiftCard, loadingGiftCard, giftCardNumber, giftCardError } = useGiftCard();
  const [warning, setWarning] = useState('');
  const { setValue } = useFormContext();
  const tracker = useTracker();

  useEffect(() => {
    if(giftCardNumber) {
      tracker.track('Gift card applied successfully', { firstOrThirdParty: '3P' });
    } else if(giftCardError) {
      tracker.track('Gift card error', { firstOrThirdParty: '3P', error: giftCardError });
    }
  }, [giftCardNumber, giftCardError, tracker]);

  const onApplyGiftCard = useCallback(async () => {
    if(cartGuid && giftCard) {
      tracker.track('Clicked apply gift card', {
        firstOrThirdParty: '3P',
        verificationCodeOptional: gcConfig.supportVerificationCode,
        verificationCodeSubmitted: Boolean(pinCode)
      });
      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(config.reCaptchaEnterprise.siteKey, { action: 'giftcard_extension_inquiry' });
        const tokenProto = `recaptcha:enterprise:${token}`;
        let pin = gcConfig.supportVerificationCode ? pinCode : undefined;
        applyGiftCardExtension(cartGuid, giftCard, tokenProto, pin);
      });
    }
  }, [applyGiftCardExtension, cartGuid, giftCard, pinCode, gcConfig.supportVerificationCode, tracker]);

  useEffect(() => setWarning(giftCardError), [setWarning, giftCardError]);

  const onRemoveCard = () => {
    if(gcConfig.supportVerificationCode) {
      setValue('pinCode', '');
    }
    removeGiftCard();
  };

  return (
    <div data-testid="gift-card-extension-input">
      <AppliedInput
        id="giftCard"
        label="Gift Card"
        value={giftCard}
        loading={loadingGiftCard}
        isApplied={Boolean(giftCardNumber)}
        applyButtonActive={giftCard && !getFieldState('giftCard').invalid}
        removeButtonActive={true}
        warn={(value: string) => value === '' || value.length < 6 ? 'gifts card must be at least 6 digits' : ''}
        warning={warning}
        onApply={onApplyGiftCard}
        onRemove={onRemoveCard}>
        {
          gcConfig.supportVerificationCode &&
            <CardAndPinInput
              giftCard={giftCard}
              pinCode={pinCode}
              warning={warning}
              isApplied={Boolean(giftCardNumber)} />
        }
      </AppliedInput>
      <div className={classnames('note')} role="contentinfo">
        This site is protected by reCAPTCHA and the Google&nbsp;
        <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a> and&nbsp;
        <a href="https://policies.google.com/terms" rel="noopener noreferrer" target="_blank">Terms of Service</a> apply.
      </div>

    </div>
  );
};

export default GiftCardExtensionInput;
