import React from 'react';
import { useParams } from 'react-router';

import EditorAwareExport from 'src/editor/build-tools/exporter';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import PrimaryCTA from 'shared/components/primary_cta/PrimaryCTA';

import { CTAData, getCtaContent } from 'public/components/default_template/ctas';


type Props = {
  primaryCta?: CTAData | null;
}

const ClientStickyFooter = ({ primaryCta }: Props) => {
  const params = useParams<{ slug?: string }>(); // this call cannot be in the editor
  return <StickyFooterView slug={params.slug} primaryCta={primaryCta} />;
};

const EditorStickyFooter = ({ primaryCta }: Props) => {
  return <StickyFooterView primaryCta={primaryCta} />;
};

type FooterCTAProps = {
  primaryCta?: CTAData | null
  slug?: string
}

/** The underlying view component that is compatible in both the client and editor bundle. */
const StickyFooterView = ({ primaryCta, slug }: FooterCTAProps) => {
  const { restaurant, orderingUrl, reservationsUrl, phoneNumber, selectedLocation } = useRestaurant();

  const ctaProps = {
    restaurantConfig: restaurant.config,
    orderingUrl,
    reservationsUrl,
    phoneNumber,
    selectedLocation: slug ? selectedLocation : undefined
  };

  const primaryCtaContent = primaryCta ? getCtaContent(primaryCta, ctaProps) : null;

  if(!primaryCtaContent) {
    return null;
  }

  return (
    <div className="stickyFooter" data-testid="sticky-footer-wrapper">
      <PrimaryCTA href={primaryCtaContent.link} text={primaryCtaContent.text} onClick={primaryCtaContent.onClick} />
    </div>
  );
};


export default EditorAwareExport(ClientStickyFooter, EditorStickyFooter);


