import { TestSurface } from './types';

export const TESTS = {
  'woo-federated-gateway': {
    variants: {
      bff: 0,
      gateway: 100
    },
    controlVariant: 'bff',
    testSurfaces: [TestSurface.OOV4, TestSurface.CUSTOM_DOMAINS, TestSurface.TOAST_LOCAL]
  },
  'tto-universal-links-v2': {
    variants: {
      control: 50,
      variant: 50
    },
    controlVariant: 'control',
    testSurfaces: [TestSurface.OOV4]
  },
  'woo-mobile-ui-improvements': {
    variants: {
      control: 75, // we will update this to a 50/50 down the road
      test: 25
    },
    controlVariant: 'control',
    testSurfaces: [TestSurface.OOV4, TestSurface.CUSTOM_DOMAINS, TestSurface.TOAST_LOCAL]
  }
};
