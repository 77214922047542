import { useEffect, useMemo, useState } from 'react';

import { throttle } from 'lodash';

// Maximum screen width in pixels, from `src/shared/styles/responsive.scss`
export enum ScreenWidth {
  EXTRA_SMALL = 470,
  SMALL = 767,
  MEDIUM = 1023,
  LARGE = 1279
}

export const useIsMobile = (maxMobileWidth: number): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    if(typeof window !== 'undefined') {
      const mediaQuery = `(max-width: ${maxMobileWidth}px)`;
      const mediaQueryList = window.matchMedia(mediaQuery);
      setIsMobile(mediaQueryList.matches);

      const updateIsMobile = (event: MediaQueryListEvent) => {
        setIsMobile(event.matches);
      };

      mediaQueryList.addEventListener('change', updateIsMobile);
      return () => window.removeEventListener('change', updateIsMobile);
    }
    return;
  }, [setIsMobile, maxMobileWidth]);

  return isMobile;
};

export const useScreenWidth = (): number => {
  const _window = useMemo(() => typeof window !== 'undefined' ? window : {} as Window, []);
  const [width, setWidth] = useState(_window.innerWidth);
  useEffect(() => {
    const updateWidth = throttle(() => setWidth(_window.innerWidth), 500);
    _window.addEventListener('resize', updateWidth);
    return () => {
      _window.removeEventListener('resize', updateWidth);
    };
  }, [_window]);

  return width;
};
