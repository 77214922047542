import React from 'react';

import Image from 'src/shared/components/common/Image';

const CommissionDisclaimer = () =>
  <div className="commission-disclaimer">
    <div className="storefront-icon"><Image src="/icons/storefront.svg"></Image></div>
    <p>Orders through Toast are commission free and go directly to this restaurant</p>
  </div>;

export default CommissionDisclaimer;
