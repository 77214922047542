import React from 'react';

import { useEditor } from '@toasttab/sites-components';

import { ButtonType, CtaType } from 'src/apollo/sites';

import Button from 'shared/components/common/button';
import Link from 'shared/components/common/link';

type PrimaryCTAProps = {
  onClick?: (event: React.SyntheticEvent) => void;
  href?: string | null;
  text: string;
  iconRight?: JSX.Element;
  disabled?: boolean;
};

type EditablePrimaryCTAProps = {
  type: CtaType | 'customAction';
  editPath: string;
} & PrimaryCTAProps;

export const PrimaryCTAClasses = 'primaryCta';

export const EditablePrimaryCTA = (props: React.PropsWithChildren<EditablePrimaryCTAProps>) => {
  const { useEditableRef } = useEditor();

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'primary cta',
    displayName: 'Button',
    actions: [],
    path: props.editPath,
    schema: { fields: [] }
  });

  return (
    <div ref={editableRef}>
      <PrimaryCTA {...props} />
    </div>
  );
};

const PrimaryCTA = ({ disabled = false, ...props }: PrimaryCTAProps) => {
  const Wrapper = ({ children }: React.PropsWithChildren<{}>) => props.href ? <Link href={props.href}>{children}</Link> : <>{children}</>;
  return (
    <Wrapper>
      <Button type="button" className={PrimaryCTAClasses} variant={ButtonType.Primary} onClick={props.onClick} iconRight={props.iconRight} disabled={disabled}>
        {props.text}
      </Button>
    </Wrapper>
  );
};

export default PrimaryCTA;
