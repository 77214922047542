import React, { useMemo } from 'react';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { BaseSectionFieldsFragment, PaddingEnum } from 'src/apollo/sites';
import AttributionContextProvider from 'src/shared/components/common/attribution_context/AttributionContext';

type BaseProps = {
  index: number
  testId?: string
  attributionUtmTerm: string
  className?: string
}
type Props = BaseProps & Omit<BaseSectionFieldsFragment, '__typename'>;

const SectionWrapper = ({ children, index, testId, attributionUtmTerm, className, ...section } : React.PropsWithChildren<Props>) => {
  const backgroundColorStyle = useMemo(() => section.backgroundColor ? { backgroundColor: section.backgroundColor } : {}, [section.backgroundColor]);
  const padding = useMemo(() => section.padding || PaddingEnum.Medium, [section.padding]);
  const { moduleWrapper } = useEditor();

  const WrapperComponent = useMemo(() => moduleWrapper || (({ children }: React.PropsWithChildren<{ index: number; }>) => <>{children}</>), [moduleWrapper]);

  return (
    <WrapperComponent index={index}>
      <AttributionContextProvider utm_term={attributionUtmTerm}>
        <div
          id={`sectionIdx${index}`}
          className={classnames('sectionWrapper bottomPadding topPadding', `PADDING-SETTING--${padding}`, className)}
          style={backgroundColorStyle}
          data-testid={testId}>
          {children}
        </div>
      </AttributionContextProvider>
    </WrapperComponent>
  );
};

export default SectionWrapper;
