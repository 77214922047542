import React from 'react';

import Link from 'shared/components/common/link';

import { formatRXName } from 'public/components/default_template/restaurant_profile/formatting';
import { TLRestaurantType } from 'public/components/default_template/restaurant_profile/profileUtils';

import { resources } from 'config';

type Props = {
  restaurant: TLRestaurantType;
};

const Breadcrumbs = ({ restaurant }: Props) => {
  const crumbs = [];
  const location = restaurant.locations && restaurant.locations[0];
  const city = location?.tlCity;

  const cuisine = restaurant.cuisines && restaurant.cuisines.length ? restaurant.cuisines[0] : null;
  if(city) {
    if(city.parentCity) {
      crumbs.push({ name: city.parentCity.city, path: `https://${resources.toastLocalHost}/local/${city.parentCity.slug}-restaurants` });
      crumbs.push({ name: city.city, path: `https://${resources.toastLocalHost}/local/${city.parentCity.slug}-restaurants/${city.slug}` });
    } else {
      crumbs.push({ name: city.city, path: `https://${resources.toastLocalHost}/local/${city.slug}-restaurants` });
    }
  }
  if(cuisine) {
    if(!city) {
      crumbs.push({ name: cuisine.name, path: `https://${resources.toastLocalHost}/local/best-${cuisine.slug}` });
    } else {
      if(city.parentCity) {
        crumbs.push({ name: cuisine.name, path: `https://${resources.toastLocalHost}/local/${city.parentCity.slug}-restaurants/${city.slug}/best-${cuisine.slug}` });
      } else {
        crumbs.push({ name: cuisine.name, path: `https://${resources.toastLocalHost}/local/${city.slug}-restaurants/best-${cuisine.slug}` });
      }
    }
  }
  crumbs.push({ name: formatRXName(restaurant.name) || 'Restaurant', path: 'rx-name' }); // Don't need real path here

  const numCrumbs = crumbs.length;
  const crumbNodes = crumbs.map((crumb, i) => {
    return (
      <React.Fragment key={i}>
        <li className="crumb slash">/</li>
        <li className="crumb">{i < numCrumbs - 1 ? <Link href={crumb.path} sameTab>{crumb.name}</Link> : crumb.name}</li>
      </React.Fragment>);
  });
  // prepend the crumbs with the "Home" text.
  crumbNodes.unshift(<li className="crumb" key={-1}><Link href={`https://${resources.toastLocalHost}/local`} sameTab>Home</Link></li>);

  return (
    <div className="breadcrumbs">
      <ul>
        {crumbNodes}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
