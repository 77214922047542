import React, { createContext, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router';

import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { createClient } from 'src/apollo/createClient';
import { RequestContextProps } from 'src/lib/js/context';
import { useGiaAuth } from 'src/shared/js/hooks/useGiaAuth';

import { getSelectedVariant, useExperimentUserId } from 'shared/components/common/ab_testing/ABTestContext';
import { TESTS } from 'shared/components/common/ab_testing/tests.config';

import { resources } from 'config';


type OOClientContextProps = {
  client: ApolloClient<any> | ((host: string) => ApolloClient<any>);
};

export type OOClientType = {
  client: ApolloClient<any>;
  refreshClient?: () => void;
};

declare global {
  interface Window {
    __OO_STATE__: NormalizedCacheObject | undefined;
  }
}

export const OOClientContext = createContext<OOClientType | undefined>(undefined);

export const WrappedOOClientProvider = (props: React.PropsWithChildren<Partial<RequestContextProps> & OOClientContextProps>) => {
  const giaAuth = useGiaAuth();
  const { nv5UseFederatedGateway } = useFlags();

  const cookies = useCookies();
  const userId = useExperimentUserId();
  const variant = userId ? getSelectedVariant({ experimentName: 'woo-federated-gateway', userId, cookies: cookies[0], tests: TESTS }) : 'bff';
  const [client, setClient] = useState<ApolloClient<any>>(
    typeof props.client === 'function' ? props.client(variant === 'gateway' && nv5UseFederatedGateway ? resources.federatedProxyHost : resources.ooProxyHost) : props.client
  );

  const refreshClient = () => {
    setClient(createClient(variant === 'gateway' ? resources.federatedProxyHost : resources.ooProxyHost, window.__OO_STATE__, true, false, undefined, true, giaAuth, resources.clientQueryTimeoutMs));
  };
  return (
    <OOClientContext.Provider value={{ client: client, refreshClient: refreshClient }}>
      {props.children}
    </OOClientContext.Provider>
  );
};


export const OOClientProvider = withRouter<RequestContextProps & OOClientContextProps, React.ComponentType<RequestContextProps & OOClientContextProps>>(WrappedOOClientProvider);

export const useOOClient = () => {
  const context = useContext(OOClientContext);
  if(!context) {
    throw new Error('useOOClient must be used with an OOClientProvider');
  }

  return context.client;
};
export const useRefreshOOClient = () => {
  const context = useContext(OOClientContext);
  if(!context) {
    throw new Error('useRefreshOOClient must be used with an OOClientProvider');
  }

  return context.refreshClient;
};
