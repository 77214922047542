import React, { useState, useMemo } from 'react';

import { useEditor, FieldType } from '@toasttab/sites-components';
import classnames from 'classnames';
import { marked } from 'marked';
import sanitizeHtml from 'sanitize-html';

import { LoyaltyBanner, SpotlightConfig } from 'src/apollo/onlineOrdering';

import { useAlertModalContext } from 'shared/components/common/alert_modal/AlertModal';

import MiniScroll from 'public/components/default_template/mini_scroll/MiniScroll';


type SitesMessageProps = { message: string; }
type SpotlightMessageProps = { headerText: string; bodyText?: string | null; }

const SitesMessage = ({ message }: SitesMessageProps) => <div className="bannerMessage" dangerouslySetInnerHTML={{ __html: sanitizeHtml(marked.parse(message)) }} />;

const SpotlightMessage = ({ headerText, bodyText }: SpotlightMessageProps) => {
  const { openAlertModal } = useAlertModalContext();
  let more = null;
  if(bodyText) {
    const onClickMore = () => openAlertModal(bodyText);
    more = <span className="link-text" onClick={onClickMore}>More</span>;
  }
  // Note that the inner span is needed here to prevent unwanted line-wrap from parent flex box.
  return <div className="bannerMessage"><span>{headerText} {more}</span></div>;
};

export type BannerMessages = {
  sitesBanners: string[];
  loyaltyBanners: LoyaltyBanner[];
  spotlightConfig: SpotlightConfig | null;
}

type BannerProps = {
  messages: BannerMessages;
  className?: string;
}

const Banner = ({ messages, className }: BannerProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { useEditableRef } = useEditor();

  const shouldScroll = (amount: number) => setCurrentIndex(currentIndex + amount);

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'promotional banner',
    displayName: 'Promo Banner',
    path: 'content.banner',
    actions: messages.sitesBanners[0] ? ['delete'] : [],
    schema: {
      fields: [{
        displayName: 'Content',
        path: 'content.banner.content',
        type: FieldType.Text,
        value: messages.sitesBanners[0] || '',
        validation: { maxLen: 400 }
      }]
    }
  });

  const allMessages = useMemo(() => {
    const sites = messages.sitesBanners ? messages.sitesBanners.map((message, i) => <SitesMessage message={message} key={i} />) as JSX.Element[] : [];
    const loyalty = messages.loyaltyBanners
      ? messages.loyaltyBanners.map((banner: LoyaltyBanner) => <SitesMessage message={banner.bannerText} key={banner.bannerGuid} />) as JSX.Element[]
      : [];
    const spotlight = messages.spotlightConfig?.headerText
      ? [<SpotlightMessage headerText={messages.spotlightConfig.headerText} bodyText={messages.spotlightConfig.bodyText} key="spotlight" />] as JSX.Element[]
      : [];

    return [...sites, ...spotlight, ...loyalty];
  }, [messages]) as JSX.Element[];

  return (
    <div className={classnames('banner paddedSection')} ref={editableRef}>
      <div className={classnames('bannerContent', className)}>
        {allMessages[currentIndex]}
        <MiniScroll numPages={allMessages.length} currentIndex={currentIndex} shouldScroll={shouldScroll} />
      </div>
    </div>
  );
};

export default Banner;
