import React, { useCallback, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';

import classnames from 'classnames';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

import useTracker from 'src/lib/js/hooks/useTracker';

import PhoneInput from 'shared/components/common/form_input/PhoneInput';
import { ModalContent, useModalContext, ModalCloseButton } from 'shared/components/common/modal';

import { CompleteProfileForm } from 'public/components/default_template/online_ordering/account/completeProfileForm/CompleteProfileForm';
import { AuthenticationSource } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';
import { ConfirmCodeForm } from 'public/components/default_template/online_ordering/customer/ConfirmationCode';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';


type FormInputs = {
  yourInfoPhone: string;
}
export const PHONE_FOCUS_MSG = 'By providing a mobile number, you give Toast permission to contact you using automated text messages to provide transactional messages such as order status updates.';

export const PwlessAuth = ({ checkingOut = false, onClickCheckoutAsGuest, source }: {checkingOut?: boolean, onClickCheckoutAsGuest?: () => void, source: AuthenticationSource}) => {
  const { onClose } = useModalContext();
  const [needsSignup, setNeedsSignup] = useState(false);
  const formMethods = useForm<FormInputs>({
    mode: 'onTouched',
    defaultValues: { yourInfoPhone: '' }
  });
  const phoneNumber = useWatch({ name: 'yourInfoPhone', control: formMethods.control });
  const { customer, refetchCustomer, passwordlessLogin, fetchCustomer } = useCustomer();
  const [sentCode, setSentCode] = useState(false);
  const [error, setError] = useState('');
  const tracker = useTracker();
  const validatePhone = useCallback((value: string) => isValidPhoneNumber(value, 'US') || 'enter a valid phone number', []);

  const onSubmitPhone = useCallback(async () => {
    if(phoneNumber && isValidPhoneNumber(phoneNumber, 'US')) {
      setSentCode(true);
      tracker.track('Clicked Send code', { source });

      if(!await passwordlessLogin(phoneNumber)) {
        setError('Error sending confirmation code');
        setSentCode(false);
      } else {
        setError('');
      }
    }
  }, [passwordlessLogin, phoneNumber, tracker, source]);

  const onSuccessfulAccountCreation = useCallback(() => {
    onClose();
    toast.success(customer?.firstName ? `Welcome, ${customer?.firstName}!` : 'Welcome!');
  },
  [customer, onClose]);


  const onLogin = useCallback(async () => {
    const fetchedCustomer = await fetchCustomer();
    if(!fetchedCustomer) {
      setNeedsSignup(true);
    } else {
      refetchCustomer();
      onClose();
      toast.success(customer?.firstName ? `Welcome back, ${customer?.firstName}!` : 'Welcome back!');
      tracker.track('Completed 2FA', { source, newOrExistingAccount: fetchedCustomer ? 'existing account' : 'new account' });
    }
  }, [customer, fetchCustomer, onClose, refetchCustomer, tracker, source]);

  const handlePhoneInputKeydown = (event: React.KeyboardEvent) => {
    if(event.key === 'Enter') {
      onSubmitPhone();
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const headerId = 'pwless-auth-modal-header';

  return (
    <ModalContent contentClassName="pwlessModalContent" slideIn slideOut data-testid="PwlessAuth" ariaLabelledBy={headerId}>
      <div className="modalHeader" data-testid="PwlessAuth">
        <h2 className="header" id={headerId}>{needsSignup ? 'Complete your profile' : sentCode ? 'Confirm it\'s you' : 'Enter your mobile number'}</h2>
        <ModalCloseButton />
      </div>
      {needsSignup
        ? <CompleteProfileForm phoneNumber={phoneNumber} setHasSubmitted={onSuccessfulAccountCreation} checkingOut={checkingOut} />
        :
        <FormProvider {...formMethods}>
          <div className="body">
            {!sentCode ?
              <>
                <p>If you don&apos;t have a Toast account, we will help you create one.</p>
                <PhoneInput
                  id="yourInfoPhone"
                  required
                  label="Phone number"
                  validate={validatePhone}
                  autoComplete="tel"
                  defaultValue={customer?.phone || ''}
                  warning={error}
                  focusMessage={PHONE_FOCUS_MSG}
                  filled={Boolean(customer?.phone)}
                  disabled={needsSignup}
                  unregisterOnUnmount={false}
                  onKeyDown={handlePhoneInputKeydown}
                  shouldUnFocus={false}
                  button={
                    <button className={classnames('inputButton', !sentCode && phoneNumber && isValidPhoneNumber(phoneNumber, 'US') && 'active')}
                      onClick={onSubmitPhone} onKeyDown={handlePhoneInputKeydown} data-testid="auth-send-code">Send code
                    </button>
                  } />
              </> :
              <>
                <p>{`Enter the code sent to ${parsePhoneNumber(phoneNumber, 'US').formatNational()} to confirm it's you.`}</p>
                <ConfirmCodeForm phoneNumber={`+1${phoneNumber}`} newAccount={false} onComplete={onLogin} isCheckout={false} />
                <div className="checkoutMode">
                  <button className="guestCheckout" onClick={onSubmitPhone}>
                    <div className="text">Resend code</div>
                  </button>
                </div>
              </>}
          </div>
        </FormProvider>}
      {checkingOut &&
        <div
          tabIndex={0}
          role="button"
          className="guestCheckoutLink"
          onClick={() => { onClose(); onClickCheckoutAsGuest?.(); }}
          onKeyDown={e => {
            if(e.key === 'Enter') {
              e.stopPropagation();
              e.preventDefault(); onClose(); onClickCheckoutAsGuest?.();
            }
          }}>
          {needsSignup ? 'Not now, checkout as a guest' : 'Checkout as a guest'}
        </div>}
    </ModalContent>
  );
};
