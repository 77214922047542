import { authClient, isProfileCreated } from '../initialization'

/**
 * Returns the authentication header key / value pair to be attached to requests
 * in order to fetch guest profile data, or null if there is no authentication token.
 *
 * If the token has expired, this function will refresh the token and return the new token,
 * or null if it failed to refresh.
 *
 * If the guest does not have a verified profile in guest-profiles service, this returns null.
 *
 * If null is returned, depending on the use case, the consuming application should consider
 * logging the guest out, as requests that require a guest authentication token will not work.
 */
export async function getAuthenticationHeader() {
  if (!isProfileCreated(authClient)) {
    return null
  }

  return authClient.getAuthHeader()
}
