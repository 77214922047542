import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import ColorContrastChecker from 'color-contrast-checker';

import { ButtonType, SpotlightBanner as SpotlightBannerType, SpotlightBannerContent, BannerPlacement } from 'src/apollo/sites';
import Image from 'src/shared/components/common/Image';
import Link from 'src/shared/components/common/link';

import Button from 'shared/components/common/button';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { useScreenWidth } from 'shared/js/utils/WindowContext';

import { getFontFaceAndFamily, GOOGLE_FONTS } from 'public/components/default_template/meta/StyleMeta';

import { useSpotlightContext } from './SpotlightContext';

const FOUR_SECONDS_MS = 4000;

type Props = {
  placement: BannerPlacement;
  fixed?: boolean | 'both';
  style?: React.CSSProperties;
}

type WrappedProps = Props & {
  route: string
}

// Don't show if
// 1. Is not enabled or has no contents
// 2. Is set to show in a different placement than this instance
// 3. Is on a page that is not supported
export const showSpotlight = (spotlightInstance: Props, configuredSpotlight: SpotlightBannerType, currentRootPath: string) => {
  return (
    configuredSpotlight?.enabled
    && configuredSpotlight.contents?.length
    && spotlightInstance.placement === configuredSpotlight.placement
    && (spotlightInstance.fixed === 'both' || spotlightInstance.fixed === configuredSpotlight.fixed)
    && (!configuredSpotlight?.applicablePages || configuredSpotlight.applicablePages.includes(currentRootPath))
  );
};

const AnnouncementIcon = () =>
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* eslint-disable-next-line max-len */}
    <path d="M1.84089 3.36815V4.21033L19.5254 0.841797V2.52597L9.41996 4.21015H19.5254V12.6315L14.4573 11.6656C14.3024 14.0834 12.2981 16 9.84087 16C7.28334 16 5.20907 13.9258 5.20907 11.3682C5.20907 10.8714 5.28907 10.3948 5.43304 9.94679L1.8407 9.26305V10.1052H0.998522V3.36842L1.84089 3.36815ZM6.4727 11.3686C6.4727 13.2255 7.98436 14.7371 9.84123 14.7371C11.6787 14.7371 13.171 13.2567 13.2038 11.4276L6.6975 10.1872C6.55846 10.5559 6.47263 10.9525 6.47263 11.3686L6.4727 11.3686Z" fill="currentColor" />
  </svg>;

const SpotlightBannerContents = (
  { description, additionalDetails, link, color, onModalOpen, onModalClose }:
  SpotlightBannerContent & { color: string; onModalOpen: () => void; onModalClose: () => void }
) => {
  const { isOpen, onOpen, onClose } = useModal();
  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const width = useScreenWidth();

  const truncatedDescription = useMemo(
    () => descriptionRef.current ? descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight : false,
    // recalculate when the window width changes to check if the description has been truncated
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [descriptionRef?.current?.scrollHeight, width]
  );

  return (
    <>
      <span className="icon"><AnnouncementIcon /></span>
      <span className="description" ref={descriptionRef}>{link ? <Link style={{ color }} data-testid="spotlightLink" href={link}>{description}</Link> : description}</span>
      {truncatedDescription || additionalDetails
        ? <Button variant={ButtonType.Text} onClick={() => { onOpen(); onModalOpen(); }} style={{ color }}>More</Button>
        : null}
      <Modal isOpen={isOpen} onClose={() => {
        onClose();
        onModalClose();
      }}>
        <ModalOverlay />
        <ModalContent>
          <div className="spotlightModal">
            <div className="header">
              <ModalCloseButton />
            </div>
            <div className="description">
              {description}
            </div>
            <div className="additionalDetails">
              {additionalDetails}
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

const WrappedSpotlightBanner = (props: WrappedProps) => {
  const { useEditableRef } = useEditor();
  const { restaurant } = useRestaurant();
  const [index, setIndex] = useState(0);
  const intervalRef = useRef<NodeJS.Timer>();
  const { isHidden, hide } = useSpotlightContext();

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'spotlight banner',
    displayName: 'Banner',
    path: 'content.spotlightBanner',
    actions: ['delete'],
    schema: { fields: [] }
  });

  const spotlight = restaurant.content?.spotlightBanner;
  const contents = spotlight?.contents;
  const currentRootPath = `/${props.route.split('/')[1]}`;

  const pauseRotating = useCallback(() => intervalRef.current && clearInterval(intervalRef.current), []);

  const startRotating = useCallback(() => {
    if(contents?.length) {
      intervalRef.current = setInterval(() => {
        setIndex(currIndex => (currIndex + 1) % contents.length);
      }, FOUR_SECONDS_MS);
    }
  }, [contents]);

  // Rotates the displayed contents
  useEffect(() => {
    startRotating();

    return pauseRotating;
  }, [startRotating, pauseRotating]);

  if(!contents || !showSpotlight({ ...props }, spotlight, currentRootPath)) {
    return null;
  }

  // We know this will be present because of our mod in `startRotating` and the condition above
  const currentContent = contents[index]!;

  const contrastChecker = new ColorContrastChecker();

  const { backgroundColor } = spotlight;
  const textColor = ['#FFFFFF', '#000000'].filter(color => contrastChecker.isLevelAA(backgroundColor, color))[0] || '#FFFFFF';
  const font = spotlight.font ? getFontFaceAndFamily(spotlight.font) : undefined;
  const fontLink = font && typeof font !== 'string' && font.fontFamily && GOOGLE_FONTS.includes(font.fontFamily)
    ? <link href={`https://fonts.googleapis.com/css?family=${font.fontFamily}:wght@400;600;700&display=swap`} rel="stylesheet" />
    : null;
  const button = spotlight.fixed ?
    <Button aria-label="Hide banner" data-testid="spotlightHideButton" variant={ButtonType.Text} className="hideButton" onClick={hide}><Image src="icons/close.svg" /></Button>
    : null;

  return (
    <>
      {font ?
        <Helmet>
          {fontLink}
          <style type="text/css">
            {`${font.fontFace}`}
          </style>
        </Helmet>
        : null}
      <h5
        ref={editableRef}
        hidden={isHidden}
        data-testid="spotlightBanner"
        className={classnames(
          'spotlightBanner',
          {
            fixedTop: spotlight.fixed && spotlight.placement === BannerPlacement.Top,
            fixedBottom: spotlight.placement === BannerPlacement.Bottom,
            underNav: spotlight.placement === BannerPlacement.UnderNav,
            underHeader: spotlight.placement === BannerPlacement.UnderHeader,
            isHidden
          }
        )}
        style={{ ...props.style, backgroundColor: backgroundColor || restaurant.meta.primaryColor, color: textColor, fontFamily: font?.fontFamily }}>
        <div className="contentWrapper">
          <SpotlightBannerContents {...currentContent} color={textColor} onModalOpen={pauseRotating} onModalClose={startRotating} />
          {button}
        </div>
      </h5>
    </>
  );
};

const EditorSpotlightBanner = (props: Props) => {
  const { url } = useEditor();

  return <WrappedSpotlightBanner {...props} route={url} />;
};

const SitesSpotlightBanner = (props: Props) => {
  const { pathname } = useLocation();

  return <WrappedSpotlightBanner {...props} route={pathname} />;
};

const SpotlightBanner = (props: Props) => {
  const { isEditor } = useEditor();

  return isEditor ? <EditorSpotlightBanner {...props} /> : <SitesSpotlightBanner {...props} />;
};

export default SpotlightBanner;
