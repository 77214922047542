import React from 'react';

import classnames from 'classnames';

import LoadingSpinner, { LoadingSpinner as LoadingSpinnerWithColor } from './LoadingSpinner';

type Props = {
  withBorderRadius?: boolean;
  fullScreen?: boolean;
  color?: string;
}

const LoadingSpinnerOverlay = (props: Props) => {
  return (
    <div className={classnames('loadingSpinnerOverlay', { withBorderRadius: props.withBorderRadius, fullScreen: props.fullScreen })}>
      {props.color ? <LoadingSpinnerWithColor color={props.color} /> : <LoadingSpinner />}
    </div>
  );
};

export default LoadingSpinnerOverlay;
