import React from 'react';

import { CtaSubMenuType, CtaType, Maybe } from 'src/apollo/sites';
import { formatAddress } from 'src/shared/components/common/form_input/LocationInput';

import Link, { isToastTabLink } from 'shared/components/common/link';
import { RestaurantConfig, RestaurantLocation } from 'shared/components/common/restaurant_context/RestaurantContext';
import PrimaryCTA, { PrimaryCTAClasses } from 'shared/components/primary_cta/PrimaryCTA';

import CTAItem from './CTAItem';

export type CTAData = {
  type: CtaType | 'customAction';
  text: string;
  nav?: boolean | null;
  link?: string | null;
  inMoreMenu?: boolean | null;
  submenus?: Maybe<Array<Maybe<CtaSubMenuType>>>;
  onClick?: () => void;
  icon?: JSX.Element;
}

export type CTA = {
  type: CtaType | 'customAction';
  text: string;
  link?: string;
  onClick?: () => void;
  inMoreMenu?: boolean | null;
  submenus?: CTA[];
  utm_campaign?: string;
  icon?: JSX.Element;
}

export type AdditionalCTAData = {
  restaurantConfig?: RestaurantConfig;
  phoneNumber?: string | null;
  orderingUrl?: string | null;
  reservationsUrl?: string | null;
  updateLocation?: (guid: string) => void;
  locations?: RestaurantLocation[] | null;
  selectedLocation?: RestaurantLocation;
  reservationOnOpen?: () => void;
  giftcardsUrl?: string | null;
}


const toastTabLinkCampaign = (urlStr: string): string | undefined => {
  // Discover an appropriate utm_campaign for the given link.
  if(isToastTabLink(urlStr)) {
    const url = new URL(urlStr);
    const simpleURL = url.hostname + url.pathname;
    if(/(booking|bk)\.toasttab\.com/gm.test(simpleURL)) {
      // See https://regex101.com/r/oCEUIc/2 for regex description
      return 'reservations';
    } else if(/^(www\.)?toasttab\.com\/.*\/rewards/gm.test(simpleURL)) {
      // See https://regex101.com/r/dCwjQ4/2 for regex description
      return 'rewards';
    } else if(/^(www\.)?toasttab\.com\/.*\/giftcards/gm.test(simpleURL)) {
      // See https://regex101.com/r/l8ea5a/1 for regex description
      return 'giftcards';
    }
  }
  return undefined;
};

const MAX_NAV_LOCATIONS = 5;

export const getCtaContent = (data: CTAData, additionalData?: AdditionalCTAData): CTA | null => {
  switch(data.type) {
    case 'phone':
      return additionalData?.phoneNumber
        ? {
          type: CtaType.Phone,
          text: data.text,
          link: `tel:${additionalData.phoneNumber}`,
          inMoreMenu: data.inMoreMenu
        }
        : null;
    case 'submenu':
      return {
        type: CtaType.Submenu,
        text: data.text,
        inMoreMenu: data.inMoreMenu,
        submenus: data.submenus?.map(cta => cta && getCtaContent(cta, additionalData)).filter(Boolean) as CTA[]
      };
    case 'menu': {
      const locationsWithShortUrl = additionalData?.locations?.filter(loc => loc.shortUrl) || [];
      return {
        type: CtaType.Menu,
        text: data.text,
        link: locationsWithShortUrl.length > 1 ? '' : '/menu',
        utm_campaign: 'menu',
        inMoreMenu: data.inMoreMenu,
        submenus: locationsWithShortUrl.length > 1 ?
          locationsWithShortUrl.map(location => ({
            type: CtaType.Menu,
            text: formatAddress(location),
            onClick: additionalData?.updateLocation !== undefined ? () => additionalData.updateLocation!(location.externalId) : undefined,
            link: `/menu/${location.shortUrl}`
          })) :
          undefined
      };
    }
    case 'order':
      return additionalData?.restaurantConfig?.isOnlineOrderingEnabled || additionalData?.orderingUrl
        ? {
          type: CtaType.Order,
          text: data.text,
          link: additionalData?.restaurantConfig?.isOnlineOrderingEnabled
            ? additionalData?.selectedLocation?.shortUrl ? `/order/${additionalData?.selectedLocation?.shortUrl}` : '/order'
            : additionalData?.orderingUrl || '',
          utm_campaign: 'order_online',
          inMoreMenu: data.inMoreMenu,
          // if we have multiple locations and either don't have OO enabled or have too few, show submenus
          submenus: (additionalData?.locations?.length || 0) > 1 && (!additionalData?.restaurantConfig?.isOnlineOrderingEnabled || (additionalData?.locations?.length || 0) < MAX_NAV_LOCATIONS) ?
            additionalData?.locations?.map(location => ({
              type: CtaType.Order,
              text: formatAddress(location),
              onClick: additionalData?.updateLocation !== undefined ? () => additionalData.updateLocation!(location.externalId) : undefined,
              link: additionalData?.restaurantConfig?.isOnlineOrderingEnabled && location.shortUrl ? `/order/${location.shortUrl}` : location.onlineOrderingUrl || ''
            })) :
            undefined
        }
        : null;
    case 'reservations':
      return additionalData?.reservationsUrl
        ? {
          type: CtaType.Reservations,
          text: data.text,
          link: additionalData.reservationsUrl,
          utm_campaign: 'reservations',
          inMoreMenu: data.inMoreMenu,
          submenus: (additionalData?.locations?.length || 0) > 1 ?
            additionalData?.locations?.map(location => ({
              type: CtaType.Reservations,
              text: formatAddress(location),
              link: location.reservationsUrl || ''
            })) :
            undefined
        }
        : null;
    case 'reservationsModal':
      return additionalData?.reservationOnOpen
        ? {
          type: CtaType.ReservationsModal,
          text: data.text,
          inMoreMenu: data.inMoreMenu,
          onClick: additionalData.reservationOnOpen,
          utm_campaign: 'reservations'
        }
        : null;
    case 'link':
      return data.link
        ? {
          type: CtaType.Link,
          text: data.text,
          link: data.link,
          inMoreMenu: data.inMoreMenu,
          utm_campaign: toastTabLinkCampaign(data.link),
          icon: data.icon
        }
        : null;
    case 'giftCard':
      return additionalData?.giftcardsUrl
        ? {
          type: CtaType.GiftCard,
          text: data.text,
          link: additionalData.giftcardsUrl,
          inMoreMenu: data.inMoreMenu,
          utm_campaign: toastTabLinkCampaign(additionalData.giftcardsUrl)
        }
        : null;
    case 'customAction':
      return {
        type: 'customAction',
        text: data.text,
        inMoreMenu: data.inMoreMenu,
        onClick: data.onClick,
        utm_campaign: data.text,
        icon: data.icon
      };
    default:
      return null;
  }
};

type Props = {
  primaryCta?: CTAData | null;
  nonPrimaryCtas?: CTAData[];
} & AdditionalCTAData;

const CTAs = (props: Props) => {
  const { primaryCta, nonPrimaryCtas } = props;
  if(!nonPrimaryCtas?.length && !primaryCta) {
    return null;
  }

  const primaryCtaContent = primaryCta ? getCtaContent(primaryCta, props) : null;

  return (
    <div className="coreCtas">
      {primaryCtaContent &&
        <CTAItem cta={primaryCtaContent} isPrimary className={PrimaryCTAClasses} leftAligned>
          <PrimaryCTA href={primaryCtaContent.link} text={primaryCtaContent.text} />
        </CTAItem>}
      {nonPrimaryCtas?.length ?
        nonPrimaryCtas.map(cta => getCtaContent(cta, props))
          .filter(Boolean)
          .map((cta: CTA, i: number) =>
            <div className="secondaryCta" key={`cta${i}`}>
              <CTAItem cta={cta} className="cta" leftAligned>
                <Link className="cta" href={cta.link} >{cta.text}</Link>
              </CTAItem>
            </div>)
        : null}
    </div>
  );
};

export default CTAs;
