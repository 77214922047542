import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useEditor } from '@toasttab/sites-components';
import classNames from 'classnames';
import ColorContrastChecker from 'color-contrast-checker';
import copy from 'copy-to-clipboard';

import { ButtonType } from 'src/apollo/sites';

import Image from 'shared/components/common/Image';
import Button from 'shared/components/common/button';
import { usePopoverContext } from 'shared/components/common/popover/PopoverContext';
import { PromoBanners, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { GOOGLE_FONTS, getFontFaceAndFamily } from 'public/components/default_template/meta/StyleMeta';
import MiniScroll from 'public/components/default_template/mini_scroll/MiniScroll';
import { CART_POPOVER_CONTEXT_KEY } from 'public/components/default_template/online_ordering/cart/CartPopover';
import { useCart } from 'public/components/online_ordering/CartContext';


type Props = {
  ooPromoBanners?: PromoBanners | null;
};

const Promo = ({ message, code, color }: { message: string; code: string, color: string }) => {
  const { applyPromoCode, cartGuid, loadingCart } = useCart();
  const context = usePopoverContext(CART_POPOVER_CONTEXT_KEY);

  const timeoutKey = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [copyMessageVisible, setCopyMessageVisible] = useState(false);

  // Clean up the timeout call on dismount, if needed.
  useEffect(() => {
    return () => {
      if(timeoutKey.current) {
        clearTimeout(timeoutKey.current);
      }
    };
  }, []);

  const onClickCode = () => {
    copy(code);
    setCopyMessageVisible(true);
    timeoutKey.current = setTimeout(() => {
      timeoutKey.current = null;
      setCopyMessageVisible(false);
    }, 2000);
  };

  return (
    <div className="bannerMessage">
      {message}
      <div className="codeContent">
        <span className="promoCode" onClick={onClickCode}>
          <div className="code">{code}</div>
          <div className={classNames('copyMessage', { show: copyMessageVisible })}>Copied!</div>
        </span>
        {cartGuid ?
          <Button disabled={loadingCart} variant={ButtonType.Text} style={{ color }} onClick={async () => {
            await applyPromoCode(code);
            context?.open();
          }}>
            Apply code
          </Button>
          : null}
      </div>
    </div>
  );
};

const PromoBanner = (props: Props) => {
  const { restaurant } = useRestaurant();
  const { useEditableRef } = useEditor();
  const { cart } = useCart();
  const [currentIndex, setCurrentIndex] = useState(0);

  const shouldScroll = (amount: number) => setCurrentIndex(currentIndex + amount);

  const promoCodeBanners = props.ooPromoBanners?.promoCodeBanners;
  const promoCodeConfig = restaurant.ooConfig?.promoCodeConfig;

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'promo codes',
    displayName: 'Promo Banner',
    actions: [],
    path: 'config.ooConfig.promoCodeConfig',
    schema: { fields: [] }
  });

  if(!promoCodeBanners?.length) {
    return null;
  }

  const contrastChecker = new ColorContrastChecker();

  const backgroundColor = promoCodeConfig?.backgroundColor || '#F7F7F7';
  const textColor = ['#FFFFFF', '#000000'].filter(color => contrastChecker.isLevelAA(backgroundColor, color))[0] || '#FFFFFF';
  const font = promoCodeConfig?.font ? getFontFaceAndFamily(promoCodeConfig.font) : undefined;
  const fontLink = font && typeof font !== 'string' && font.fontFamily && GOOGLE_FONTS.includes(font.fontFamily)
    ? <link href={`https://fonts.googleapis.com/css?family=${font.fontFamily}:wght@400;600;700&display=swap`} rel="stylesheet" />
    : null;

  const appliedDiscountCode = cart?.order?.discounts?.restaurantDiscount?.promoCode;
  const allPages = promoCodeBanners
    .filter(banner => banner.promoCode !== appliedDiscountCode)
    .map(banner => <Promo key={banner.bannerGuid} message={banner.bannerText} code={banner.promoCode} color={textColor} />);

  if(!allPages.length) {
    return null;
  }

  return (
    <>
      {font ?
        <Helmet>
          {fontLink}
          <style type="text/css">
            {`${font.fontFace}`}
          </style>
        </Helmet>
        : null}
      <div className="promoCodeBanner" style={{ backgroundColor, color: textColor, fontFamily: font?.fontFamily }} ref={editableRef} data-testid="promoCodeBanner">
        <div className="textContent">
          <Image src="icons/tag.svg" />
          {allPages[currentIndex]}
        </div>
        <MiniScroll shouldScroll={shouldScroll} numPages={allPages.length} currentIndex={currentIndex} />
      </div>
    </>
  );
};

export default PromoBanner;
