import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classnames from 'classnames';
import convert from 'convert-units';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { computeDistanceBetween } from 'spherical-geometry-js';

import { DeliveryInfoInput, DiningOptionBehavior, useRestaurantSchedulesQuery } from 'src/apollo/onlineOrdering';
import PlacesAutocomplete, { GMAPS_PLACERESULT_FIELDS } from 'src/shared/components/common/location_search/PlacesAutocomplete';

import Image from 'shared/components/common/Image';
import LocationMap from 'shared/components/common/location_map/LocationMap';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import Toggle from 'shared/components/common/toggle/Toggle';
import useWindowMessage from 'shared/js/hooks/useWindowMessage';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import { MapMessage } from 'public/components/google_maps/messages';
import { saveDeliveryInfo } from 'public/components/online_ordering/DeliveryContext';
import ToastProduct from 'public/components/online_ordering/ToastProduct';
import { normalizeGoogleAddress } from 'public/components/online_ordering/addressUtils';
import { schedulesByGuid } from 'public/components/online_ordering/scheduleUtils';
import { ScheduleType } from 'public/components/online_ordering/types';
import { StreetGridIcon } from 'public/components/pages/location_selection_page/LocationSelectionIcons';

import { gPlacesAPIKey, server } from 'config';

import LocationOption, { getAvailableBehaviors } from './LocationOption';


export const MAP_SOURCE_ID = 'LocationMap';

type BasicProps = {
  diningOptionBehavior: DiningOptionBehavior;
  updateDiningBehavior: any;
  view: View;
  allowMap: boolean;
};

type FullMapPickerProps = {
  locationRefs?: {[_: string]: RefObject<HTMLDivElement>};
  rootRef?: RefObject<HTMLDivElement>;
  onSelect?: (guid?: string) => void;
  selectedLocationGuid?: string;
}

export enum View {
  Map = 'Map',
  List = 'List'
}

const LocationPickerWithMap = ({ diningOptionBehavior, updateDiningBehavior, view, allowMap }: BasicProps) => {
  const [selectedLocationGuid, setSelectedLocationGuid] = useState<string>();
  const { locations } = useRestaurant();

  const rootRef = useRef<HTMLDivElement>(null);
  const locationRefs = useMemo(() => locations?.reduce((map, loc) => ({
    ...map,
    [loc.externalId]: React.createRef<HTMLDivElement>()
  }), {} as { [key: string]: React.RefObject<HTMLDivElement> }) || {}, [locations]);

  const messageHandlers = useMemo(() => ({
    [MapMessage.SELECTED_PIN]: (data: { guid: string }) => {
      const locRef = locationRefs[data.guid];

      if(locRef?.current) {
        rootRef?.current?.scrollTo({ top: locRef.current?.offsetTop, behavior: 'smooth' });
      }

      setSelectedLocationGuid(data.guid);
    }
  }), [setSelectedLocationGuid, rootRef, locationRefs]);

  useWindowMessage({ sourceId: MAP_SOURCE_ID, origin: `${server.protocol}://${server.fullHost}`, messageHandlers });

  const onSelect = useCallback((guid?: string) => {
    setSelectedLocationGuid(guid);
    if(guid) {
      for(let i = 0; i < window.frames.length; i++) {
        window.frames[i]?.postMessage(JSON.stringify({
          name: MapMessage.SELECTED_GUID,
          data: { sourceId: MAP_SOURCE_ID, guid }
        }), `${server.protocol}://${server.fullHost}`);
      }
    }
  }, [setSelectedLocationGuid]);

  return (
    <BasicLocationPicker
      diningOptionBehavior={diningOptionBehavior}
      updateDiningBehavior={updateDiningBehavior}
      locationRefs={locationRefs}
      rootRef={rootRef}
      onSelect={onSelect}
      selectedLocationGuid={selectedLocationGuid}
      view={view}
      allowMap={allowMap} />
  );
};

export const BasicLocationPicker = ({ diningOptionBehavior, updateDiningBehavior, locationRefs, rootRef, onSelect, selectedLocationGuid, view, allowMap }: FullMapPickerProps & BasicProps) => {
  const { restaurant: { externalId: restaurantGuid, meta, name }, locations, toastProduct } = useRestaurant();
  const { tdsDeliveryProvidersApiV2SitesWeb } = useFlags();

  const [customerLocation, setCustomerLocation] = useState<google.maps.places.PlaceResult | null>(null);
  const [deliveryInfo, setDeliveryInfo] = useState<DeliveryInfoInput | null | undefined>(null);

  const setAndStoreCustomerLocation = useCallback((location: google.maps.places.PlaceResult | null) => {
    setCustomerLocation(location);

    const deliveryInfo = location ? normalizeGoogleAddress(location) : null;
    setDeliveryInfo(deliveryInfo);
    saveDeliveryInfo(deliveryInfo, restaurantGuid);
  }, [restaurantGuid]);

  const locationGuids = useMemo(() => locations?.map(loc => loc.externalId) || [], [locations]);

  const { data: scheduleData } = useRestaurantSchedulesQuery({
    variables: { restaurantGuids: locationGuids },
    skip: !locationGuids.length
  });

  const locationSchedules = useMemo(() => schedulesByGuid(scheduleData), [scheduleData]) as { [key: string]: ScheduleType };
  const hasDelivery = Object.values(locationSchedules).some(loc => {
    const behaviorSet = getAvailableBehaviors(loc, tdsDeliveryProvidersApiV2SitesWeb);

    return behaviorSet.has(DiningOptionBehavior.Delivery);
  });

  useEffect(() => {
    if(!hasDelivery && diningOptionBehavior === DiningOptionBehavior.Delivery) {
      updateDiningBehavior(DiningOptionBehavior.TakeOut);
    }
  }, [hasDelivery, diningOptionBehavior, updateDiningBehavior]);

  const onToggle = useCallback((value: DiningOptionBehavior) => updateDiningBehavior(value), [updateDiningBehavior]);
  const toggleValue = useMemo(() => diningOptionBehavior === DiningOptionBehavior.Delivery ? 'left' : 'right', [diningOptionBehavior]);

  const locationsWithDistance = useMemo(() => locations?.map(loc => {
    const distanceMeters = customerLocation?.geometry?.location ?
      computeDistanceBetween({ lat: loc.lat, long: loc.long }, { lat: customerLocation.geometry.location.lat(), long: customerLocation.geometry.location.lng() })
      : null;

    return {
      ...loc,
      distanceToCustomer: distanceMeters
        ? convert(distanceMeters).from('m')
          .to('mi')
        : -1

    };
  }), [customerLocation, locations]);

  const showFullPicker = toastProduct === ToastProduct.Sites || toastProduct === ToastProduct.OOPro;

  return (
    <div className="locationSelectionWrapper">
      <div className="locationSelectionContent">
        <div className={classnames('optionsLocationSelector', { basic: !showFullPicker })} ref={rootRef}>
          <div className={classnames('topContent', { basic: !showFullPicker })}>
            <h3>Welcome to {name}!</h3>
            {hasDelivery ?
              <Toggle
                value={toggleValue}
                onChange={onToggle}
                left={{
                  name: 'Delivery',
                  value: DiningOptionBehavior.Delivery,
                  disabled: !hasDelivery
                }}
                right={{
                  name: 'Pickup',
                  value: DiningOptionBehavior.TakeOut
                }} />
              : null}
            <>
              <label htmlFor="basic-location-picker-address-input" className="header">Find your closest location</label>
              <div className="addressInput">
                <PlacesAutocomplete
                  id="basic-location-picker-address-input"
                  placeholder={`Enter ${diningOptionBehavior === DiningOptionBehavior.Delivery ? 'delivery' : 'pickup'} address`}
                  apiKey={gPlacesAPIKey}
                  placeDetailsFields={GMAPS_PLACERESULT_FIELDS}
                  onPlaceSelected={place => setAndStoreCustomerLocation(place)}
                  options={{ types: ['geocode'], fields: GMAPS_PLACERESULT_FIELDS }}
                  normalized
                  useCurrentLocation={showFullPicker} />
              </div>
            </>
          </div>
          <AnimatedSection className="resultSection" expanded={view === View.List && (diningOptionBehavior === DiningOptionBehavior.TakeOut || !!customerLocation)} testid="animated-results-section">
            {customerLocation || diningOptionBehavior !== DiningOptionBehavior.Delivery ?
              <div className="results">
                {locationsWithDistance?.sort((a, b) => {
                  if(a.distanceToCustomer >= 0 && b.distanceToCustomer >= 0) {
                    return a.distanceToCustomer - b.distanceToCustomer;
                  }

                  return (a.name || a.externalId).localeCompare(b.name || b.externalId);
                })
                  .map((loc, index) =>
                    <LocationOption
                      index={index}
                      ref={locationRefs?.[loc.externalId] || undefined}
                      deliveryInfo={deliveryInfo}
                      location={loc}
                      diningOptionBehavior={diningOptionBehavior}
                      key={loc.externalId}
                      onSelect={onSelect ?? (() => {})}
                      schedule={locationSchedules[loc.externalId] || undefined}
                      selected={loc.externalId === selectedLocationGuid} />)}
                {diningOptionBehavior === DiningOptionBehavior.Delivery ?
                  <div className="prompt ifFirstChild">
                    Sorry! We don&apos;t deliver to your area. Try pickup instead.
                  </div>
                  : null}
              </div> :
              <div className="results">
                <div className="prompt">
                  Search to find locations that deliver to you.
                </div>
                <StreetGridIcon color={meta.primaryColor} />
              </div>}
          </AnimatedSection>
          {allowMap &&
            <div className={classnames('map', { hiddenView: view === View.List })} data-testid="mobile-map-view">
              <LocationMap hideDefaultLocation sourceId={MAP_SOURCE_ID} markerStyle="locationDetails" selectedDiningOption={diningOptionBehavior} />
            </div>}
          <div className="poweredBy">
            <Image aria-hidden="true" src="icons/powered-by-toast.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationPickerWithMap;
