import React from 'react';

import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';

export const IncreaseQuantityIcon = ({ color = DEFAULT_COLORS.text }: {color?: string}) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <path d="M5.04999 1.05005V9.05005M9.04999 5.05005H1.04999" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;

export const DecreaseQuantityIcon = ({ color = DEFAULT_COLORS.text }: {color?: string}) =>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 2" fill="none">
    <path d="M9 1H1" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;
