import { utcToZonedTime } from 'date-fns-tz';
import upperFirst from 'lodash/upperFirst';

import { UpcomingSchedules, DailySchedule } from 'src/apollo/onlineOrdering';


const TIME_REGEX = new RegExp(/^([0-9]{1,2}):([0-9]{2}).*/);
const DECIMAL_RADIX = 10;

export type Day = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export const DAYS: Day[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

type Interval = {
  startTime: string;
  endTime: string;
  overnight?: boolean | null;
}

type Schedule = {
  days: string[];
  intervals?: Interval[] | null | undefined;
}[];


export type OpenInterval = {
  startTime: string;
  endTime: string;
  overnight?: boolean;
}


export type DaySchedule = {
  intervals: OpenInterval[];
  overrideDescription?: string;
}

export type FullSchedule = Record<Day, DaySchedule>;

const parseDecimal = (value: string) => parseInt(value, DECIMAL_RADIX);

const isBeforeOrSame = (hour1: number, min1: number, hour2: number, min2: number): boolean =>
  hour1 < hour2 || hour1 === hour2 && min1 <= min2;

const isAfterOrSame = (hour1: number, min1: number, hour2: number, min2: number): boolean =>
  hour1 > hour2 || hour1 === hour2 && min1 >= min2;

// checks if `time` is within `interval`
// If `startCurrentDay` is true, it doesn't consider overnight intervals to count as "today"
const _intervalContainsInternal = (interval: Interval, time: Date, startCurrentDay: boolean) => {
  const timeHour = time.getHours();
  const timeMin = time.getMinutes();

  const startMatch = TIME_REGEX.exec(interval.startTime);
  const endMatch = TIME_REGEX.exec(interval.endTime);

  const [, startHourStr, startMinStr] = startMatch || [];
  const [, endHourStr, endMinStr] = endMatch || [];

  if(!startHourStr || !startMinStr || !endHourStr || !endMinStr) {
    // can't parse the interval, no way to be sure whether we're in it
    return false;
  }

  const startHour = parseDecimal(startHourStr),
    startMin = parseDecimal(startMinStr),
    endHour = parseDecimal(endHourStr),
    endMin = parseDecimal(endMinStr);

  // crosses midnight
  if(endHour < startHour) {
    return startCurrentDay
      ? isBeforeOrSame(timeHour, timeMin, endHour, endMin)
      : isAfterOrSame(timeHour, timeMin, startHour, startMin);
  }

  return (!startCurrentDay || interval.overnight)
    && isBeforeOrSame(timeHour, timeMin, endHour, endMin) && isAfterOrSame(timeHour, timeMin, startHour, startMin);
};

// Checks if the current time in `timeZoneId` is within the interval. If `startCurrentDay` is true, it
// doesn't consider overnight intervals to count as "today"
export const intervalContainsCurrentTime = (interval: Interval, timeZoneId: string, startCurrentDay: boolean): boolean => {
  const nowRx = utcToZonedTime(new Date(Date.now()), timeZoneId);
  return _intervalContainsInternal(interval, nowRx, startCurrentDay) || false;
};

export const formatTime = (hour: string, min: string, withMinutes?: boolean): string => {
  const intHour = hour === '00' ? 12 : parseInt(hour);
  const meridian = hour === '00' || intHour < 12 ? 'AM' : 'PM';
  return `${intHour > 12 ? intHour - 12 : intHour}${min === '00' && !withMinutes ? '' : `:${min}`}${meridian}`;
};

export const formatTimeString = (time: string, withMinutes?: boolean): string => {
  const match = TIME_REGEX.exec(time);
  let startTime = '';
  if(match && match.length >= 3) {
    const [, hour, min] = match;
    startTime = formatTime(hour || '00', min || '00', withMinutes);
  }
  return startTime;
};

export const formatInterval = ({ startTime, endTime }: Interval, withMinutes?: boolean): Interval => {
  return {
    startTime: formatTimeString(startTime, withMinutes) ?? 'Open',
    endTime: formatTimeString(endTime, withMinutes) ?? 'Close'
  };
};

export const getDay = (date: Date, utc?: boolean): Day => {
  const dayIndex = utc ? date.getUTCDay() : date.getDay();
  return DAYS[dayIndex] as Day;
};

export const getToday = (timezoneId: string) => {
  const date = utcToZonedTime(new Date(), timezoneId);
  return getDay(date);
};

// EXPORTED FOR TESTING -- DO NOT USE
export const _isOpenInternal = (schedule: Schedule, timeZoneId: string, nowLocal: Date): boolean => {
  const nowRx = utcToZonedTime(nowLocal, timeZoneId);

  const todayIndex = nowRx.getDay();
  const todayStr = DAYS[todayIndex] as string;

  // because % is remainder, not mod
  const yesterdayIndex = ((todayIndex - 1) % DAYS.length + DAYS.length) % DAYS.length;
  const yesterdayStr = DAYS[yesterdayIndex] as string;

  const todaySchedule = schedule.find(group => group.days.includes(todayStr));
  if(todaySchedule?.intervals && todaySchedule.intervals.some(interval => _intervalContainsInternal(interval, nowRx, false))) {
    return true;
  }

  const yesterdaySchedule = schedule.find(group => group.days.includes(yesterdayStr));
  if(yesterdaySchedule?.intervals && yesterdaySchedule.intervals.some(interval => _intervalContainsInternal(interval, nowRx, true))) {
    return true;
  }

  return false;
};

// Given a schedule (from the sites database) and a timezone ID, determines if
// the the current time in the given timezone is "open" according to the schedule
export const isOpen = (schedule: Schedule, timeZoneId: string): boolean =>
  _isOpenInternal(schedule, timeZoneId, new Date(Date.now()));

// Returns true if the schedule passed is for today.
export const isTodaysSchedule = (schedule: DailySchedule | undefined, timeZoneId: string): boolean => {
  let nowRx: Date = utcToZonedTime(new Date(Date.now()), timeZoneId);
  const scheduleDate = schedule?.date ? new Date(schedule.date) : null;
  return scheduleDate?.getUTCDate() == nowRx.getUTCDate();
};

export const intervalContainsNow = (startTime: string, endTime: string, timeZoneId: string, overnight?: boolean): boolean => {
  let nowRx: Date = utcToZonedTime(new Date(Date.now()), timeZoneId);
  const interval: Interval = {
    startTime: startTime,
    endTime: endTime,
    overnight
  };
  return _intervalContainsInternal(interval, nowRx, false) || false;
};

// Given an upcoming schedule, if the restaurant is currently open, it will return the time it will close.
// If the restaurant is closed, it will return the empty string.
export const getNextCloseTime = (schedule: UpcomingSchedules | undefined, timeZoneId: string): string => {
  // Daily schedules is organized by next first.
  const nextDaySchedule = schedule?.dailySchedules[0];
  if(!isTodaysSchedule(nextDaySchedule, timeZoneId)) return '';
  for( const period of nextDaySchedule?.servicePeriods || []) {
    if(intervalContainsNow(period.startTime, period.endTime, timeZoneId)) {
      return period.endTime;
    }
  }
  return '';
};

// Returns a string saying what time the restaurant closes if it is open. If it is closed it will say so.
export const getTakingOrdersTillString = (isOpen: boolean, schedule: UpcomingSchedules | undefined, timeZoneId: string): string => {
  const closingTime = getNextCloseTime(schedule, timeZoneId);
  return isOpen ?
    closingTime ? `Taking orders till ${formatTimeString(closingTime)}` : 'Taking orders' :
    'Closed now';
};

export const getDayFromDate = (dateString: string): string => upperFirst(DAYS[new Date(dateString).getUTCDay()]) || '';

export const getCurrentScheduleInterval = (schedule: FullSchedule, timezoneId: string): OpenInterval | undefined => {
  const localTime = utcToZonedTime(new Date(), timezoneId);
  const today = getToday(timezoneId);

  const currInterval = schedule[today].intervals.find(interval => intervalContainsNow(interval.startTime, interval.endTime, timezoneId, interval.overnight));

  if(currInterval) {
    return currInterval;
  }

  // check if we are in an interval that started yesterday and went overnight.
  const yesterday = getDay(localTime);
  return schedule[yesterday].intervals.filter(interval => interval.overnight).find(interval => intervalContainsNow(interval.startTime, interval.endTime, timezoneId, interval.overnight));
};

export const getNextScheduleInterval = (schedule: FullSchedule, timezoneId: string): { interval: OpenInterval, day: Day } | undefined => {
  const localTime = utcToZonedTime(new Date(), timezoneId);
  const today = getToday(timezoneId);

  const nextIntervalToday = schedule[today].intervals.find(interval => interval.startTime > toTimeString(localTime, false, timezoneId));

  if(nextIntervalToday) {
    return {
      interval: nextIntervalToday,
      day: today
    };
  }

  for(let daysAfterToday = 1; daysAfterToday < 7; daysAfterToday++) {
    const nextDate = new Date(localTime);
    nextDate.setDate(nextDate.getDate() + daysAfterToday);

    const firstIntervalAfterToday = schedule[getDay(nextDate)].intervals[0];
    if(firstIntervalAfterToday) {
      return {
        interval: firstIntervalAfterToday,
        day: getDay(nextDate)
      };
    }
  }
  return undefined;
};

const toTimeString = (date: Date, hour12: boolean, timzoneId: string) => {
  return new Intl.DateTimeFormat('en-US', {
    timeStyle: 'short',
    hour12: hour12,
    timeZone: timzoneId
  }).format(date);
};

// convert the upcomingSchedules we get from the Consumer App BFF to the schedule format
// that we store in the Sites DB
export const ooScheduleToSitesSchedule = (upcomingSchedules?: Array<UpcomingSchedules>) => {
  // This maps a day of the week to a list of time intervals
  const scheduleMap = new Map();

  // An upcomingSchedule exists for each dining option behavior. This combines the service
  // periods for all dining option behaviors on the same day.
  upcomingSchedules?.forEach(s => {
    s.dailySchedules.forEach(dailySchedule => {
      const d = new Date(dailySchedule.date);
      const dayString = DAYS[d.getDay()];

      const dayIntervals = dailySchedule.servicePeriods.map(p => {
        const startMatch = TIME_REGEX.exec(p.startTime);
        const endMatch = TIME_REGEX.exec(p.endTime);
        const [, startHourStr, startMinStr] = startMatch || [];
        const [, endHourStr, endMinStr] = endMatch || [];
        const startHour = parseDecimal(startHourStr || ''),
          startMin = parseDecimal(startMinStr || ''),
          endHour = parseDecimal(endHourStr || ''),
          endMin = parseDecimal(endMinStr || '');

        return {
          startTime: p.startTime,
          endTime: p.endTime,
          overnight: isBeforeOrSame(endHour, endMin, startHour, startMin)
        };
      });

      // append this list of intervals to the current list for this day
      const currIntervals = scheduleMap.get(dayString);
      scheduleMap.set(dayString, [
        ...currIntervals || [],
        ...dayIntervals
      ]);
    });
  });

  // convert scheduleMap to an array
  const schedule = [] as Schedule;
  scheduleMap.forEach((intervals: Interval[], day: string) => schedule.push({ days: [day], intervals }));

  return schedule;
};
