import React, { useMemo } from 'react';

// eslint-disable-next-line camelcase
import { useRestaurantProfileQuery, RestaurantProfileQuery, MenuApiType, Menus_VisibilityEnum, MenusResponse } from 'src/apollo/onlineOrdering';
import { ImageType } from 'src/apollo/toastLocal';
import { formatImageURL } from 'src/lib/js/utilities';

import Image from 'shared/components/common/Image';
import UhOh from 'shared/components/uh_oh/UhOh';

import { OrderPageContent } from 'public/components/default_template/OrderPage';
import { getMenuItemSrcSet } from 'public/components/default_template/menu_section/MenuItemCard';

import TitleCard from './TitleCard';
import Attributes from './attributes/Attributes';
import Breadcrumbs from './breadcrumbs/Breadcrumbs';
import CuisinePills from './cuisine_pills/CuisinePills';
import Footer from './footer/Footer';
import { formatRXName } from './formatting';
import Gallery from './gallery/Gallery';
import HeaderImage from './header_image/HeaderImage';
import Hours from './hours/Hours';
import Location from './location/Location';
import Overview from './overview/Overview';
import { getPopularRestaurantsLink, getRestaurantCarouselList, getSimilarRestaurantsLink, TLRestaurantType } from './profileUtils';
import RXCarousel from './rx_carousel/RXCarousel';
import RXHotSpots from './rx_hotspots/RXHotSpots';

type RestaurantType = RestaurantProfileQuery['restaurantV2'] & { __typename: 'Restaurant' };

type Props = {
  rxGuid: string;
  shortUrl: string;
  tlRestaurant: TLRestaurantType;
  scrollNavRef: React.Ref<HTMLDivElement>;
  navOpen?: boolean;
  usingSecondaryNav?: boolean;
};

const RestaurantProfile = ({ rxGuid, shortUrl, tlRestaurant, scrollNavRef, navOpen, usingSecondaryNav }: Props) => {
  const { data, error, loading } = useRestaurantProfileQuery({
    variables: {
      restaurantGuid: rxGuid,
      menuInput: {
        shortUrl: shortUrl,
        restaurantGuid: rxGuid,
        menuApi: MenuApiType.Do,
        // eslint-disable-next-line camelcase
        visibility: Menus_VisibilityEnum.ToastOnlineOrdering
      }
    }
  });

  const restaurant = data?.restaurantV2 as RestaurantType | undefined;
  const attributesExist = useMemo(() => Boolean(tlRestaurant.ambiances?.length || tlRestaurant.amenities?.length), [tlRestaurant]);

  const menus = data?.menusV3 as MenusResponse | undefined;
  const menuItemImages = useMemo(() => {
    const uniqueMenuItemImages = new Set();

    return menus?.menus?.
      flatMap(m => m.groups)
      .flatMap(g => g?.items)
      .map(i => i?.imageUrls)
      .filter(images => {
        if(images?.raw) {
          const existed = images?.raw && uniqueMenuItemImages.has(images.raw);
          uniqueMenuItemImages.add(images.raw);
          return existed;
        }
        return false;
      });
  }, [menus]);


  // A list of popular restaurants in the same city.
  const similarNearbyRx = useMemo(
    () => getRestaurantCarouselList(tlRestaurant.similarNearbyRestaurants?.edges, tlRestaurant.name),
    [tlRestaurant.similarNearbyRestaurants?.edges, tlRestaurant.name]
  );

  // A list of popular restaurants in the same city.
  const location = tlRestaurant?.locations?.length ? tlRestaurant.locations[0] : null;
  const popularRx = useMemo(
    () => getRestaurantCarouselList(location?.tlCity?.popularRestaurants?.edges, tlRestaurant.name),
    [location, tlRestaurant.name]
  );

  if(loading) {
    return null;
  }

  if(error || !restaurant) {
    return <UhOh />;
  }

  return (
    <>
      <div className="paddedSection restaurantProfile">
        <Breadcrumbs restaurant={tlRestaurant} />
        <div className="lg-reverse">
          <HeaderImage
            id="rx_profile"
            rxName={formatRXName(restaurant.name || '')}
            location={restaurant.location}
            images={tlRestaurant.images}
            rxImageUrl={restaurant.imageUrl}>
            <a href="#rx-gallery" className="go-to-gallery-button">
              <Image src="/icons/gallery-icon.svg" />
              <span className="hidden-sm-down">View gallery</span>
            </a>
          </HeaderImage>
          <div className="rx-info">
            <div>
              <CuisinePills restaurant={tlRestaurant} />
              <div className="title-info">
                <h1 className="rx-page-section">{formatRXName(restaurant.name || '')}</h1>
              </div>
            </div>
            <div className="overview-container">
              <Overview
                avgRating={tlRestaurant.meta?.avg_rating}
                numRatings={tlRestaurant.meta?.num_ratings}
                priceDollars={tlRestaurant.meta?.price}
                hoursHref="#rx-hours"
                phone={restaurant.location?.phone}
                location={restaurant.location}
                giftCardHref={restaurant.giftCardLinks?.purchaseLink} />
            </div>
          </div>
        </div>
      </div>

      <OrderPageContent
        scrollNavRef={scrollNavRef}
        navOpen={navOpen}
        hideHero={true}
        usingSecondaryNav={usingSecondaryNav} />

      <div className="paddedSection rxPageSectionWrapper">
        <div><hr className="divider" /></div>
        <div className="rx-page-section rx-cards">
          {attributesExist &&
            <div className="attributes">
              <TitleCard title="Attributes and Amenities" defaultState="open">
                <Attributes ambiances={tlRestaurant.ambiances} amenities={tlRestaurant.amenities} className="rx-page-section" />
              </TitleCard>
            </div>}
          <div className="rx-hours" id="hours">
            <TitleCard title="All hours" defaultState="open">
              <Hours schedules={tlRestaurant?.schedules} />
            </TitleCard>
          </div>
          {(restaurant.description || tlRestaurant.links?.rxWebsiteURL) &&
            <div className="rx-description">
              <TitleCard title="Restaurant info" defaultState="open">
                {restaurant.description && <p>{restaurant.description}</p>}
                {tlRestaurant.links?.rxWebsiteURL && <p><a href={tlRestaurant.links.rxWebsiteURL} target="_blank" rel="noreferrer noopener">Website</a></p>}
              </TitleCard>
            </div>}
          {restaurant.location &&
            <div className="location">
              <TitleCard title="Location" defaultState="open">
                <Location location={restaurant.location} restaurantName={formatRXName(restaurant.name || '')} />
              </TitleCard>
            </div>}
        </div>
      </div>

      <div className="paddedSection rxPageSectionWrapper">
        <div><hr className="divider" /></div>
        <div className="rx-page-section rx-gallery" id="rx-gallery">
          <TitleCard title="Gallery" defaultState="open">
            <Gallery
              menuImages={
                menuItemImages?.map(images => ({
                  src: formatImageURL(images!.raw),
                  srcSet: getMenuItemSrcSet(images!)
                })) || []
              }
              rxImages={[tlRestaurant.images?.consumer, tlRestaurant.images?.banner, tlRestaurant.images?.bg, tlRestaurant.images?.main].filter(i => Boolean(i?.src)) as ImageType[]}
              rxName={restaurant.name} />
          </TitleCard>
        </div>
        <div><hr className="divider" /></div>
      </div>

      {similarNearbyRx.length ?
        <div className="paddedSection rxPageSectionWrapper">
          <RXCarousel
            title="Similar restaurants in your area"
            elements={similarNearbyRx}
            className="rx-page-section"
            elementClass="pure-u-12-24 pure-u-md-8-24 pure-u-lg-6-24"
            moreRxHref={getSimilarRestaurantsLink(tlRestaurant.cuisines && tlRestaurant.cuisines[0]?.id)}
            moreRxDescription="Search similar restaurants" />
        </div>
        : null}

      {popularRx.length ?
        <div className="paddedSection rxPageSectionWrapper">
          <RXCarousel
            title={`Popular restaurants in ${location?.city}`}
            elements={popularRx}
            className="rx-page-section"
            elementClass="pure-u-12-24 pure-u-md-8-24 pure-u-lg-6-24"
            moreRxHref={getPopularRestaurantsLink(location?.tlCity?.parentCity?.slug, location?.tlCity?.slug)}
            moreRxDescription="Search popular restaurants" />
        </div>
        : null}

      {location?.tlCity?.neighboring?.length ?
        <div className="paddedSection rxPageSectionWrapper">
          <RXHotSpots title={`More near ${location?.city}`}
            iconProps={{ src: '/icons/map-with-path.svg', alt: 'Map', height: 42 }}
            titlePos={'top'}
            elements={location?.tlCity.neighboring}
            elementClass="pure-u-12-24 pure-u-md-8-24" />
        </div>
        : null}

      <Footer />
    </>
  );
};


export default RestaurantProfile;
