import React, { useCallback } from 'react';

import PlacesAutocomplete, { GMAPS_PLACERESULT_FIELDS } from 'src/shared/components/common/location_search/PlacesAutocomplete';

import ErrorNotice from 'shared/components/common/error_notice';
import { RestaurantLocation } from 'shared/components/common/restaurant_context/RestaurantContext';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { normalizeGoogleAddress, formatDeliveryAddress } from 'public/components/online_ordering/addressUtils';

import { gPlacesAPIKey } from 'config';

type Props = {
  selectedLocation?: RestaurantLocation;
}

const DeliveryAddressInput = ({ selectedLocation }: Props) => {
  const { cart } = useCart();
  const { validDeliveryAddress, validateDeliveryAddress, deliveryValidationError } = useDelivery();

  const onPlaceSelected = useCallback((place: google.maps.places.PlaceResult) => {
    const normalizedAddress = normalizeGoogleAddress(place);
    if((cart?.restaurant || selectedLocation?.externalId) && normalizedAddress) {
      validateDeliveryAddress(normalizedAddress, cart?.restaurant?.guid || selectedLocation?.externalId);
    }
  }, [validateDeliveryAddress, cart?.restaurant, selectedLocation]);

  return (
    <>
      <div className="addressInput">
        <PlacesAutocomplete
          id="delivery-address-input-autocomplete"
          defaultValue={formatDeliveryAddress(cart?.order?.deliveryInfo || validDeliveryAddress, true)}
          placeholder="Enter your delivery address"
          apiKey={gPlacesAPIKey}
          placeDetailsFields={GMAPS_PLACERESULT_FIELDS}
          onPlaceSelected={onPlaceSelected}
          locationBias={selectedLocation?.lat && selectedLocation?.long ? { lat: selectedLocation.lat, long: selectedLocation.long } : undefined}
          options={{ types: ['geocode'], fields: GMAPS_PLACERESULT_FIELDS }} />
      </div>
      <AnimatedSection className="addressInputError" expanded={Boolean(deliveryValidationError)}>
        <div className="error"><ErrorNotice>{deliveryValidationError}</ErrorNotice></div>
      </AnimatedSection>
    </>
  );
};

export default DeliveryAddressInput;
