import React from 'react';

import classnames from 'classnames';

import { MenuFormatConfig, MenuTemplate } from 'src/apollo/sites';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import MenuGroupPills from 'public/components/default_template/menu_nav/MenuGroupPills';
import { RefMenu } from 'public/components/default_template/menu_nav/MenuNav';
import MenuTabs from 'public/components/default_template/menu_nav/MenuTabs';


type Props = {
  menus: RefMenu[];
  menuGroups: {
    guid: string;
    anchorRef: React.RefObject<HTMLDivElement>;
    value: string;
  }[];
  selectedMenuIndex: number;
  setSelectedMenuIndex: (index: number) => void;
  selectedGroupGuid?: string;
  setSelectedGroupGuid: (guid: string) => void;
  menuConfig?: MenuFormatConfig | null;
  navRef?: React.RefObject<HTMLDivElement>;
}

const LegacyMenuNav = (props: Props) => {
  const hasMenuNav = props.menus.length > 1 && !props.menuConfig?.hideMenuNav;
  const hasSubMenuNav = !props.menuConfig?.hideSubMenuNav;
  const { restaurant } = useRestaurant();

  if(!hasMenuNav && !hasSubMenuNav) return null;

  const hasHero = !!restaurant.config.ooConfig?.heroImage?.src;
  const classes = { twoItem: hasMenuNav && hasSubMenuNav, condensed: props.menuConfig?.template == MenuTemplate.Condensed };

  return (
    <div className="paddedContentWrapper">
      <div className={classnames('paddedContent', { withoutHero: !hasHero })}>
        <div className={classnames('menuNav', classes)} ref={props.navRef} role="navigation" data-testid="legacy-menu-nav">
          {hasMenuNav ? <MenuTabs menus={props.menus} selectedIndex={props.selectedMenuIndex} setSelectedIndex={props.setSelectedMenuIndex} menuConfig={props.menuConfig} /> : null}
          {hasSubMenuNav ?
            <MenuGroupPills options={props.menuGroups} selected={props.selectedGroupGuid} setSelected={props.setSelectedGroupGuid} menuConfig={props.menuConfig} />
            : null}
        </div>
      </div>
    </div>
  );
};

export default LegacyMenuNav;
