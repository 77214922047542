import React from 'react';

import upperFirst from 'lodash/upperFirst';

import { getRestaurantCountryCode } from 'src/shared/js/phoneUtils';

import PhoneLink from 'shared/components/common/link/PhoneLink';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import config from 'config';

const TIME_REGEX = new RegExp(/^0?([0-9]{1,2}):([0-9]{2}).*/);

type Interval = {
  startTime: string;
  endTime: string;
}

export type DaySchedule = {
  days: string[];
  intervals?: Interval[] | null;
}

type AddressProps = {
  name: string;
  address1: string;
  address2?: string | null;
  city: string;
  state: string;
  zipcode: string;
  alwaysShowMap: boolean;
}

type Props = {
  name: string;
  phoneNumber?: string | null;
  hours: DaySchedule[];
} & AddressProps;

const formatTime = (hour: string, min: string): string => {
  const intHour = hour === '0' ? 12 : parseInt(hour);
  const meridian = hour === '0' || intHour < 12 ? 'AM' : 'PM';
  return `${intHour > 12 ? intHour - 12 : intHour}${min === '00' ? '' : `:${min}`}${meridian}`;
};

const formatInterval = ({ startTime, endTime }: Interval): Interval => {
  const res: Interval = {
    startTime: 'Open',
    endTime: 'Close'
  };

  const startMatch = TIME_REGEX.exec(startTime);
  const endMatch = TIME_REGEX.exec(endTime);

  if(startMatch && startMatch.length >= 3) {
    const [, startHour, startMin] = startMatch;
    res.startTime = formatTime(startHour || '00', startMin || '00');
  }

  if(endMatch && endMatch.length >= 3) {
    const [, endHour, endMin] = endMatch;
    res.endTime = formatTime(endHour || '00', endMin || '00');
  }

  return res;
};

export const Map = (props: AddressProps) => {
  const { restaurant } = useRestaurant();
  const secondaryAddress = props.address2 ? `, ${props.address2}` : '';
  const addressParams = new URLSearchParams({ address: `${props.name}, ${props.address1}${secondaryAddress}, ${props.city}, ${props.state} ${props.zipcode}` });
  const addressQuery = addressParams.toString();
  const showMap = props.alwaysShowMap || restaurant?.content?.footerConfig?.hideMap === false;

  return (
    showMap ?
      <div className="mapFrame">
        <iframe title="Google Maps" width="100%" height="100%" loading="lazy" allowFullScreen className="mapsFrame"
          src={`${config.resources.mapEndpoint}/map?${addressQuery}`}>
        </iframe>
      </div>
      : null
  );
};

const ScheduleMap = (props: Props) => {
  const { ooRestaurant } = useRestaurant();
  const i18nCountryCode = getRestaurantCountryCode(ooRestaurant?.i18n?.country);
  const addressLine1 = `${props.address1}${props.address2 ? `, ${props.address2}` : ''}`;
  const addressLine2 = `${props.city}, ${props.state} ${props.zipcode}`;

  return (
    <div className="scheduleMap" id="schedule">
      <div className="schedule">
        <div className="content">
          <h2 className="header">Where &amp; when to visit us</h2>
          <h3>
            <div className="name">{props.name}</div>
            <div className="address1">{addressLine1}</div>
            <div className="address2">{addressLine2}</div>
            <PhoneLink className="phoneNumber" phoneNumber={props.phoneNumber} countryCode={i18nCountryCode} />
          </h3>
          <div className="hours">
            {props.hours.map(item => {
              if(!item.days.length) {
                return null;
              }

              const dayStr = item.days.length > 1 ?
                `${upperFirst(item.days[0]?.slice(0, 3))}-${upperFirst(item.days[item.days.length - 1]?.slice(0, 3))}`
                : upperFirst(item.days[0]?.slice(0, 3));

              return (
                <div key={dayStr} className="item">
                  <div className="day">{dayStr}</div>
                  <div>
                    {item.intervals
                      ? item.intervals
                        .map(formatInterval)
                        .map(interval =>
                          <div key={interval.startTime} className="times">
                            {interval.startTime}-{interval.endTime}
                          </div>)
                      : <div className="times">Closed</div>}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Map
        name={props.name}
        address1={props.address1}
        address2={props.address2}
        city={props.city}
        state={props.state}
        zipcode={props.zipcode}
        alwaysShowMap={true} />
    </div>
  );
};

export default ScheduleMap;
