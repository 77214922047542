import React, { useCallback, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { DiningOptionBehavior, GiftCardConfigFieldsFragment } from 'src/apollo/onlineOrdering';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import useTracker from 'src/lib/js/hooks/useTracker';
import PayPalCheckoutMethod from 'src/public/components/default_template/paypal/PayPalCheckoutMethod';
import { usePaypal } from 'src/public/components/online_ordering/paypalUtils';
import { ShowForUS } from 'src/shared/components/common/show_for_us/ShowForUS';

import Image from 'shared/components/common/Image';
import { ToggleInput } from 'shared/components/common/forms';
import { Modal, ModalOverlay, useModal } from 'shared/components/common/modal';
import { OORestaurant, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { PwlessAuth } from 'public/components/default_template/online_ordering/account/pwlessAuth/PwlessAuth';
import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';
import CustomerInfoSection from 'public/components/default_template/online_ordering/checkout/CustomerInfoSection';
import { AuthenticationSource } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';
import LoyaltyRedemption from 'public/components/default_template/online_ordering/checkout/loyalty/LoyaltyRedemption';
import { PackagingOptions } from 'public/components/default_template/online_ordering/checkout/packaging/PackagingOptions';
import CreditCardForm from 'public/components/default_template/online_ordering/checkout/payment/CreditCardForm';
import GiftCardExtensionInput from 'public/components/default_template/online_ordering/checkout/payment/GiftCardExtensionInput';
import GiftCardInput from 'public/components/default_template/online_ordering/checkout/payment/GiftCardInput';
import OrderPrices from 'public/components/default_template/online_ordering/checkout/payment/OrderPrices';
import PromoCodeInput from 'public/components/default_template/online_ordering/checkout/payment/PromoCodeInput';
import SavedCreditCards, { AcceptedPaymentMethods } from 'public/components/default_template/online_ordering/checkout/payment/SavedCreditCards';
import TipSelector from 'public/components/default_template/online_ordering/checkout/payment/TipSelector';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { Customer, useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';
import { Cart } from 'public/components/online_ordering/types';


import Fundraising from './Fundraising';


const PaymentSection = () => {
  const { ooRestaurant } = useRestaurant();
  const { cart } = useCart();
  const { customer } = useCustomer();
  const { oo3PLoyalty } = useFlags();
  const { paymentOption, setPaymentOption, fundraisingEnabled, tipEnabled } = usePayment();
  const { setTipAmount } = usePayment();
  const { setPackagingOptions, packagingOptions } = useCheckout();
  const [selectedTipButton, setSelectedTipButton] = useState('');
  const { isOpen, onClose, onOpen } = useModal();
  const isIntlRestaurant = useIsIntlRestaurant();
  const tracker = useTracker();
  const { enabledPaymentOptions } = useCheckout();

  const clickLogIn = () => {
    onOpen();
    tracker.track('Started Authentication', { source: AuthenticationSource.GuestCheckout } );
  };

  const onTipChange = useCallback((buttonId: string, tip: number) => {
    setSelectedTipButton(buttonId);
    setTipAmount(tip);
  }, [setTipAmount, setSelectedTipButton]);

  if(!ooRestaurant || !cart) {
    return null;
  }

  const gcConfig = ooRestaurant.giftCardConfig;
  const validGCPaymentOption = paymentOption != PaymentOption.Paypal && paymentOption != PaymentOption.Venmo;

  const canAcceptGiftCards =
    !isIntlRestaurant &&
    validGCPaymentOption &&
    // First party and 3rd party check
    (gcConfig.redemptionAllowed || gcConfig.hasGiftCardsExtension);

  const canPayAtCheckout = !enabledPaymentOptions.has(PaymentOption.UponReceipt) || enabledPaymentOptions.size > 1;

  return (
    <>
      {customer ?
        <CustomerInfoSection /> :
        <ShowForUS>
          <CheckoutSection title="Checkout as a guest">
            <span>Already have a Toast account? For faster checkout,{' '}</span>
            <span role="button" className="logInLink" onClick={clickLogIn}>log in.</span>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay fadeIn fadeOut />
              <PwlessAuth checkingOut source={AuthenticationSource.GuestCheckout} />
            </Modal>
          </CheckoutSection>
        </ShowForUS>}

      <CheckoutSection title="Payment">
        {canPayAtCheckout && <AcceptedPaymentMethods />}
        <PaymentOptionsSection cart={cart} paymentOption={paymentOption} setPaymentOption={setPaymentOption} />
        <ShowForUS>
          <CreditCardInfo paymentOption={paymentOption} customer={customer} />
        </ShowForUS>
      </CheckoutSection>

      {!customer && <CustomerInfoSection />}
      {showLoyaltySection(ooRestaurant, oo3PLoyalty) && <LoyaltyRedemption />}
      <CheckoutSection title={canAcceptGiftCards ? 'Promos and Gift Cards' : 'Promos'}>
        <PromoCodeInput />
        <ShowForUS>
          <GiftCardInputWrapper config={gcConfig} paymentOption={paymentOption} />
        </ShowForUS>
      </CheckoutSection>
      {tipEnabled &&
        <CheckoutSection>
          <TipSelector tipOptions={cart.preComputedTips} selectedTipButton={selectedTipButton} onChange={onTipChange} />
        </CheckoutSection>}
      {
        ooRestaurant?.packagingConfig?.enabled &&
          <CheckoutSection title="Packaging options">
            <PackagingOptions packagingConfig={ooRestaurant.packagingConfig} selectedPackagingOptions={packagingOptions} onPackagingOptionsUpdate={setPackagingOptions} />
          </CheckoutSection>
      }
      {fundraisingEnabled &&
        <CheckoutSection>
          <FundraisingSection />
        </CheckoutSection>}
      <OrderPrices />
    </>
  );
};

interface GiftCardInputWrapperProps {
  paymentOption: PaymentOption | null
  config: GiftCardConfigFieldsFragment
}

const GiftCardInputWrapper = ({ config, paymentOption }: GiftCardInputWrapperProps) => {
  if(paymentOption === PaymentOption.Paypal || paymentOption === PaymentOption.Venmo) {
    return null;
  }

  return config.hasGiftCardsExtension
    ? <GiftCardExtensionInput gcConfig={config} />
    : config.redemptionAllowed && <GiftCardInput /> || null;
};

function showLoyaltySection(ooRestaurant: OORestaurant, oo3PLoyalty: boolean) {
  const loyaltyConfig = ooRestaurant?.loyaltyConfig;
  return loyaltyConfig?.loyaltyRedemptionEnabled && (loyaltyConfig?.programName !== 'INTEGRATION' || oo3PLoyalty);
}

/** Set of radio buttons for selecting payment options (e.g. Pay now/later, credit/debit/ApplePay/PayPal/Venmo) */
export const PaymentOptionsSection = ({
  paymentOption,
  setPaymentOption,
  cart
} : {
  paymentOption: PaymentOption | null,
  setPaymentOption: (option: PaymentOption) => void,
  cart: Cart
}) => {
  const { enabledPaymentOptions } = useCheckout();
  const { isPaypalScriptLoading } = usePaypal();

  const canPayLater = enabledPaymentOptions.has(PaymentOption.UponReceipt);
  const canPayNow = enabledPaymentOptions.has(PaymentOption.CreditCard);

  if(!canPayLater && !canPayNow) {
    return null;
  }

  if(!canPayNow) {
    setPaymentOption(PaymentOption.UponReceipt);
    const message =
      cart.diningOptionBehavior === DiningOptionBehavior.Delivery ?
        'Please pay delivery driver upon receiving your order.' :
        'Please pay at the restaurant when you pick up your order.';
    return <div className="payAtRestaurantNote">{message}</div>;
  }

  const showPaymentTypeButtons = enabledPaymentOptions.size > 1 && paymentOption !== PaymentOption.UponReceipt;

  return (
    <div data-testid="payment-option-radio-buttons">
      {canPayLater &&
        <div className="paymentButtons">
          <ToggleInput
            checked={paymentOption !== PaymentOption.UponReceipt}
            onChange={() => setPaymentOption(PaymentOption.CreditCard)}
            name="payNow"
            id="payNow"
            type="radio"
            dataTestId="input-payNow">
            Pay now
          </ToggleInput>
          <ToggleInput
            checked={paymentOption === PaymentOption.UponReceipt}
            onChange={() => setPaymentOption(PaymentOption.UponReceipt)}
            name="payLater"
            id="payLater"
            type="radio"
            dataTestId="input-payLater">
            {cart.diningOptionBehavior === DiningOptionBehavior.Delivery ? 'Pay upon delivery' : 'Pay at restaurant'}
          </ToggleInput>
        </div>}
      <ShowForUS>
        <AnimatedSection expanded={showPaymentTypeButtons} testid="pay-now-options-animated-section">
          <hr />
          <div className="paymentButtons">
            {enabledPaymentOptions.has(PaymentOption.ApplePay) &&
            <ToggleInput
              checked={paymentOption === PaymentOption.ApplePay}
              onChange={() => setPaymentOption(PaymentOption.ApplePay)}
              name="applepay-checkoutMethod"
              id="applyPay"
              type="radio"
              dataTestId="input-applePay">
              <Image className="appleLogo" alt="ApplePay" src="/icons/apple-pay.svg" />
            </ToggleInput>}
            {enabledPaymentOptions.has(PaymentOption.Paypal) &&
            <ToggleInput
              disabled={isPaypalScriptLoading}
              checked={paymentOption === PaymentOption.Paypal}
              onChange={() => setPaymentOption(PaymentOption.Paypal)}
              name={`${PaymentOption.Paypal}-checkoutMethod`}
              id={`${PaymentOption.Paypal}-payment-method`}
              type="radio"
              dataTestId={`input-${PaymentOption.Paypal}`}>
              <PayPalCheckoutMethod paymentOption={PaymentOption.Paypal} />
            </ToggleInput>}
            {enabledPaymentOptions.has(PaymentOption.Venmo) &&
            <ToggleInput
              disabled={isPaypalScriptLoading}
              checked={paymentOption === PaymentOption.Venmo}
              onChange={() => setPaymentOption(PaymentOption.Venmo)}
              name={`${PaymentOption.Venmo}-checkoutMethod`}
              id={`${PaymentOption.Venmo}-payment-method`}
              type="radio"
              dataTestId={`input-${PaymentOption.Venmo}`}>
              <PayPalCheckoutMethod paymentOption={PaymentOption.Venmo} />
            </ToggleInput>}
            <ToggleInput
              checked={paymentOption === PaymentOption.CreditCard}
              onChange={() => setPaymentOption(PaymentOption.CreditCard)}
              name="card-checkoutMethod"
              id="payWithCard"
              type="radio"
              dataTestId="input-cc">
                Debit or credit card
            </ToggleInput>
          </div>
        </AnimatedSection>
      </ShowForUS>
    </div>
  );
};

const CreditCardInfo = ({ paymentOption, customer }: {paymentOption: PaymentOption | null, customer?: Customer}) => {
  return (
    <>
      <AnimatedSection expanded={paymentOption === PaymentOption.CreditCard && (customer?.creditCards?.length || 0) > 0}>
        <SavedCreditCards />
      </AnimatedSection>
      <AnimatedSection expanded={paymentOption === PaymentOption.CreditCard && (customer?.creditCards?.length || 0) === 0}>
        <CreditCardForm showCheckbox={Boolean(customer)} />
      </AnimatedSection>
    </>
  );
};

const FundraisingSection = () => {
  const { ooRestaurant } = useRestaurant();
  const { cart } = useCart();
  const { tipAmount, fundraisingEnabled, setFundraisingAmount, fundraisingAmount } = usePayment();
  const fundraisingCampaign = ooRestaurant?.fundraisingConfig;
  return (
    <Fundraising
      fundraisingCampaign={fundraisingCampaign}
      onChange={(amount: number) => setFundraisingAmount(amount)}
      orderTotal={(cart?.order?.totalV2 || 0) + tipAmount}
      fundraisingEnabled={fundraisingEnabled}
      fundraisingAmount={fundraisingAmount} />
  );
};

export default PaymentSection;
