import React, { Fragment, useMemo } from 'react';

import { ContainsEditableProps, useEditor, _span } from '@toasttab/sites-components';
import classnames from 'classnames';

import { Image, ImageFit, PaddingEnum } from 'src/apollo/sites';
import { getSectionPaddingModule } from 'src/shared/components/common/editor_context/editableUtils';

import { _Image } from 'shared/components/common/editable_image/EditableImage';

interface CommonProps {
  className?: string;
  // If image is passed, src, fit, and link will not be listened to.
  image?: Image | null;
  imagePath?: string;
  src?: string | null;
  srcPath?: string;
  fit?: ImageFit | null;
  fitPath?: string;
  link?: string | null;
  linkPath?: string;
  tags?: string[] | null;
  padding?: PaddingEnum | null;
}

type ConditionalProps = {
  secondSrc: string | null | undefined;
  secondSrcPath: string;
}
| {
  secondSrc?: never;
  secondSrcPath?: never;
};

type Props = CommonProps & ConditionalProps & Partial<ContainsEditableProps>

const ImageWithTags = ({ editPath, image, imagePath, src, secondSrc, srcPath, secondSrcPath, tags, className, fit, fitPath, padding = PaddingEnum.Medium, link, linkPath }: Props) => {
  const { useEditableRef } = useEditor();
  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'tag section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    path: editPath,
    schema: {
      fields: [
        getSectionPaddingModule(editPath || '', padding) // we don't apply the ref when editPath is undefined, so we can provide a bad backup path here.
      ]
    }
  });

  const tagsLen = tags ? tags.length : 0;
  const tagData = useMemo(() => tags?.reduce((acc, tag, i) => {
    const pathToTag = (idx: number) => `${editPath}.tags[${idx}]`;
    if(i < tagsLen - 1) {
      acc.htmlTags.push(
        <Fragment key={i}><_span html={tag} className="inverted-bg" editPath={pathToTag(i)} />
          <span> • </span>
        </Fragment>
      );
      acc.altText += tag + ', ';
    } else {
      acc.htmlTags.push(<_span html={tag} className="inverted-bg" key={i} editPath={pathToTag(i)} />);
      acc.altText += tag;
    }

    return acc;
  }, { htmlTags: [] as JSX.Element[], altText: '' }), [editPath, tagsLen, tags]);

  if(!src && !image?.src) return null;

  return (
    <div ref={editPath ? editableRef : undefined}
      className={classnames('imageWithTags', className)}
      data-testid="image-with-tags">
      {tagData?.htmlTags ? <div className="tagSection">{tagData.htmlTags}</div> : null}
      <_Image imageObject={image} imageObjectPath={imagePath} src={src}
        className={classnames('image', secondSrc && 'halfWidth')} alt={tagData?.altText} editPath={srcPath} fit={fit} fitEditPath={fitPath} link={link} linkEditPath={linkPath} />
      {secondSrc && <_Image src={secondSrc} className="image halfWidth" editPath={secondSrcPath} fit={fit} fitEditPath={fitPath} link={link} linkEditPath={linkPath} />}
    </div>
  );
};

export default ImageWithTags;
