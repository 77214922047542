import React, { useContext, createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useCompletedOrderQuery } from 'src/apollo/onlineOrdering';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { CompletedOrder } from './types';

type CompletedOrderContextType = {
  orderGuid?: string | null;
  loadingCompletedOrder: boolean;
  completedOrder?: CompletedOrder | null;
  completedOrderError: string | null;
}

type LocationState = {
  completedOrder: CompletedOrder | null;
}

export const CompletedOrderContext = createContext<CompletedOrderContextType | undefined>(undefined);
export const ORDER_KEY = 'toast-embedded-oo-order';

export const CompletedOrderContextProvider = (props: React.PropsWithChildren<{}>) => {
  const location = useLocation<LocationState>();
  const { selectedLocation } = useRestaurant();
  const [loadingCompletedOrder, setLoadingCompletedOrder] = useState(true);
  const [orderGuid, setOrderGuid] = useState<string | null>(null);
  const [order, setOrder] = useState<CompletedOrder | null>(null);

  useEffect(() => {
    if(location.state?.completedOrder) {
      setOrder(location.state.completedOrder as CompletedOrder);
      setLoadingCompletedOrder(false);
    } else if(typeof window !== 'undefined') {
      const orderGuid = localStorage.getItem(ORDER_KEY);
      if(orderGuid) {
        setOrderGuid(orderGuid);
        setLoadingCompletedOrder(false);
      }
    }
  }, [location]);

  const { data, loading, error } = useCompletedOrderQuery({
    ssr: false,
    skip: !orderGuid || !selectedLocation?.externalId,
    variables: { input: { orderGuid: orderGuid || '', restaurantGuid: selectedLocation?.externalId || '' } },
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if(data?.completedOrder?.__typename === 'CompletedOrder') {
      setOrder(data.completedOrder as CompletedOrder);
    }
  }, [data]);

  return (
    <CompletedOrderContext.Provider value={{
      orderGuid,
      loadingCompletedOrder: loadingCompletedOrder || loading,
      completedOrder: order,
      completedOrderError: error?.message || ''
    }}>
      {props.children}
    </CompletedOrderContext.Provider>);
};

export const useCompletedOrder = () => {
  const context = useContext(CompletedOrderContext);
  if(!context) {
    throw new Error('useCompletedOrder must be used within a CompletedOrderContextProvider');
  }

  return context;
};
