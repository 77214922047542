import type { RefMenu, RefMenuGroup } from 'public/components/default_template/menu_nav/MenuNav';
import type { MenuItem } from 'public/components/default_template/menu_section/MenuSection';

function isNonNull<T>(item: T | null): item is T {
  if(item === null) {
    return false;
  } {
    return true;
  }
}

// Include items that match by name or description
function filterItem(item: MenuItem, searchString: string): MenuItem | null {
  if(item.name?.toLowerCase().includes(searchString.toLowerCase())) {
    return item;
  } else if(item.description?.toLowerCase().includes(searchString.toLowerCase())) {
    return item;
  } else {
    return null;
  }
}

export function filterItems(items: MenuItem[], searchString: string): MenuItem[] {
  return items.map(item => filterItem(item, searchString)).filter(isNonNull);
}

// Include all items when when the search string matches the menu group name
function filterMenuGroup(menuGroup: RefMenuGroup, searchString: string): RefMenuGroup | null {
  if(menuGroup.name.toLowerCase().includes(searchString.toLowerCase())) {
    return menuGroup;
  }

  const filteredItems = filterItems(menuGroup.items, searchString);

  if(filteredItems.length === 0) {
    return null;
  }

  return {
    ...menuGroup,
    items: filteredItems
  };
}

function filterMenuGroups(menuGroups: RefMenuGroup[], searchString: string): RefMenuGroup[] {
  return menuGroups.map(group => filterMenuGroup(group, searchString)).filter(isNonNull);
}


// Include all menu groups and items when when the search string matches the menu name
function filterMenu(menu: RefMenu, searchString: string): RefMenu | null {
  if(menu.name.toLowerCase().includes(searchString.toLowerCase())) {
    return menu;
  }

  const filteredGroups = filterMenuGroups(menu.groups, searchString);

  if(filteredGroups.length === 0) {
    return null;
  }

  return {
    ...menu,
    groups: filteredGroups
  };
}

export function filterMenus(menus: RefMenu[], searchString: string): RefMenu[] {
  return menus.map(menu => filterMenu(menu, searchString)).filter(isNonNull);
}
