import React from 'react';

import { InfoIcon } from '@toasttab/buffet-pui-icons';

type Props = {
  bookingMinHoursInAdvance?: number;
  testId?: string;
}

const MinHoursInAdvanceMessage = ({ bookingMinHoursInAdvance, testId }: Props) => {
  if(!bookingMinHoursInAdvance) {
    return null;
  }
  return (
    <div className="minHoursInAdvance" data-testid={testId}>
      <InfoIcon className="infoIcon" />
      <p className="minHoursInAdvanceMessage">
        Online reservations must be made at least {bookingMinHoursInAdvance}{' '}
        {bookingMinHoursInAdvance === 1 ? 'hour' : 'hours'} before reservation start time.
      </p>
    </div>
  );
};

export default MinHoursInAdvanceMessage;
