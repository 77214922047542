import React from 'react';

import { ButtonType } from 'src/apollo/sites';

import Image from 'shared/components/common/Image';
import { ButtonLink } from 'shared/components/common/button';
import Link from 'shared/components/common/link';

type Props = {
  cancelMessage?: string;
  loyaltySignupUrl?: string | null;
};

const CancelationConfirmation = ({ cancelMessage, loyaltySignupUrl }: Props) => {
  return (
    <div className="confirmation">
      <div className="description">{cancelMessage}</div>
      <div className="buttons">
        <ButtonLink variant={ButtonType.Primary} href="/menu">View menu</ButtonLink>
        {loyaltySignupUrl ?
          <div className="loyaltyContainer">
            <Link href={loyaltySignupUrl} isBold>
              <Image alt="Join loyalty icon" src="icons/join-loyalty.svg" /><div className="joinLoyalty">Join our Loyalty Program</div>
            </Link>
          </div>
          : null}
      </div>
    </div>
  );
};

export default CancelationConfirmation;
