import React, { useMemo, useRef } from 'react';


import { ContainsEditableProps, useEditor, _div, _span, _heading } from '@toasttab/sites-components';
import classnames from 'classnames';
import ColorContrastChecker from 'color-contrast-checker';

import { PaddingEnum, Review, ThemeTypeEnum } from 'src/apollo/sites';

import Carousel, { CarouselTypes } from 'shared/components/common/carousel/Carousel';
import { getBackgroundColorModule, getSectionPaddingModule } from 'shared/components/common/editor_context/editableUtils';
import Stars from 'shared/components/common/stars';


type ReviewCardProps = {
  review: Review;
  index: number;
  fillColor: string;
  theme?: ThemeTypeEnum | null;
} & ContainsEditableProps;

const ReviewCard = (props: ReviewCardProps) => {
  const pathToContent = `${props.editPath}.content`;
  const pathToAuthorName = `${props.editPath}.authorName`;
  const pathToReviewStarRating = `${props.editPath}.rating`;

  return (
    <div className={classnames('reviewCard', { paddedSection: props.theme === ThemeTypeEnum.Wide })}>
      <div className="reviewWrapper">
        {props.theme === ThemeTypeEnum.Wide && <span className="startQuote" aria-hidden="true">&ldquo;</span>}
        <blockquote className="reviewContent txt-body-md">
          <div className="quote">
            {props.theme !== ThemeTypeEnum.Wide && <span aria-hidden="true">&ldquo;</span>}
            <_span html={props.review.content} editPath={pathToContent} />
            {props.theme !== ThemeTypeEnum.Wide && <span className="endQuote" aria-hidden="true">&rdquo;</span>}
          </div>
          <div className="authorRating">
            <_div className="author txt-title-sm" html={props.review.authorName} editPath={pathToAuthorName} />
            <div className="rating txt-body-lg">
              <Stars fillColor={props.fillColor} count={props.review.rating} size={15} editPath={pathToReviewStarRating} />
            </div>
          </div>
        </blockquote>
      </div>
    </div>
  );
};


type ReviewsProps = {
  id: string
  header?: string | null;
  reviews: Review[];
  fillColor?: string;
  theme?: ThemeTypeEnum | null;
  backgroundColor?: string | null;
  padding?: PaddingEnum | null;
} & ContainsEditableProps;


const Reviews = ({ padding = PaddingEnum.Medium, ...props }: ReviewsProps) => {
  const { useEditableRef, isEditor } = useEditor();

  const carouselRef = useRef<HTMLDivElement>(null);
  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'review section',
    actions: ['move-up', 'move-down', 'duplicate', 'delete'],
    path: props.editPath,
    skipRef: carouselRef,
    schema: {
      fields: [
        getSectionPaddingModule(props.editPath, padding),
        getBackgroundColorModule(props.editPath, props.backgroundColor)
      ]
    }
  });

  const starColor = useMemo(() => {
    const contrastChecker = new ColorContrastChecker();
    let backgroundColor = props.backgroundColor;
    if(props.theme !== ThemeTypeEnum.Wide) {
      // if not wide, then the reviews are displayed with a white background.
      backgroundColor = '#ffffff';
    }

    return [props.fillColor, '#FFE606', '#ffffff', '#000000'].filter(color => contrastChecker.isLevelAA(backgroundColor, color, 14))[0] || '#FFFFFF';
  }, [props.fillColor, props.backgroundColor, props.theme]);

  if(!props.reviews?.length) {
    if(isEditor) {
      return <div ref={editableRef} className={classnames('reviewsSection', 'emptySection', props.theme)} ></div>;
    }
    return null;
  }

  const pathToReview = (idx: number) => `${props.editPath}.reviews[${idx}]`;


  return (
    <div ref={editableRef} className={classnames('reviewsSection', props.theme)} data-testid="reviews-section" >
      <div className="paddedSection">
        <_heading
          styleLevel={2}
          className="sectionHeader"
          html={props.theme !== ThemeTypeEnum.Wide ? props.header || 'What people are saying...' : ''}
          editPath={`${props.editPath}.reviewHeader`} />
      </div>
      <Carousel
        id={props.id}
        theme={props.theme}
        primaryColor={props.fillColor}
        whiteInactiveArrow={props.theme === ThemeTypeEnum.Wide}
        carouselRef={carouselRef}
        carouselType={CarouselTypes.Reviews}
        ariaLabel="Reviews"
        items={props.reviews.map((review, index) =>
          <div key={`review${index}`} className="reviewCardWrapper carouselItem">
            <ReviewCard editPath={pathToReview(index)} review={review} index={index} fillColor={starColor} theme={props.theme} />
          </div>)} />
    </div>
  );
};

export default Reviews;
