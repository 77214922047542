import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Route, Switch, useHistory } from 'react-router-dom';

import { FilePage, Redirect, SitePagesDataFragment, TemplatePage } from 'src/apollo/sites';
import { RequestContext } from 'src/lib/js/context';
import { isPreviewGenericSite } from 'src/lib/js/previewModeUtils';
import { getHost } from 'src/lib/js/utilities';

import RedirectWithStatus from 'shared/components/common/RedirectWithStatus';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import { RxDataUsage } from 'shared/js/hooks/useDefaultSiteData';

import AccountPage from 'public/components/default_template/AccountPage';
import CheckoutPage from 'public/components/default_template/CheckoutPage';
import ConfirmationPage from 'public/components/default_template/ConfirmationPage';
import MenuPage from 'public/components/default_template/MenuPage';
import OrderPage from 'public/components/default_template/OrderPage';
import MainPage, { EditPathRoot } from 'public/components/default_template/main_page/MainPage';
import { CustomerContextProviderWrapper } from 'public/components/online_ordering/CustomerContextWrapper';
import EmailSignupPage, { EmailMarketingPageType } from 'public/components/pages/email_marketing_page/EmailSignupPage';
import FormPageComponent, { FormPageType } from 'public/components/pages/form_page/FormPage';
import LocationSelectionPage from 'public/components/pages/location_selection_page/LocationSelectionPage';
import LoyaltyPage, { LoyaltyPageType } from 'public/components/pages/loyalty_page/LoyaltyPage';
import MapPage from 'public/components/pages/map_page/MapPage';
import PageShell from 'public/components/pages/page_shell';
import PdfPage from 'public/components/pages/pdf_page/PDFPage';
import TemplatePageComponent from 'public/components/pages/template_page';
import { UntappdMenuPage, UntappdMenuPageType } from 'public/components/pages/untappd_menu_page/UntappdMenuPage';

import { PageDomainProvider } from './PageDomainContext';
import { OrderRoutes } from './ToastOrderRoutes';
import useSitePagesData from './useSitePagesData';

type PageType = NonNullable<SitePagesDataFragment['additionalPages']>[0];

const GoBack = () => {
  const history = useHistory();
  useEffect(() => {
    history.goBack();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

const SitesRoutes = ({ staticContext }: { staticContext?: RequestContext }) => {
  const { pathname } = useLocation();
  const host = getHost(staticContext);

  const { sitePagesData: siteRestaurant, loading } = useSitePagesData(staticContext);
  const isPreviewMode = isPreviewGenericSite(staticContext, siteRestaurant?.config);

  if(loading) {
    return null;
  }

  if(!host || !siteRestaurant) {
    return <NoMatch404 meta={siteRestaurant?.meta} />;
  }

  const pages = siteRestaurant.additionalPages;
  const hasMultiLocations = (siteRestaurant.locations?.length || 0) > 1;
  const redirects = siteRestaurant.redirects as Array<Redirect>;
  const primaryColor = siteRestaurant.meta?.primaryColor;
  const pathToPage = (pageIdx: number): EditPathRoot => `additionalPages[${pageIdx}]`;

  if(siteRestaurant.config?.isMenuOnly) {
    return (
      <CustomerContextProviderWrapper>
        <Switch>
          {redirects.map(({ from, to, status }) => <Route key={from} exact path={from} component={() => <RedirectWithStatus to={to} status={status} />} />)}
          <Route exact path="/" component={() => <PageShell ooUsage={RxDataUsage.Required} titleTag="Menu"><MenuPage /></PageShell>} />
          <Route exact path="/menu/:slug/:menuSlug([^_]+_)?:menuGuid?" component={() => <PageShell ooUsage={RxDataUsage.Required} titleTag="Menu"><MenuPage /></PageShell>} />
          <Route exact path="/menu/:slug" component={() => <PageShell ooUsage={RxDataUsage.Required} titleTag="Menu"><MenuPage /></PageShell>} />
          <Route exact path="/menu" component={() => <PageShell ooUsage={RxDataUsage.Required} titleTag="Menu"><MenuPage /></PageShell>} />
          <Route exact path="/map" component={() => <MapPage color={primaryColor} />} />
          <Route component={() => <NoMatch404 meta={siteRestaurant?.meta} />} />
        </Switch>
      </CustomerContextProviderWrapper>
    );
  }

  if(siteRestaurant.config?.isOnlineOrderingOnly && siteRestaurant.config?.isOnlineOrderingEnabled) {
    return <OrderRoutes staticContext={staticContext} sitesUsage={RxDataUsage.Required} />;
  }

  const routes = [
    // MainPage needs OO data to load the Promo/Loyalty banners, but failing to load OO shouldnt cause MainPage to fail.
    { path: '/', component: () => <PageShell ooUsage={RxDataUsage.Optional}><MainPage /></PageShell> },
    { path: '/menu/:slug/:menuSlug([^_]+_)?:menuGuid?', component: () => <PageShell ooUsage={RxDataUsage.Required} titleTag="Menu"><MenuPage /></PageShell> },
    { path: '/menu/:slug', component: () => <PageShell ooUsage={RxDataUsage.Required} titleTag="Menu"><MenuPage /></PageShell> },
    { path: '/menu', component: () => <PageShell ooUsage={RxDataUsage.Required} titleTag="Menu"><MenuPage /></PageShell> },
    { path: '/map', component: () => <MapPage color={primaryColor} /> }
  ];

  pages?.forEach((page: PageType, pageIdx: number) => {
    const route = page.route.startsWith('/') ? page.route : `/${page.route}`;

    if(page.content.__typename === 'FilePage') {
      const content = page.content as FilePage;
      routes.push({
        path: route,
        component: () =>
          <PageShell titleTag={content.name}>
            <PdfPage file={content.filePath} config={page.config} />
          </PageShell>
      });
    } else if(page.content.__typename === 'TemplatePage') {
      const content = page.content as TemplatePage;
      routes.push({
        path: route,
        component: () =>
          <PageShell ooUsage={RxDataUsage.Optional}>
            <TemplatePageComponent content={content} editPathRoot={pathToPage(pageIdx)} config={page.config} />
          </PageShell>
      });
    } else if(page.content.__typename === 'FormPage') {
      const content = page.content as FormPageType;
      routes.push({
        path: route,
        component: () =>
          <PageShell titleTag={page.meta?.title || page.route.replace('/', '')}>
            <FormPageComponent content={content} editPathRoot={pathToPage(pageIdx)} id={page.route} config={page.config} title={page.meta?.title || page.route} />
          </PageShell>
      });
    } else if(page.content.__typename === 'LoyaltySignUpPage') {
      const content = page.content as LoyaltyPageType;
      routes.push({
        path: route,
        component: () =>
          <PageShell titleTag={page.meta?.title || page.route.replace('/', '')}>
            <LoyaltyPage content={content} editPathRoot={pathToPage(pageIdx)} config={page.config} />
          </PageShell>
      });
    } else if(page.content.__typename === 'EmailMarketingPage') {
      const content = page.content as EmailMarketingPageType;
      routes.push({
        path: route,
        component: () =>
          <PageShell titleTag={page.meta?.title || page.route.replace('/', '')}>
            <EmailSignupPage content={content} editPathRoot={pathToPage(pageIdx)} config={page.config} />
          </PageShell>
      });
    } else if(page.content.__typename === 'UntappdMenuPage') {
      const content = page.content as UntappdMenuPageType;
      routes.push({
        path: route,
        component: () =>
          <PageShell titleTag={page.meta?.title || page.route.replace('/', '')}>
            <UntappdMenuPage content={content} editPathRoot={pathToPage(pageIdx)} config={page.config} />
          </PageShell>
      });
    }
  });

  if(siteRestaurant.config?.isOnlineOrderingEnabled) {
    const orderRoutes = [
      {
        path: '/order',
        component: () =>
          <PageShell noPopups={!hasMultiLocations} titleTag={hasMultiLocations ? 'Location Selection' : 'Order Online'} ooUsage={!hasMultiLocations ? RxDataUsage.Required : RxDataUsage.None}>
            {hasMultiLocations ? <LocationSelectionPage /> : <OrderPage />}
          </PageShell>
      },
      { path: '/order/:slug/:itemSlug(item-[^_]+_.+)?', component: () => <PageShell noPopups titleTag="Order Online" ooUsage={RxDataUsage.Required}><OrderPage /></PageShell> },
      { path: '/order/add/:guid(.+)/:ignore', component: () => <PageShell noPopups titleTag="Order Online" ooUsage={RxDataUsage.Required}><OrderPage /></PageShell> },
      { path: '/order/:slug/:menuSlug([^_]+_)?:menuGuid?', component: () => <PageShell noPopups titleTag="Order Online" ooUsage={RxDataUsage.Required}><OrderPage /></PageShell> },
      { path: '/order/:slug', component: () => <PageShell noPopups titleTag="Order Online" ooUsage={RxDataUsage.Required}><OrderPage /></PageShell> },
      {
        path: '/checkout', component: () =>
          <PageShell noPopups titleTag="Checkout" ooUsage={RxDataUsage.Required}>{isPreviewMode ? <GoBack /> : <CheckoutPage />} </PageShell>
      },
      { path: '/confirm', component: () => <PageShell noPopups titleTag="Order Confirmation" ooUsage={RxDataUsage.Required}><ConfirmationPage /></PageShell> },
      { path: '/account', component: () => <PageShell noPopups titleTag="My Account" ooUsage={RxDataUsage.Required}><AccountPage /></PageShell> }
    ];

    routes.push(...orderRoutes);
  }

  const routesByPath = new Map(routes.map(r => [r.path, r]));
  const verifiedDomains = siteRestaurant.domainsV2.filter(d => !d.pendingVerification);
  const currentDomain = verifiedDomains.find(d => d.domain === host);
  // a domain with a page route renders that route's component a the root
  const routeOverride = currentDomain?.pageRoute ? routesByPath.get(currentDomain.pageRoute) : null;

  return (
    <CustomerContextProviderWrapper>
      <PageDomainProvider domains={verifiedDomains} host={host} pathname={pathname}>
        <Switch>
          {/* when the domain is mapped to a page, this should take priority over redirects */}
          {routeOverride ? <Route exact path="/" component={routeOverride.component} /> : null}
          {redirects.map(({ from, to, status }) => <Route key={from} exact path={from} component={() => <RedirectWithStatus to={to} status={status} />} />)}
          {routes.map((r, i) => <Route exact key={`${r.path}-${i}`} path={r.path} component={r.component} /> )}
          <Route component={() => <NoMatch404 meta={siteRestaurant?.meta} />} />
        </Switch>
      </PageDomainProvider>
    </CustomerContextProviderWrapper>
  );
};

export default SitesRoutes;
