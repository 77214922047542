import React, { FC, useState } from 'react';

import { CreditCardRow } from 'public/components/default_template/online_ordering/checkout/payment/SavedCreditCards';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

interface ProfileWalletProps {}

export const ProfileWallet: FC<ProfileWalletProps> = () => {
  const { customer } = useCustomer();
  const [errorMsg, setErrorMsg] = useState('');

  return (
    <div className="profileWallet" data-testid="ProfileWallet" role="form">
      <div className="accountSubheading">My Wallet</div>
      <br />
      {customer?.creditCards?.length
        ? customer?.creditCards.map(card => <CreditCardRow key={card.guid} {...card} onError={setErrorMsg} hideInputs={true} /> )
        : <div className="empty">Check back here after placing an order for your saved payment methods!</div>}
      {errorMsg && <div className="submitError">{errorMsg}</div>}
    </div>
  );
};

