import React from 'react';

import Image from 'shared/components/common/Image';

type LockedLoyaltyRedemptionProps = {
  accrualTarget: number | undefined | null;
  conversionRate: number | undefined | null;
}

// Displayed for 1PL when the user does not have any redemptions. Shows their progress to the next reward.
export const LockedLoyaltyRedemption = ({ conversionRate, accrualTarget } : LockedLoyaltyRedemptionProps) => {
  // Only 1PL gives us the conversionRate and accrualTarget
  if(!conversionRate || !accrualTarget || conversionRate <= 0) {
    return null;
  }
  return (// TODO: Modify this for internationalization
    <button disabled className="lockedRedemptionButton" data-testid="locked-redemption">
      <div className="lockedLoyaltyRedemption">
        <Image alt="Locked Loyalty reward" src={'icons/lock-locked.svg'} data-testid="locked-loyalty-icon" />
        <div className="textContainer">
          <span className="lockedHeader" data-testid="locked-header">${(conversionRate * accrualTarget).toFixed(2)} Off</span>
          <span className="message" data-testid="locked-message">for {accrualTarget} points</span>
        </div>
      </div>
    </button>
  );
};
