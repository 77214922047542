import React, { RefObject, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import { motion } from 'framer-motion';

import { MenuFormatConfig, MenuTemplate } from 'src/apollo/sites';
import { normalizeHtmlId } from 'src/shared/js/normalizeUtils';

import { Modal, ModalCloseButton, ModalOverlay } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { DropdownTriangle, LeftArrow, NavControl, RightArrow } from 'public/components/default_template/menu_nav/Icons';
import { RefMenu } from 'public/components/default_template/menu_nav/MenuNav';
import { ScrollDirection, scrollToRef, useHorizontalScroll } from 'public/components/default_template/menu_nav/menuNavScrollUtils';
import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import { useMenuSearchContext, SearchInput } from 'public/components/default_template/search';

const TopMenuNav = ({
  menus,
  selectedMenuIndex,
  setSelectedMenuIndex,
  selectedGroupGuid,
  setSelectedGroupGuid,
  menuConfig,
  navRef
} : {
  menus: RefMenu[];
  selectedMenuIndex: number;
  setSelectedMenuIndex: (index: number) => void;
  selectedGroupGuid?: string;
  setSelectedGroupGuid: (guid: string) => void;
  menuConfig?: MenuFormatConfig | null;
  navRef?: React.RefObject<HTMLDivElement>;
}) => {
  const { scrollContainerRef, showLeftArrow, showRightArrow, scroll, scrollEvent } = useHorizontalScroll();
  const { restaurant } = useRestaurant();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { canUseSearch } = useMenuSearchContext();

  useEffect(() => {
    scrollEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menus.length]);

  if(menuConfig?.hideMenuNav && menuConfig?.hideSubMenuNav) {
    return null;
  }

  const hasMenuNav = menus.length > 1 && !menuConfig?.hideMenuNav;
  const hasSubMenuNav = !menuConfig?.hideSubMenuNav;

  const hasHero = !!restaurant.config.ooConfig?.heroImage?.src;
  const primaryColor = restaurant.meta.primaryColor ?? DEFAULT_COLORS.primary;

  // There are 3 cases to account for:
  // 1. There is a menuNav and a subMenuNav, in which case we'll render a nav with dropdowns
  // 2. There is only a menuNav and no subMenuNav, in which case we'll render the menus without dropdowns
  // 3. There is only a subMenuNav and no menuNav, in which case we redefine the menus as the submenus and render them without dropdowns
  let topLevelMenus: RefMenu[];
  if(menus.length === 0) {
    topLevelMenus = [];
  } else if(hasMenuNav) {
    topLevelMenus = menus;
  } else {
    topLevelMenus = menus[0]!.groups.map(group => { return { ...group, groups: [] } as RefMenu; });
  }
  const hasSubGroups = hasMenuNav && hasSubMenuNav;

  return (
    <div className="paddedContentWrapper">
      <div className={classnames('paddedContent', { withoutHero: !hasHero })}>
        <nav role="tablist" className={classnames('topMenuNav', { condensed: menuConfig?.template == MenuTemplate.Condensed })} ref={navRef} data-testid="top-menu-nav">
          {topLevelMenus.length > 0 &&
          <div className="topMenuNavMenuSelection">
            <div className="navControl hidden-md-up" >
              <div onClick={() => setShowMobileMenu(!showMobileMenu)} aria-label="Open full menu" role="button">
                <NavControl color={primaryColor} />
              </div>
              <MobileMenuModal menus={topLevelMenus} hasMenuGroups={hasSubGroups} isOpen={showMobileMenu} onClose={() => setShowMobileMenu(false)} />
            </div>
            <div className="topMenuNavWrapper">
              <button type="button" aria-label="Scroll left" onClick={scroll(ScrollDirection.Backwards)} className={classnames('arrow leftArrow', { arrowHidden: !showLeftArrow })}>
                <LeftArrow color={primaryColor} />
              </button>
              <div className="sections" ref={scrollContainerRef} onScroll={scrollEvent}>
                {topLevelMenus.map((menuItem, index) =>
                  <MenuItem
                    key={menuItem.name}
                    menuItem={menuItem}
                    selected={hasMenuNav ? selectedMenuIndex === index : selectedGroupGuid === menuItem.guid}
                    setSelected={() => { hasMenuNav ? setSelectedMenuIndex(index) : setSelectedGroupGuid(menuItem.guid); }}
                    scrollContainerRef={scrollContainerRef}
                    hasDropdown={hasSubGroups} />)}
              </div>
              <button type="button" aria-label="Scroll right" onClick={scroll(ScrollDirection.Forwards)} className={classnames('arrow rightArrow', { arrowHidden: !showRightArrow })}>
                <RightArrow color={primaryColor} />
              </button>
            </div>
          </div>}
          {canUseSearch &&
            <div className="topMenuNavSearch">
              <SearchInput />
            </div>}
        </nav>
      </div>
    </div>
  );
};

export const MenuItem = (
  {
    menuItem,
    selected,
    setSelected,
    scrollContainerRef,
    hasDropdown
  } : {
    menuItem: RefMenu,
    selected: boolean,
    setSelected: () => void,
    scrollContainerRef: RefObject<HTMLDivElement>,
    hasDropdown: boolean,
  }
) => {
  const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    { placement: 'bottom-start', modifiers: [{ name: 'offset', options: { offset: [30, 15] }/* [skidding, distance], https://popper.js.org/docs/v2/modifiers/offset/ */ }] }
  );
  const { isEditor } = useEditor();
  const { restaurant: { meta } } = useRestaurant();
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  useEffect(() => {
    const detectOutsideAction = (event: MouseEvent) => {
      const target = event.target as Node | null;
      if(dropdownIsOpen && !popperElement?.contains(target) && !referenceElement?.contains(target)) {
        setDropdownIsOpen(false);
      }
    };

    const detectSelectionFromVerticalScroll = (event: MouseEvent) => {
      const target = event.target as Node | null;
      if(selected && referenceElement && !scrollContainerRef.current?.contains(target)) {
        referenceElement.scrollIntoView({ block: 'nearest' });
      }
    };

    if(!isEditor) {
      document.addEventListener('click', detectOutsideAction);
      document.addEventListener('scroll', detectOutsideAction);
      document.addEventListener('scroll', detectSelectionFromVerticalScroll);
      scrollContainerRef.current?.addEventListener('scroll', detectOutsideAction);
    }

    return () => {
      document.removeEventListener('click', detectOutsideAction);
      document.removeEventListener('scroll', detectOutsideAction);
      document.removeEventListener('scroll', detectSelectionFromVerticalScroll);
    };
  }, [dropdownIsOpen, popperElement, scrollContainerRef, referenceElement, selected, isEditor]);

  const isPartiallyInViewport = () => {
    if(!referenceElement) {
      return;
    }
    const rect = referenceElement.getBoundingClientRect();
    const leftEdge = 60;
    const rightEdge = window.innerWidth || document.documentElement.clientWidth;
    const partiallyVisibleLeft = rect.left < leftEdge && rect.right < rightEdge;
    const partiallyVisibleRight = rect.left > leftEdge && rect.right > rightEdge;
    return partiallyVisibleRight || partiallyVisibleLeft;
  };

  function handleMenuItemClick(item: RefMenu) {
    setSelected();
    if(hasDropdown) {
      if(isPartiallyInViewport()) {
        referenceElement?.scrollIntoView({ block: 'nearest' });
        // Set a timeout so the event listener that automatically closes the dropdown on scroll
        // doesn't fire before we can scroll the menu item into view and open the dropdown
        setTimeout(() => setDropdownIsOpen(!dropdownIsOpen), 50);
      } else {
        setDropdownIsOpen(!dropdownIsOpen);
      }
    } else {
      scrollToRef(item.ref, isEditor);
    }
  }

  return (
    <div key={menuItem.name} className="menuItem" ref={setReferenceElement} >
      <div className="menuItemTarget" onClick={() => handleMenuItemClick(menuItem)}>
        <a
          id={`${normalizeHtmlId(menuItem.name)}-tab`}
          className={`menuLink ${selected ? 'selected' : ''}`}
          role="tab"
          aria-selected={selected}
          data-testid={`menu-tab-${menuItem.name}`}>
          {menuItem.name}
        </a>
        {hasDropdown &&
          <div className="dropdownTriangle" data-testid="dropdown-triangle">
            <DropdownTriangle color={meta.primaryColor} />
          </div>}
      </div>
      {hasDropdown && dropdownIsOpen &&
        ReactDOM.createPortal(
          <div className="dropdown" data-testid="menu-item-dropdown" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            {menuItem.groups.map(item =>
              <button
                key={item.id}
                className="subMenuItem"
                onClick={() => {
                  setDropdownIsOpen(false);
                  scrollToRef(item.ref, isEditor);
                }}>
                {`${item.name} (${item.items.length})`}
              </button>)}
          </div>, document.body
        )}
    </div>
  );
};

function MobileMenuModal(
  {
    menus,
    hasMenuGroups,
    isOpen,
    onClose
  } : {
    menus: RefMenu[],
    hasMenuGroups: boolean,
    isOpen: boolean,
    onClose: () => void
  }
) {
  const { isEditor } = useEditor();
  function handleClickItem(ref: RefObject<HTMLDivElement>) {
    onClose();
    scrollToRef(ref, isEditor);
  }
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay fadeIn fadeOut />
      <motion.div
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        transition={{ duration: 0.2, ease: 'easeIn' }}
        className="menuContent"
        data-testid="mobile-menu-content">
        <div className="header"><span>Menu</span> <ModalCloseButton className="closeButton" /></div>
        {menus.map(menu =>
          <div className="section" key={menu.guid}>
            <span
              className="item groupName"
              onClick={() => handleClickItem(menu.ref)}>
              {menu.name}
            </span>
            {hasMenuGroups &&
              <>
                <div className="separator" />
                {menu.groups.map(group =>
                  <span
                    className="item"
                    key={group.id}
                    onClick={() => handleClickItem(group.ref)}>
                    {`${group.name} (${group.items.length})`}
                  </span>)}
              </>}
          </div>)}
      </motion.div>
    </Modal>
  );
}

export default TopMenuNav;
