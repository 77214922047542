import React, { useMemo } from 'react';

import classnames from 'classnames';
import parseISO from 'date-fns/parseISO';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { formatAddressLabel } from 'src/shared/components/common/form_input/LocationInput';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { toLocalDateTime } from 'shared/js/timeUtils';

import { useCart } from 'public/components/online_ordering/CartContext';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { useFulfillment } from 'public/components/online_ordering/FulfillmentContext';
import { formatDeliveryAddress } from 'public/components/online_ordering/addressUtils';

const DiningOptionBehaviorMessage = () => {
  const { cart } = useCart();
  const { validDeliveryAddress } = useDelivery();
  const { fulfillmentData, selectedDiningOptionBehavior } = useFulfillment();
  const { orderingDisabled, selectedLocation } = useRestaurant();

  const availableNow = fulfillmentData?.scheduleData.asapAvailableNow;
  const deliveryAddress = useMemo(() => cart ? cart.order?.deliveryInfo : validDeliveryAddress, [cart, validDeliveryAddress]);
  const nextAvailableTime = fulfillmentData?.scheduleData.futureScheduleDates[0]?.times[0]?.time;
  const parsedTime = nextAvailableTime ? parseISO(nextAvailableTime) : null;
  const behaviorString = selectedDiningOptionBehavior === DiningOptionBehavior.TakeOut ? 'pickup' : 'delivery';

  const infoContent = useMemo(() => {
    if(orderingDisabled) {
      return <>We are not accepting online orders right now.</>;
    } else if(!availableNow) {
      return <>You can only place scheduled {behaviorString} orders. The earliest {behaviorString} time is <span className="bolder">{parsedTime ? toLocalDateTime(parsedTime) : ''}</span></>;
    } else if(selectedDiningOptionBehavior === DiningOptionBehavior.TakeOut) {
      return <>You can pick up your order at <span className="bolder">{formatAddressLabel(selectedLocation)}</span></>;
    } else if(selectedDiningOptionBehavior === DiningOptionBehavior.Delivery) {
      return <>Your order will be delivered to <span className="bolder">{formatDeliveryAddress(deliveryAddress)}</span></>;
    }
    return null;
  }, [orderingDisabled, availableNow, behaviorString, parsedTime, selectedDiningOptionBehavior, selectedLocation, deliveryAddress]);

  if(!infoContent || selectedDiningOptionBehavior === DiningOptionBehavior.Delivery && !deliveryAddress || !availableNow && !parsedTime) {
    return null;
  }

  return (
    <div className={classnames('diningOptionBehaviorInfoWrapper', 'hidden-sm-down')}>
      <div className="diningOptionBehaviorInfo">
        {infoContent}
      </div>
    </div>
  );
};

export default DiningOptionBehaviorMessage;

export const SimpleDiningOptionBehaviorMessage = () => {
  const { fulfillmentData, selectedDiningOptionBehavior } = useFulfillment();
  const { orderingDisabled } = useRestaurant();
  const behaviorString = selectedDiningOptionBehavior === DiningOptionBehavior.TakeOut ? 'pickup' : 'delivery';

  let infoString = '';
  if(orderingDisabled) {
    infoString = 'We are not accepting online orders right now.';
  } else if(!fulfillmentData?.scheduleData.asapAvailableNow) {
    infoString = `You can only place scheduled ${behaviorString} orders.`;
  }

  if(infoString == '') {
    return null;
  }

  return (
    <div className="diningOptionBehaviorInfo">
      {infoString}
    </div>
  );
};
