import React from 'react';
import { Helmet } from 'react-helmet-async';

import ColorContrastChecker from 'color-contrast-checker';

import { getImageUrl } from 'shared/components/common/Image';


type HexColor = `#${string}`;

export type StyleMetaProps = {
  primaryColor?: string;
  backgroundColor?: string;
  textColor?: string;
  secondaryTextColor?: string;
  fontFamily?: { name: string; url?: string } | string;
  headerFontFamily?: { name: string; url?: string } | string;
}

export const DEFAULT_COLORS: {[purpose: string]: HexColor} = {
  primary: '#000000',
  background: '#FFFFFF',
  text: '#252525',
  secondaryText: '#FFFFFF',
  link: '#2B4FB9'
};

export const GOOGLE_FONTS = [
  'Abril Fatface',
  'Arimo',
  'Barlow',
  'Capriola',
  'Chewy',
  'Chonburi',
  'Cormorant',
  'Crimson Pro',
  'Fira Sans',
  'Fira Sans Condensed',
  'Francois One',
  'Gudea',
  'Helvetica Neue',
  'Inconsolata',
  'Josefin Sans',
  'Josefin Slab',
  'Junge',
  'Karla',
  'Khula',
  'Lato',
  'Libre Baskerville',
  'Lobster Two',
  'Long Cang',
  'Lora',
  'Lugrasimo',
  'Merriweather',
  'Montserrat',
  'Nunito Sans',
  'Open Sans',
  'Playfair Display',
  'Poppins',
  'Quicksand',
  'Raleway',
  'Roboto',
  'Roboto Mono',
  'Roboto Slab',
  'Rubik',
  'Shrikhand',
  'Simonetta',
  'Simonetta',
  'Source Sans',
  'Tienne',
  'Unna',
  'Work Sans',
  'Yeseva One'
];
const DEFAULT_FONT = 'Effra';


export const getFontFaceAndFamily = (fontData?: string | { name: string, url?: string | null }) => {
  if(!fontData) {
    return { fontFamily: DEFAULT_FONT, fontFace: '' };
  } else if(typeof fontData === 'string') {
    // Strip `sans-serif` from "Effra, sans-serif". Effra is the only case that has ', sans-serif' affixed to it.
    // TODO: remove `, sans-serif` from Admin SPA, Sites API, and create a DB migration to remove it from all site entries.
    const family = fontData === 'Effra, sans-serif' ? 'Effra' : fontData;
    return { fontFamily: family, fontFace: '' };
  }

  if(!fontData.url) {
    const family = fontData.name === 'Effra, sans-serif' ? 'Effra' : fontData.name;
    return { fontFamily: family, fontFace: '' };
  }

  const fontFace = `
    @font-face {
      font-family: ${fontData.name.startsWith('"') ? fontData.name : `"${fontData.name}"`};
      src: url('${getImageUrl(fontData.url)}');
    }
  `;

  return { fontFamily: fontData.name, fontFace };
};

const parseColorHues = (color?: string) => color?.match(/^(#)?([A-Za-z0-9]{2})([A-Za-z0-9]{2})([A-Za-z0-9]{2})/)?.slice(2);

export const getSiteStyles = (meta: StyleMetaProps) => {
  const contrastChecker = new ColorContrastChecker();

  const primaryHues = parseColorHues(meta.primaryColor);
  const primaryColor = getColor(meta.primaryColor) || DEFAULT_COLORS.primary;
  const background = getColor(meta.backgroundColor) || DEFAULT_COLORS.background;
  const text = getColor(meta.textColor) || DEFAULT_COLORS.text;
  const secondaryText = getColor(meta.secondaryTextColor) || DEFAULT_COLORS.secondaryText;
  const hoverColor = getColor(primaryHues
    ?.map(hue => Math.max(parseInt(hue, 16) - 20, 0))
    ?.map(hue => hue.toString(16).padStart(2, '0'))
    ?.join('')) || DEFAULT_COLORS.primary;

  const contrastingColors: HexColor[] = ['#FFFFFF', '#000000'];

  const textContrastingColor = [primaryColor, ...contrastingColors].filter(color => contrastChecker.isLevelAAA(text, color, 14))[0];
  const primaryContrastingColor = [text, ...contrastingColors].filter(color => contrastChecker.isLevelAAA(primaryColor, color, 14))[0] || '#FFFFFF';
  const backgroundContrastingColor = [primaryColor, ...contrastingColors].filter(color => contrastChecker.isLevelAAA(background, color, 14))[0];
  const whiteContrastingColor = [primaryColor, text, '#000000'].filter(color => contrastChecker.isLevelAAA('#FFFFFF', color, 14))[0];

  const bodyFont = getFontFaceAndFamily(meta.fontFamily);
  const headerFont = getFontFaceAndFamily(meta.headerFontFamily || meta.fontFamily);

  return {
    primaryColor,
    background,
    text,
    secondaryText,
    hoverColor,
    textContrastingColor,
    primaryContrastingColor,
    backgroundContrastingColor,
    whiteContrastingColor,
    bodyFont,
    headerFont
  };
};

const StyleMeta = (meta: StyleMetaProps) => {
  const {
    primaryColor, background, text, secondaryText, hoverColor, textContrastingColor,
    primaryContrastingColor, backgroundContrastingColor, whiteContrastingColor, bodyFont, headerFont
  } = getSiteStyles(meta);

  return (
    <Helmet>
      {typeof meta.fontFamily !== 'string' && meta.fontFamily?.name
  && GOOGLE_FONTS.includes(meta.fontFamily.name)
        ? <link href={`https://fonts.googleapis.com/css?family=${meta.fontFamily.name.replace(/ /g, '+')}:wght@400;600;700&display=swap`} rel="stylesheet" />
        : null}
      {typeof meta.headerFontFamily !== 'string' && meta.headerFontFamily?.name
  && GOOGLE_FONTS.includes(meta.headerFontFamily.name)
        ? <link href={`https://fonts.googleapis.com/css?family=${meta.headerFontFamily.name.replace(/ /g, '+')}:wght@400;600;700&display=swap`} rel="stylesheet" />
        : null}
      <style type="text/css">
        {`
  ${bodyFont.fontFace}
  ${headerFont.fontFace}

  :root {
    /* Try to prevent CSS conflicts in Toast Admin by prefixing variables with 'tsw-' (Toast Sites Web) */
    --tsw-background-color: ${background};
    --tsw-background-color-med: ${background}40;
    --tsw-background-contrasting-color: ${backgroundContrastingColor}; /* Guaranteed to contrast with --tsw-background-color */
    --tsw-background-contrasting-color-50: ${backgroundContrastingColor}66;

    --tsw-primary-color: ${primaryColor};
    --tsw-primary-color-90: ${primaryColor}cc;
    --tsw-primary-color-75: ${primaryColor}aa;
    --tsw-primary-color-50: ${primaryColor}66;
    --tsw-primary-color-25: ${primaryColor}22;
    --tsw-primary-contrasting-color: ${primaryContrastingColor}; /* Guaranteed to contrast with --tsw-primary-color */
    --tsw-primary-contrasting-color-50: ${primaryContrastingColor}66;
    --tsw-primary-hover-color: ${hoverColor};
    --tsw-primary-hover-color-25: ${primaryColor}25;

    --tsw-primary-text-color: ${text};
    --tsw-primary-text-color-90: ${text}cc;
    --tsw-primary-text-color-75: ${text}aa;
    --tsw-primary-text-color-50: ${text}66;
    --tsw-primary-text-color-25: ${text}22;
    --tsw-primary-text-contrasting-color: ${textContrastingColor}; /* Guaranteed to contrast with --tsw-primary-text-color */
    --tsw-primary-text-contrasting-color-50: ${textContrastingColor}66;

    --tsw-white-contrasting-color: ${whiteContrastingColor}; /* A user color that contrasts with white */
    --tsw-white-contrasting-color-75: ${whiteContrastingColor}aa;
    --tsw-white-contrasting-color-50: ${whiteContrastingColor}66;

    --tsw-secondary-text-color: ${secondaryText};
    --tsw-link-color: ${text === DEFAULT_COLORS.text ? DEFAULT_COLORS.link : text};
    --tsw-font-family: "${bodyFont.fontFamily}";
    --tsw-header-font-family: "${headerFont.fontFamily}";
  }
  `}
      </style>
    </Helmet>);
};


const getColor = (color?: string): HexColor | null => {
  const hues = parseColorHues(color);
  return hues?.length ? `#${hues.join('')}` : null;
};

export default StyleMeta;
