import React from 'react';

import Button from 'src/shared/components/common/button';
import { ModalCloseButton } from 'src/shared/components/common/modal';

export const DeleteAccountModal = (props: any) => {
  return (
    <div className="DeleteAccountModal">
      <ModalCloseButton />
      <div className="content">
        <h2 className="deleteModalHeader">Delete my account</h2>
        <div className="deleteAccountModalContent">
          Your contact information, order history and payment information within your Toast account will be deleted across all Toast restaurants. Are you sure you want to delete your account?
        </div>
        <div className="modalButtonWrapper">
          <Button className="deleteAccountButton" onClick={props.onDelete}>Delete my account</Button>
          <br />
          <Button className="deleteAccountCancel" onClick={props.onClose}>Cancel</Button>
        </div>
      </div>
    </div>
  );
};
