import React, { useMemo } from 'react';

import classnames from 'classnames';

import Image from 'shared/components/common/Image';

import { resources } from 'config';

import AVGRating from './AVGRating';
import SimpleListing from './SimpleListing';

type HotSpotElement = {
  city: string;
  slug: string;
  shortBlurb?: string;
  avgRating?: number | null;
  numRXs?: number | null;
};

type Props = {
  title: string;
  titlePos?: 'left' | 'top';
  blurb?: string;
  iconProps: { src: string, alt: string, height: number };
  elements?: (HotSpotElement | null)[];
  elementClass?: string;
  ratingUnits?: string;
  className?: string;
};

const RXHotSpots = ({ title, titlePos = 'left', blurb, iconProps, elements, elementClass, ratingUnits = 'restaurants', className }: Props) => {
  const hotSpots = useMemo(() => {
    return elements?.map(el => {
      if(el) {
        const subtitle = <AVGRating rating={el.avgRating || 0} count={el.numRXs || 0} units={ratingUnits} />;
        return <SimpleListing title={el.city} linkToURL={`https://${resources.toastLocalHost}/local/${el.slug}-restaurants`} subtitle={subtitle}
          description={el.shortBlurb || ''} className={classnames(elementClass || 'pure-u-24-24 pure-u-md-12-24')} key={el.slug} />;
      }
      return null;
    });
  }, [elements, elementClass, ratingUnits]);

  const isLeftTitle = useMemo(() => titlePos == 'left', [titlePos]);

  return (
    <div className={classnames('rx-hot-spots', { 'pure-g': isLeftTitle }, className)}>
      <div className={classnames('title-section', { 'pure-u-24-24 pure-u-lg-6-24': isLeftTitle } )}>
        {iconProps && <Image {...iconProps} className="icon" />}
        <div className="title txt-body-xxl">{title}</div>
        {blurb && <p className="blurb">{blurb}</p>}
      </div>
      <div className={classnames('element-grid', { 'pure-u-24-24 pure-u-lg-18-24': isLeftTitle })}>
        <div className="pure-g hot-spots">{hotSpots}</div>
      </div>
    </div>
  );
};

export default RXHotSpots;
