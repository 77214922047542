import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';

import { useEditor } from '@toasttab/sites-components';

import { RequestContextProps } from 'src/lib/js/context';

import BaseErrorComponent from 'shared/components/base_error/BaseErrorComponent';

import StyleMeta, { StyleMetaProps } from 'public/components/default_template/meta/StyleMeta';

type Props = Partial<RequestContextProps> & {
  metaTitle?: boolean
  // An error message to be shown on the editor view of the 404 page.
  errorMessage?: string,
  meta?: StyleMetaProps
}


const NoMatch404 = ({ staticContext, metaTitle = true, errorMessage, meta }: Props) => {
  // Update the static routers status prop so the server can send the proper response code (serverside only)
  if(staticContext) { staticContext.statusCode = 404; }

  return (
    <Fragment>
      <StyleMeta {...meta} />
      {metaTitle &&
      <Helmet>
        <title>Page Not Found | 404</title>
      </Helmet>}

      <div><BaseErrorComponent errorCode={'404'} errorMessage={errorMessage || 'Sorry, we don’t serve that. This page is not found.'} showCta={false} /></div>
    </Fragment>
  );
};

const NoMatch404WithRouter = withRouter<RequestContextProps & Props, React.ComponentType<RequestContextProps & Props>>(NoMatch404);

const WrappedNoMatch404 = (props: Props) => {
  const { isEditor } = useEditor();
  return isEditor
    ? <NoMatch404 {...props} metaTitle={false} />
    : <NoMatch404WithRouter {...props} />;
};

export default WrappedNoMatch404;
