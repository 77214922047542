import React, { useCallback } from 'react';

import classnames from 'classnames';

type Side = 'right' | 'left';

type Option<T> = {
  name: string;
  subLabel?: string;
  icon?: React.ReactElement;
  value: T;
  disabled?: boolean;
  dataTestId?: string;
}

type Props<T> = { [key in Side]: Option<T> } & {
  value: Side;
  onChange: (value: T) => void;
  className?: string;
}

const Toggle = <T, >(props: Props<T>) => {
  const selected = props.value || (
    !props.left.disabled ?
      'left'
      : !props.right.disabled ?
        'right'
        : null);

  const hasDisabled = props.left.disabled || props.right.disabled;

  const select = useCallback((side: Side) => () => {
    if(!props[side].disabled && selected !== side) {
      props.onChange(props[side].value);
    }
  }, [props, selected]);

  return (
    <div className={classnames('toggle', selected, props.className, { hasDisabled })} role="group">
      <div className={classnames('switch')} />
      <button
        type="button"
        className={classnames('option', { selected: selected === 'left', disabled: props.left.disabled })}
        onClick={select('left')}
        data-testid={props.left.dataTestId}
        aria-pressed={selected === 'left'}
        aria-label={`Switch to ${props.left.name} ${props.left.subLabel ? props.left.subLabel : ''}`}>
        {props.left.icon ? props.left.icon : null}
        <div className="optionText">
          <div>{props.left.name}</div>
          {props.left.subLabel ? <div>{props.left.subLabel}</div> : null}
        </div>
      </button>
      <button
        type="button"
        className={classnames('option', { selected: selected === 'right', disabled: props.right.disabled })}
        onClick={select('right')}
        data-testid={props.right.dataTestId}
        aria-pressed={selected === 'right'}
        aria-label={`Switch to ${props.right.name} ${props.right.subLabel ? props.right.subLabel : ''}`}>
        {props.right.icon ? props.right.icon : null}
        <div className="optionText">
          <div>{props.right.name}</div>
          {props.right.subLabel ? <div>{props.right.subLabel}</div> : null}
        </div>
      </button>
    </div>
  );
};

export default Toggle;
