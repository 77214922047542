import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const useForceUpdate = () => {
  const unloadingRef = React.useRef(false);
  const [count, setCount] = React.useState(0);

  useEffect(() => () => {
    unloadingRef.current = true;
  }, []);

  return React.useCallback(() => {
    if(!unloadingRef.current) {
      setCount(count + 1);
    }
  }, [count]);
};

type Props = {
  allowScroll?: boolean;
}

const Portal = ({ children, allowScroll }: React.PropsWithChildren<Props>) => {
  const tempNode = useRef<HTMLDivElement | null>(null);
  const portal = useRef<HTMLDivElement | null>(null);

  const forceUpdate = useForceUpdate();

  useLayoutEffect(() => {
    const doc = tempNode.current!.ownerDocument;
    const host = doc.body;
    if(!tempNode.current || !host) return;

    const content = doc.getElementById('content');

    if(!allowScroll && content) {
      content.style.overflow = 'hidden';
      content.ariaHidden = 'true';
    }

    portal.current = doc.createElement('div');
    portal.current.className = 'PORTAL';

    host.appendChild(portal.current);
    forceUpdate();

    const portalNode = portal.current;
    return () => {
      if(host.contains(portalNode)) {
        host.removeChild(portalNode);
      }
      if(content) {
        content.style.overflow = 'visible';
        content.ariaHidden = 'false';
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On the client side, the init content must be empty, otherwise the component will mount twice.
  const isServerSide = typeof window === 'undefined';
  const initContent = isServerSide ? children : '';

  return portal.current
    ? createPortal(children, portal.current)
    : <span ref={tempNode}>{initContent}</span>;
};

export default Portal;
