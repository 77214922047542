import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { AnimatePresence } from 'framer-motion';

import { formatImageURL } from 'src/lib/js/utilities';

import Image from 'shared/components/common/Image';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from 'shared/components/common/modal';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  withTransitions?: boolean;
  name?: string | null;
  description?: string | null;
  image?: string;
};

type BodyProps = {
  name?: string | null;
  description?: string | null;
  image?: string;
}

const ModalBody = (props: BodyProps) => {
  const { name, description, image } = props;

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <div className="content">
          <ModalCloseButton className="itemModalCloseButton" />
          {image ?
            <div className="imageWrapper">
              {image ? <Image src={formatImageURL(image)} className="itemModalImage" eagerLoad /> : null}
            </div>
            : null}
          <div className="header">
            <h2 className="modalTitle">{name || <Skeleton />}</h2>
          </div>
          <div className="paddedContent">
            <p className="description">{description}</p>
          </div>
        </div>
      </AnimatePresence>
    </>
  );
};

const ReadOnlyItemModal = (props: Props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay fadeIn={props.withTransitions} fadeOut />
      <ModalContent wrapperClassName="itemModalWrapper" contentClassName="itemModalContent" slideIn={props.withTransitions} slideOut>
        <div className="itemModal">
          <ModalBody
            name={props.name}
            description={props.description}
            image={props.image} />
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ReadOnlyItemModal;
